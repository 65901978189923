import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateBlockMutation,
  UpdateBlockMutation,
  GetBlockQuery,
  ListBlocksQuery,
  DeleteBlockMutation,
  FulfilBlockMutation
} from 'src/API';
import { getBlock, listBlocks } from 'src/graphql/queries';
import {
  createBlock,
  updateBlock,
  deleteBlock,
  fulfilBlock
} from 'src/graphql/mutations';

export const listBlocksOfCustomerAPI = async (customerId) => {
  try {
    const blocks = await callGraphQL<ListBlocksQuery>(listBlocks, {
      variables: {
        customerId
      }
    });
    // console.log(blocks);
    return blocks;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listBlocksOfAppointmentAPI = async (appointmentId) => {
  try {
    const blocks = await callGraphQL<ListBlocksQuery>(listBlocks, {
      variables: {
        appointmentId
      }
    });
    // console.log(blocks);
    return blocks;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listBlocksOfClassAppointmentAPI = async (classAppointmentId) => {
  try {
    const blocks = await callGraphQL<ListBlocksQuery>(listBlocks, {
      variables: {
        classAppointmentId
      }
    });
    // console.log(blocks);
    return blocks;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getBlockAPI = async (blockId) => {
  try {
    const block = await callGraphQL<GetBlockQuery>(getBlock, {
      variables: {
        id: blockId
      }
    });
    // console.log(block);
    return block;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createBlockAPI = async ({
  customerId,
  name,
  locationId,
  classAppointmentId,
  category,
  subCategory,
  appointmentId = 'Customer'
}) => {
  try {
    const input = {
      customerId,
      appointmentId,
      locationId,
      classAppointmentId,
      name,
      category,
      subCategory
    };

    const newBlock = await callGraphQL<CreateBlockMutation>(createBlock, {
      variables: {
        input: input
      }
    });

    return newBlock;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateBlockAPI = async ({
  blockId,
  name,
  category,
  subCategory
}) => {
  try {
    const updatedBlock = await callGraphQL<UpdateBlockMutation>(updateBlock, {
      variables: {
        input: {
          blockId,
          name,
          category,
          subCategory
        }
      }
    });

    return updatedBlock;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteBlockAPI = async (blockId) => {
  try {
    const deletedBlock = await callGraphQL<DeleteBlockMutation>(deleteBlock, {
      variables: {
        id: blockId
      }
    });
    // console.log(deletedBlock);
    return deletedBlock;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const fulfilBlockAPI = async (blockId) => {
  try {
    const fulfiledBlock = await callGraphQL<FulfilBlockMutation>(fulfilBlock, {
      variables: {
        id: blockId
      }
    });
    // console.log(deletedBlock);
    return fulfiledBlock;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

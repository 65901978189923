import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'src/store';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  styled
} from '@mui/material';

import { deleteNoticeBoardMessage } from 'src/slices/noticeboard_messages';

import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function DeleteNoticeBoardMessage({
  noticeBoardMessageToDelete,
  openConfirmDelete,
  handleDeleteClose,
  handleDeleteSuccess
}) {
  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((state) => state.users);

  const handleCurrentDeleteClose = () => {
    handleDeleteClose();
  };

  const handleCurrentDeleteSuccess = () => {
    dispatch(deleteNoticeBoardMessage(noticeBoardMessageToDelete?.id));

    handleDeleteSuccess();
  };

  if (!noticeBoardMessageToDelete) {
    return null;
  }

  return (
    <>
      <Dialog
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        keepMounted
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick') {
            return;
          }

          handleCurrentDeleteClose();
        }}
      >
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={2}
              >
                <AvatarError>{<PriorityHighTwoToneIcon />}</AvatarError>
              </Box>
              <Typography
                align="center"
                sx={{
                  py: 1,
                  px: 2
                }}
                variant="h3"
              >
                {t('Are you sure you want to permanently delete this message')}?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            py: 3
          }}
        >
          <Box
            display="flex"
            width="1"
            flexDirection="row"
            justifyContent="center"
          >
            <Button
              sx={{
                mx: 1
              }}
              color="secondary"
              variant="outlined"
              onClick={handleCurrentDeleteClose}
            >
              {'No'}
            </Button>
            <Button
              sx={{
                mx: 1
              }}
              onClick={handleCurrentDeleteSuccess}
              // type="submit"
              // startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={
                loggedInUser?.id !== noticeBoardMessageToDelete.createdBy
              }
              variant="contained"
              color="error"
            >
              {'Yes'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteNoticeBoardMessage;

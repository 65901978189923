import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';
import { endOfToday } from 'date-fns';

import {
  createProviderNoteTemplateAPI,
  updateProviderNoteTemplateAPI,
  deleteProviderNoteTemplateAPI,
  listProviderNoteTemplatesAPI,
  getProviderNoteTemplateAPI
} from 'src/content/ProviderNoteTemplates/api';

interface ProviderNoteTemplateState {
  isLoadingNoteTemplates: boolean;
  isEditingNoteTemplate: boolean;
  isCreatingNoteTemplate: boolean;
  isDeletingNoteTemplate: boolean;
  allProviderNoteTemplatesList: any[];
  providerNoteTemplateDetail: any[];
  providerNoteTemplateErrorMessage: string;
  providerNoteTemplateSuccessMessage: string;
}

const initialState: ProviderNoteTemplateState = {
  isLoadingNoteTemplates: false,
  isEditingNoteTemplate: false,
  isCreatingNoteTemplate: false,
  isDeletingNoteTemplate: false,
  allProviderNoteTemplatesList: [],

  providerNoteTemplateDetail: [],
  providerNoteTemplateErrorMessage: '',
  providerNoteTemplateSuccessMessage: ''
};

// Sort by created time DSCending first
const sortProviderNoteTemplatesDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

// Sort by created time ASCending first
const sortProviderNoteTemplatesAsc = (a, b) => {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'appointmentPlanTemplates',
  initialState,
  reducers: {
    getProviderNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.providerNoteTemplateDetail = [action.payload];
      } else {
        state.providerNoteTemplateDetail = [{ id: 'Not Found' }];
      }
    },

    listProviderNoteTemplates(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      const allProviderNoteTemplatesList = action.payload;

      allProviderNoteTemplatesList.sort(sortProviderNoteTemplatesDesc);

      state.allProviderNoteTemplatesList = [...allProviderNoteTemplatesList];
    },

    createProviderNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      const newProviderNoteTemplate = action.payload;

      state.allProviderNoteTemplatesList = [
        newProviderNoteTemplate,
        ...state.allProviderNoteTemplatesList
      ];
    },

    updateProviderNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      const updatedTemplate = action.payload;

      state.providerNoteTemplateDetail =
        state.providerNoteTemplateDetail[0]?.id === updatedTemplate.id
          ? [updatedTemplate]
          : [...state.providerNoteTemplateDetail];

      let templateIndex = state.allProviderNoteTemplatesList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      // search all templates list
      if (templateIndex >= 0) {
        state.allProviderNoteTemplatesList =
          state.allProviderNoteTemplatesList.map((appt, i) =>
            i === templateIndex ? action.payload : appt
          );
      }
    },

    deleteProviderNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.allProviderNoteTemplatesList =
        state.allProviderNoteTemplatesList.filter(
          (template) => template.id !== action.payload.id
        );
    },

    clearSelectedProviderNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.providerNoteTemplateDetail = [];
    },

    setIsLoadingNoteTemplates(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.isLoadingNoteTemplates = action.payload;
    },

    setIsEditingNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.isEditingNoteTemplate = action.payload;
    },

    setIsCreatingNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.isCreatingNoteTemplate = action.payload;
    },

    setIsDeletingNoteTemplate(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.isDeletingNoteTemplate = action.payload;
    },

    setErrorMessage(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.providerNoteTemplateErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: ProviderNoteTemplateState,
      action: PayloadAction<any>
    ) {
      state.providerNoteTemplateSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getProviderNoteTemplate =
  (templateId): AppThunk =>
  async (dispatch) => {
    const response = await getProviderNoteTemplateAPI(templateId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getProviderNoteTemplate(
          response.data.getProviderNoteTemplate
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listProviderNoteTemplates =
  (providerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingNoteTemplates());

    const response = await backOff(
      () => listProviderNoteTemplatesAPI(providerId),
      {
        numOfAttempts: 3
      }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listProviderNoteTemplates(
          response.data.listProviderNoteTemplates
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingNoteTemplates());
  };

export const createProviderNoteTemplate =
  (template): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingNoteTemplate());
    dispatch(startLoadingNoteTemplates());

    const response = await createProviderNoteTemplateAPI(template);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createProviderNoteTemplate(
          response.data.createProviderNoteTemplate
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Template successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingNoteTemplate());
      dispatch(stopLoadingNoteTemplates());
    };

    return followUp();
  };

export const updateProviderNoteTemplate =
  (template): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingNoteTemplate());

    const response = await updateProviderNoteTemplateAPI(template);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateProviderNoteTemplate(
          response.data.updateProviderNoteTemplate
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Template successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingNoteTemplate());
  };

export const deleteProviderNoteTemplate =
  (templateId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingNoteTemplates());
    dispatch(startDeletingNoteTemplate());

    const response = await deleteProviderNoteTemplateAPI(templateId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteProviderNoteTemplate(
          response.data.deleteProviderNoteTemplate
        )
      );

      dispatch(
        slice.actions.setSuccessMessage('Template successfully deleted')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingNoteTemplate());
      dispatch(stopLoadingNoteTemplates());
    };

    return followUp();
  };

export const clearSelectedProviderNoteTemplate =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedProviderNoteTemplate({}));
  };

export const startLoadingNoteTemplates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingNoteTemplates(true));
};

export const stopLoadingNoteTemplates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingNoteTemplates(false));
};

export const startEditingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingNoteTemplate(true));
};

export const stopEditingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingNoteTemplate(false));
};

export const startCreatingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingNoteTemplate(true));
};

export const stopCreatingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingNoteTemplate(false));
};

export const startDeletingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingNoteTemplate(true));
};

export const stopDeletingNoteTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingNoteTemplate(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

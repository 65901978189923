import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';

import {
  getPassAPI,
  listPassesAPI,
  createPassAPI,
  updatePassAPI
} from 'src/content/Passes/api';

interface PassState {
  isLoadingPasses: boolean;
  isEditingPass: boolean;
  isCreatingPass: boolean;
  allPassesList: any[];
  activePassesList: any[];
  inactivePassesList: any[];
  passDetail: any[];
  passErrorMessage: string;
  passSuccessMessage: string;
}

const initialState: PassState = {
  isLoadingPasses: false,
  isEditingPass: false,
  isCreatingPass: false,
  allPassesList: [],
  activePassesList: [],
  inactivePassesList: [],
  passDetail: [],
  passErrorMessage: '',
  passSuccessMessage: ''
};

const slice = createSlice({
  name: 'passes',
  initialState,
  reducers: {
    getAllPasses(state: PassState, action: PayloadAction<any>) {
      const allPassesList = action.payload;

      state.allPassesList = [...allPassesList];

      state.activePassesList = [
        ...allPassesList.filter((pass) => pass.isActive === true)
      ];

      state.inactivePassesList = [
        ...allPassesList.filter((pass) => pass.isActive === false)
      ];

      state.isLoadingPasses = false;
    },

    getActivePasses(state: PassState, action: PayloadAction<any>) {
      const activePassesList = action.payload;

      state.activePassesList = [...activePassesList];

      state.isLoadingPasses = false;
    },

    getInactivePasses(state: PassState, action: PayloadAction<any>) {
      const inactivePassesList = action.payload;

      state.inactivePassesList = [...inactivePassesList];

      state.isLoadingPasses = false;
    },

    getPass(state: PassState, action: PayloadAction<any>) {
      if (action.payload) {
        state.passDetail = [action.payload];
      } else {
        state.passDetail = [{ id: 'Not Found' }];
      }
    },

    updatePass(state: PassState, action: PayloadAction<any>) {
      const editedPass = action.payload;

      let activePassIndex = state.activePassesList.findIndex(
        (obj) => obj.id === editedPass.id
      );

      if (activePassIndex >= 0) {
        state.activePassesList = state.activePassesList.map((pass, i) =>
          i === activePassIndex ? editedPass : pass
        );
      }

      let inactivePassIndex = state.inactivePassesList.findIndex(
        (obj) => obj.id === editedPass.id
      );

      if (activePassIndex >= 0) {
        state.inactivePassesList = state.inactivePassesList.map((pass, i) =>
          i === inactivePassIndex ? editedPass : pass
        );
      }

      if (action.payload) {
        state.passDetail = [action.payload];
      } else {
        state.passDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedPass(state: PassState, action: PayloadAction<any>) {
      state.passDetail = [];
    },

    createPass(state: PassState, action: PayloadAction<any>) {
      const newPass = action.payload;

      state.allPassesList = [newPass, ...state.allPassesList];

      if (newPass.isActive) {
        state.activePassesList = [newPass, ...state.activePassesList];
      }
    },

    setIsLoadingPasses(state: PassState, action: PayloadAction<any>) {
      state.isLoadingPasses = action.payload;
    },

    setIsEditingPass(state: PassState, action: PayloadAction<any>) {
      state.isEditingPass = action.payload;
    },

    setIsCreatingPass(state: PassState, action: PayloadAction<any>) {
      state.isCreatingPass = action.payload;
    },

    setErrorMessage(state: PassState, action: PayloadAction<any>) {
      state.passErrorMessage = action.payload;
    },

    setSuccessMessage(state: PassState, action: PayloadAction<any>) {
      state.passSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getAllPasses = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingPasses());

  const response = await backOff(() => listPassesAPI(null, null, null), {
    numOfAttempts: 3
  });
  if (!response.customErrorMessage) {
    dispatch(slice.actions.getAllPasses(response.data.listPasses));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingPasses());
};

export const getAllActivePasses = (): AppThunk => async (dispatch) => {
  const response = await backOff(() => listPassesAPI(null, null, true), {
    numOfAttempts: 3
  });
  if (!response.customErrorMessage) {
    dispatch(slice.actions.getActivePasses(response.data.listPasses));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const getAllInactivePasses =
  (serviceIds, classServiceIds): AppThunk =>
  async (dispatch) => {
    const response = await backOff(() => listPassesAPI(null, null, false), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getInactivePasses(response.data.listPasses));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getPass =
  (passId): AppThunk =>
  async (dispatch) => {
    const response = await getPassAPI(passId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPass(response.data.getPass));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedPass({}));
};

export const createPass =
  (pass): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingPass());
    dispatch(startLoadingPasses());

    const response = await createPassAPI(pass);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createPass(response.data.createPass));
      dispatch(slice.actions.setSuccessMessage('Pass successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingPass());
      dispatch(stopLoadingPasses());
    };

    return followUp();
  };

export const updatePass =
  (pass): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingPass());

    const response = await updatePassAPI(pass);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.updatePass(response.data.updatePass));
      dispatch(slice.actions.setSuccessMessage('Pass successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingPass());
  };

export const startLoadingPasses = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPasses(true));
};

export const stopLoadingPasses = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPasses(false));
};

export const startEditingPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPass(true));
};

export const stopEditingPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPass(false));
};

export const startCreatingPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPass(true));
};

export const stopCreatingPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPass(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const customGetClassAppointment = /* GraphQL */ `
  query GetClassAppointment($id: ID!) {
    getClassAppointment(id: $id) {
      id
      classServiceId
      classService {
        id
        locationId
        practitionerId
        name
        code
        description
        category
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
      }
      practitionerId
      practitioner {
        id
        username
        type
        preferredName
      }
      locationId
      location {
        id
        code
      }
      classAppointmentDate
      classAppointmentStartTime
      classAppointmentEndTime
      classPublicNotes
      maxParticipants
      status
      recurId
      patientIds
      classNotes
      isBookingAllowed
      recurFrequency
      recurStartDate
      recurEndDate
      recurInstances
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetClassAppointmentInternalNote = /* GraphQL */ `
  query GetClassAppointment($id: ID!) {
    getClassAppointment(id: $id) {
      id
      classNotes
    }
  }
`;

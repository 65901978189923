import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  getGlobalUISettingsAPI,
  storeGlobalUISettingsAPI
} from 'src/content/Settings/api';
import { storeCalendarSettings } from './calendar';
import { storeTimeOffCalendarSettings } from './timeOff';
import { storeApptCalendarSettings } from './appointments';
import { storeClassApptCalendarSettings } from './class_appointments';

interface SettingsState {
  globalUISettings: any;
  globalUICurrentTab: string;
  settingsErrorMessage: string;
  settingsSuccessMessage: string;
}

const initialState: SettingsState = {
  globalUISettings: {},
  globalUICurrentTab: '',
  settingsErrorMessage: '',
  settingsSuccessMessage: ''
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getGlobalUISettings(state: SettingsState, action: PayloadAction<any>) {
      // const settings = JSON.parse(action.payload?.content);

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings);
      state.globalUISettings = { ...settings };
    },

    setGlobalUICurrentTab(state: SettingsState, action: PayloadAction<any>) {
      // console.log('payload')
      // console.log(action.payload)
      state.globalUICurrentTab = action.payload;
    },

    setErrorMessage(state: SettingsState, action: PayloadAction<any>) {
      state.settingsErrorMessage = action.payload;
    },

    setSuccessMessage(state: SettingsState, action: PayloadAction<any>) {
      state.settingsSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getGlobalUISettings = (): AppThunk => async (dispatch) => {
  const response = await getGlobalUISettingsAPI();
  // console.log(response);
  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.getGlobalUISettings(response.data.getGlobalUISettings)
    );

    dispatch(storeCalendarSettings(response.data.getGlobalUISettings));
    dispatch(storeTimeOffCalendarSettings(response.data.getGlobalUISettings));
    dispatch(storeApptCalendarSettings(response.data.getGlobalUISettings));
    dispatch(storeClassApptCalendarSettings(response.data.getGlobalUISettings));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const storeGlobalUISettings =
  (settings): AppThunk =>
  async (dispatch) => {
    const settingsString = JSON.stringify(settings);
    // console.log(settingsString);
    const response = await storeGlobalUISettingsAPI({
      content: settingsString
    });
    console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getGlobalUISettings(response.data.storeGlobalUISettings)
      );
      dispatch(slice.actions.setSuccessMessage('Settings successfully stored'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return window.location.reload();
  };

export const setGlobalUICurrentTab =
  (tabName): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setGlobalUICurrentTab(tabName));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );



// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const GenericSuccess = Loader(
  lazy(() => import('src/content/HelmOverview/GenericSuccess'))
);
const GenericError = Loader(
  lazy(() => import('src/content/HelmOverview/GenericError'))
);



const baseRoutes = [
  {
    path: '/',
    // element: <Overview />
    // Force navingation to Home Page of the App.
    element: <Navigate to="/app/overview" replace />
    // element: <Navigate to="maintenance" replace />
    
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: 'status',
    children: [
      {
        path: '/',
        element: <Navigate to="404" replace />
      },
      
      // {
      //   path: '500',
      //   element: <Status500 />
      // },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'success',
        element: <GenericSuccess />
      },
      {
        path: 'error',
        element: <GenericError />
      },
      // {
      //   path: 'coming-soon',
      //   element: <StatusComingSoon />
      // }
      {
        path: '',
        element: <Status404 />
      },
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;

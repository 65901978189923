import { useState, FC, useEffect, useRef } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';
import { useWatch } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import {
  HookFormAutocompleteField,
  HookFormCheckboxField,
  HookFormInputField,
  HookFormMultilineInputField,
  HookFormDateTimePickerField
} from 'src/components/FormFields';
import {
  addMinutes,
  addDays,
  startOfDay,
  parseISO,
  isEqual,
  format,
  lastDayOfMonth,
  startOfMonth,
  endOfMonth,
  addMonths,
  areIntervalsOverlapping,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  differenceInMinutes,
  isAfter
} from 'date-fns';
import {
  Alert,
  TextField,
  Grid,
  Box,
  Button,
  Typography,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
import MoreTimeTwoToneIcon from '@mui/icons-material/MoreTimeTwoTone';
import {
  listFreeAppointmentBookingSlots,
  clearFreeAppointmentBookingSlots,
  getPractitionerSchedule,
  clearPractitionerSchedule
} from 'src/slices/appointments';

import {
  getPractitionerClassSchedule,
  clearPractitionerClassSchedule
} from 'src/slices/class_appointments';

import {
  getPractitionerTimeOff,
  clearPractitionerTimeOffEvents
} from 'src/slices/timeOff';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import tenantMappings from 'src/utils/tenantMappings';

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(2)};

    & .fc-license-message {
      display: none;
    }

    .fc-media-screen .fc-timegrid-now-indicator-container {
      position: unset;
    }
  
    .fc .fc-timegrid-now-indicator-line {
      border-bottom-width: 1px; 
      border-top-width: 1px;
      border-color: red;
      background-color: red;
    }
  
    // Day view
    .fc-resourceTimeGridDay-view  .fc-timegrid-now-indicator-arrow {
      position: absolute;
      border: none;
      left: unset;
      right: -6px;
      width: 12px;
      height: 12px;
      background-color: red;
      border-radius: 50%;
    }
     
    // Week view
    .fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
      display: none;
    }
  
    .fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      width: 12px;
      height: 12px;
      background-color: red;
      border-radius :50%;
    }
    .fc {

      .fc-col-header-cell {
        padding: ${theme.spacing(1)};
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover,
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: ${theme.colors.alpha.black[5]} !important;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[10]} !important;
        color: ${theme.colors.alpha.black[70]} !important;
      }

      &.fc-theme-standard td,
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }

      .fc-timegrid-slot {
        height: 30px !important
    }
    }
`
);

const dictionaryMappings = tenantMappings?.dictionaryMappings;
const appointmentMappings = tenantMappings?.appointmentMappings;

const CreateAppointmentForm: FC<any> = ({
  control,
  errors,
  watch,
  setValue,
  getValues,
  rescheduleOption = false,
  fromCalendar = false
}) => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const mappedWordsToUse =
    dictionaryMappings?.getMappedWordToUse(tenantIdLowerCase);
  const getMappedWordToUse = (word) => {
    const fallback = word;

    const result = mappedWordsToUse[word];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  };

  const { practitionerTimeOffEvents } = useSelector((state) => state.timeoff);
  const { appointmentFreeSlots, providerSchedule } = useSelector(
    (state) => state.appointments
  );
  const { practitionerClassSchedule } = useSelector(
    (state) => state.classAppointments
  );
  const { activeServicesList } = useSelector((state) => state.services);
  const { activeLocationDetails } = useSelector((state) => state.locations);
  const { loggedInUser, providerOptions } = useSelector((state) => state.users);

  const [reducedHoursShown, setReducedHoursShown] = useState(true);
  const [editFirstTouch, setEditFirstTouch] = useState(true);
  const calendarRef = useRef<FullCalendar | null>(null);

  const [dateValue, setDateValue] = useState(new Date());

  const [filteredPractitioners, setFilteredPractitioners] =
    useState(providerOptions);
  const [serviceOptions, setServiceOptions] = useState([
    { label: '', value: null, duration: 0 }
  ]);

  const locationOptions = activeLocationDetails
    ?.filter((loc) => loggedInUser?.allowedLocationIds?.includes(loc.id))
    ?.map((loc) => ({
      label: loc.name,
      value: loc.id
    }));

  const selectedApptStartTime = useWatch({
    control,
    name: 'appointmentStartTime'
  });

  const selectedApptEndTime = useWatch({
    control,
    name: 'appointmentEndTime'
  });

  const selectedLocationId = useWatch({
    control,
    name: 'locationId'
  });

  const selectedPractitionerId = useWatch({
    control,
    name: 'practitionerId'
  });

  const selectedRecurFrequency = useWatch({
    control,
    name: 'recurFrequency'
  });

  const filteredServices = () => {
    // console.log('filteredServices ran');
    // console.log('selectedLocationId', selectedLocationId);
    // console.log('selectedPractitionerId', selectedPractitionerId);

    // First filter list of services by selected location
    const filteredServicesByLocation = activeServicesList?.filter(
      (service) => service?.locationId === selectedLocationId
    );

    // Reset Practitioner Options and choose first one to setValue and setSelectedPrac
    const practitionersByServiceLocation = [
      ...new Set(filteredServicesByLocation?.map((item) => item.practitionerId))
    ];

    const filteredPracOptions = providerOptions?.filter((prac) =>
      practitionersByServiceLocation?.includes(prac.value)
    );
    setFilteredPractitioners(filteredPracOptions);

    // if practitioner is not in filtered options list, set default to first in list. othewise keep practitioner (do nothing)
    if (
      !practitionersByServiceLocation?.includes(selectedPractitionerId) &&
      filteredPracOptions?.length > 0
    ) {
      setValue('practitionerId', filteredPracOptions?.[0]?.value);
      // setValue('practitionerId', null);
    }

    // Generate New service options based on above practitioner and location combination
    const filteredServicesByLocationPractitioner =
      filteredServicesByLocation?.filter(
        (service) =>
          service?.practitionerId ===
          (!practitionersByServiceLocation?.includes(selectedPractitionerId)
            ? filteredPracOptions?.[0]?.value
            : selectedPractitionerId)
      );

    const filterServicesOptions = filteredServicesByLocationPractitioner?.map(
      (service) => ({
        label: service.name,
        value: service.id,
        duration: service.duration
      })
    );

    // Reset Service Options to what is available
    setServiceOptions([
      { label: '', value: null, duration: 0 },
      ...filterServicesOptions
    ]);
    // setValue('serviceId', null);
  };

  const reducedHours = ['07:00', '22:30'];

  const sgFreeSlots = appointmentFreeSlots
    .filter((timeslot) => isAfter(parseISO(timeslot.startTime), new Date()))
    .map((timeslot) => ({
      ...timeslot,
      startTime: parseISO(timeslot.startTime),
      endTime: parseISO(timeslot.endTime)
    }));

  const uniqueDates = sgFreeSlots.filter(
    (date, i, self) =>
      self.findIndex((d) =>
        isEqual(startOfDay(d.startTime), startOfDay(date.startTime))
      ) === i
  );

  const shouldDisableDate = (date) => {
    // return date.getDay() === 0 || date.getDay() === 6;
    return !uniqueDates.find((uniqueDate) =>
      isEqual(startOfDay(uniqueDate.startTime), startOfDay(date))
    );
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'locationId' && type === 'change') {
        // console.log(`location is ${value.locationId}`);

        // setSelectedLocationId(value.locationId);

        setValue('serviceId', null);
        dispatch(clearFreeAppointmentBookingSlots());
      }

      if (name === 'practitionerId' && type === 'change') {
        // console.log(`practitioner is ${value.practitionerId}`);

        // setSelectedPractitionerId(value.practitionerId);

        setValue('serviceId', null);
        dispatch(clearFreeAppointmentBookingSlots());
      }

      if (name === 'appointmentStartTime' && type === 'change') {
        // console.log(`start time is ${value.appointmentStartTime}`);

        if (value.serviceId) {
          const serviceDuration = serviceOptions.find(
            (service) => service.value === value.serviceId
          )
            ? serviceOptions.find(
                (service) => service.value === value.serviceId
              ).duration
            : 60;

          const newEndDate = addMinutes(
            value.appointmentStartTime,
            serviceDuration
          );

          // console.log(`end time is ${newEndDate}`);
          setValue('appointmentEndTime', newEndDate);
        }
      }
      if (name === 'serviceId' && type === 'change') {
        onSearchSubmit(new Date());
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, serviceOptions]);

  const onSearchSubmit = (startDate) => {
    const selectedService = getValues('serviceId');
    if (selectedService === null || selectedService === '') {
      // console.log('Service not selected');
      return null;
    } else if (
      serviceOptions.find(
        (option) => option.value === selectedService && option.duration > 0
      )
    ) {
      const endDate = addDays(lastDayOfMonth(startDate), 1);
      dispatch(
        listFreeAppointmentBookingSlots(
          selectedService,
          format(startDate, 'yyyy-MM-dd'),
          format(endDate, 'yyyy-MM-dd')
        )
      );
    } else {
      // console.log('Service not found');
      return null;
    }
  };

  const checkPractitionerSchedule = (inputDate) => {
    const twoMonthsLater = addMonths(inputDate, 2);
    dispatch(
      getPractitionerSchedule(
        format(endOfMonth(twoMonthsLater), 'yyyy-MM-dd'),
        format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        getValues('practitionerId')
      )
    );

    dispatch(
      getPractitionerClassSchedule(
        format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        format(endOfMonth(twoMonthsLater), 'yyyy-MM-dd'),

        getValues('practitionerId')
      )
    );

    if (getValues('practitionerId') && getValues('locationId')) {
      dispatch(
        getPractitionerTimeOff(
          [getValues('practitionerId')],
          startOfMonth(new Date()),
          endOfMonth(twoMonthsLater),
          [getValues('locationId')]
        )
      );
    }

    // If the practitioner changes mid-way, just reset the date to today
    setDateValue(new Date());
  };

  useEffect(() => {
    // dont start the useEffect items until allServices have been fetched
    if (activeServicesList?.length === 0) {
      return;
    }

    filteredServices();
  }, [activeServicesList]);

  useEffect(() => {
    if (selectedLocationId && providerOptions?.length > 0) {
      filteredServices();
    }
  }, [selectedLocationId, selectedPractitionerId, providerOptions?.length]);

  useEffect(() => {
    if (getValues('practitionerId') !== '' && getValues('practitionerId')) {
      checkPractitionerSchedule(new Date());
    }
  }, [getValues('practitionerId'), getValues('locationId')]);

  // For edit form, on load, if there is a service selected. Find some free slots
  useEffect(() => {
    if (
      getValues('practitionerId') !== '' &&
      getValues('serviceId') !== '' &&
      getValues('practitionerId') &&
      getValues('serviceId')
    ) {
      onSearchSubmit(startOfMonth(selectedApptStartTime));
      // console.log('Since this is edit - make the calendar switch the appointment current date')
      setDateValue(selectedApptStartTime);
    }

    // Adding serviceOptions to dependency so that it runs properly for edit appt
    // Reason is - serviceId gets set to Null until there are legit serviceOptions received.
    // So we need this to run when serviceOptions is updated.
    // For Create and subsequent edits, this will not be triggered cos serviceId is always set to null when there is a change in location or practitioner
    // Basically adding serviceOptions into dependency array solves for the list free slots not firing when editing an appt
  }, [serviceOptions]);

  // for component will unmount
  useEffect(
    () => () => {
      dispatch(clearFreeAppointmentBookingSlots());
      dispatch(clearPractitionerSchedule());
      dispatch(clearPractitionerClassSchedule());
      dispatch(clearPractitionerTimeOffEvents());
    },
    []
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
        <Grid
          container
          spacing={0}
          rowGap={1.5}
          sx={{
            px: { xs: 0.5, sm: 3 },
            py: 2
          }}
          // alignItems="center"
          // justifyContent="center"
          flexDirection="column"
        >
          <Grid
            item
            container
            spacing={0}
            flexDirection="row"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} md={5}>
              <HookFormAutocompleteField
                name="locationId"
                label="Location"
                options={locationOptions}
                control={control}
                errors={errors}
                rules={{ required: 'Location is required.' }}
                notClearable={true}
                // disabled={loggedInUser?.type?.toLowerCase() === 'practitioner'}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0}
            flexDirection="row"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} md={5}>
              <HookFormAutocompleteField
                name="practitionerId"
                label={getMappedWordToUse('Practitioner')}
                options={[...filteredPractitioners, { label: '', value: null }]}
                control={control}
                errors={errors}
                rules={{
                  required: `${getMappedWordToUse('Practitioner')} is required.`
                }}
                notClearable={true}
                disabled={loggedInUser?.type?.toLowerCase() === 'practitioner'}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0}
            flexDirection="row"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} md={5}>
              <HookFormAutocompleteField
                name="serviceId"
                label="Service"
                options={serviceOptions}
                control={control}
                errors={errors}
                rules={{
                  required: 'Service is required.'
                }}
                notClearable={false}
              />
            </Grid>
          </Grid>

          {/* Old codes */}
          {/* <Grid item xs={12}>
            <HookFormAutocompleteField
              name="locationId"
              label="Location"
              options={locationOptions}
              control={control}
              errors={errors}
              rules={{ required: 'Location is required.' }}
              notClearable={true}
              disabled={loggedInUser?.type?.toLowerCase() === 'practitioner'}
            />
          </Grid>

          <Grid item xs={12}>
            <HookFormAutocompleteField
              name="practitionerId"
              label={getMappedWordToUse('Practitioner')}
              options={practitionerOptions}
              control={control}
              errors={errors}
              rules={{
                required: `${getMappedWordToUse('Practitioner')} is required.`
              }}
              notClearable={true}
              disabled={loggedInUser?.type?.toLowerCase() === 'practitioner'}
            />
          </Grid>

          <Grid item xs={12}>
            <HookFormAutocompleteField
              name="serviceId"
              label="Service"
              options={serviceOptions}
              control={control}
              errors={errors}
              rules={{
                required: 'Service is required.'
              }}
              notClearable={false}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={8}>
            <HookFormAutocompleteField
              name="type"
              label="Type"
              options={
                formOptions?.appointmentTypeOptions || [
                  { label: '', value: null }
                ]
              }
              control={control}
              errors={errors}
              rules={{
                required: 'Appointment Type is required.'
              }}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Divider sx={{ my: 0 }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={0} rowGap={1.5}>
          <Grid item xs={12}>
            <Tooltip
              arrow
              placement="top"
              title={
                reducedHoursShown
                  ? 'Show more timeslots'
                  : 'Show less timeslots'
              }
              // sx={{ ml: 4, mb: -3, mt: -2 }}
              sx={{ ml: 1, mb: -3 }}
            >
              <IconButton
                color="primary"
                onClick={() => setReducedHoursShown(!reducedHoursShown)}
              >
                <MoreTimeTwoToneIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={12}>
            {dateValue && (
              <FullCalendarWrapper>
                <FullCalendar
                  allDayMaintainDuration
                  initialDate={dateValue}
                  // initialView="timeGridDay"
                  droppable={false}
                  editable={false}
                  eventDisplay="block"
                  slotMinTime={reducedHoursShown ? reducedHours[0] : '00:00'}
                  slotMaxTime={reducedHoursShown ? reducedHours[1] : '24:00'}
                  // eventClick={handleEventSelect}
                  // eventDrop={handleEventDrop}
                  // eventMouseEnter={(arg) => {
                  //   console.log(arg.event.title);
                  // }}

                  dayMaxEventRows={4}
                  eventResizableFromStart={false}
                  // eventResize={handleEventResize}

                  events={[
                    ...providerSchedule.filter(
                      (event) =>
                        !(
                          (event?.status === 'Cancelled')
                          // ||
                          // event?.status === 'NoShow'
                        )
                    ),
                    ...practitionerClassSchedule.filter(
                      (event) =>
                        !(
                          (event?.status === 'Cancelled')
                          // ||
                          // event?.status === 'NoShow'
                        )
                    ),
                    ...practitionerTimeOffEvents
                  ]}
                  // eventShortHeight={50}
                  eventContent={(eventInfo) => {
                    // console.log(eventInfo)
                    return (
                      <div
                        style={{
                          overflow: 'hidden',
                          height: '100%',
                          width: '100%',
                          fontSize: '9px',
                          color:
                            eventInfo.event._def.extendedProps.type ===
                              'holiday' ||
                            eventInfo.event._def.extendedProps.type ===
                              'Time Off'
                              ? '#f5f5f5'
                              : 'black',
                          borderLeft:
                            eventInfo.event._def.extendedProps.status ===
                              'PaymentPending' ||
                            eventInfo.event._def.extendedProps.status ===
                              'Pending'
                              ? '4px solid red'
                              : eventInfo.event._def.extendedProps.status ===
                                'N/A'
                              ? ''
                              : '4px solid #72F401',
                          // borderRight:
                          //   eventInfo.event._def.extendedProps.type ===
                          //     'Initial Visit' ||
                          //   eventInfo.event._def.extendedProps.type ===
                          //     'Follow-Up Visit'
                          //     ? '4px solid yellow'
                          //     : '4px solid blue',
                          // borderTop: '4px solid red',
                          paddingLeft: '2px'
                        }}
                      >
                        {eventInfo.event._def.extendedProps.type ===
                        'holiday' ? (
                          <b>{eventInfo.event.title.toUpperCase()}</b>
                        ) : eventInfo.event._def.extendedProps.type ===
                          'Time Off' ? (
                          <>
                            <b>
                              {eventInfo.event._def.extendedProps.category.toUpperCase()}
                            </b>
                            <br />
                            {`${format(
                              parseISO(
                                eventInfo.event._def.extendedProps
                                  .eventStartTime
                              ),
                              'HH:mm'
                            )} - ${format(
                              parseISO(
                                eventInfo.event._def.extendedProps.eventEndTime
                              ),
                              'HH:mm'
                            )}`}
                            <br />
                            {`${eventInfo.event._def?.title} ${eventInfo.event._def?.extendedProps.description}`}
                          </>
                        ) : (
                          <>
                            <b>{`${
                              eventInfo.event._def.extendedProps.status ===
                              'NoShow'
                                ? '🔴'
                                : ''
                            } ${eventInfo.event.title.toUpperCase()}`}</b>
                            <br />
                            {`${format(
                              parseISO(
                                eventInfo.event._def.extendedProps
                                  .eventStartTime
                              ),
                              'HH:mm'
                            )} - ${format(
                              parseISO(
                                eventInfo.event._def.extendedProps.eventEndTime
                              ),
                              'HH:mm'
                            )}`}
                            <br />
                            {eventInfo.event._def.extendedProps?.description}
                          </>
                        )}
                      </div>
                    );
                  }}
                  headerToolbar={false}
                  height={'auto'}
                  eventMinHeight={30}
                  ref={calendarRef}
                  rerenderDelay={10}
                  // select={handleRangeSelect}
                  // selectable
                  weekends
                  nowIndicator={true}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    // interactionPlugin,
                    listPlugin
                  ]}
                  views={{
                    gridThreeDay: {
                      type: 'timeGrid',
                      duration: { days: 3 },
                      buttonText: '3 day',
                      dayHeaderContent: (args) =>
                        format(args.date, 'EEE, dd MMM')
                    },
                    gridOneDay: {
                      type: 'timeGrid',
                      duration: { days: 1 },
                      buttonText: '1 day',
                      dayHeaderContent: (args) =>
                        format(args.date, 'EEE, dd MMM')
                    }
                  }}
                  initialView={isMobile ? 'gridOneDay' : 'gridThreeDay'}
                />
              </FullCalendarWrapper>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid
          container
          spacing={1}
          rowGap={1.5}
          sx={{ px: { xs: 0.5, sm: 3 }, py: 2, justifyContent: 'center' }}
        >
          <Grid item xs={12} md={12}>
            <StaticDatePicker
              disabled={Boolean(getValues('serviceId') === null)}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={dateValue}
              onChange={(newValue) => {
                // console.log('date picker on change')
                // console.log(newValue)

                const changedValue =
                  editFirstTouch && (rescheduleOption || fromCalendar)
                    ? selectedApptStartTime
                    : newValue;

                setDateValue(changedValue);

                const calItem = calendarRef.current;
                calItem.getApi().gotoDate(changedValue);

                const serviceDuration =
                  getValues('serviceId') &&
                  serviceOptions.find(
                    (service) => service.value === getValues('serviceId')
                  )
                    ? serviceOptions.find(
                        (service) => service.value === getValues('serviceId')
                      ).duration
                    : 60;

                if (getValues('serviceId') && !editFirstTouch) {
                  const newStartDateTime = setMinutes(
                    setHours(
                      changedValue,
                      getHours(getValues('appointmentStartTime'))
                    ),
                    getMinutes(getValues('appointmentStartTime'))
                  );
                  setValue('appointmentStartTime', newStartDateTime);
                  setValue(
                    'appointmentEndTime',
                    addMinutes(newStartDateTime, serviceDuration)
                  );
                } else if (getValues('serviceId') && editFirstTouch) {
                  const newStartDateTime = setMinutes(
                    setHours(
                      changedValue,
                      getHours(getValues('appointmentStartTime'))
                    ),
                    getMinutes(getValues('appointmentStartTime'))
                  );
                  setValue('appointmentStartTime', newStartDateTime);
                  setValue('appointmentEndTime', selectedApptEndTime);
                }

                if (editFirstTouch && rescheduleOption) {
                  setEditFirstTouch(false);
                }
              }}
              onMonthChange={(monthStart) => {
                // console.log(monthStart);
                onSearchSubmit(monthStart);
                // if (!(getValues('practitionerId') === '')) {
                //   checkPractitionerSchedule(new Date());
                // }
              }}
              renderInput={(params) => <TextField {...params} />}
              //  renderDay={(day, value, DayComponentProps) => {

              //  }}
              shouldDisableDate={shouldDisableDate}
              minDate={new Date()}
              maxDate={new Date().setMonth(new Date().getMonth() + 3)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box sx={{ maxHeight: '160px', overflow: 'auto' }}>
              {sgFreeSlots
                .filter((dayValue) =>
                  isEqual(startOfDay(dayValue.startTime), startOfDay(dateValue))
                )
                // .slice(0, 15)
                .map((timeslot, index) => (
                  <Button
                    size="small"
                    key={format(timeslot.startTime, 'ddMMMyyyy') + index}
                    variant={
                      isEqual(timeslot.startTime, selectedApptStartTime) &&
                      isEqual(timeslot.endTime, selectedApptEndTime)
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={{ m: 0.25, p: 0.75 }}
                    onClick={() => {
                      const serviceDuration = serviceOptions.find(
                        (service) => service.value === getValues('serviceId')
                      )
                        ? serviceOptions.find(
                            (service) =>
                              service.value === getValues('serviceId')
                          ).duration
                        : 60;
                      // console.log(
                      //   `service chosen: ${
                      //     serviceOptions.find(
                      //       (service) =>
                      //         service.value === getValues('serviceId')
                      //     ).label
                      //   }`
                      // );

                      // console.log(`Start time set as ${timeslot.startTime}`);
                      // console.log(
                      //   `End time is ${addMinutes(
                      //     timeslot.startTime,
                      //     serviceDuration
                      //   )}`
                      // );

                      setValue('appointmentStartTime', timeslot.startTime);
                      setValue(
                        'appointmentEndTime',
                        addMinutes(timeslot.startTime, serviceDuration)
                      );
                    }}
                  >
                    {' '}
                    {format(timeslot.startTime, 'hh:mm a')}
                  </Button>
                ))}

              {sgFreeSlots.length === 0 && (
                <Typography variant="h4" sx={{ p: isMobile ? 1 : 3 }}>
                  Select a service or change the calendar month.
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <HookFormDateTimePickerField
              name="appointmentStartTime"
              label="Appointment Start Time"
              control={control}
              errors={errors}
              rules={{
                required: 'Appointment Start Time is a required field.',
                validate: (v) => v > 0 || 'Please enter a valid time.' // using js trick to validate date
              }}
              disabled={false}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <HookFormDateTimePickerField
              name="appointmentEndTime"
              label="Appointment End Time"
              control={control}
              errors={errors}
              rules={{
                required: 'Appointment End Time is a required field.',
                validate: (v) =>
                  (v > 0 &&
                    v > getValues('appointmentStartTime') &&
                    isEqual(
                      startOfDay(v),
                      startOfDay(getValues('appointmentStartTime'))
                    )) ||
                  'End time must be same day and after start time.' // using js trick to validate date
              }}
              disabled={false}
            />
            {getValues('serviceId') &&
              differenceInMinutes(
                selectedApptEndTime,
                selectedApptStartTime
              ) !==
                serviceOptions.find(
                  (service) => service.value === getValues('serviceId')
                )?.duration && (
                <Alert variant="outlined" severity="error" sx={{ mt: 1 }}>
                  <Typography variant="subtitle2" color="red">
                    {/* <Typography sx={{ p: 1 }} variant="h5" color="red"> */}
                    Selected time period is not equal to service duration
                  </Typography>
                </Alert>
              )}
          </Grid>

          <Grid item xs={12} md={12}>
            <HookFormMultilineInputField
              name="appointmentNote"
              label="Appointment Notes (Internal)"
              control={control}
              errors={errors}
              rules={{
                required: false
              }}
              rows={5}
            />
          </Grid>

          {!rescheduleOption && (
            <>
              <Grid item xs={12} md={6}>
                <HookFormAutocompleteField
                  name="recurFrequency"
                  label="Repeat Frequency"
                  options={[
                    { label: 'None', value: null },
                    { label: 'Daily', value: 'Daily' },
                    { label: 'Weekly', value: 'Weekly' },
                    { label: 'Monthly', value: 'Monthly' }
                  ]}
                  control={control}
                  errors={errors}
                  rules={{
                    required: false
                  }}
                  notClearable={true}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {Boolean(selectedRecurFrequency) && (
                  <HookFormAutocompleteField
                    name="recurInstances"
                    label="Total # of Appts"
                    options={[
                      { label: '1', value: null },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7', value: 7 },
                      { label: '8', value: 8 }
                    ]}
                    control={control}
                    errors={errors}
                    rules={{
                      required: false
                    }}
                    notClearable={true}
                    disabled={false}
                  />
                )}
              </Grid>
            </>
          )}

          {selectedRecurFrequency && (
            <Grid item xs={12} md={12}>
              <Typography
                color={'red'}
              >{`Please check that the schedule is available for future appointments. By using this feature, you may be booking overlapping appointments.`}</Typography>
            </Grid>
          )}

          {rescheduleOption &&
            appointmentMappings?.getAllowRescheduleOption(
              tenantIdLowerCase
            ) && (
              <Grid item xs={12} md={12}>
                <HookFormCheckboxField
                  name="rescheduleAppointment"
                  label={`Record a cancellation count due to reschedule?`}
                  // label={`Record a ${getMappedWordToUse(
                  //   'Patient'
                  // )} cancellation count due to reschedule?`}
                  control={control}
                  errors={errors}
                  rules={{
                    required: false
                  }}
                  // defaultVal={
                  //   appointmentMappings?.getRecordRescheduleCancellationOption(
                  //     tenantIdLowerCase
                  //   ) || true
                  // }
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateAppointmentForm;

export const listPatientPaymentsQuery = /* GraphQL */ `
  query ListPatientPayments($patientId: ID) {
    listPayments(patientId: $patientId) {
      id
      invoiceId
      invoice {
        id
        invoiceNumber
      }
      type
      mode
      isRefunded
      status
      reference
      locationId
      amountPaid {
        amount
        currency
        precision
      }
      allocations {
        invoiceItemId
        amountAllocated {
          amount
          currency
          precision
        }
        invoiceItem {
          id
          description
          discountCodes
          amountOutstanding {
            amount
            currency
            precision
          }
          voidedAt
          voidedBy
          voidedReason
        }
      }
      paymentNumber
      paymentTime
      practitionerId
      settlementTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

export const listAllPaymentsInDateRangeQuery = /* GraphQL */ `
  query ListPaymentsInDateRange(
    $paymentStartTime: AWSDateTime!
    $paymentEndTime: AWSDateTime!
    $locationIds: [ID!]
  ) {
    listPaymentsInDateRange(
      paymentStartTime: $paymentStartTime
      paymentEndTime: $paymentEndTime
      locationIds: $locationIds
    ) {
      id
      invoiceId
      invoice {
        id
        invoiceNumber
        patient {
          id
          fullName
        }
        appointmentId
        practitionerId
        locationId
        createdAt
      }
      type
      mode
      isRefunded
      status
      reference
      locationId
      location {
        code
        name
      }
      patientId
      patient {
        fullName
      }
      amountPaid {
        amount
        currency
        precision
      }
      allocations {
        invoiceItemId
        amountAllocated {
          amount
          currency
          precision
        }
        invoiceItem {
          id
          description
          discountCodes
          amountOutstanding {
            amount
            currency
            precision
          }
          voidedAt
          voidedBy
          voidedReason
        }
      }
      paymentNumber
      paymentTime
      practitionerId
      settlementTime
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

export const customListShortPaymentsInDateRange = /* GraphQL */ `
  query ListPaymentsInDateRange(
    $paymentStartTime: AWSDateTime!
    $paymentEndTime: AWSDateTime!
    $locationIds: [ID!]
  ) {
    listPaymentsInDateRange(
      paymentStartTime: $paymentStartTime
      paymentEndTime: $paymentEndTime
      locationIds: $locationIds
    ) {
      id
      invoiceId
      invoice {
        # id
        invoiceNumber
        # appointmentId
        # practitionerId
        # locationId
        # createdAt
        issueDate
      }
      type
      mode
      isRefunded
      status
      locationId
      # location {
      # code
      # name
      # }
      patient {
        fullName
      }
      amountPaid {
        amount
        currency
        precision
      }
      paymentNumber
      paymentTime
      practitionerId
      settlementTime
      createdAt
      createdBy
      voidedAt
      voidedBy
    }
  }
`;

export const getMyPaymentReports = /* GraphQL */ `
  query GetMyPaymentReports($startTime: AWSDateTime!, $endTime: AWSDateTime!) {
    getMyPaymentReports(startTime: $startTime, endTime: $endTime) {
      summary {
        practitionerId
        practitionerName
        total {
          amount
          currency
          precision
          scale
        }
        serviceAmount {
          amount
          currency
          precision
          scale
        }
        inventoryAmount {
          amount
          currency
          precision
          scale
        }
      }
      details {
        practitionerId
        paymentDate
        invoiceItem
        invoiceNumber
        patientName
        locationId
        paymentAmount {
          amount
          currency
          precision
          scale
        }
        paymentCreatedAt
        itemType
      }
    }
  }
`;

export const getPractitionerPaymentReports = /* GraphQL */ `
  query GetPractitionerPaymentReports(
    $practitionerIds: [ID!]!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getPractitionerPaymentReports(
      practitionerIds: $practitionerIds
      startTime: $startTime
      endTime: $endTime
    ) {
      summary {
        practitionerId
        practitionerName
        total {
          amount
          currency
          precision
          scale
        }
        serviceAmount {
          amount
          currency
          precision
          scale
        }
        inventoryAmount {
          amount
          currency
          precision
          scale
        }
      }
      details {
        practitionerId
        paymentDate
        invoiceItem
        invoiceNumber
        patientName
        locationId
        paymentAmount {
          amount
          currency
          precision
          scale
        }
        paymentCreatedAt
        itemType
      }
    }
  }
`;

export const customCreateCreditNote = /* GraphQL */ `
  mutation CreateCreditNote($input: CreateCreditNoteInput!) {
    createCreditNote(input: $input) {
      id
      locationId
      customerId
      customer {
        id
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
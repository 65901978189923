export const customListContactsOnLoad = /* GraphQL */ `
  query ListContacts {
    listContacts {
      id
      entityName
      personName
      entityType
      category
    }
  }
`;

import { mock } from 'src/utils/axios';
import type { Note } from 'src/models/note';

let notes: Note[] = [
  {
  id: '1',
  tenant_id: 'csg',
  patient_id: '1',
  appointment_id: '12',
  type: 'blah',
  title: 'First note',
  content: 'This is the first in a series of notes on this patient',
  is_published: true,
  created_at: '2022-03-07 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 12:00:00',
  edited_by: 'ben',
  voided_at: null,
  voided_by: null

  },
  {
    id: '2',
    tenant_id: 'csg',
    patient_id: '1',
    appointment_id: '14',
    type: 'blah',
    title: 'Second note',
    content: 'Follow up treatment went very well. Patient is responding to treatment. Thats life.... please help me. Need to figure out how to store multi line line breaks. What happens if this is super duper long??????',
    is_published: true,
    created_at: '2022-03-12 12:00:00',
    created_by: 'ben',
    edited_at: '2022-03-12 12:00:00',
    edited_by: 'ben',
    voided_at: null,
    voided_by: null
  },
  {
    id: '3',
    tenant_id: 'csg',
    patient_id: '2',
    appointment_id: '12',
    type: 'blah',
    title: 'Amelia note',
    content: 'This is my first note for the second patient',
    is_published: true,
    created_at: '2022-03-14 15:00:00',
    created_by: 'ben',
    edited_at: '2022-03-14 15:00:00',
    edited_by: 'ben',
    voided_at: null,
    voided_by: null
  },
  
  
  
];

// mock.onGet('/api/notes').reply(() => {
//   return [200, { notes }];
// });

mock.onGet('/api/notes').reply((config) => {
  const { patientId } = config.params;
  const patient_notes = notes.filter((_note) => _note.patient_id === patientId);

  return [200, { patient_notes }];
});

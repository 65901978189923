import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createSignatureAPI,
  listSignaturesByPatientAPI,
  voidSignatureAPI,
  deleteSignatureAPI
} from 'src/content/Signatures/api';

interface SignatureState {
  isLoadingSignatures: boolean;
  isVoidingSignature: boolean;
  customerSignatures: any[];
  signatureErrorMessage: string;
  signatureSuccessMessage: string;
}

const initialState: SignatureState = {
  isLoadingSignatures: false,
  isVoidingSignature: false,
  customerSignatures: [],
  signatureErrorMessage: '',
  signatureSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortSignatureDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'signatures',
  initialState,
  reducers: {
    createCustomerSignature(state: SignatureState, action: PayloadAction<any>) {
      state.customerSignatures = [...state.customerSignatures, action.payload];
    },

    listSignaturesByCustomer(
      state: SignatureState,
      action: PayloadAction<any>
    ) {
      let customerSignatures = [...action.payload];
      customerSignatures.sort(sortSignatureDesc);

      state.customerSignatures = [...customerSignatures];
    },

    clearSignaturesByCustomer(
      state: SignatureState,
      action: PayloadAction<any>
    ) {
      state.customerSignatures = [];
    },

    voidSignature(state: SignatureState, action: PayloadAction<any>) {
      let signatureIndex = state.customerSignatures.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.customerSignatures = state.customerSignatures.map((signature, i) =>
        i === signatureIndex ? action.payload : signature
      );
    },

    setIsLoadingSignatures(state: SignatureState, action: PayloadAction<any>) {
      state.isLoadingSignatures = action.payload;
    },

    setIsVoidingSignature(state: SignatureState, action: PayloadAction<any>) {
      state.isVoidingSignature = action.payload;
    },

    setErrorMessage(state: SignatureState, action: PayloadAction<any>) {
      state.signatureErrorMessage = action.payload;
    },

    setSuccessMessage(state: SignatureState, action: PayloadAction<any>) {
      state.signatureSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createCustomerSignature =
  (patientSignature): AppThunk =>
  async (dispatch) => {
    const response = await createSignatureAPI(patientSignature);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createCustomerSignature(
          response.data.createPatientSignature
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Signature successfully recorded')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listSignaturesByCustomer =
  (patientId, startTime, endTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingSigatures());

    const response = await listSignaturesByPatientAPI(
      patientId,
      startTime,
      endTime
    );
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listSignaturesByCustomer(
          response.data.listPatientSignatures
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingSigatures());
  };

export const clearSignaturesByCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSignaturesByCustomer({}));
};

export const voidPatientSignature =
  (voidPatientSignature): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingSigature());
    dispatch(startLoadingSigatures());

    const response = await voidSignatureAPI(voidPatientSignature);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidSignature(response.data.voidPatientSignature));
      dispatch(
        slice.actions.setSuccessMessage('Signature successfully voided')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingSigature());
      dispatch(stopLoadingSigatures());
    };

    return followUp();
  };

export const startLoadingSigatures = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingSignatures(true));
};

export const stopLoadingSigatures = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingSignatures(false));
};

export const startVoidingSigature = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingSignature(true));
};

export const stopVoidingSigature = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingSignature(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListInventoriesQuery,
  CreateInventoryMutation,
  UpdateInventoryMutation,
  DeleteInventoryMutation,
  GetInventoryQuery
} from 'src/API';
import {
  createInventory,
  updateInventory,
  deleteInventory
} from 'src/graphql/mutations';
import { getInventory } from 'src/graphql/queries';
import { listAllInventoriesQuery, listInventoryOnLoad } from './queries';

export const listInventoryOnLoadAPI = async () => {
  try {
    const inventories = await callGraphQL<ListInventoriesQuery>(
      listInventoryOnLoad,
      {
        variables: {}
      }
    );
    // console.log(inventories);
    return inventories;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAllInventoriesAPI = async (locationIds) => {
  try {
    const inventories = await callGraphQL<ListInventoriesQuery>(
      listAllInventoriesQuery,
      {
        variables: {locationIds}
      }
    );
    // console.log(inventories);
    return inventories;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listLocationInventoriesAPI = async (locationIds) => {
  try {
    const inventories = await callGraphQL<ListInventoriesQuery>(
      listAllInventoriesQuery,
      {
        variables: {
          locationIds
        }
      }
    );
    // console.log(inventories);
    return inventories;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createInventoryAPI = async (inventory) => {
  try {
    const newInventory = await callGraphQL<CreateInventoryMutation>(
      createInventory,
      {
        variables: {
          input: inventory
        }
      }
    );

    // console.log(newInventory);
    return newInventory;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getInventoryAPI = async (inventoryId) => {
  try {
    const inventory = await callGraphQL<GetInventoryQuery>(getInventory, {
      variables: {
        id: inventoryId
      }
    });

    // console.log(newInventory);
    return inventory;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateInventoryAPI = async (inventory) => {
  try {
    const updatedInventory = await callGraphQL<UpdateInventoryMutation>(
      updateInventory,
      {
        variables: {
          input: inventory
        }
      }
    );

    // console.log(updatedInventory);
    return updatedInventory;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteInventoryAPI = async (inventoryId) => {
  try {
    const deletedInventory = await callGraphQL<DeleteInventoryMutation>(
      deleteInventory,
      {
        variables: {
          id: inventoryId
        }
      }
    );
    return deletedInventory;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppThunk, useSelector } from 'src/store';
import axios from 'src/utils/axios';
import {
  Event
  // CalendarAppointmentColourMapping,
  // CalendarClassAppointmentColourMapping
} from 'src/models/calendar';
import type { OfficeLocation, Practitioner, Appointment } from 'src/API';
import { listAppointmentsAPI } from 'src/content/Calendar/api';
import { listClassAppointmentsAPI } from 'src/content/ClassAppointments/api';

interface CalendarState {
  events: Event[];
  appointments: Appointment[];
  classAppointments: any[];
  isDrawerOpen: boolean;
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
  selectedResource: string;
  selectedPractitioners: Practitioner[] | null;
  selectedLocations: OfficeLocation[] | null;
  currentDate: string;
  calendarSettings: any;
  calErrorMessage: string;
}

const initialState: CalendarState = {
  events: [],
  appointments: [],
  classAppointments: [],
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: null,
  selectedResource: '',
  selectedPractitioners: null,
  selectedLocations: null,
  currentDate: null,
  calendarSettings: {},
  calErrorMessage: ''
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(
      state: CalendarState,
      action: PayloadAction<any>
      // action: PayloadAction<{ events: Event[] }>
    ) {
      // Original event
      // const { events } = action.payload;
      // state.events = events;

      const appointmentColourMapping =
        state.calendarSettings?.appointmentColourMapping;

      const classEvents = state.events.filter(
        (event) => event?.type === 'Class'
      );

      const { listAppointments } = action.payload.listAppointments;

      const convertedAppts = listAppointments
        .filter((appt) => appt?.service)
        .map((appointment) => {
          return {
            id: appointment.id,
            title:
              appointment.patient?.piiProfile?.fullName &&
              appointment.patient?.piiProfile?.fullName?.length > 0
                ? appointment.patient?.piiProfile?.fullName
                : appointment.patient?.piiProfile?.preferredName,
            description: `${appointment?.location?.code} - ${appointment?.service.name}`,
            start: appointment.appointmentStartTime,
            end: appointment.appointmentEndTime,
            eventStartTime: appointment.appointmentStartTime,
            eventEndTime: appointment.appointmentEndTime,
            allDay: false,
            // color:
            //   CalendarAppointmentColourMapping?.find(
            //     (item) =>
            //       item.type === appointment?.type &&
            //       item.category === appointment?.service?.category
            //   )?.colourCode || '#FFF3B0',
            color:
              appointmentColourMapping?.find(
                (item) =>
                  item.type === appointment?.type &&
                  item.category === appointment?.service?.category
              )?.colourCode || '#FFF3B0',
            status: appointment.status,
            patientId: appointment.patient?.id,
            type: appointment.type,
            category: appointment.service?.category,
            practitionerName: appointment.practitioner?.preferredName,
            resourceId: appointment.practitioner?.id
          };
        });

      state.events = [...convertedAppts, ...classEvents];
    },

    getClassEvents(state: CalendarState, action: PayloadAction<any>) {
      const classAppointmentColourMapping =
        state.calendarSettings?.classAppointmentColourMapping;

      const { listClassAppointments } = action.payload.listClasses;
      const nonClassEvents = state.events.filter(
        (event) => event?.type !== 'Class'
      );

      // console.log(listClassAppointments)

      const convertedClasses = listClassAppointments.map((classAppt) => ({
        id: classAppt.id,
        title: `${classAppt.classService?.name} ( ${classAppt?.patientIds?.length || 0} / ${classAppt?.maxParticipants} )`,
        description: `${classAppt.classService?.code} - ${classAppt.location?.code}`,
        start: classAppt.classAppointmentStartTime,
        end: classAppt.classAppointmentEndTime,
        eventStartTime: classAppt.classAppointmentStartTime,
        eventEndTime: classAppt.classAppointmentEndTime,
        allDay: false,
        // color:
        //   CalendarClassAppointmentColourMapping?.find(
        //     (item) => item.category === classAppt.classService?.category
        //   )?.colourCode || '#fff3b0',
        color:
          classAppointmentColourMapping?.find(
            (item) => item.category === classAppt.classService?.category
          )?.colourCode || '#fff3b0',
        status: classAppt.status,
        patientId: classAppt.practitionerId,
        type: 'Class',
        category: classAppt.classService?.category,
        practitionerName: classAppt.practitioner?.preferredName,
        resourceId: classAppt.practitioner?.id
      }));

      state.events = [...nonClassEvents, ...convertedClasses];
    },
    createEvent(state: CalendarState, action: PayloadAction<{ event: Event }>) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId?: string }>
    ) {
      const { eventId = null } = action.payload;

      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
    },
    updateClassEvent(state: CalendarState, action: PayloadAction<any>) {
      const events = action.payload;

      const classAppointmentColourMapping =
        state.calendarSettings?.classAppointmentColourMapping;

      events?.forEach((event) => {
        const classAppt = { ...event };
        const updatedEvent = {
          ...classAppt,
          id: classAppt.id,
          title: classAppt.classService?.name,
          description: `${classAppt.classService?.code} - ${classAppt.location?.code}`,
          start: classAppt.classAppointmentStartTime,
          end: classAppt.classAppointmentEndTime,
          eventStartTime: classAppt.classAppointmentStartTime,
          eventEndTime: classAppt.classAppointmentEndTime,
          allDay: false,
          color:
            classAppointmentColourMapping?.find(
              (item) => item.category === classAppt.classService?.category
            )?.colourCode || '#fff3b0',
          status: classAppt.status,
          patientId: classAppt.practitionerId,
          type: 'Class',
          category: classAppt.classService?.category,
          practitionerName: classAppt.practitioner?.preferredName,
          resourceId: classAppt.practitioner?.id
        };

        if (state.events.find((env) => env.id === updatedEvent.id)) {
          state.events = _.map(state.events, (_event) => {
            if (_event.id === updatedEvent.id) {
              return updatedEvent;
            }
            return _event;
          });
        } else {
          state.events = [updatedEvent, ...state.events];
        }
      });
    },
    updateEvent(state: CalendarState, action: PayloadAction<any>) {
      const event = action.payload;

      // if (state.events.find((env) => env.id === event.id)) {
      //   state.events = _.map(state.events, (_event) => {
      //     if (_event.id === event.id) {
      //       return event;
      //     }
      //     return _event;
      //   });
      // } else {
      //   state.events = [event, ...state.events];
      // }

      const appointmentColourMapping =
        state.calendarSettings?.appointmentColourMapping;

      const appointment = { ...event };

      const updatedEvent = {
        ...appointment,
        id: appointment.id,
        title:
          appointment.patient?.piiProfile?.fullName &&
          appointment.patient?.piiProfile?.fullName?.length > 0
            ? appointment.patient?.piiProfile?.fullName
            : appointment.patient?.piiProfile?.preferredName,
        description: appointment.service?.name,
        start: appointment.appointmentStartTime,
        end: appointment.appointmentEndTime,
        eventStartTime: appointment.appointmentStartTime,
        eventEndTime: appointment.appointmentEndTime,
        allDay: false,
        // color:
        //   CalendarAppointmentColourMapping?.find(
        //     (item) =>
        //       item.type === appointment.type &&
        //       item.category === appointment.service?.category
        //   )?.colourCode || '#FFF3B0',
        color:
          appointmentColourMapping?.find(
            (item) =>
              item.type === appointment.type &&
              item.category === appointment.service?.category
          )?.colourCode || '#FFF3B0',
        status: appointment.status,
        patientId: appointment.patient?.id,
        type: appointment.type,
        category: appointment.service?.category,
        practitionerName: appointment.practitioner?.preferredName,
        resourceId: appointment.practitioner?.id
      };

      if (state.events.find((env) => env.id === updatedEvent.id)) {
        state.events = _.map(state.events, (_event) => {
          if (_event.id === updatedEvent.id) {
            return updatedEvent;
          }
          return _event;
        });
      } else {
        state.events = [updatedEvent, ...state.events];
      }
    },
    deleteEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId: string }>
    ) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(
      state: CalendarState,
      action: PayloadAction<{ start: number; end: number }>
    ) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    selectResource(
      state: CalendarState,
      action: PayloadAction<{ resourceId: string }>
    ) {
      const { resourceId } = action.payload;

      state.selectedResource = resourceId;
    },
    openDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    updateSelectedPractitioners(
      state: CalendarState,
      action: PayloadAction<Practitioner[]>
    ) {
      state.selectedPractitioners = action.payload;
    },
    updateSelectedLocations(
      state: CalendarState,
      action: PayloadAction<OfficeLocation[]>
    ) {
      state.selectedLocations = action.payload;
      state.selectedPractitioners = [];
    },
    // getAppointments(
    //   state: CalendarState,
    //   action: PayloadAction<{ appointments: Appointment[] }>
    // ) {
    //   const { appointments } = action.payload;
    //   state.appointments = appointments.filter((appt) => appt.service);
    //   state.events = appointments.map((appointment) => {
    //     return {
    //       id: appointment.id,
    //       title: appointment.practitioner.firstName,
    //       description: `${appointment.service}`,
    //       start: appointment.appointmentStartTime,
    //       end: appointment.appointmentEndTime,
    //       eventStartTime: appointment.appointmentStartTime,
    //       eventEndTime: appointment.appointmentEndTime,
    //       allDay: false,
    //       color:
    //         CalendarAppointmentColourMapping?.find(
    //           (item) =>
    //             item.type === appointment.type &&
    //             item.category === appointment.service?.category
    //         )?.colourCode || '#FFF3B0',
    //       status: appointment.status,
    //       patientId: appointment.patient?.id,
    //       type: appointment.type,
    //       category: appointment.service?.category,
    //       practitionerName: appointment.practitioner?.preferredName,
    //       resourceId: appointment.practitioner?.id
    //     };
    //   });
    // },
    updateCurrentDate(state: CalendarState, action: PayloadAction<Date>) {
      const date = action.payload;
      state.currentDate = date.toISOString();
    },

    storeCalendarSettings(state: CalendarState, action: PayloadAction<any>) {
      // state.calendarSettings = action.payload;

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings?.calendars?.formOptions);
      state.calendarSettings = { ...settings?.calendars?.formOptions };
    },
    setErrorMessage(state: CalendarState, action: PayloadAction<any>) {
      state.calErrorMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getEvents =
  (
    appointmentEndDate,
    appointmentStartDate,
    locationIds?,
    practitionerIds?
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await listAppointmentsAPI(
      formatISO(appointmentEndDate, { representation: 'date' }),
      formatISO(appointmentStartDate, { representation: 'date' }),
      locationIds,
      practitionerIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getEvents({
          listAppointments: response.data
        })
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getClassEvents =
  (
    appointmentEndDate,
    appointmentStartDate,
    locationIds?,
    practitionerIds?
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const classResponse = await listClassAppointmentsAPI(
      formatISO(appointmentStartDate, { representation: 'date' }),
      formatISO(appointmentEndDate, { representation: 'date' }),
      locationIds,
      practitionerIds
    );

    if (!classResponse.customErrorMessage) {
      dispatch(
        slice.actions.getClassEvents({
          listClasses: classResponse.data
        })
      );
    } else {
      dispatch(slice.actions.setErrorMessage(classResponse.customErrorMessage));
    }
  };

export const clearEvents =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(
        slice.actions.getEvents({
          listAppointments: { listAppointments: [] }
        })
      );
      dispatch(
        slice.actions.getClassEvents({
          listClasses: { listClassAppointments: [] }
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

export const createEvent =
  (data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post<{ event: Event }>(
      '/api/calendar/meetings/create',
      data
    );

    dispatch(slice.actions.createEvent(response.data));
  };

export const selectEvent =
  (eventId?: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.selectEvent({ eventId }));
  };

export const updateClassEvent =
  (updatedEvent: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateClassEvent(updatedEvent));
  };

export const updateEvent =
  (updatedEvent: any): AppThunk =>
  async (dispatch) => {
    // const incomingEvent = [updatedEvent];
    // const response = incomingEvent.map((appointment) => {
    //   return {
    //     id: appointment.id,
    //     title:
    //       appointment.patient?.piiProfile?.fullName &&
    //       appointment.patient?.piiProfile?.fullName?.length > 0
    //         ? appointment.patient?.piiProfile?.fullName
    //         : appointment.patient?.piiProfile?.preferredName,
    //     description: appointment.service?.name,
    //     start: appointment.appointmentStartTime,
    //     end: appointment.appointmentEndTime,
    //     eventStartTime: appointment.appointmentStartTime,
    //     eventEndTime: appointment.appointmentEndTime,
    //     allDay: false,
    //     color:
    //       CalendarAppointmentColourMapping?.find(
    //         (item) =>
    //           item.type === appointment.type &&
    //           item.category === appointment.service?.category
    //       )?.colourCode || '#FFF3B0',
    //     status: appointment.status,
    //     patientId: appointment.patient?.id,
    //     type: appointment.type,
    //     category: appointment.service?.category,
    //     practitionerName: appointment.practitioner?.preferredName,
    //     resourceId: appointment.practitioner?.id
    //   };
    // });
    // dispatch(slice.actions.updateEvent(response[0]));

    dispatch(slice.actions.updateEvent(updatedEvent));
  };

export const deleteEvent =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    await axios.post('/api/calendar/meetings/delete', {
      eventId
    });

    dispatch(slice.actions.deleteEvent({ eventId }));
  };

export const selectRange =
  (start: Date, end: Date): AppThunk =>
  (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };

export const selectResource =
  (resourceId: string): AppThunk =>
  (dispatch) => {
    dispatch(
      slice.actions.selectResource({
        resourceId
      })
    );
  };

export const openDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeDrawerPanel());
};

// export const getAppointments =
//   (): AppThunk =>
//   async (dispatch): Promise<void> => {
//     try {
//       const response = await axios.get<{ appointments: Appointment[] }>(
//         '/api/calendar/appointments'
//       );
//       console.log(response);
//       // dispatch(slice.actions.getAppointments(response.data));
//     } catch (err) {
//       console.error(err);
//     }
//   };

export const updateSelectedPractitioners = function (
  practitioners: Practitioner[]
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.updateSelectedPractitioners(practitioners));
  };
};

export const updateSelectedLocations = function (
  locations: OfficeLocation[]
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.updateSelectedLocations(locations));
  };
};

export const updateCurrentDate = function (date: Date): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.updateCurrentDate(date));
  };
};

export const storeCalendarSettings = function (
  calendarSettings: any
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.storeCalendarSettings(calendarSettings));
  };
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { Block } from '../API';

import {
  createBlockAPI,
  getBlockAPI,
  listBlocksOfCustomerAPI,
  listBlocksOfAppointmentAPI,
  listBlocksOfClassAppointmentAPI,
  updateBlockAPI,
  deleteBlockAPI,
  fulfilBlockAPI
} from '../content/Blocks/api';

interface BlockState {
  isCreatingBlock: boolean;
  isFulfillingBlock: boolean;
  allBlocks: Block[];
  customerBlocks: Block[];
  classApptBlocks: Block[];
  blockDetail: any[];
  blockErrorMessage: string;
  blockSuccessMessage: string;
}

const initialState: BlockState = {
  isCreatingBlock: false,
  isFulfillingBlock: false,
  allBlocks: [],
  customerBlocks: [],
  classApptBlocks: [],
  blockDetail: [],
  blockErrorMessage: '',
  blockSuccessMessage: ''
};

const slice = createSlice({
  name: 'memos',
  initialState,
  reducers: {
    listBlocksOfCustomer(state: BlockState, action: PayloadAction<any>) {
      state.allBlocks = [...action.payload];
      state.customerBlocks = [...action.payload];
    },

    listBlocksOfClassAppointment(
      state: BlockState,
      action: PayloadAction<any>
    ) {
      state.classApptBlocks = [...action.payload];
    },

    getBlock(state: BlockState, action: PayloadAction<any>) {
      if (action.payload) {
        state.blockDetail = [action.payload];
      } else {
        state.blockDetail = [{ id: 'Not Found' }];
      }
    },

    createBlock(state: BlockState, action: PayloadAction<any>) {
      const newBlock = action.payload;

      state.allBlocks = [newBlock, ...state.allBlocks];
      state.customerBlocks = [newBlock, ...state.customerBlocks];
    },

    deleteBlock(state: BlockState, action: PayloadAction<any>) {
      state.allBlocks = [
        ...state.allBlocks.filter((block) => block.id !== action.payload.id)
      ];
    },

    fulfilBlock(state: BlockState, action: PayloadAction<any>) {
      state.allBlocks = [
        ...state.allBlocks.map((block) =>
          block.id !== action.payload.id ? block : action.payload
        )
      ];

      state.customerBlocks = [
        ...state.customerBlocks.map((block) =>
          block.id !== action.payload.id ? block : action.payload
        )
      ];

      state.classApptBlocks = [
        ...state.classApptBlocks.map((block) =>
          block.id !== action.payload.id ? block : action.payload
        )
      ];
    },

    setIsCreatingBlock(state: BlockState, action: PayloadAction<any>) {
      state.isCreatingBlock = action.payload;
    },

    setIsFulfillingBlock(state: BlockState, action: PayloadAction<any>) {
      state.isFulfillingBlock = action.payload;
    },

    setErrorMessage(state: BlockState, action: PayloadAction<any>) {
      state.blockErrorMessage = action.payload;
    },

    setSuccessMessage(state: BlockState, action: PayloadAction<any>) {
      state.blockSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listBlocksOfCustomer =
  (customerId): AppThunk =>
  async (dispatch) => {
    const response = await backOff(() => listBlocksOfCustomerAPI(customerId), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(slice.actions.listBlocksOfCustomer(response.data.listBlocks));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listBlocksOfClassAppointment =
  (classAppointmentId): AppThunk =>
  async (dispatch) => {
    const response = await backOff(
      () => listBlocksOfClassAppointmentAPI(classAppointmentId),
      {
        numOfAttempts: 3
      }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listBlocksOfClassAppointment(response.data.listBlocks)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getBlock =
  (blockId): AppThunk =>
  async (dispatch) => {
    const response = await getBlockAPI(blockId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getBlock(response.data.getBlock));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const createBlock =
  (blockData): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingBlock());

    const response = await createBlockAPI(blockData);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createBlock(response.data.createBlock));
      dispatch(slice.actions.setSuccessMessage('Block successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingBlock());
      // any other required list calls
    };

    return followUp();
  };

export const deleteBlock =
  (blockId): AppThunk =>
  async (dispatch) => {
    const response = await deleteBlockAPI(blockId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteBlock(response.data.deleteBlock));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const fulfilBlock =
  (blockId): AppThunk =>
  async (dispatch) => {
    dispatch(startFulfillingBlock());

    const response = await fulfilBlockAPI(blockId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.fulfilBlock(response.data.fulfilBlock));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopFulfillingBlock());
      // any other required list calls
    };

    return followUp();
  };

export const startCreatingBlock = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingBlock(true));
};

export const stopCreatingBlock = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingBlock(false));
};

export const startFulfillingBlock = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsFulfillingBlock(true));
};

export const stopFulfillingBlock = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsFulfillingBlock(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

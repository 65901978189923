import { useState, useEffect } from 'react';
import { ContactlessOutlined } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import Label from 'src/components/Label';
import {
  Box,
  Zoom,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  IconButton,
  InputAdornment,
  Button,
  createFilterOptions,
  Rating
} from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { MobileDatePicker } from '@mui/lab';
import { DesktopDateTimePicker, TimePicker } from '@mui/lab';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import nationalityOptions from './nationalityOptions';

const filter = createFilterOptions();

// Filter function for AutoComplete fields to reset them correctly because we do not store the full option
function filterOptions(options, searchKey, result = 'value') {
  return options.filter(function (obj) {
    return obj[result] === searchKey;
  });
}

function filterMultiOptions(options, searchArray, result = 'value') {
  return options.filter(function (obj) {
    return searchArray.includes(obj[result]);
  });
}

// Defining React Hook Form helper Components
export const HookFormInputField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  size = 'medium',
  disabled = false,
  autoFocus = false,
  fullWidth = true
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <TextField
          autoFocus={autoFocus}
          disabled={disabled}
          error={Boolean(errors[name])}
          fullWidth={fullWidth}
          helperText={errors?.[name]?.message}
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          onBlur={onBlur} // notify when input is touched
          onChange={onChange} // send value to hook form
          value={value}
          variant="outlined"
          name={name}
          inputRef={ref}
          size={size}
          // autoComplete="new-password"
          autoComplete="off"
          data-testid={name?.replace('.', '_')}
        />
      )}
    />
  );
};

export const HookFormNumberInputField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  decimalScale = 0,
  autoFocus = false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <NumberFormat
          fullWidth
          // defaultValue={defaultVal}
          name={name}
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          customInput={TextField}
          error={Boolean(errors[name])}
          helperText={errors?.[name]?.message}
          variant="outlined"
          isNumericString={true}
          thousandSeparator={true}
          value={value.floatValue}
          inputRef={ref}
          decimalScale={decimalScale}
          autoFocus={autoFocus}
          autoComplete='off'
          onValueChange={onChange}
          disabled={disabled}
          data-testid={name?.replace('.', '_')}
          // InputProps={{
          //   startAdornment: <span>$</span>
          // }}
        />
      )}
    />
  );
};

export const HookFormCurrencyInputField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  decimalScale = 2,
  autoFocus = false
  // defaultVal=0
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0.0}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <NumberFormat
          fullWidth
          // defaultValue={defaultVal}
          name={name}
          disabled={disabled}
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          customInput={TextField}
          error={Boolean(errors[name])}
          helperText={errors?.[name]?.message}
          variant="outlined"
          isNumericString={true}
          thousandSeparator={true}
          value={value.floatValue}
          decimalScale={decimalScale}
          autoFocus={autoFocus}
          autoComplete='off'
          onValueChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          data-testid={name?.replace('.', '_')}
          InputProps={{
            startAdornment: <span>$</span>
          }}
        />
      )}
    />
  );
};

// OLD COMPONENT which accessed floatvalue directly. Cannot reset and cannot set value
// export const HookFormCurrencyInputField: FC<any> = ({
//   name,
//   label,
//   control,
//   errors,
//   rules,
//   disabled=false,
//   // defaultVal=0

// }) => {
//   const { t }: { t: any } = useTranslation();
//   return (
//     <Controller
//       name={name}
//       control={control}
//       defaultValue={0.0}
//       rules={rules}

//       render={({
//         field: { onChange, onBlur, value, name, ref },
//         fieldState: { invalid, isTouched, isDirty, error },
//         formState
//       }) => (
//         <NumberFormat
//         fullWidth
//         // defaultValue={defaultVal}
//         name={name}
//         label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
//         customInput={TextField}
//         error={Boolean(errors[name])}
//         helperText={errors?.[name]?.message}
//         variant="outlined"
//         isNumericString={true}
//         thousandSeparator={true}
//         value={value.floatValue}
//         decimalScale={2}
//         onValueChange={(value) => onChange({type: 'text', target: { value: value.floatValue, name:name}})}
//         onBlur={onBlur}
//         inputRef={ref}
//         InputProps={{
//           startAdornment: <span>$</span>
//         }}

//       />
//       )}
//     />
//   );
// };

export const HookFormMultilineInputField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  rows,
  disabled = false,
  autoFocus = false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <TextField
          autoFocus={autoFocus}
          error={Boolean(errors[name])}
          fullWidth
          multiline
          rows={rows}
          helperText={errors?.[name]?.message}
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          onBlur={onBlur} // notify when input is touched
          onChange={onChange} // send value to hook form
          value={value}
          variant="outlined"
          name={name}
          inputRef={ref}
          disabled={disabled}
          data-testid={name?.replace('.', '_')}
        />
      )}
    />
  );
};

export const HookFormDatePickerField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  disableFuture = false,
  minDate = new Date(1900, 0, 1),
  size = 'medium'
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <DesktopDatePicker
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={onChange}
          inputRef={ref}
          disableFuture={disableFuture}
          minDate={minDate}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
          InputProps={{
            readOnly: true
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onKeyDown={(e) => e.preventDefault()}
              fullWidth
              error={Boolean(errors[name])}
              helperText={errors?.[name] && errors?.[name].message}
              data-testid={name?.replace('.', '_')}
              size={size}
            />
          )}
          disabled={disabled}
        />
        // <TextField
        //   disabled={disabled}
        //   error={Boolean(errors[name])}
        //   type="date"
        //   helperText={errors?.[name]?.message}
        //   label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
        //   onBlur={onBlur} // notify when input is touched
        //   onChange={onChange} // send value to hook form
        //   value={value}
        //   variant="outlined"
        //   name={name}
        //   inputRef={ref}
        //   autoComplete="new-password"
        //   InputLabelProps={{
        //     shrink: true,
        //   }}
        // />
      )}
    />
  );
};

export const HookFormDateTimePickerField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  // shouldDisableDate=false
  minDate = new Date(1920, 0, 1),
  size = 'medium'
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <DesktopDateTimePicker
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          inputFormat="dd/MM/yyyy p"
          value={value}
          onChange={onChange}
          inputRef={ref}
          minutesStep={5}
          minDate={minDate}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
          // shouldDisableDate={shouldDisableDate}
          // PaperProps={{
          //   sx: { "& *:focus": { outline: "none" } },
          // }}
          onViewChange={() => {
            setTimeout(() => {
              const el = document.activeElement;
              if (el) {
                (el as HTMLElement).blur();
              }
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={Boolean(errors[name])}
              helperText={errors?.[name] && errors?.[name].message}
              data-testid={name?.replace('.', '_')}
              size={size}
            />
          )}
          disabled={disabled}
        />
      )}
    />
  );
};

export const HookFormTimePickerField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  defaultValue = new Date()
  // shouldDisableTime=false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <TimePicker
          label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          value={value}
          onChange={onChange}
          inputRef={ref}
          disabled={disabled}
          minutesStep={5}
          // shouldDisableTime={shouldDisableTime}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={Boolean(errors[name])}
              onBlur={onBlur}
              helperText={errors?.[name] && errors?.[name].message}
              data-testid={name?.replace('.', '_')}
            />
          )}
        />
      )}
    />
  );
};

export const HookFormAutocompleteField: FC<any> = ({
  name,
  label,
  options,
  control,
  errors,
  rules,
  notClearable = false,
  disabled = false,
  size = 'medium',
  shouldAutoFocus = false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      // defaultValue={null}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <Autocomplete
          // disablePortal
          ref={ref}
          // defaultValue={options[0]}
          disableClearable={notClearable}
          options={options}
          disabled={disabled}
          size={size}
          // noOptionsText='Nothing selected'
          value={filterOptions(options, value)[0] || null}
          isOptionEqualToValue={(option, value) =>
            option.value === (value?.value || null)
          }
          getOptionLabel={(option: { label: string; value: string | number }) =>
            option.label
          }
          getOptionDisabled={(option) => option?.isActive === false}
          onBlur={onBlur} // notify when input is touched
          onChange={(e, data) =>
            data ? onChange(data['value']) : onChange(null)
          }
          renderOption={(props, option) => {
            return (
              <Box
                data-testid={`${name?.replace('.', '_')}-${option.label
                  .replace(' ', '_')
                  .trim()}`}
                component="li"
                {...props}
              >
                {option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              autoFocus={shouldAutoFocus}
              error={Boolean(errors[name])}
              helperText={errors?.[name]?.message}
              {...params}
              label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
              data-testid={name?.replace('.', '_')}
            />
          )}
        />
      )}
    />
  );
};

// EXPERIMENTAL
export const HookFormMultiCreateableAutocompleteField: FC<any> = ({
  name,
  label,
  options,
  control,
  errors,
  rules,
  disabled = false,
  stayOpenOnSelect = true
}) => {
  const [fieldOptions, setFieldOptions] = useState(options || []);

  // Ensure fieldOptions includes all provided options plus any new user-created options
  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error }
      }) => (
        <Autocomplete
          multiple
          freeSolo={true}
          disableCloseOnSelect={stayOpenOnSelect}
          disabled={disabled}
          fullWidth
          clearOnBlur
          // Map value (array of values) to corresponding option objects
          value={fieldOptions.filter((option) => value?.includes(option.value))}
          options={fieldOptions}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onBlur={onBlur}
          onChange={(event, newValue, reason, details) => {
            // Extract values for onChange
            const valueArray = newValue.map((option) => option.value);
            onChange(valueArray); // Update form value

            // If a new option is created, add it to fieldOptions

            if (reason === 'selectOption' && details.option) {
              // Trim the value to remove leading and trailing whitespace
              const trimmedValue = details.option.value.trim();

              // Proceed only if the trimmed value is not blank
              if (trimmedValue.length > 0) {
                const newOption = {
                  label: trimmedValue, // Use trimmed value for label as well
                  value: trimmedValue
                };

                setFieldOptions((prevOptions) => {
                  const optionExists = prevOptions.some(
                    (option) => option.value === newOption.value
                  );
                  return optionExists
                    ? prevOptions
                    : [...prevOptions, newOption];
                });
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                label: `Add ${inputValue.trim()}`,
                value: inputValue.trim()
              });
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={Boolean(errors[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
      )}
    />
  );
};

// THIS IS THE FALL BACK
export const HookFormMultiAutocompleteField: FC<any> = ({
  name,
  label,
  options,
  control,
  errors,
  rules,
  disabled = false,
  stayOpenOnSelect = true
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <Autocomplete
          disabled={disabled}
          multiple
          disableCloseOnSelect={stayOpenOnSelect}
          fullWidth
          ref={ref}
          value={filterMultiOptions(options, value)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          limitTags={20}
          options={options}
          getOptionLabel={(option: { label: string; value: string | number }) =>
            option.label
          }
          onBlur={onBlur}
          onChange={(e, data) =>
            onChange(
              data.map(
                (item: { label: string; value: string | number }) => item.value
              )
            )
          }
          renderInput={(params) => (
            <TextField
              fullWidth
              error={Boolean(errors[name])}
              helperText={errors?.[name]?.message}
              {...params}
              variant="outlined"
              label={label}
              // placeholder={t('Add more...')}
            />
          )}
        />
      )}
    />
  );
};

export const HookFormCheckboxField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  disabled = false,
  defaultVal = false
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultVal}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!value}
              onChange={
                (e, data) => {
                  onChange(e.target.checked);
                }

                // invert ? onChange(!data) : onChange(data)
              }
              onBlur={onBlur}
              name={name}
              ref={ref}
              disabled={disabled}
              // data-testid={name?.replace('.', '_')} // doesn't work
            />
          }
          label={label}
          labelPlacement="end"
          // data-testid={name?.replace('.', '_')} // doesn't work
        />
      )}
    />
  );
};

export const HookFormCountryField: FC<any> = ({
  name,
  label,
  control,
  errors,
  result,
  rules,
  size = 'medium',
  notClearable = false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <Autocomplete
          // disablePortal
          autoHighlight
          fullWidth
          size={size}
          // defaultValue={nationalityOptions[0]}
          disableClearable={notClearable}
          options={nationalityOptions}
          value={filterOptions(nationalityOptions, value, result)[0]}
          isOptionEqualToValue={(option, value) =>
            option.value === value?.value || option.value === value
          }
          getOptionLabel={(option: {
            label: string;
            value: string;
            phone: string;
          }) => {
            if (option.label === '') {
              return '';
            }
            // return `${option[result]} - ${option.label}`;
            return result === 'phone'
              ? `${option[result]} (${option.value})`
              : `${option.label} (${option[result]})`;
          }}
          onBlur={onBlur} // notify when input is touched
          onChange={(e, data) =>
            data ? onChange(data[result]) : onChange(null)
          }
          renderOption={(props, option) => {
            if (option.value === '') {
              return (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                />
              );
            }
            return (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.label.toLowerCase()}.png`}
                  srcSet={
                    option.value &&
                    `https://flagcdn.com/w40/${option.label.toLowerCase()}.png 2x`
                  }
                  alt=""
                />
                {/* {option[result]} ({option.label}) */}
                {result === 'phone'
                  ? `${option[result]} (${option.value})`
                  : `${option.label} (${option[result]})`}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              error={Boolean(errors[name])}
              helperText={errors?.[name]?.message}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              label={Boolean(rules.required) ? t(`${label}* `) : t(label)}
            />
          )}
        />
      )}
    />
  );
};

export const HookFormRatingField: FC<any> = ({
  name,
  label,
  control,
  errors,
  rules,
  size = 'medium',
  disabled = false,
  defaultValue = 0,
  max = 5,
  precision = 1,
  readOnly = false
}) => {
  const { t }: { t: any } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <Rating
          // ref={ref} // do we need this?
          name={name}
          value={value}
          onChange={(e, data) => (data ? onChange(data) : onChange(0))}
          disabled={disabled}
          defaultValue={defaultValue}
          max={max}
          size={size}
          precision={precision}
          readOnly={readOnly}
          // emptyLabelText={Boolean(rules.required) ? t(`${label}*`) : t(label)}
          emptyLabelText={
            typeof rules.required === 'boolean' && rules.required === false
              ? ''
              : rules.required
          }
        />
        // <TextField
        //   autoFocus={autoFocus}
        //   disabled={disabled}
        //   error={Boolean(errors[name])}
        //   fullWidth={fullWidth}
        //   helperText={errors?.[name]?.message}
        //   label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
        //   onBlur={onBlur} // notify when input is touched
        //   onChange={onChange} // send value to hook form
        //   value={value}
        //   variant="outlined"
        //   name={name}
        //   inputRef={ref}
        //   size={size}
        //   autoComplete="new-password"
        //   data-testid={name?.replace('.', '_')}
        // />
      )}
    />
  );
};

// export const renderInputField = (name, label, control, errors, rules) => {
//   const { t }: { t: any } = useTranslation();
//   return (
//     <Controller
//       name={name}
//       control={control}
//       rules={rules}
//       render={({
//         field: { onChange, onBlur, value, name, ref },
//         fieldState: { invalid, isTouched, isDirty, error },
//         formState
//       }) => (
//         <TextField
//           error={Boolean(errors[name])}
//           fullWidth
//           helperText={errors?.[name]?.message}
//           label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
//           onBlur={onBlur} // notify when input is touched
//           onChange={onChange} // send value to hook form
//           value={value}
//           variant="outlined"
//           name={name}
//           inputRef={ref}
//         />
//       )}
//     />
//   );
// };

// export const renderMultilineInputField = (
//   name,
//   label,
//   control,
//   errors,
//   rules,
//   rows
// ) => {
//   const { t }: { t: any } = useTranslation();
//   return (
//     <Controller
//       name={name}
//       control={control}
//       rules={rules}
//       render={({
//         field: { onChange, onBlur, value, name, ref },
//         fieldState: { invalid, isTouched, isDirty, error },
//         formState
//       }) => (
//         <TextField
//           error={Boolean(errors[name])}
//           fullWidth
//           multiline
//           rows={rows}
//           helperText={errors?.[name]?.message}
//           label={Boolean(rules.required) ? t(`${label}*`) : t(label)}
//           onBlur={onBlur} // notify when input is touched
//           onChange={onChange} // send value to hook form
//           value={value}
//           variant="outlined"
//           name={name}
//           inputRef={ref}
//         />
//       )}
//     />
//   );
// };

export const customListFlowActivitySessions = /* GraphQL */ `
  query ListFlowActivitySessions(
    $locationId: ID!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listFlowActivitySessions(
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;

export const customGetFlowActivitySession = /* GraphQL */ `
  query GetFlowActivitySession($id: ID!) {
    getFlowActivitySession(id: $id) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        createdAt
        updatedAt
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        createdAt
        updatedAt
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        createdAt
        updatedAt
        blocks {
          id
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
      }
      stationIds
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createTimeOffAPI,
  editTimeOffAPI,
  deleteTimeOffAPI,
  listTimeOffAPI
} from '../content/TimeOff/api';

interface TimeOffState {
  isLoadingTimeOffs: boolean;
  isCreatingTimeOff: boolean;
  isEditingTimeOff: boolean;
  isDeletingTimeOff: boolean;
  timeOffList: any[];
  timeOffEvents: any[];
  practitionerTimeOffEvents: any[];
  calendarSettings: any;
  timeOffErrorMessage: string;
  timeOffSuccessMessage: string;
}

const initialState: TimeOffState = {
  isLoadingTimeOffs: false,
  isCreatingTimeOff: false,
  isEditingTimeOff: false,
  isDeletingTimeOff: false,
  timeOffList: [],
  timeOffEvents: [],
  practitionerTimeOffEvents: [],
  calendarSettings: {},
  timeOffErrorMessage: '',
  timeOffSuccessMessage: ''
};

const slice = createSlice({
  name: 'timeoff',
  initialState,
  reducers: {
    createTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      const timeOffCategoryOptions =
        state.calendarSettings?.timeOffCategoryOptions;

      const newAppointments = [...action.payload, ...state.timeOffEvents];
      // state.timeOffList = newAppointments; // replacing this with line of code at the bottom

      state.timeOffEvents = newAppointments.map((timeOff) => {
        return {
          ...timeOff,
          category:
            timeOff?.category === 'Lunch'
              ? 'Break'
              : timeOff?.category === 'Meeting'
              ? 'Block'
              : timeOff.category,
          // color:
          //   CalendarTimeOffColourMapping.find(
          //     (item) => item.category === timeOff.category
          //   )?.colourCode || '#000000',
          color:
            timeOffCategoryOptions.find(
              (item) => item.value === timeOff.category
            )?.colourCode || '#000000',
          start: timeOff.eventStartTime,
          end: timeOff.eventEndTime,
          status: 'N/A',
          resourceId: timeOff.practitionerId
        };
      });

      state.timeOffList = [...action.payload, ...state.timeOffList];
    },

    listTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      state.timeOffList = [...action.payload];

      state.isLoadingTimeOffs = false;
    },

    getTimeOffEvents(state: TimeOffState, action: PayloadAction<any>) {
      const timeOffCategoryOptions =
        state.calendarSettings?.timeOffCategoryOptions;

      const timeOffs = [...action.payload];
      // console.log(timeOffs)
      state.timeOffEvents = timeOffs.map((timeOff) => {
        return {
          ...timeOff,
          category:
            timeOff?.category === 'Lunch'
              ? 'Break'
              : timeOff?.category === 'Meeting'
              ? 'Block'
              : timeOff.category,
          // color:
          //   CalendarTimeOffColourMapping.find(
          //     (item) => item.category === timeOff.category
          //   )?.colourCode || '#000000',
          color:
            timeOffCategoryOptions.find(
              (item) => item.value === timeOff.category
            )?.colourCode || '#000000',
          start: timeOff.eventStartTime,
          end: timeOff.eventEndTime,
          status: 'N/A',
          resourceId: timeOff.practitionerId
        };
      });
    },

    deleteTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      // state.timeOffEvents = state.timeOffEvents.filter(
      //   (timeOff) => timeOff.id !== action.payload.id
      // );

      const delTimeOff = action.payload;

      let timeOffIndex = state.timeOffEvents.findIndex(
        (obj) => obj.id === delTimeOff.id
      );

      if (timeOffIndex >= 0) {
        const newList = [...state.timeOffEvents];
        newList.splice(timeOffIndex, 1);
        state.timeOffEvents = [...newList];
      } else {
        state.timeOffEvents = [...state.timeOffEvents];
      }
    },

    editTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      const timeOffCategoryOptions =
        state.calendarSettings?.timeOffCategoryOptions;

      let timeOffListIndex = state.timeOffList.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (timeOffListIndex >= 0) {
        state.timeOffList = state.timeOffList.map((timeOff, i) =>
          i === timeOffListIndex ? action.payload : timeOff
        );
      }

      let timeOffIndex = state.timeOffEvents.findIndex(
        (obj) => obj.id === action.payload.id
      );

      const transformedTimeOff = {
        ...action.payload,
        // color: action.payload.colourCode,
        category:
          action.payload?.category === 'Lunch'
            ? 'Break'
            : action.payload?.category === 'Meeting'
            ? 'Block'
            : action.payload.category,
        // color:
        //   CalendarTimeOffColourMapping.find(
        //     (item) => item.category === action.payload.category
        //   )?.colourCode || '#000000',
        color:
          timeOffCategoryOptions.find(
            (item) => item.value === action.payload.category
          )?.colourCode || '#000000',
        start: action.payload.eventStartTime,
        end: action.payload.eventEndTime,
        status: 'N/A',
        resourceId: action.payload.practitionerId
      };

      if (timeOffIndex >= 0) {
        state.timeOffEvents = state.timeOffEvents.map((timeOff, i) =>
          i === timeOffIndex ? transformedTimeOff : timeOff
        );
      }
    },

    getPractitionerTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      const timeOffCategoryOptions =
        state.calendarSettings?.timeOffCategoryOptions;

      const timeOffs = [...action.payload];

      state.practitionerTimeOffEvents = timeOffs.map((timeOff) => {
        return {
          ...timeOff,
          category:
            timeOff?.category === 'Lunch'
              ? 'Break'
              : timeOff?.category === 'Meeting'
              ? 'Block'
              : timeOff.category,
          // color:
          //   CalendarTimeOffColourMapping.find(
          //     (item) => item.category === timeOff.category
          //   )?.colourCode || '#000000',
          color:
            timeOffCategoryOptions.find(
              (item) => item.value === timeOff.category
            )?.colourCode || '#000000',
          start: timeOff.eventStartTime,
          end: timeOff.eventEndTime,
          status: 'N/A',
          resourceId: timeOff.practitionerId
        };
      });

      state.isLoadingTimeOffs = false;
    },

    clearTimeOffEvents(state: TimeOffState, action: PayloadAction<any>) {
      state.timeOffEvents = [];
    },

    clearPractitionerTimeOffEvents(
      state: TimeOffState,
      action: PayloadAction<any>
    ) {
      state.practitionerTimeOffEvents = [];
    },

    setIsLoadingTimeOffs(state: TimeOffState, action: PayloadAction<any>) {
      state.isLoadingTimeOffs = action.payload;
    },

    setIsCreatingTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      state.isCreatingTimeOff = action.payload;
    },

    setIsEditingTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      state.isEditingTimeOff = action.payload;
    },

    setIsDeletingTimeOff(state: TimeOffState, action: PayloadAction<any>) {
      state.isDeletingTimeOff = action.payload;
    },

    storeTimeOffCalendarSettings(
      state: TimeOffState,
      action: PayloadAction<any>
    ) {
      // state.calendarSettings = action.payload;

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings?.calendars?.formOptions);
      state.calendarSettings = { ...settings?.calendars?.formOptions };
    },

    setErrorMessage(state: TimeOffState, action: PayloadAction<any>) {
      state.timeOffErrorMessage = action.payload;
    },

    setSuccessMessage(state: TimeOffState, action: PayloadAction<any>) {
      state.timeOffSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createTimeOff =
  (timeOff, bulkCreate = false): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingTimeOff());
    dispatch(startLoadingTimeOffs());

    const response = await createTimeOffAPI(timeOff);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createTimeOff(response.data.createTimeOff));

      if (!bulkCreate) {
        dispatch(
          slice.actions.setSuccessMessage('TimeOff successfully created')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingTimeOff());
      dispatch(stopLoadingTimeOffs());
    };

    return followUp();
  };

export const listTimeOff =
  (
    practitionerIds,
    startTime,
    endTime,
    locationIds = [],
    calFormat = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingTimeOffs());

    const response = await listTimeOffAPI(
      practitionerIds,
      startTime,
      endTime,
      locationIds
    );

    if (!response.customErrorMessage) {
      if (calFormat) {
        // console.log(response);
        dispatch(slice.actions.getTimeOffEvents(response.data.listTimeOffs));
      } else {
        // console.log(response);
        dispatch(slice.actions.listTimeOff(response.data.listTimeOffs));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingTimeOffs());
  };

export const getPractitionerTimeOff =
  (practitionerIds, startTime, endTime, locationIds = []): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingTimeOffs());

    const response = await listTimeOffAPI(
      practitionerIds,
      startTime,
      endTime,
      locationIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getPractitionerTimeOff(response.data.listTimeOffs)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingTimeOffs());
  };

export const deleteTimeOff =
  (timeOffId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startDeletingTimeOff());
    dispatch(startLoadingTimeOffs());

    const response = await deleteTimeOffAPI(timeOffId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteTimeOff(response.data.deleteTimeOff));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('TimeOff successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingTimeOff());
      dispatch(stopLoadingTimeOffs());
    };

    return followUp();
  };

export const editTimeOff =
  (timeOff, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingTimeOff());

    const response = await editTimeOffAPI(timeOff);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editTimeOff(response.data.updateTimeOff));

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage('TimeOff successfully updated')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingTimeOff());
  };

export const clearTimeOffEvents = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearTimeOffEvents(''));
};

export const clearPractitionerTimeOffEvents =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearPractitionerTimeOffEvents(''));
  };

export const startLoadingTimeOffs = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingTimeOffs(true));
};

export const stopLoadingTimeOffs = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingTimeOffs(false));
};

export const startCreatingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingTimeOff(true));
};

export const stopCreatingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingTimeOff(false));
};

export const startEditingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingTimeOff(true));
};

export const stopEditingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingTimeOff(false));
};

export const startDeletingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingTimeOff(true));
};

export const stopDeletingTimeOff = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingTimeOff(false));
};

export const storeTimeOffCalendarSettings = function (
  calendarSettings: any
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.storeTimeOffCalendarSettings(calendarSettings));
  };
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

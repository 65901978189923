import PermContactCalendarTwoToneIcon from '@mui/icons-material/PermContactCalendarTwoTone';
import ViewAgendaTwoToneIcon from '@mui/icons-material/ViewAgendaTwoTone';
import ViewDayTwoToneIcon from '@mui/icons-material/ViewDayTwoTone';
import CalendarViewMonthTwoToneIcon from '@mui/icons-material/CalendarViewMonthTwoTone';
import ViewWeekTwoToneIcon from '@mui/icons-material/ViewWeekTwoTone';

export const getCalendarViewOptions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [
    {
      label: 'Month',
      value: 'dayGridMonth',
      icon: CalendarViewMonthTwoToneIcon
    },
    {
      label: 'Week',
      value: 'timeGridWeek',
      icon: ViewWeekTwoToneIcon
    },
    {
      label: 'Day',
      value: 'timeGridDay',
      icon: ViewDayTwoToneIcon
    },
    {
      label: 'Agenda',
      value: 'listWeek',
      icon: ViewAgendaTwoToneIcon
    },
    {
      label: 'Practitioner',
      value: 'resourceTimeGridDay',
      icon: PermContactCalendarTwoToneIcon
    }
  ];

  try {
    const calendarViewOptions = {
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [
        {
          label: 'Month',
          value: 'dayGridMonth',
          icon: CalendarViewMonthTwoToneIcon
        },
        {
          label: 'Week',
          value: 'timeGridWeek',
          icon: ViewWeekTwoToneIcon
        },
        {
          label: 'Day',
          value: 'timeGridDay',
          icon: ViewDayTwoToneIcon
        },
        {
          label: 'Agenda',
          value: 'listWeek',
          icon: ViewAgendaTwoToneIcon
        },
        {
          label: 'Designer',
          value: 'resourceTimeGridDay',
          icon: PermContactCalendarTwoToneIcon
        }
      ]
    };

    const result = calendarViewOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCalendarSlotDuration = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = '00:30:00';

  try {
    const calendarSlotDuration = {
      cityosteophysio: '00:30:00',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': '00:30:00',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': '00:30:00',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': '00:10:00',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': '00:30:00'
    };

    const result = calendarSlotDuration[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListOfficeLocationsQuery,
  GetOfficeLocationQuery,
  DeleteOfficeLocationMutation,
  CreateOfficeLocationMutation,
  UpdateOfficeLocationMutation
} from 'src/API';
import { listOfficeLocations, getOfficeLocation } from 'src/graphql/queries';
import {
  createOfficeLocation,
  deleteOfficeLocation,
  updateOfficeLocation
} from 'src/graphql/mutations';
import { listOfficeLocationsOnLoad } from './queries';

export const createOfficeLocationAPI = async (location) => {
  try {
    const newLocation = await callGraphQL<CreateOfficeLocationMutation>(
      createOfficeLocation,
      {
        variables: {
          input: location
        }
      }
    );

    return newLocation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getOfficeLocationAPI = async (locationId) => {
  try {
    const officeLocation = await callGraphQL<GetOfficeLocationQuery>(
      getOfficeLocation,
      {
        variables: { id: locationId }
      }
    );
    return officeLocation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listOfficeLocationsOnLoadAPI = async () => {
  try {
    const locations = await callGraphQL<ListOfficeLocationsQuery>(
      listOfficeLocationsOnLoad,
      {
        variables: {}
      }
    );
    return locations;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listOfficeLocationsAPI = async () => {
  try {
    const locations = await callGraphQL<ListOfficeLocationsQuery>(
      listOfficeLocations,
      {
        variables: {}
      }
    );
    return locations;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateOfficeLocationAPI = async (location) => {
  try {
    const updateLocation = await callGraphQL<UpdateOfficeLocationMutation>(
      updateOfficeLocation,
      {
        variables: {
          input: location
        }
      }
    );

    console.log(updateLocation);
    return updateLocation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteOfficeLocationAPI = async (locationId) => {
  try {
    const deletedLocation = await callGraphQL<DeleteOfficeLocationMutation>(
      deleteOfficeLocation,
      {
        variables: {
          locationId: locationId
        }
      }
    );

    return deletedLocation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createNoticeBoardMessageAPI,
  getNoticeBoardMessageAPI,
  listNoticeBoardMessagesAPI,
  deleteNoticeBoardMessageAPI
} from 'src/content/NoticeBoardMessages/api';

interface NoticeBoardMessageState {
  noticeBoardMessages: any[];
  noticeBoardMessageErrorMessage: string;
  noticeBoardMessageSuccessMessage: string;
}

const initialState: NoticeBoardMessageState = {
  noticeBoardMessages: [],
  noticeBoardMessageErrorMessage: '',
  noticeBoardMessageSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortSignatureDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'noticeBoardMessages',
  initialState,
  reducers: {
    createNoticeBoardMessage(
      state: NoticeBoardMessageState,
      action: PayloadAction<any>
    ) {
      state.noticeBoardMessages = [
        action.payload,
        ...state.noticeBoardMessages
      ];
    },

    listNoticeBoardMessages(
      state: NoticeBoardMessageState,
      action: PayloadAction<any>
    ) {
      let noticeBoardMessages = [...action.payload];
      noticeBoardMessages.sort(sortSignatureDesc);

      state.noticeBoardMessages = [...noticeBoardMessages];
    },

    deleteNoticeBoardMessage(
      state: NoticeBoardMessageState,
      action: PayloadAction<any>
    ) {
      state.noticeBoardMessages = state.noticeBoardMessages.filter(
        (message) => message.id !== action.payload.id
      );
    },

    setErrorMessage(
      state: NoticeBoardMessageState,
      action: PayloadAction<any>
    ) {
      state.noticeBoardMessageErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: NoticeBoardMessageState,
      action: PayloadAction<any>
    ) {
      state.noticeBoardMessageSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createNoticeBoardMessage =
  (noticeBoardMessage): AppThunk =>
  async (dispatch) => {
    const response = await createNoticeBoardMessageAPI(noticeBoardMessage);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createNoticeBoardMessage(
          response.data.createNoticeBoardMessage
        )
      );
      dispatch(slice.actions.setSuccessMessage('Message successfully posted'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listNoticeBoardMessages =
  (locationId, startTime, endTime): AppThunk =>
  async (dispatch) => {
    const response = await listNoticeBoardMessagesAPI(
      locationId,
      startTime,
      endTime
    );
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listNoticeBoardMessages(
          response.data.listNoticeBoardMessages
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const deleteNoticeBoardMessage =
  (noticeBoardMessageId): AppThunk =>
  async (dispatch) => {
    const response = await deleteNoticeBoardMessageAPI(noticeBoardMessageId);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteNoticeBoardMessage(
          response.data.deleteNoticeBoardMessage
        )
      );
      dispatch(slice.actions.setSuccessMessage('Message successfully deleted'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

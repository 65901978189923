import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  RequestDocumentUploadMutation,
  ListDocumentsQuery,
  RequestDocumentDownloadMutation,
  DeleteDocumentMutation
} from 'src/API';
import {
  requestDocumentUpload,
  requestDocumentDownload,
  deleteDocument
} from 'src/graphql/mutations';
import { listDocuments } from 'src/graphql/queries';
// import { listAllInventoriesQuery } from './queries';

export const requestDocumentUploadAPI = async (document) => {
  try {
    const upload = await callGraphQL<RequestDocumentUploadMutation>(
      requestDocumentUpload,
      {
        variables: { input: document }
      }
    );
    // console.log(upload);
    return upload;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listDocumentsAPI = async (patientId) => {
  try {
    const patientFiles = await callGraphQL<ListDocumentsQuery>(listDocuments, {
      variables: {
        patientId,
        type: 'Other'
      }
    });
    // console.log(patientFiles);
    return patientFiles;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listDocumentsByNoteAPI = async (noteId) => {
  try {
    const noteFiles = await callGraphQL<ListDocumentsQuery>(listDocuments, {
      variables: {
        customerNoteId: noteId,
        type: 'CUSTOMER_NOTE_PHOTO'
      }
    });
    // console.log(patientFiles);
    return noteFiles;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const requestDocumentDownloadAPI = async (documentId) => {
  try {
    const downloadLink = await callGraphQL<RequestDocumentDownloadMutation>(
      requestDocumentDownload,
      {
        variables: {
          documentId
        }
      }
    );
    // console.log(downloadLink);
    return downloadLink;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteDocumentAPI = async (documentId) => {
  try {
    const deletedDoc = await callGraphQL<DeleteDocumentMutation>(
      deleteDocument,
      {
        variables: {
          id: documentId
        }
      }
    );
    // console.log(deletedDoc);
    return deletedDoc;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};


export const listXRayDocumentsAPI = async (patientId) => {
  try {
    const patientFiles = await callGraphQL<ListDocumentsQuery>(listDocuments, {
      variables: {
        patientId,
        type: 'XRAY'
      }
    });
    // console.log(patientFiles);
    return patientFiles;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListPatientSignaturesQuery,
  GetPatientSignatureQuery,
  CreatePatientSignatureMutation,
  UpdatePatientSignatureMutation,
  VoidPatientSignatureMutation,
  DeletePatientSignatureMutation
} from 'src/API';
import { listSignaturesByPatientsQuery } from './queries';
import {
  getPatientSignature,
  listPatientSignatures
} from 'src/graphql/queries';
import {
  createPatientSignature,
  updatePatientSignature,
  voidPatientSignature,
  deletePatientSignature
} from 'src/graphql/mutations';

export const listSignaturesByPatientAPI = async (
  patientId,
  startTime,
  endTime
) => {
  try {
    const signatures = await callGraphQL<ListPatientSignaturesQuery>(
      listPatientSignatures,
      {
        variables: {
          patientId,
          startTime,
          endTime
        }
      }
    );
    // console.log(signatures);
    return signatures;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createSignatureAPI = async ({
  documentContent,
  documentNameSigned,
  patientSignatureContent,
  practitionerSignatureContent,
  patientId,
  appointmentId = null,
  locationId = null,
  practitionerId = null,
  additionalSignatories = []
}) => {
  try {
    const newSignature = await callGraphQL<CreatePatientSignatureMutation>(
      createPatientSignature,
      {
        variables: {
          input: {
            documentContent,
            documentNameSigned,
            patientSignatureContent,
            practitionerSignatureContent,
            patientId,
            appointmentId,
            locationId,
            practitionerId,
            additionalSignatories
          }
        }
      }
    );

    return newSignature;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// export const editTimeOffAPI = async (timeOff) => {
//   try {
//     const updatedTimeOff = await callGraphQL<UpdateTimeOffMutation>(
//       updateTimeOff,
//       {
//         variables: {
//           input: timeOff
//         }
//       }
//     );
//     // console.log(updatedTimeOff);
//     return updatedTimeOff;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

export const voidSignatureAPI = async (voidSignature) => {
  try {
    const voidedSignature = await callGraphQL<VoidPatientSignatureMutation>(
      voidPatientSignature,
      {
        variables: {
          input: {
            id: voidSignature.id,
            voidReason: voidSignature.voidReason
          }
        }
      }
    );
    // console.log(voidedSig);
    return voidedSignature;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteSignatureAPI = async (signatureId) => {
  try {
    const deletedSig = await callGraphQL<DeletePatientSignatureMutation>(
      deletePatientSignature,
      {
        variables: {
          id: signatureId
        }
      }
    );
    // console.log(deletedSig);
    return deletedSig;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getDefaultCustomerPaymentOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = 'Cash';

  try {
    const defaultPaymentOption = {
      cityosteophysio: 'VisaMastercard',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 'Visa',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 'Cash',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'VisaMastercard',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 'PayNow',

      // Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f55': 'Visa',
      '46ef088a-d044-4000-8a53-578e6be74ef1': 'Visa'
    };

    const result = defaultPaymentOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDefaultCorporatePaymentOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = 'BankTransfer';

  try {
    const defaultPaymentOption = {
      cityosteophysio: 'BankTransfer',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 'BankTransfer',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 'BankTransfer',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'BankTransfer',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 'BankTransfer'
    };

    const result = defaultPaymentOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const ConfirmationPage = Loader(lazy(() => import('src/content/Confirmation')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);


const confirmationRoutes = [
  {
    path: '/',
    // element: <Overview />
    // Force navingation to Home Page of the App.
    element: <Status404 replace />
  },
  {
    path: 'appt',
    element: <ConfirmationPage />
  },
  
  {
    path: '*',
    element: <Status404 />
  }
];

export default confirmationRoutes;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateNoticeBoardMessageMutation,
  GetNoticeBoardMessageQuery,
  ListNoticeBoardMessagesQuery,
  DeleteNoticeBoardMessageMutation
} from 'src/API';
// import { getOnlineBookingPage, getOnlineBookingBySlug, listOnlineBookingPages } from 'src/graphql/queries';
import {
  createNoticeBoardMessage,
  deleteNoticeBoardMessage
} from 'src/graphql/mutations';
import {
  getNoticeBoardMessage,
  listNoticeBoardMessages
} from 'src/graphql/queries';

export const createNoticeBoardMessageAPI = async (noticeBoardMessage) => {
  try {
    const newMessage = await callGraphQL<CreateNoticeBoardMessageMutation>(
      createNoticeBoardMessage,
      {
        variables: {
          input: noticeBoardMessage
        }
      }
    );

    return newMessage;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getNoticeBoardMessageAPI = async (noticeBoardMessageId) => {
  try {
    const noticeBoardMessage = await callGraphQL<GetNoticeBoardMessageQuery>(
      getNoticeBoardMessage,
      {
        variables: {
          id: noticeBoardMessageId
        }
      }
    );

    return noticeBoardMessage;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listNoticeBoardMessagesAPI = async (
  locationId,
  startTime,
  endTime
) => {
  try {
    const noticeBoardMessages = await callGraphQL<ListNoticeBoardMessagesQuery>(
      listNoticeBoardMessages,
      {
        variables: {
          locationId,
          startTime,
          endTime
        }
      }
    );
    // console.log(signatures);
    return noticeBoardMessages;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// export const voidPatientNoteAPI = async (voidNote) => {
//   try {
//     const voidedNote = await callGraphQL<VoidPatientNoteMutation>(
//       voidPatientNote,
//       {
//         variables: {
//           input: voidNote
//         }
//       }
//     );
//     // console.log(editedNote);
//     return voidedNote;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

export const deleteNoticeBoardMessageAPI = async (messageId) => {
  try {
    const deletedMessage = await callGraphQL<DeleteNoticeBoardMessageMutation>(
      deleteNoticeBoardMessage,
      {
        variables: {
          id: messageId
        }
      }
    );
    // console.log(editedNote);
    return deletedMessage;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  getContactAPI,
  createContactAPI,
  listContactsAPI,
  updateContactAPI,
  deleteContactAPI,
  listContactsByCategoryAPI,
  listContactsOnLoadAPI
} from 'src/content/Contacts/api';

interface ContactState {
  isLoadingContacts: boolean;
  isEditingContact: boolean;
  isCreatingContact: boolean;
  isDeletingContact: boolean;
  allContacts: any[];
  contactDetail: any[];
  allReferralPartners: any[];
  contactErrorMessage: string;
  contactSuccessMessage: string;
}

const initialState: ContactState = {
  isLoadingContacts: false,
  isEditingContact: false,
  isCreatingContact: false,
  isDeletingContact: false,
  allContacts: [],
  contactDetail: [],
  allReferralPartners: [],
  contactErrorMessage: '',
  contactSuccessMessage: ''
};

// IDEA: Sort the array by entity name ASC
const sortContacts = (a, b) => {
  if (a.entityName < b.entityName) {
    return -1;
  }
  if (a.entityName > b.entityName) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    listContacts(state: ContactState, action: PayloadAction<any>) {
      state.allContacts = [...action.payload];

      state.isLoadingContacts = false;
    },

    listReferralPartners(state: ContactState, action: PayloadAction<any>) {
      const allContacts = [...action.payload];
      allContacts.sort(sortContacts);

      state.allReferralPartners = allContacts.filter(
        (contact) => contact.category === '6 - Clinic/Doctor'
      );
    },

    createContact(state: ContactState, action: PayloadAction<any>) {
      state.allContacts = [action.payload, ...state.allContacts];
    },

    deleteContact(state: ContactState, action: PayloadAction<any>) {
      // state.allContacts = state.allContacts.filter(
      //   (corp) => corp.id !== action.payload.id
      // );

      const delContact = action.payload;

      let contactIndex = state.allContacts.findIndex(
        (obj) => obj.id === delContact.id
      );

      if (contactIndex >= 0) {
        const newList = [...state.allContacts];
        newList.splice(contactIndex, 1);
        state.allContacts = [...newList];
      } else {
        state.allContacts = [...state.allContacts];
      }
    },

    editContact(state: ContactState, action: PayloadAction<any>) {
      const editedContact = action.payload;

      let contactIndex = state.allContacts.findIndex(
        (obj) => obj.id === editedContact.id
      );

      if (contactIndex >= 0) {
        const newList = [...state.allContacts];
        newList.splice(contactIndex, 1);
        state.allContacts = [editedContact, ...newList];
      } else {
        state.allContacts = [...state.allContacts];
      }

      state.contactDetail = [editedContact];
    },

    getContact(state: ContactState, action: PayloadAction<any>) {
      if (action.payload) {
        state.contactDetail = [action.payload];
      } else {
        state.contactDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedContact(state: ContactState, action: PayloadAction<any>) {
      state.contactDetail = [];
    },

    clearContacts(state: ContactState, action: PayloadAction<any>) {
      state.allContacts = [];
    },

    setIsLoadingContacts(state: ContactState, action: PayloadAction<any>) {
      state.isLoadingContacts = action.payload;
    },

    setIsEditingContact(state: ContactState, action: PayloadAction<any>) {
      state.isEditingContact = action.payload;
    },

    setIsCreatingContact(state: ContactState, action: PayloadAction<any>) {
      state.isCreatingContact = action.payload;
    },

    setIsDeletingContact(state: ContactState, action: PayloadAction<any>) {
      state.isDeletingContact = action.payload;
    },

    setErrorMessage(state: ContactState, action: PayloadAction<any>) {
      state.contactErrorMessage = action.payload;
    },

    setSuccessMessage(state: ContactState, action: PayloadAction<any>) {
      state.contactSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listContactsOnLoad = (): AppThunk => async (dispatch) => {
  const response = await listContactsOnLoadAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listContacts(response.data.listContacts));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const listContacts = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingContacts());

  const response = await listContactsAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listContacts(response.data.listContacts));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingContacts());
};

export const listReferralPartners = (): AppThunk => async (dispatch) => {
  const response = await listContactsAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listReferralPartners(response.data.listContacts));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const createContact =
  (contact): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingContact());
    dispatch(startLoadingContacts());

    const response = await createContactAPI(contact);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createContact(response.data.createContact));
      dispatch(slice.actions.setSuccessMessage('Contact successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingContact());
      dispatch(stopLoadingContacts());
    };

    return followUp();
  };

export const deleteContact =
  (contactId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingContacts());
    dispatch(startDeletingContact());

    const response = await deleteContactAPI(contactId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteContact(response.data.deleteContact));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Contact successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingContact());
      dispatch(stopLoadingContacts());
    };

    return followUp();
  };

export const getContact =
  (contactId): AppThunk =>
  async (dispatch) => {
    const response = await getContactAPI(contactId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getContact(response.data.getContact));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedContact({}));
};

export const updateContact =
  (contact): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingContact());

    const response = await updateContactAPI(contact);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editContact(response.data.updateContact));
      dispatch(slice.actions.setSuccessMessage('Contact successfully updated'));
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingContact());
  };

export const clearContacts = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearContacts(''));
};

export const startLoadingContacts = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingContacts(true));
};

export const stopLoadingContacts = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingContacts(false));
};

export const startEditingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingContact(true));
};

export const stopEditingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingContact(false));
};

export const startCreatingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingContact(true));
};

export const stopCreatingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingContact(false));
};

export const startDeletingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingContact(true));
};

export const stopDeletingContact = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingContact(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateCreditNoteMutation,
  GetCreditNoteQuery,
  ListCreditNotesQuery,
  VoidCreditNoteMutation,
  CreateCreditNoteStripePaymentMutation,
  UpdateCreditNoteNoteMutation,
  UpdateCreditNoteReasonMutation,
  GetCreditNoteByNumberQuery
} from 'src/API';
import {
  createCreditNote,
  createCreditNoteStripePayment,
  updateCreditNoteNote,
  updateCreditNoteReason,
  voidCreditNote
} from 'src/graphql/mutations';
import { getCreditNote, listCreditNotes, getCreditNoteByNumber } from 'src/graphql/queries';

import {
  customGetCreditNote,
  customListCreditNotes,
  customListShortCreditNotesInDateRange,
  customGetCreditNoteByNumber
} from './queries';
import { customCreateCreditNote } from './mutations';
// import { customUpdateCorporate } from './mutations';

export const listCreditNotesByCustomerAPI = async (customerId) => {
  try {
    const creditNotes = await callGraphQL<ListCreditNotesQuery>(
      customListCreditNotes,
      {
        variables: {
          customerId
        }
      }
    );

    // console.log(creditNotes);

    return creditNotes;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// export const listCorporatesByCategoryAPI = async (category) => {
//   try {
//     const corporates = await callGraphQL<ListCorporatesByCategoryQuery>(
//       listCorporatesByCategory,
//       {
//         variables: { category }
//       }
//     );

//     return corporates;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

export const createCreditNoteAPI = async (creditNoteInput) => {
  try {
    const newCreditNote = await callGraphQL<CreateCreditNoteMutation>(
      customCreateCreditNote,
      {
        variables: {
          input: creditNoteInput
        }
      }
    );

    // console.log(newCreditNote);
    return newCreditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCreditNoteNoteAPI = async ({ creditNoteId, note }) => {
  try {
    const creditNote = await callGraphQL<UpdateCreditNoteNoteMutation>(
      updateCreditNoteNote,
      {
        variables: {
          id: creditNoteId,
          note
        }
      }
    );
    return creditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCreditNoteReasonAPI = async ({ creditNoteId, reason }) => {
  try {
    const creditNote = await callGraphQL<UpdateCreditNoteReasonMutation>(
      updateCreditNoteReason,
      {
        variables: {
          id: creditNoteId,
          reason
        }
      }
    );
    return creditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCreditNoteAPI = async (creditNoteId) => {
  try {
    const creditNote = await callGraphQL<GetCreditNoteQuery>(
      customGetCreditNote,
      {
        variables: {
          id: creditNoteId
        }
      }
    );

    // console.log(creditNote);
    return creditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// export const updateCorporateAPI = async (corporate) => {
//   try {
//     const updatedCorporate = await callGraphQL<UpdateCorporateMutation>(
//       customUpdateCorporate,
//       {
//         variables: {
//           input: corporate
//         }
//       }
//     );

//     // console.log(updatedCorporate);
//     return updatedCorporate;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

export const voidCreditNoteAPI = async (creditNoteInput) => {
  try {
    const voidedCreditNote = await callGraphQL<VoidCreditNoteMutation>(
      voidCreditNote,
      {
        variables: {
          input: creditNoteInput
        }
      }
    );
    return voidedCreditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listShortCreditNotesInDateRangeAPI = async (
  effectiveStartTime = new Date(),
  effectiveEndTime = new Date(),
  locationIds = []
) => {
  try {
    const creditNote = await callGraphQL<ListCreditNotesQuery>(
      customListShortCreditNotesInDateRange,
      {
        variables: {
          effectiveStartTime,
          effectiveEndTime,
          locationId: locationIds[0]
        }
      }
    );

    // console.log(creditNote);
    return creditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCreditNoteByIdAPI = async (creditNoteId) => {
  try {
    const invoice = await callGraphQL<GetCreditNoteQuery>(customGetCreditNote, {
      variables: {
        id: creditNoteId
      }
    });
    return invoice;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};


export const getCreditNoteByNumberAPI = async (creditNoteNumber) => {
  try {
    const creditNote = await callGraphQL<GetCreditNoteByNumberQuery>(
      customGetCreditNoteByNumber,
      {
        variables: {
          noteNumber: creditNoteNumber
        }
      }
    );

    // console.log(creditNote);
    return creditNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import mixpanel from 'mixpanel-browser';

let env_check = process.env.NODE_ENV === 'production';

mixpanel.init('be7388d567c7aaeb939150df79fcf7eb', {
  persistence: 'localStorage',
  secure_cookie: true,
  track_pageview: true,
  debug: env_check ? false : true
});

mixpanel.set_config({
  // type of persistent store for super properties (cookie/
  // localStorage) if set to 'localStorage', any existing
  // mixpanel cookie value with the same persistence_name
  // will be transferred to localStorage and deleted
  persistence: 'localStorage',

  // if this is true, mixpanel cookies will be marked as
  // secure, meaning they will only be transmitted over https
  secure_cookie: true
});

let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (env_check) {
      mixpanel.alias(id);
    }
  },
  track: (name, props?) => {
    if (env_check) {
      if (props) {
        mixpanel.track(name, props);
      } else {
        mixpanel.track(name);
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
      }
    }
  },
  reset: () => {
    if (env_check) {
      mixpanel.reset();
    }
  }
};

export let Mixpanel = actions;

import { useEffect, useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import useRefMounted from 'src/hooks/useRefMounted';
import { useSelector } from 'src/store';
import { format, addHours, startOfHour, startOfMinute } from 'date-fns';
import { Box, Button, CircularProgress, Divider } from '@mui/material';

import CustomSplitButton from 'src/components/FormFields/CustomSplitButton';
import CreateAppointmentForm from 'src/content/Appointments/appointmentForm/CreateAppointmentForm';

// import { Mixpanel } from 'src/contexts/MixPanelContext';

const CreateAppointmentAction = ({
  setCurrentTab,
  setAppointmentData,
  setNotify
}) => {
  const isInitialMount = useRef(true);
  const isMountedRef = useRefMounted();

  const { isCreatingAppointment, isEditingAppointment } = useSelector(
    (state) => state.appointments
  );
  const { activeServicesList } = useSelector((state) => state.services);
  const { locationDetails } = useSelector((state) => state.locations);
  const { loggedInUser, providerList } = useSelector((state) => state.users);

  // Registering React Hook Form
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {}
  });

  const resetForm = () => {
    reset({
      serviceId: null,
      locationId: loggedInUser?.currentLocationId,
      practitionerId: loggedInUser?.type?.toLowerCase().includes('practitioner')
        ? loggedInUser?.id
        : null,
      // practitionerId: loggedInUser?.type?.toLowerCase().includes('practitioner')
      //   ? loggedInUser?.id
      //   : activeServicesList?.find(
      //       (service) => service?.locationId === loggedInUser?.currentLocationId
      //     )?.practitionerId,
      appointmentStartTime: new Date(),
      appointmentEndTime: new Date(),
      type: 'Follow-Up Visit',
      recurInstances: null,
      recurFrequency: null,
      appointmentNote: ''
    });
  };

  const onSubmitNoNotify = (data) => {
    // console.log(data);
    const finalData = {
      serviceName: activeServicesList.find(
        (service) => service.id === data.serviceId
      )?.name,
      practitionerName: providerList.find(
        (provider) => provider.id === data.practitionerId
      )?.preferredName,
      // serviceName: serviceOptions.find(
      //   (service) => service.value === data.serviceId
      // ).label,
      // practitionerName: filteredPractitioners.find(
      //   (prac) => prac.value === selectedPractitionerId
      // ).label,
      locationCode: locationDetails.find((loc) => loc.id === data.locationId)
        ?.code,
      serviceId: data.serviceId,
      appointmentDate: format(data.appointmentStartTime, 'yyyy-MM-dd'),
      appointmentStartTime: startOfMinute(data.appointmentStartTime),
      appointmentEndTime: startOfMinute(data.appointmentEndTime),
      // type: data.type,
      appointmentNote:
        data.appointmentNote?.length > 0 ? data.appointmentNote : '',
      status: 'Pending',
      recurFrequency: data?.recurFrequency || null,
      recurInstances: data?.recurFrequency ? data?.recurInstances || 1 : null
    };

    resetForm();

    setNotify(false);
    setAppointmentData(finalData);
    setCurrentTab('existingPatient');
  };

  const onSubmitSendEmail = (data) => {
    // console.log(data);
    const finalData = {
      serviceName: activeServicesList.find(
        (service) => service.id === data.serviceId
      )?.name,
      practitionerName: providerList.find(
        (provider) => provider.id === data.practitionerId
      ).preferredName,
      locationCode: locationDetails.find((loc) => loc.id === data.locationId)
        .code,
      serviceId: data.serviceId,
      appointmentDate: format(data.appointmentStartTime, 'yyyy-MM-dd'),
      appointmentStartTime: startOfMinute(data.appointmentStartTime),
      appointmentEndTime: startOfMinute(data.appointmentEndTime),
      // type: data.type,
      appointmentNote:
        data.appointmentNote?.length > 0 ? data.appointmentNote : '',
      status: 'Pending',
      recurFrequency: data?.recurFrequency || null,
      recurInstances: data?.recurFrequency ? data?.recurInstances || 1 : null
    };

    resetForm();

    setNotify(true);
    setAppointmentData(finalData);
    setCurrentTab('existingPatient');
  };

  useEffect(() => {
    resetForm();
  }, [isMountedRef]);

  // Mixpanel.identify(loggedInUser?.id);
  // Mixpanel.track('Quick Action - Create Appointment');

  // if (loadingServices) {
  //   return <SuspenseLoader />;
  // }

  return (
    <form onSubmit={handleSubmit(onSubmitSendEmail)}>
      <Box display="flex">
        <CreateAppointmentForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
        />
      </Box>

      <Divider />

      <Box display="flex" justifyContent="flex-end" sx={{ p: 2 }}>
        <CustomSplitButton
          color="primary"
          disabled={
            isSubmitting || isCreatingAppointment || isEditingAppointment
          }
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          options={[
            {
              label: 'Notify',
              function: handleSubmit(onSubmitSendEmail)
            },
            {
              label: 'No Notification',
              function: handleSubmit(onSubmitNoNotify)
            }
          ]}
        />
      </Box>
    </form>
  );
};

export default CreateAppointmentAction;

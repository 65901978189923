export const customGetPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
        amount {
          amount
          currency
          precision
          scale
        }
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      rules
      ruleTemplateType
      ruleTemplateVariables
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $validStartTime: AWSDateTime
    $validEndTime: AWSDateTime
  ) {
    listPromoCodes(
      validStartTime: $validStartTime
      validEndTime: $validEndTime
    ) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
        amount {
          amount
          currency
          precision
          scale
        }
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      rules
      ruleTemplateType
      ruleTemplateVariables
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListPromoCodeRedemptions = /* GraphQL */ `
  query ListPromoCodeRedemptions(
    $promoCodeId: ID
    $redemptionStartTime: AWSDateTime!
    $redemptionEndTime: AWSDateTime!
  ) {
    listPromoCodeRedemptions(
      promoCodeId: $promoCodeId
      redemptionStartTime: $redemptionStartTime
      redemptionEndTime: $redemptionEndTime
    ) {
      id
      promoCodeId
      promoCodeCode
      promoCodeType
      locationId
      customerId
      customerName
      # redemptionCount
      # onlineBookingPageId
      # onlineBookingTransactionId
      # appointmentId
      invoiceId
      invoiceNumber
      providerId
      providerName
      serviceId
      serviceName
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

export const customListCustomerPromoCodeRedemptions = /* GraphQL */ `
  query ListPromoCodeRedemptions(
    $promoCodeId: ID
    $customerId: ID
    $redemptionStartTime: AWSDateTime!
    $redemptionEndTime: AWSDateTime!
  ) {
    listPromoCodeRedemptions(
      promoCodeId: $promoCodeId
      customerId: $customerId
      redemptionStartTime: $redemptionStartTime
      redemptionEndTime: $redemptionEndTime
    ) {
      id
      promoCodeId
      promoCodeCode
      promoCodeType
      locationId
      customerId
      customerName
      # redemptionCount
      # onlineBookingPageId
      # onlineBookingTransactionId
      # appointmentId
      invoiceId
      invoiceNumber
      providerId
      providerName
      serviceId
      serviceName
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

export const isFlowIntegrated = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const flowIntegratedMapping = {
      cityosteophysio: false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false,

      // Dev
      'a75b6463-f88f-4299-ad5d-b184eeae0513': true
    };

    const result = flowIntegratedMapping[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const isGoogleCalendarIntegrated = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const googleCalendarIntegratedMapping = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': false,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false
    };

    const result = googleCalendarIntegratedMapping[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCorporateStatusLabel = (
  corporateStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Active: {
        text: 'ACTIVE',
        color: 'success'
      },
      Inactive: {
        text: 'INACTIVE',
        color: 'error'
      }
    };

    const { text, color }: any = map[corporateStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const EmailIndex = Loader(
  lazy(() => import('src/content/Communications/email/'))
);
const SMSIndex = Loader(lazy(() => import('src/content/Communications/sms/')));
const FollowUpEmailIndex = Loader(
  lazy(() => import('src/content/Communications/email/FollowUpEmailIndex'))
);

const communicationsRoutes = [
  {
    path: '/',
    element: <Navigate to="/email" replace />
  },
  {
    path: '/email',
    element: <EmailIndex />
  },
  {
    path: '/sms',
    element: <SMSIndex />
  },
  {
    path: '/followup',
    element: <FollowUpEmailIndex />
  }
];

export default communicationsRoutes;

export const listAvailableServicesQuery = /* GraphQL */ `
  query ListServices($locationId: ID, $practitionerId: ID) {
    listServices(locationId: $locationId, practitionerId: $practitionerId) {
      id
      name
      description
      code
      category
      duration
      isActive
      price {
        amount
        currency
        precision
        scale
      }
      locationId
      practitionerId
      onlineBookingName
      onlineBookingCategory
      # location {
      #   id
      #   name
      # }
      # practitioner {
      #   id
      #   firstName
      #   lastName
      # }
      # workingHours
      quickbooksId
    }
  }
`;

export const customGetResourceScheduleQuery = /* GraphQL */ `
  query GetResourceSchedule(
    $resourceId: ID!
    $resourceType: String!
    $year: Int!
  ) {
    getResourceSchedule(
      resourceId: $resourceId
      resourceType: $resourceType
      year: $year
    ) {
      id
      resourceId
      resourceType
      year
      days {
        day
        slots {
          startTime
          endTime
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetResourceSchedulesInRange = /* GraphQL */ `
  query GetResourceSchedulesInRange(
    $resourceId: ID!
    $resourceType: String!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    getResourceSchedulesInRange(
      resourceId: $resourceId
      resourceType: $resourceType
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      resourceId
      resourceType
      year
      days {
        day
        slots {
          startTime
          endTime
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

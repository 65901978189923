export const customUpdateCustomerFull = /* GraphQL */ `
  mutation UpdatePatientFull($input: UpdatePatientFullInput!) {
    updatePatientFull(input: $input) {
      id
      profilePicture
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        consent
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateCustomerEmail = /* GraphQL */ `
  mutation UpdatePatientFull($input: UpdatePatientFullInput!) {
    updatePatientFull(input: $input) {
      id
      piiProfile {
        id
        email
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateCustomerInternalNote = /* GraphQL */ `
  mutation UpdatePatientFull($input: UpdatePatientFullInput!) {
    updatePatientFull(input: $input) {
      id
      piiProfile {
        internalNote
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customDeleteCustomer = /* GraphQL */ `
  mutation DeletePatient($patientId: ID!) {
    deletePatient(patientId: $patientId) {
      id
      fullName
    }
  }
`;

export const customerUpdateCustomerProfilePicture = /* GraphQL */ `
  mutation UpdatePatientFull($input: UpdatePatientFullInput!) {
    updatePatientFull(input: $input) {
      id
      profilePicture
    }
  }
`;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetPassQuery,
  ListPassesQuery,
  CreatePassMutation,
  UpdatePassMutation
} from 'src/API';
import { createPass, updatePass } from 'src/graphql/mutations';
import { getPass, listPasses } from 'src/graphql/queries';

export const createPassAPI = async (passDetails) => {
  try {
    const newPass = await callGraphQL<CreatePassMutation>(createPass, {
      variables: {
        input: passDetails
      }
    });

    // console.log(newPass);
    return newPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPassAPI = async (passId) => {
  try {
    const pass = await callGraphQL<GetPassQuery>(getPass, {
      variables: {
        id: passId
      }
    });

    // console.log(pass);
    return pass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPassesAPI = async (serviceIds, classServiceIds, isActive) => {
  try {
    const passes = await callGraphQL<ListPassesQuery>(listPasses, {
      variables: {
        serviceIds: serviceIds,
        classServiceIds: classServiceIds,
        isActive: isActive
      }
    });

    // console.log(passes);
    return passes;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePassAPI = async (passDetails) => {
  try {
    const updatedPass = await callGraphQL<UpdatePassMutation>(updatePass, {
      variables: {
        input: passDetails
      }
    });

    // console.log(updatedPass);
    return updatedPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Patients = Loader(lazy(() => import('src/content/Patients')));
const SinglePatient = Loader(lazy(() => import('src/content/Patients/single')));

const patientRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Patients />
  },
  {
    path: ':patientId/:tabName/:financeTabName',
    element: <SinglePatient />
  },
  {
    path: ':patientId/:tabName/:communicationTabName',
    element: <SinglePatient />
  },
  {
    path: ':patientId/:tabName/:appointmentTabName',
    element: <SinglePatient />
  },
  {
    path: ':patientId/:tabName',
    element: <SinglePatient />
  },
  {
    path: ':patientId/',
    element: <SinglePatient />
  }
  // {
  //   path: 'perio',
  //   element: <PerioChartForm />
  // }
];

export default patientRoutes;

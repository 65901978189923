import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateOnlineBookingPageMutation,
  UpdateOnlineBookingPageMutation,
  DeleteOnlineBookingPageMutation,
  GetOnlineBookingPageQuery,
  ListOnlineBookingPagesQuery
} from 'src/API';
import {
  customGetPublicOnlineBookingPage,
  customGetOnlineBookingPage,
  customListOnlineBookingPages
} from './queries';
import {
  customCreateOnlineBookingPage,
  customUpdateOnlineBookingPage,
  customDeleteOnlineBookingPage
} from './mutations';

export const createOnlineBookingPageAPI = async (onlineBookingPage) => {
  try {
    const newPage = await callGraphQL<CreateOnlineBookingPageMutation>(
      customCreateOnlineBookingPage,
      {
        variables: {
          input: onlineBookingPage
        }
      }
    );

    return newPage;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getOnlineBookingPageAPI = async (onlineBookingId) => {
  try {
    const page = await callGraphQL<GetOnlineBookingPageQuery>(
      customGetOnlineBookingPage,
      {
        variables: {
          id: onlineBookingId
        }
      }
    );

    return page;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPublicOnlineBookingPageAPI = async (onlineBookingId) => {
  try {
    const page = await callGraphQL<GetOnlineBookingPageQuery>(
      customGetPublicOnlineBookingPage,
      {
        variables: {
          id: onlineBookingId
        }
      }
    );

    return page;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listOnlineBookingPagesAPI = async () => {
  try {
    const pages = await callGraphQL<ListOnlineBookingPagesQuery>(
      customListOnlineBookingPages,
      {
        variables: {}
      }
    );
    // console.log(notes);
    return pages;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteOnlineBookingPageAPI = async (onlineBookingPageId) => {
  try {
    const page = await callGraphQL<DeleteOnlineBookingPageMutation>(
      customDeleteOnlineBookingPage,
      {
        variables: {
          id: onlineBookingPageId
        }
      }
    );

    return page;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateOnlineBookingPageAPI = async (onlineBookingPage) => {
  try {
    const editedOnlineBookingPage =
      await callGraphQL<UpdateOnlineBookingPageMutation>(
        customUpdateOnlineBookingPage,
        {
          variables: {
            input: onlineBookingPage
          }
        }
      );
    // console.log(editedNote);
    return editedOnlineBookingPage;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// export const publishPatientNoteAPI = async (noteId) => {
//   try {
//     const publishedNote = await callGraphQL<PublishPatientNoteMutation>(
//       publishPatientNote,
//       {
//         variables: {
//           id: noteId
//         }
//       }
//     );
//     // console.log(editedNote);
//     return publishedNote;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

// export const voidPatientNoteAPI = async (voidNote) => {
//   try {
//     const voidedNote = await callGraphQL<VoidPatientNoteMutation>(
//       voidPatientNote,
//       {
//         variables: {
//           input: voidNote
//         }
//       }
//     );
//     // console.log(editedNote);
//     return voidedNote;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

// export const deletePatientNoteAPI = async (noteId) => {
//   try {
//     const deletedNote = await callGraphQL<DeletePatientNoteMutation>(
//       deletePatientNote,
//       {
//         variables: {
//           id: noteId
//         }
//       }
//     );
//     // console.log(editedNote);
//     return deletedNote;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

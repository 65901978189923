export const customCreatePaymentWalletTransaction = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      patientId
      locationId
      type
      mode
      status
      paymentNumber
      paymentTime
      settlementTime
      amountPaid {
        amount
        currency
        precision
        scale
      }
      reference
      isInsurance
      isRefunded
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

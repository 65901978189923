import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  listPatientCommunicationsAPI,
  listPatientRecentCommunicationsAPI,
  listCommunicationsInDateRangeAPI,
  listReviewerCommunicationsAPI,
  updateCommunicationAPI,
  voidCommunicationAPI
} from 'src/content/Communications/api';

interface CommunicationState {
  isLoadingCommunications: boolean;
  customerCommunications: any[];
  listCommunications: any[];
  listReviewerComms: any[];
  commErrorMessage: string;
  commSuccessMessage: string;
}

const initialState: CommunicationState = {
  isLoadingCommunications: false,
  customerCommunications: [],
  listCommunications: [],
  listReviewerComms: [],
  commErrorMessage: '',
  commSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortCommsDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    updateCommunication(state: CommunicationState, action: PayloadAction<any>) {
      state.listReviewerComms = [...state.listReviewerComms].map((comms) =>
        comms.id === action.payload.id ? action.payload : comms
      );

      state.isLoadingCommunications = false;
    },

    listPatientCommunications(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      let comms = [...action.payload];
      comms.sort(sortCommsDesc);

      state.customerCommunications = [...comms];

      state.isLoadingCommunications = false;
    },

    listCommunicationsInDateRange(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      let comms = [...action.payload];
      comms.sort(sortCommsDesc);

      state.listCommunications = [...comms];

      state.isLoadingCommunications = false;
    },

    listReviewerCommunications(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      let comms = [...action.payload];
      comms.sort(sortCommsDesc);

      state.listReviewerComms = [...comms];

      state.isLoadingCommunications = false;
    },

    clearPatientCommunications(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      state.customerCommunications = [];
    },

    clearReviewerCommunications(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      state.listReviewerComms = [];
    },

    setIsLoadingCommunications(
      state: CommunicationState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCommunications = action.payload;
    },

    setErrorMessage(state: CommunicationState, action: PayloadAction<any>) {
      state.commErrorMessage = action.payload;
    },

    setSuccessMessage(state: CommunicationState, action: PayloadAction<any>) {
      state.commSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const updateCommunication =
  (updatedComms): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await updateCommunicationAPI(updatedComms);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCommunication(response.data.updateCommunication)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const listPatientCommunications =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await listPatientCommunicationsAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listPatientCommunications(
          response.data.listPatientCommunications
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const listPatientRecentCommunications =
  (patientId, startTime, endTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await listPatientRecentCommunicationsAPI(
      patientId,
      startTime,
      endTime
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listPatientCommunications(
          response.data.listPatientCommunications
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const listCommunicationsInDateRange =
  (startTime, endTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await listCommunicationsInDateRangeAPI(startTime, endTime);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listCommunicationsInDateRange(
          response.data.listCommunicationsInDateRange
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const listReviewerCommunications =
  (reviewerId, status, startTime, endTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await listReviewerCommunicationsAPI(
      reviewerId,
      status,
      startTime,
      endTime
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listReviewerCommunications(
          response.data.listReviewerCommunications
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const voidCommunication =
  (id, voidReason): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCommunications());

    const response = await voidCommunicationAPI(id, voidReason);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCommunication(response.data.voidCommunication)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCommunications());
  };

export const clearPatientCommunications = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearPatientCommunications(''));
};

export const clearReviewerCommunications = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearReviewerCommunications(''));
};

export const startLoadingCommunications = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCommunications(true));
};

export const stopLoadingCommunications = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCommunications(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

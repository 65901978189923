import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  getStationAPI,
  createStationAPI,
  listStationsAPI,
  updateStationAPI,
  deleteStationAPI,
  resetStationAPI
} from 'src/content/Stations/api';

interface StationState {
  isLoadingStations: boolean;
  isEditingStation: boolean;
  isCreatingStation: boolean;
  isDeletingStation: boolean;
  allStations: any[];
  stationDetail: any[];
  stationErrorMessage: string;
  stationSuccessMessage: string;
}

const initialState: StationState = {
  isLoadingStations: false,
  isEditingStation: false,
  isCreatingStation: false,
  isDeletingStation: false,
  allStations: [],
  stationDetail: [],
  stationErrorMessage: '',
  stationSuccessMessage: ''
};

// IDEA: Sort the array by entity name ASC
const sortStationsByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    listStations(state: StationState, action: PayloadAction<any>) {
      const stationsByName = [...action.payload];

      stationsByName.sort(sortStationsByName);

      state.allStations = stationsByName;

      state.isLoadingStations = false;
    },

    createStation(state: StationState, action: PayloadAction<any>) {
      state.allStations = [action.payload, ...state.allStations];
    },

    deleteStation(state: StationState, action: PayloadAction<any>) {
      const delStation = action.payload;

      let stationIndex = state.allStations.findIndex(
        (obj) => obj.id === delStation.id
      );

      if (stationIndex >= 0) {
        const newList = [...state.allStations];
        newList.splice(stationIndex, 1);
        state.allStations = [...newList];
      } else {
        state.allStations = [...state.allStations];
      }
    },

    editStation(state: StationState, action: PayloadAction<any>) {
      const editedStation = action.payload;

      let stationIndex = state.allStations.findIndex(
        (obj) => obj.id === editedStation.id
      );

      if (stationIndex >= 0) {
        const newList = [...state.allStations];
        newList.splice(stationIndex, 1);
        state.allStations = [editedStation, ...newList];
      } else {
        state.allStations = [...state.allStations];
      }

      state.stationDetail = [editedStation];
    },

    getStation(state: StationState, action: PayloadAction<any>) {
      if (action.payload) {
        state.stationDetail = [action.payload];
      } else {
        state.stationDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedStation(state: StationState, action: PayloadAction<any>) {
      state.stationDetail = [];
    },

    clearStations(state: StationState, action: PayloadAction<any>) {
      state.allStations = [];
    },

    setIsLoadingStations(state: StationState, action: PayloadAction<any>) {
      state.isLoadingStations = action.payload;
    },

    setIsEditingStation(state: StationState, action: PayloadAction<any>) {
      state.isEditingStation = action.payload;
    },

    setIsCreatingStation(state: StationState, action: PayloadAction<any>) {
      state.isCreatingStation = action.payload;
    },

    setIsDeletingStation(state: StationState, action: PayloadAction<any>) {
      state.isDeletingStation = action.payload;
    },

    setErrorMessage(state: StationState, action: PayloadAction<any>) {
      state.stationErrorMessage = action.payload;
    },

    setSuccessMessage(state: StationState, action: PayloadAction<any>) {
      state.stationSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listStations = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingStations());

  const response = await listStationsAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listStations(response.data.listStations));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingStations());
};

export const createStation =
  (station): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingStation());
    dispatch(startLoadingStations());

    const response = await createStationAPI(station);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createStation(response.data.createStation));
      dispatch(slice.actions.setSuccessMessage('Station successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingStation());
      dispatch(stopLoadingStations());
    };

    return followUp();
  };

export const deleteStation =
  (stationId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingStations());
    dispatch(startDeletingStation());

    const response = await deleteStationAPI(stationId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteStation(response.data.deleteStation));
      dispatch(slice.actions.setSuccessMessage('Station successfully deleted'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingStation());
      dispatch(stopLoadingStations());
    };

    return followUp();
  };

export const getStation =
  (stationId): AppThunk =>
  async (dispatch) => {
    const response = await getStationAPI(stationId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getStation(response.data.getStation));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedStation({}));
};

export const updateStation =
  (station): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingStation());

    const response = await updateStationAPI(station);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editStation(response.data.updateStation));
      dispatch(slice.actions.setSuccessMessage('Station successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingStation());
  };

export const resetStation =
  (station): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingStation());
    dispatch(startLoadingStations());

    const response = await resetStationAPI(station);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editStation(response.data.updateStation));
      dispatch(slice.actions.setSuccessMessage('Station successfully reset'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopEditingStation());
      dispatch(stopLoadingStations());
    };

    return followUp();
  };

export const clearStations = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearStations(''));
};

export const startLoadingStations = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingStations(true));
};

export const stopLoadingStations = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingStations(false));
};

export const startEditingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingStation(true));
};

export const stopEditingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingStation(false));
};

export const startCreatingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingStation(true));
};

export const stopCreatingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingStation(false));
};

export const startDeletingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingStation(true));
};

export const stopDeletingStation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingStation(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

export const customUpdateCorporate = /* GraphQL */ `
  mutation UpdateCorporate($input: UpdateCorporateInput!) {
    updateCorporate(input: $input) {
      id
      companyName
      code
      category
      contactPerson {
        name
        email
        phone
        isPrimary
      }
      officePhone
      officeFax
      officeEmail
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      websiteURL
      note
      affectsPricing
      insurancePaymentType
      insurancePaymentScheme {
        type
        isCap
        amount {
          amount
          currency
          precision
          scale
        }
      }
      invoiceNoteTemplate
      discounts {
        coverage
        type
        category
        amount {
          amount
          currency
          precision
          scale
        }
      }
      specialPricing {
        itemId
        itemType
        category
        price {
          amount
          currency
          precision
          scale
        }
      }
      restrictions {
        itemId
        itemType
        category
      }
      validFrom
      validTo
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

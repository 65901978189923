import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';
import { getDayOfYear, getYear } from 'date-fns';

import {
  listAvailableServicesAPI,
  createServiceAPI,
  getServiceAPI,
  deleteServiceAPI,
  updateServiceAPI,
  getServiceScheduleAPI,
  getServiceSchedulesInRangeAPI,
  getServiceScheduleSlotsAPI,
  putServiceScheduleDayAPI,
  upsertServiceScheduleAPI
} from 'src/content/Services/api';

interface ServiceState {
  isLoadingServices: boolean;
  isEditingService: boolean;
  isCreatingService: boolean;
  isDeletingService: boolean;
  allServicesList: any[];
  activeServicesList: any[];
  serviceDetail: any[];
  serviceErrorMessage: string;
  serviceSuccessMessage: string;
  serviceSchedule: any[];
  serviceScheduleSlots: any[];
  serviceScheduleSlotsStartDate: any;
  serviceScheduleSlotsEndDate: any;
}

const initialState: ServiceState = {
  isLoadingServices: false,
  isEditingService: false,
  isCreatingService: false,
  isDeletingService: false,
  allServicesList: [],
  activeServicesList: [],
  serviceDetail: [],
  serviceErrorMessage: '',
  serviceSuccessMessage: '',
  serviceSchedule: [],
  serviceScheduleSlots: [],
  serviceScheduleSlotsStartDate: null,
  serviceScheduleSlotsEndDate: null
};

// sort DESC by category and then ASC by name
const sortServices = (a, b) => {
  if (a.category > b.category) {
    return -1;
  }
  if (a.category < b.category) {
    return 1;
  } else {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }
};

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    getAllServices(state: ServiceState, action: PayloadAction<any>) {
      const allServices = action.payload;

      allServices.sort(sortServices);

      state.allServicesList = [...allServices];
      state.activeServicesList = [
        ...allServices.filter((service) => service.isActive === true)
      ];
    },

    createService(state: ServiceState, action: PayloadAction<any>) {
      const newService = action.payload;

      state.allServicesList = [newService, ...state.allServicesList];

      if (newService.isActive) {
        state.activeServicesList = [newService, ...state.activeServicesList];
      }
    },

    getService(state: ServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        // const service = action.payload;
        state.serviceDetail = [action.payload];
      } else {
        state.serviceDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedService(state: ServiceState, action: PayloadAction<any>) {
      state.serviceDetail = [];
    },

    deleteService(state: ServiceState, action: PayloadAction<any>) {
      const delService = action.payload;

      let svcIndex = state.allServicesList.findIndex(
        (obj) => obj.id === delService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.allServicesList];
        newList.splice(svcIndex, 1);
        state.allServicesList = [...newList];
      } else {
        state.allServicesList = [...state.allServicesList];
      }

      svcIndex = state.activeServicesList.findIndex(
        (obj) => obj.id === delService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.activeServicesList];
        newList.splice(svcIndex, 1);
        state.activeServicesList = [...newList];
      } else {
        state.activeServicesList = [...state.activeServicesList];
      }
    },

    editService(state: ServiceState, action: PayloadAction<any>) {
      const editedService = action.payload;

      let svcIndex = state.allServicesList.findIndex(
        (obj) => obj.id === editedService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.allServicesList];
        newList.splice(svcIndex, 1);
        state.allServicesList = [editedService, ...newList];
      } else {
        state.allServicesList = [...state.allServicesList];
      }

      // Repeat code
      svcIndex = state.activeServicesList.findIndex(
        (obj) => obj.id === editedService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.activeServicesList];
        newList.splice(svcIndex, 1);
        state.activeServicesList = [editedService, ...newList];
      } else {
        state.activeServicesList = [...state.activeServicesList];
      }

      state.serviceDetail = [editedService];
    },

    setIsLoadingServices(state: ServiceState, action: PayloadAction<any>) {
      state.isLoadingServices = action.payload;
    },

    setIsEditingService(state: ServiceState, action: PayloadAction<any>) {
      state.isEditingService = action.payload;
    },

    setIsCreatingService(state: ServiceState, action: PayloadAction<any>) {
      state.isCreatingService = action.payload;
    },

    setIsDeletingService(state: ServiceState, action: PayloadAction<any>) {
      state.isDeletingService = action.payload;
    },

    setErrorMessage(state: ServiceState, action: PayloadAction<any>) {
      state.serviceErrorMessage = action.payload;
    },

    setSuccessMessage(state: ServiceState, action: PayloadAction<any>) {
      state.serviceSuccessMessage = action.payload;
    },

    getServiceSchedule(state: ServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        state.serviceSchedule = [action.payload];
      } else {
        state.serviceSchedule = [];
      }
    },

    getServiceSchedulesInRange(
      state: ServiceState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.serviceSchedule = action.payload;
      } else {
        state.serviceSchedule = [];
      }
    },

    getServiceScheduleSlots(state: ServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        state.serviceScheduleSlots = action.payload;
      } else {
        state.serviceScheduleSlots = [];
      }
    },

    putServiceScheduleDay(state: ServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        state.serviceSchedule = [action.payload];
      } else {
        state.serviceSchedule = [];
      }
    },

    upsertServiceSchedule(state: ServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        // state.serviceSchedule = action.payload;

        // logic to filter results by selected date range
        const selectedStartDateDayOfYear = getDayOfYear(
          state.serviceScheduleSlotsStartDate
        );
        const selectedEndDateDayOfYear = getDayOfYear(
          state.serviceScheduleSlotsEndDate
        );

        const serviceScheduleYears = state.serviceSchedule?.map(
          (serviceScheduleByYear) => {
            const scheduleYear = serviceScheduleByYear?.year;

            const updatedServiceScheduleByYear = action.payload?.find(
              (payloadScheduleByYear) =>
                payloadScheduleByYear?.year === scheduleYear
            );

            return {
              ...serviceScheduleByYear,
              days:
                updatedServiceScheduleByYear &&
                updatedServiceScheduleByYear?.days
                  ? updatedServiceScheduleByYear?.days?.filter(
                      (scheduleDay) =>
                        scheduleDay?.day >= selectedStartDateDayOfYear &&
                        scheduleDay?.day <= selectedEndDateDayOfYear
                    )
                  : serviceScheduleByYear?.days
            };
          }
        );
        console.log(serviceScheduleYears);
        state.serviceSchedule = serviceScheduleYears;
      } else {
        state.serviceSchedule = [{}];
      }
    },

    setServiceScheduleSlotStartDate(
      state: ServiceState,
      action: PayloadAction<any>
    ) {
      state.serviceScheduleSlotsStartDate = action.payload;
    },

    setServiceScheduleSlotEndDate(
      state: ServiceState,
      action: PayloadAction<any>
    ) {
      state.serviceScheduleSlotsEndDate = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getAllServices = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingServices());

  const response = await backOff(() => listAvailableServicesAPI(), {
    numOfAttempts: 3
  });
  if (!response.customErrorMessage) {
    dispatch(slice.actions.getAllServices(response.data.listServices));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    dispatch(stopLoadingServices());
  }

  return dispatch(stopLoadingServices());
};

export const createService =
  (service): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingService());
    dispatch(startLoadingServices());

    const response = await createServiceAPI(service);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createService(response.data.createService));
      dispatch(slice.actions.setSuccessMessage('Service successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingService());
      dispatch(stopLoadingServices());
    };

    return followUp();
  };

export const getService =
  (serviceId): AppThunk =>
  async (dispatch) => {
    const response = await getServiceAPI(serviceId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getService(response.data.getService));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedService({}));
};

export const deleteService =
  (serviceId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingServices());
    dispatch(startDeletingService());

    const response = await deleteServiceAPI(serviceId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteService(response.data.deleteService));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Service successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingService());
      dispatch(stopLoadingServices());
    };

    return followUp();
  };

export const editService =
  (service, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingService());

    const response = await updateServiceAPI(service);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editService(response.data.updateService));

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage('Service successfully updated')
        );
      }
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingService());
  };

export const getServiceSchedule =
  (serviceId, scheduleYear): AppThunk =>
  async (dispatch) => {
    const response = await getServiceScheduleAPI(serviceId, scheduleYear);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getServiceSchedule(response.data.getResourceSchedule)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getServiceSchedulesInRange =
  (serviceId, startTime, endTime): AppThunk =>
  async (dispatch) => {
    const response = await getServiceSchedulesInRangeAPI(
      serviceId,
      startTime,
      endTime
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getServiceSchedulesInRange(
          response.data.getResourceSchedulesInRange
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getServiceScheduleSlots =
  (serviceId, scheduleStartTime, scheduleEndTime): AppThunk =>
  async (dispatch) => {
    const response = await getServiceScheduleSlotsAPI(
      serviceId,
      scheduleStartTime,
      scheduleEndTime
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getServiceScheduleSlots(
          response.data.getResourceScheduleSlots
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const putServiceScheduleDay =
  (newServiceScheduleDay): AppThunk =>
  async (dispatch) => {
    const response = await putServiceScheduleDayAPI(newServiceScheduleDay);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.putServiceScheduleDay(
          response.data.putResourceScheduleDay
        )
      );

      dispatch(setSuccessMessage('Service schedule successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const upsertServiceSchedule =
  (newServiceSchedule): AppThunk =>
  async (dispatch) => {
    const response = await upsertServiceScheduleAPI(newServiceSchedule);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.upsertServiceSchedule(
          response.data.upsertResourceSchedule
        )
      );
      dispatch(setSuccessMessage('Service schedule successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const setServiceScheduleSlotStartDate =
  (startDate): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setServiceScheduleSlotStartDate(startDate));
  };

export const setServiceScheduleSlotEndDate =
  (endDate): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setServiceScheduleSlotEndDate(endDate));
  };

export const startLoadingServices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingServices(true));
};

export const stopLoadingServices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingServices(false));
};

export const startEditingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingService(true));
};

export const stopEditingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingService(false));
};

export const startCreatingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingService(true));
};

export const stopCreatingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingService(false));
};

export const startDeletingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingService(true));
};

export const stopDeletingService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingService(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

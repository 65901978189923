export const getCommunicationStatusLabel = (
  communicationStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Draft: {
        text: 'DRAFT',
        color: 'warning'
      },
      Pending: {
        text: 'PENDING',
        color: 'warning'
      },
      Sending: {
        text: 'SENDING',
        color: 'primary'
      },
      Sent: {
        text: 'SUCCESS',
        color: 'success'
      },
      Voided: {
        text: 'VOIDED',
        color: 'error'
      },
      Fail: {
        text: 'FAILED',
        color: 'error'
      }
    };

    const { text, color }: any = map[communicationStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

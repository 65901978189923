import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  listTodayCustomerClassAppointmentsQueryAPI,
  getFlowActivitySessionAPI,
  listFlowActivitySessionsAPI,
  createFlowActivitySessionAPI,
  updateFlowActivitySessionCustomerOrdersAPI,
  updateFlowActivitySessionStationsAPI,
  startFlowActivitySessionAPI,
  pauseFlowActivitySessionAPI,
  resumeFlowActivitySessionAPI,
  completeFlowActivitySessionAPI,
  checkInFlowActivitySessionCustomerActivityAPI,
  addFlowActivitySessionCustomerActivityAPI,
  removeFlowActivitySessionCustomerActivityAPI,
  processFlowActivitySessionAPI,
  moveCustomerActivityBackToWaitingAPI,
  forceRefreshFlowActivitySessionAPI
} from 'src/content/FlowActivitySessions/api';

import { listStationsAPI } from 'src/content/Stations/api';

const sortStationNames = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

interface FlowState {
  locationStations: any[];
  activeFlowSessions: any[];
  selectedFlowSession: any;
  isFlowProcessing: boolean;
  flowErrorMessage: string;
  flowSuccessMessage: string;
}

const initialState: FlowState = {
  locationStations: [],
  activeFlowSessions: [],
  selectedFlowSession: {},
  isFlowProcessing: false,
  flowErrorMessage: '',
  flowSuccessMessage: ''
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    listLocationStations(state: FlowState, action: PayloadAction<any>) {
      const sorted = action.payload.sort(sortStationNames);
      state.locationStations = [...sorted];
    },

    listFlowActivitySessions(state: FlowState, action: PayloadAction<any>) {
      state.activeFlowSessions = [...action.payload];
    },

    assignStationToActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      state.locationStations = state.locationStations.map((station) =>
        station.id === action.payload.id ? action.payload : station
      );
    },

    createAndStartFlowActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      state.activeFlowSessions = [action.payload, ...state.activeFlowSessions];
      state.selectedFlowSession = { ...action.payload };
    },

    removeFlowActivitySessionCustomerActivity(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      state.activeFlowSessions = [
        ...state.activeFlowSessions.map((flowSesh) =>
          flowSesh.id === action.payload.activitySessionId
            ? {
                ...flowSesh,
                waitingCustomerActivities:
                  flowSesh.waitingCustomerActivities.filter(
                    (customerActivity) =>
                      customerActivity.activityId !==
                      action.payload.customerActivityId
                  )
              }
            : flowSesh
        )
      ];
    },

    addedFlowActivitySession(state: FlowState, action: PayloadAction<any>) {
      state.activeFlowSessions = [action.payload, ...state.activeFlowSessions];
      state.selectedFlowSession = { ...action.payload };
    },

    updatedFlowActivitySession(state: FlowState, action: PayloadAction<any>) {
      state.activeFlowSessions = [
        ...state.activeFlowSessions.map((flowSesh) =>
          flowSesh?.id === action.payload?.id ? action.payload : flowSesh
        )
      ];

      if (state.selectedFlowSession?.id === action.payload?.id) {
        state.selectedFlowSession = {
          ...state.selectedFlowSession,
          ...action.payload
        };
      }
    },

    addedBlockInFlowActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      if (
        state.activeFlowSessions.find(
          (flowSesh) =>
            flowSesh.activityId === action.payload?.classAppointmentId
        )
      ) {
        state.activeFlowSessions = state.activeFlowSessions.map((flowSesh) =>
          flowSesh.activityId === action.payload?.classAppointmentId
            ? {
                ...flowSesh,
                waitingCustomerActivities: state.activeFlowSessions
                  .find(
                    (flowSesh) =>
                      flowSesh.activityId === action.payload?.classAppointmentId
                  )
                  .waitingCustomerActivities.map((customerActivity) =>
                    customerActivity.customerId === action.payload.customerId
                      ? {
                          ...customerActivity,
                          blocks: [...customerActivity.blocks, action.payload]
                        }
                      : customerActivity
                  )
              }
            : flowSesh
        );
      }

      if (
        state.selectedFlowSession?.activityId ===
        action.payload?.classAppointmentId
      ) {
        state.selectedFlowSession = {
          ...state.selectedFlowSession,
          waitingCustomerActivities:
            state.selectedFlowSession.waitingCustomerActivities.map(
              (customerActivity) =>
                customerActivity.customerId === action.payload.customerId
                  ? {
                      ...customerActivity,
                      blocks: [...customerActivity.blocks, action.payload]
                    }
                  : customerActivity
            )
        };
      }
    },

    updatedBlockInFlowActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      if (
        state.activeFlowSessions.find(
          (flowSesh) =>
            flowSesh.activityId === action.payload?.classAppointmentId
        )
      ) {
        state.activeFlowSessions = state.activeFlowSessions.map((flowSesh) =>
          flowSesh.activityId === action.payload?.classAppointmentId
            ? {
                ...flowSesh,
                waitingCustomerActivities: state.activeFlowSessions
                  .find(
                    (flowSesh) =>
                      flowSesh.activityId === action.payload?.classAppointmentId
                  )
                  .waitingCustomerActivities.map((customerActivity) =>
                    customerActivity.customerId === action.payload.customerId
                      ? {
                          ...customerActivity,
                          blocks: customerActivity.blocks.map((block) =>
                            block.id === action.payload.id
                              ? action.payload
                              : block
                          )
                        }
                      : customerActivity
                  )
              }
            : flowSesh
        );
      }

      if (
        state.selectedFlowSession?.activityId ===
        action.payload?.classAppointmentId
      ) {
        state.selectedFlowSession = {
          ...state.selectedFlowSession,
          waitingCustomerActivities:
            state.selectedFlowSession.waitingCustomerActivities.map(
              (customerActivity) =>
                customerActivity.customerId === action.payload.customerId
                  ? {
                      ...customerActivity,
                      blocks: customerActivity.blocks.map((block) =>
                        block.id === action.payload.id ? action.payload : block
                      )
                    }
                  : customerActivity
            )
        };
      }
    },

    setSelectedFlowActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      const matchedFlowSession =
        state.activeFlowSessions
          .filter((flowSesh) => flowSesh.status !== 'Completed')
          .find((flowSesh) => flowSesh.id === action.payload.id) || {};
      state.selectedFlowSession = { ...matchedFlowSession };
    },

    clearSelectedFlowActivitySession(
      state: FlowState,
      action: PayloadAction<any>
    ) {
      state.selectedFlowSession = {};
    },

    setIsFlowProcessing(state: FlowState, action: PayloadAction<any>) {
      state.isFlowProcessing = action.payload;
    },

    setErrorMessage(state: FlowState, action: PayloadAction<any>) {
      state.flowErrorMessage = action.payload;
    },

    setSuccessMessage(state: FlowState, action: PayloadAction<any>) {
      state.flowSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listLocationStations =
  (locationId): AppThunk =>
  async (dispatch) => {
    const response = await listStationsAPI(undefined, locationId, undefined);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.listLocationStations(response.data.listStations));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listFlowActivitySessions =
  (locationId, startTime, endTime): AppThunk =>
  async (dispatch) => {
    const response = await listFlowActivitySessionsAPI(
      locationId,
      startTime,
      endTime
    );
    console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listFlowActivitySessions(
          response.data.listFlowActivitySessions
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const createAndStartFlowActivitySession =
  (activityId, activityType, stationsData, otherProviderIds): AppThunk =>
  async (dispatch) => {
    // if (stationsData.length > 0) {
    console.log('create flow sesh');
    // Create flow activity session
    // const stationIds = stationsData.map((station) => station.stationId);
    const flowSeshResponse = await createFlowActivitySessionAPI(
      activityId,
      activityType,
      stationsData,
      otherProviderIds
    );

    console.log(flowSeshResponse);
    if (!flowSeshResponse.customErrorMessage) {
      // start flow activity session
      const startFlowSeshResponse = await startFlowActivitySessionAPI(
        flowSeshResponse.data.createFlowActivitySession.id
      );

      console.log(startFlowSeshResponse);

      if (!startFlowSeshResponse.customErrorMessage) {
        dispatch(
          slice.actions.createAndStartFlowActivitySession(
            startFlowSeshResponse.data.startFlowActivitySession
          )
        );
      } else {
        dispatch(
          slice.actions.setErrorMessage(
            startFlowSeshResponse.customErrorMessage
          )
        );
      }
    } else {
      dispatch(
        slice.actions.setErrorMessage(flowSeshResponse.customErrorMessage)
      );
    }
    // } else {
    //   dispatch(slice.actions.setErrorMessage('No stations selected'));
    // }
    return dispatch(setIsFlowProcessing(false));
  };

export const startFlowActivitySession =
  (flowActivitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await startFlowActivitySessionAPI(flowActivitySessionId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.startFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const pauseFlowActivitySession =
  (flowActivitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await pauseFlowActivitySessionAPI(flowActivitySessionId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.pauseFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(setIsFlowProcessing(false));
  };

export const resumeFlowActivitySession =
  (flowActivitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await resumeFlowActivitySessionAPI(flowActivitySessionId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.resumeFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const completeFlowActivitySession =
  (flowActivitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await completeFlowActivitySessionAPI(
      flowActivitySessionId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.completeFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const updateFlowActivitySessionStations =
  (flowActivitySessionId, stationIds, resumeSession = false): AppThunk =>
  async (dispatch) => {
    const response = await updateFlowActivitySessionStationsAPI(
      flowActivitySessionId,
      stationIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.updateFlowActivitySessionStations
        )
      );

      if (resumeSession) {
        return dispatch(resumeFlowActivitySession(flowActivitySessionId));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const updateFlowActivitySessionCustomerOrders =
  (flowActivitySessionId, customerActivityIds): AppThunk =>
  async (dispatch) => {
    const response = await updateFlowActivitySessionCustomerOrdersAPI(
      flowActivitySessionId,
      customerActivityIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.updateFlowActivitySessionCustomerOrders
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const checkInCustomerFlowActivitySession =
  (activityId, customerShortCode): AppThunk =>
  async (dispatch) => {
    const response = await checkInFlowActivitySessionCustomerActivityAPI(
      activityId,
      customerShortCode
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.checkInFlowActivitySessionCustomerActivity
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const moveCustomerActivityBackToWaiting =
  (activitySessionId, customerActivityId): AppThunk =>
  async (dispatch) => {
    const response = await moveCustomerActivityBackToWaitingAPI(
      activitySessionId,
      customerActivityId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.moveCustomerActivityBackToWaiting
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const removeFlowActivitySessionCustomerActivity =
  (activitySessionId, customerActivityId): AppThunk =>
  async (dispatch) => {
    const response = await removeFlowActivitySessionCustomerActivityAPI(
      activitySessionId,
      customerActivityId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.removeFlowActivitySessionCustomerActivity({
          activitySessionId,
          customerActivityId
        })
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const processFlowActivitySession =
  (activitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await processFlowActivitySessionAPI(activitySessionId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.processFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

export const forceRefreshFlowActivitySession =
  (activitySessionId): AppThunk =>
  async (dispatch) => {
    const response = await forceRefreshFlowActivitySessionAPI(
      activitySessionId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updatedFlowActivitySession(
          response.data.forceRefreshFlowActivitySession
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(setIsFlowProcessing(false));
  };

// subscriptions start here
export const addedFlowActivitySession =
  (newFlowActivitySession): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.addedFlowActivitySession({
        ...newFlowActivitySession
      })
    );
  };

export const updatedFlowActivitySession =
  (latestFlowActivitySession): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updatedFlowActivitySession({
        ...latestFlowActivitySession
      })
    );
  };

export const addedBlockInFlowActivitySession =
  (addedBlock): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addedBlockInFlowActivitySession(addedBlock));
  };

export const updatedBlockInFlowActivitySession =
  (updatedBlock): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updatedBlockInFlowActivitySession(updatedBlock));
  };

export const setSelectedFlowActivitySession =
  (flowActivitySessionId): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setSelectedFlowActivitySession({
        id: flowActivitySessionId
      })
    );
  };

export const clearSelectedFlowActivitySession =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedFlowActivitySession({}));
  };

export const setIsFlowProcessing =
  (value): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsFlowProcessing(value));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

export const getCustomerSectionsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    personal: true,
    contact: true,
    marketing: true,
    medical: true,
    nok: true,
    medicalCert: true,
    consent: true
  };

  try {
    const customerSectionsToShow = {
      cityosteophysio: {
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        personal: true,
        contact: true,
        marketing: true,
        medical: false,
        nok: true,
        medicalCert: false,
        consent: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // chirosg
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      }
    };

    const result = customerSectionsToShow[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCustomerFieldsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: true,
    fullName: true,
    preferredName: true,
    birthday: true,
    gender: true,
    nationality: true,
    maritalStatus: true,
    identityNumber: true,
    identityType: true,
    occupation: true,
    employer: true,

    // medical details
    noKnownDrugAllergy: true,
    drugAllergies: true,
    noKnownFoodAllergy: true,
    foodAllergies: true,
    noKnownOtherAllergy: true,
    otherAllergies: true,
    noKnownMedicalConditions: true,
    medicalConditions: true,
    vaccines: true,
    subsidySchemes: false,

    preferredProviderIds: false,
    strictlyProviders: false,

    // contact details
    mobileCountryCode: true,
    mobile: true,
    email: true,
    telephone2: true,
    telephone3: true,
    addressStreetAddress: true,
    addressUnitNo: true,
    addressCountry: true,
    addressPostalCode: true,

    // marketing details
    membershipTier: true,
    referralCategory: true,
    referralSource: true,
    referredBy: true,
    'marketingConsent.email': true,
    'marketingConsent.sms': true,
    'marketingConsent.whatsapp': true,
    'marketingConsent.phone': true,

    // nok details
    'nextOfKin.name': true,
    'nextOfKin.contact': true,
    'nextOfKin.relation': true
  };

  try {
    const customerFields = {
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: true,
        fullName: true,
        preferredName: true,
        birthday: true,
        gender: true,
        nationality: true,
        maritalStatus: true,
        identityNumber: true,
        identityType: true,
        occupation: true,
        employer: true,

        // medical details
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: true,
        telephone3: true,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        membershipTier: true,
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': true,
        'nextOfKin.contact': true,
        'nextOfKin.relation': true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // personal details
        title: true,
        fullName: true,
        preferredName: false,
        birthday: true,
        gender: true,
        nationality: true,
        maritalStatus: true,
        identityNumber: true,
        identityType: true,
        occupation: true,
        employer: true,

        // medical details
        noKnownDrugAllergy: true,
        drugAllergies: true,
        noKnownFoodAllergy: true,
        foodAllergies: true,
        noKnownOtherAllergy: true,
        otherAllergies: true,
        noKnownMedicalConditions: true,
        medicalConditions: true,
        vaccines: true,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: true,
        telephone3: true,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        membershipTier: true,
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': true,
        'nextOfKin.contact': true,
        'nextOfKin.relation': true
      }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCustomerFieldMandatoryRequirement = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: 'Title is a required field.',
    fullName: 'Full Name is a required field.',
    preferredName: 'First / Preferred Name is a required field.',
    birthday: false,
    gender: false,
    nationality: false,
    maritalStatus: false,
    identityNumber: false,
    identityType: false,
    occupation: false,
    employer: false,

    // medical details
    locationId: false,
    noKnownDrugAllergy: false,
    drugAllergies: false,
    noKnownFoodAllergy: false,
    foodAllergies: false,
    noKnownOtherAllergy: false,
    otherAllergies: false,
    noKnownMedicalConditions: false,
    medicalConditions: false,
    vaccines: false,
    subsidySchemes: false,

    preferredProviderIds: false,
    strictlyProviders: false,

    // contact details
    mobileCountryCode: 'Mobile Country Code is required.',
    mobile: 'Mobile is required.',
    email: 'Please enter a valid email address.',
    telephone2: false,
    telephone3: false,
    addressStreetAddress: false,
    addressUnitNo: false,
    addressCountry: false,
    addressPostalCode: false,

    // marketing details
    membershipTier: false,
    referralCategory: false,
    referralSource: false,
    referredBy: false,
    'marketingConsent.email': false,
    'marketingConsent.sms': false,
    'marketingConsent.whatsapp': false,
    'marketingConsent.phone': false,

    // nok details
    'nextOfKin.name': false,
    'nextOfKin.contact': false,
    'nextOfKin.relation': false
  };

  try {
    const customerFields = {
      cityosteophysio: {
        // personal details
        title: 'Title is a required field.',
        fullName: false,
        preferredName: 'First / Preferred Name is a required field.',
        birthday: false,
        gender: false,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        membershipTier: false,
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full Name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: false,
        gender: false,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        membershipTier: false,
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: false,
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: false,
        gender: false,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: false,
        email: false,
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        membershipTier: false,
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // CSG
        // personal details
        title: 'Title is a required field.',
        fullName: false,
        preferredName: 'First / Preferred Name is a required field.',
        birthday: false,
        gender: false,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        membershipTier: false,
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full Name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: false,
        gender: false,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        vaccines: false,
        subsidySchemes: false,

        preferredProviderIds: false,
        strictlyProviders: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        membershipTier: false,
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      }
      // '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': {
      //   // restoration room
      //   // personal details
      //   title: 'Title is a required field.',
      //   fullName: false,
      //   preferredName: 'First / Preferred Name is a required field.',
      //   birthday: false,
      //   gender: false,
      //   nationality: false,
      //   maritalStatus: false,
      //   identityNumber: false,
      //   identityType: false,
      //   occupation: false,
      //   employer: false,

      //   // medical details
      //   locationId: false,
      //   noKnownDrugAllergy: false,
      //   drugAllergies: false,
      //   noKnownFoodAllergy: false,
      //   foodAllergies: false,
      //   noKnownOtherAllergy: false,
      //   otherAllergies: false,
      //   noKnownMedicalConditions: false,
      //   medicalConditions: false,
      //   vaccines: false,
      //   subsidySchemes: false,

      //   preferredProviderIds: false,
      //   strictlyProviders: false,

      //   // contact details
      //   mobileCountryCode: 'Mobile Country Code is required.',
      //   mobile: 'Mobile is required.',
      //   email: 'Please enter a valid email address.',
      //   telephone2: false,
      //   telephone3: false,
      //   addressStreetAddress: false,
      //   addressUnitNo: false,
      //   addressCountry: false,
      //   addressPostalCode: false,

      //   // marketing details
      //   membershipTier: false,
      //   referralCategory: false,
      //   referralSource: false,
      //   referredBy: false,
      //   'marketingConsent.email': false,
      //   'marketingConsent.sms': false,
      //   'marketingConsent.whatsapp': false,
      //   'marketingConsent.phone': false,

      //   // nok details
      //   'nextOfKin.name': false,
      //   'nextOfKin.contact': false,
      //   'nextOfKin.relation': false
      // }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getTerminalSectionsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    personal: true,
    contact: true,
    marketing: true,
    medical: true,
    nok: true,
    medicalCert: true,
    consent: true
  };

  try {
    const customerSectionsToShow = {
      cityosteophysio: {
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: false,
        medicalCert: true,
        consent: true
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        personal: true,
        contact: true,
        marketing: true,
        medical: false,
        nok: true,
        medicalCert: false,
        consent: true
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // chirosg
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      }
    };

    const result = customerSectionsToShow[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getTerminalCustomerFieldsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: true,
    fullName: true,
    preferredName: true,
    birthday: true,
    gender: true,
    nationality: true,
    maritalStatus: true,
    identityNumber: true,
    identityType: true,
    occupation: true,
    employer: true,

    // medical details
    noKnownDrugAllergy: true,
    drugAllergies: true,
    noKnownFoodAllergy: true,
    foodAllergies: true,
    noKnownOtherAllergy: true,
    otherAllergies: true,
    noKnownMedicalConditions: true,
    medicalConditions: true,
    medicalConditionsList: false,
    pregnancyStatus: false,
    irregularPeriodStatus: false,
    vaccines: true,
    subsidySchemes: false,

    // contact details
    mobileCountryCode: true,
    mobile: true,
    email: true,
    telephone2: true,
    telephone3: true,
    addressStreetAddress: true,
    addressUnitNo: true,
    addressCountry: true,
    addressPostalCode: true,

    // marketing details
    referralCategory: true,
    referralSource: true,
    referredBy: true,
    'marketingConsent.email': true,
    'marketingConsent.sms': true,
    'marketingConsent.whatsapp': true,
    'marketingConsent.phone': true,

    // nok details
    'nextOfKin.name': true,
    'nextOfKin.contact': true,
    'nextOfKin.relation': true
  };

  try {
    const customerFields = {
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        // personal details
        title: true,
        fullName: true,
        preferredName: false,
        birthday: true,
        gender: true,
        nationality: true,
        maritalStatus: true,
        identityNumber: true,
        identityType: true,
        occupation: true,
        employer: true,

        // medical details
        noKnownDrugAllergy: true,
        drugAllergies: true,
        noKnownFoodAllergy: true,
        foodAllergies: true,
        noKnownOtherAllergy: true,
        otherAllergies: true,
        noKnownMedicalConditions: false,
        medicalConditions: true,
        medicalConditionsList: true,
        pregnancyStatus: true,
        irregularPeriodStatus: true,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: false,
        telephone3: false,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: true,
        fullName: true,
        preferredName: true,
        birthday: true,
        gender: true,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: true,
        employer: false,

        // medical details
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: true,
        telephone3: true,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': true,
        'nextOfKin.contact': true,
        'nextOfKin.relation': true
      }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getTerminalCustomerFieldMandatoryRequirement = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: 'Title is a required field.',
    fullName: 'Full name is a required field.',
    preferredName: 'First / Preferred Name is a required field.',
    birthday: 'Date of Birth is a required field.',
    gender: 'Gender is a required field.',
    nationality: false,
    maritalStatus: 'Marital Status is a required field.',
    identityNumber: 'Identification No. is a required field.',
    identityType: 'Identification Type is a required field.',
    occupation: false,
    employer: false,

    // medical details
    locationId: false,
    noKnownDrugAllergy: false,
    drugAllergies: false,
    noKnownFoodAllergy: false,
    foodAllergies: false,
    noKnownOtherAllergy: false,
    otherAllergies: false,
    noKnownMedicalConditions: false,
    medicalConditionsList: false,
    pregnancyStatus: false,
    irregularPeriodStatus: false,
    vaccines: false,
    subsidySchemes: false,

    // contact details
    mobileCountryCode: 'Mobile Country Code is required.',
    mobile: 'Mobile is required.',
    email: 'Please enter a valid email address.',
    telephone2: false,
    telephone3: false,
    addressStreetAddress: 'Address Street Name is required.',
    addressUnitNo: false,
    addressCountry: 'Address Country is required.',
    addressPostalCode: 'Address Postal Code is required.',

    // marketing details
    referralCategory: 'Referral Category is required.',
    referralSource: false,
    referredBy: false,
    'marketingConsent.email': false,
    'marketingConsent.sms': false,
    'marketingConsent.whatsapp': false,
    'marketingConsent.phone': false,

    // nok details
    'nextOfKin.name': false,
    'nextOfKin.contact': false,
    'nextOfKin.relation': false
  };

  try {
    const customerFields = {
      cityosteophysio: {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: 'Occupation is a required field.',
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: 'Address Unit No. is required.',
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: false,

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      }
      // '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': {
      //   // restoration room
      //   // personal details
      //   title: 'Title is a required field.',
      //   fullName: 'Full name is a required field.',
      //   preferredName: 'First / Preferred Name is a required field.',
      //   birthday: 'Date of Birth is a required field.',
      //   gender: 'Gender is a required field.',
      //   nationality: false,
      //   maritalStatus: 'Marital Status is a required field.',
      //   identityNumber: 'Identification No. is a required field.',
      //   identityType: 'Identification Type is a required field.',
      //   occupation: false,
      //   employer: false,

      //   // medical details
      //   locationId: false,
      //   noKnownDrugAllergy: false,
      //   drugAllergies: false,
      //   noKnownFoodAllergy: false,
      //   foodAllergies: false,
      //   noKnownOtherAllergy: false,
      //   otherAllergies: false,
      //   noKnownMedicalConditions: false,
      //   medicalConditions: false,
      //   medicalConditionsList: false,
      //   pregnancyStatus: false,
      //   irregularPeriodStatus: false,
      //   vaccines: false,
      //   subsidySchemes: false,

      //   // contact details
      //   mobileCountryCode: 'Mobile Country Code is required.',
      //   mobile: 'Mobile is required.',
      //   email: 'Please enter a valid email address.',
      //   telephone2: false,
      //   telephone3: false,
      //   addressStreetAddress: 'Address Street Name is required.',
      //   addressUnitNo: false,
      //   addressCountry: 'Address Country is required.',
      //   addressPostalCode: 'Address Postal Code is required.',

      //   // marketing details
      //   referralCategory: 'Referral Category is required.',
      //   referralSource: false,
      //   referredBy: false,
      //   'marketingConsent.email': false,
      //   'marketingConsent.sms': false,
      //   'marketingConsent.whatsapp': false,
      //   'marketingConsent.phone': false,

      //   // nok details
      //   'nextOfKin.name': 'N.O.K name is required',
      //   'nextOfKin.contact': 'N.O.K contact is required',
      //   'nextOfKin.relation': 'N.O.K relation is required'
      // }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPublicIntakeSectionsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    personal: true,
    contact: true,
    marketing: true,
    medical: true,
    nok: true,
    medicalCert: true,
    consent: true
  };

  try {
    const customerSectionsToShow = {
      cityosteophysio: {
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: false,
        medicalCert: true,
        consent: true
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        personal: true,
        contact: true,
        marketing: true,
        medical: false,
        nok: true,
        medicalCert: false,
        consent: true
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // chirosg
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        personal: true,
        contact: true,
        marketing: true,
        medical: true,
        nok: true,
        medicalCert: true,
        consent: true
      }
    };

    const result = customerSectionsToShow[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPublicIntakeCustomerFieldsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: true,
    fullName: true,
    preferredName: true,
    birthday: true,
    gender: true,
    nationality: true,
    maritalStatus: true,
    identityNumber: true,
    identityType: true,
    occupation: true,
    employer: true,

    // medical details
    noKnownDrugAllergy: true,
    drugAllergies: true,
    noKnownFoodAllergy: true,
    foodAllergies: true,
    noKnownOtherAllergy: true,
    otherAllergies: true,
    noKnownMedicalConditions: true,
    medicalConditions: true,
    medicalConditionsList: false,
    pregnancyStatus: false,
    irregularPeriodStatus: false,
    vaccines: true,
    subsidySchemes: false,

    // contact details
    mobileCountryCode: true,
    mobile: true,
    email: true,
    telephone2: true,
    telephone3: true,
    addressStreetAddress: true,
    addressUnitNo: true,
    addressCountry: true,
    addressPostalCode: true,

    // marketing details
    referralCategory: true,
    referralSource: true,
    referredBy: true,
    'marketingConsent.email': true,
    'marketingConsent.sms': true,
    'marketingConsent.whatsapp': true,
    'marketingConsent.phone': true,

    // nok details
    'nextOfKin.name': true,
    'nextOfKin.contact': true,
    'nextOfKin.relation': true
  };

  try {
    const customerFields = {
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        // personal details
        title: true,
        fullName: true,
        preferredName: false,
        birthday: true,
        gender: true,
        nationality: true,
        maritalStatus: true,
        identityNumber: true,
        identityType: true,
        occupation: true,
        employer: true,

        // medical details
        noKnownDrugAllergy: true,
        drugAllergies: true,
        noKnownFoodAllergy: true,
        foodAllergies: true,
        noKnownOtherAllergy: true,
        otherAllergies: true,
        noKnownMedicalConditions: false,
        medicalConditions: true,
        medicalConditionsList: true,
        pregnancyStatus: true,
        irregularPeriodStatus: true,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: false,
        telephone3: false,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: true,
        fullName: true,
        preferredName: true,
        birthday: true,
        gender: true,
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: true,
        employer: false,

        // medical details
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: true,
        mobile: true,
        email: true,
        telephone2: true,
        telephone3: true,
        addressStreetAddress: true,
        addressUnitNo: true,
        addressCountry: true,
        addressPostalCode: true,

        // marketing details
        referralCategory: true,
        referralSource: true,
        referredBy: true,
        'marketingConsent.email': true,
        'marketingConsent.sms': true,
        'marketingConsent.whatsapp': true,
        'marketingConsent.phone': true,

        // nok details
        'nextOfKin.name': true,
        'nextOfKin.contact': true,
        'nextOfKin.relation': true
      }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPublicIntakeCustomerFieldMandatoryRequirement = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    // personal details
    title: 'Title is a required field.',
    fullName: 'Full name is a required field.',
    preferredName: 'First / Preferred Name is a required field.',
    birthday: 'Date of Birth is a required field.',
    gender: 'Gender is a required field.',
    nationality: false,
    maritalStatus: 'Marital Status is a required field.',
    identityNumber: 'Identification No. is a required field.',
    identityType: 'Identification Type is a required field.',
    occupation: false,
    employer: false,

    // medical details
    locationId: false,
    noKnownDrugAllergy: false,
    drugAllergies: false,
    noKnownFoodAllergy: false,
    foodAllergies: false,
    noKnownOtherAllergy: false,
    otherAllergies: false,
    noKnownMedicalConditions: false,
    medicalConditions: false,
    medicalConditionsList: false,
    pregnancyStatus: false,
    irregularPeriodStatus: false,
    vaccines: false,
    subsidySchemes: false,

    // contact details
    mobileCountryCode: 'Mobile Country Code is required.',
    mobile: 'Mobile is required.',
    email: 'Please enter a valid email address.',
    telephone2: false,
    telephone3: false,
    addressStreetAddress: 'Address Street Name is required.',
    addressUnitNo: false,
    addressCountry: 'Address Country is required.',
    addressPostalCode: 'Address Postal Code is required.',

    // marketing details
    referralCategory: 'Referral Category is required.',
    referralSource: false,
    referredBy: false,
    'marketingConsent.email': false,
    'marketingConsent.sms': false,
    'marketingConsent.whatsapp': false,
    'marketingConsent.phone': false,

    // nok details
    'nextOfKin.name': 'N.O.K name is required',
    'nextOfKin.contact': 'N.O.K contact is required',
    'nextOfKin.relation': 'N.O.K relation is required'
  };

  try {
    const customerFields = {
      cityosteophysio: {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: 'Occupation is a required field.',
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: 'Address Unit No. is required.',
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: false,

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': false,
        'nextOfKin.contact': false,
        'nextOfKin.relation': false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: 'Marital Status is a required field.',
        identityNumber: 'Identification No. is a required field.',
        identityType: 'Identification Type is a required field.',
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: 'Address Street Name is required.',
        addressUnitNo: false,
        addressCountry: 'Address Country is required.',
        addressPostalCode: 'Address Postal Code is required.',

        // marketing details
        referralCategory: 'Referral Category is required.',
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // personal details
        title: 'Title is a required field.',
        fullName: 'Full name is a required field.',
        preferredName: 'First / Preferred Name is a required field.',
        birthday: 'Date of Birth is a required field.',
        gender: 'Gender is a required field.',
        nationality: false,
        maritalStatus: false,
        identityNumber: false,
        identityType: false,
        occupation: false,
        employer: false,

        // medical details
        locationId: false,
        noKnownDrugAllergy: false,
        drugAllergies: false,
        noKnownFoodAllergy: false,
        foodAllergies: false,
        noKnownOtherAllergy: false,
        otherAllergies: false,
        noKnownMedicalConditions: false,
        medicalConditions: false,
        medicalConditionsList: false,
        pregnancyStatus: false,
        irregularPeriodStatus: false,
        vaccines: false,
        subsidySchemes: false,

        // contact details
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Mobile is required.',
        email: 'Please enter a valid email address.',
        telephone2: false,
        telephone3: false,
        addressStreetAddress: false,
        addressUnitNo: false,
        addressCountry: false,
        addressPostalCode: false,

        // marketing details
        referralCategory: false,
        referralSource: false,
        referredBy: false,
        'marketingConsent.email': false,
        'marketingConsent.sms': false,
        'marketingConsent.whatsapp': false,
        'marketingConsent.phone': false,

        // nok details
        'nextOfKin.name': 'N.O.K name is required',
        'nextOfKin.contact': 'N.O.K contact is required',
        'nextOfKin.relation': 'N.O.K relation is required'
      }
      // '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': {
      //   // restoration room
      //   // personal details
      //   title: 'Title is a required field.',
      //   fullName: 'Full name is a required field.',
      //   preferredName: 'First / Preferred Name is a required field.',
      //   birthday: 'Date of Birth is a required field.',
      //   gender: 'Gender is a required field.',
      //   nationality: false,
      //   maritalStatus: 'Marital Status is a required field.',
      //   identityNumber: 'Identification No. is a required field.',
      //   identityType: 'Identification Type is a required field.',
      //   occupation: false,
      //   employer: false,

      //   // medical details
      //   locationId: false,
      //   noKnownDrugAllergy: false,
      //   drugAllergies: false,
      //   noKnownFoodAllergy: false,
      //   foodAllergies: false,
      //   noKnownOtherAllergy: false,
      //   otherAllergies: false,
      //   noKnownMedicalConditions: false,
      //   medicalConditions: false,
      //   pregnancyStatus: false,
      //   irregularPeriodStatus: false,
      //   vaccines: false,
      //   subsidySchemes: false,

      //   // contact details
      //   mobileCountryCode: 'Mobile Country Code is required.',
      //   mobile: 'Mobile is required.',
      //   email: 'Please enter a valid email address.',
      //   telephone2: false,
      //   telephone3: false,
      //   addressStreetAddress: 'Address Street Name is required.',
      //   addressUnitNo: false,
      //   addressCountry: 'Address Country is required.',
      //   addressPostalCode: 'Address Postal Code is required.',

      //   // marketing details
      //   referralCategory: 'Referral Category is required.',
      //   referralSource: false,
      //   referredBy: false,
      //   'marketingConsent.email': false,
      //   'marketingConsent.sms': false,
      //   'marketingConsent.whatsapp': false,
      //   'marketingConsent.phone': false,

      //   // nok details
      //   'nextOfKin.name': 'N.O.K name is required',
      //   'nextOfKin.contact': 'N.O.K contact is required',
      //   'nextOfKin.relation': 'N.O.K relation is required'
      // }
    };

    const result = customerFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getMembershipTierDetails = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    fillColour: {
      basic: '#0072ea'
    }
  };

  try {
    const membershipDetails = {
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        fillColour: {
          basic: '#0072EA',
          vip: '#FFD700'
        }
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // csg
        fillColour: {
          'flexi-osa': '#0072EA'
        }
      }
    };

    const result = membershipDetails[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQuickActionCustomerOptionalFieldsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    lastName: true
  };

  try {
    const optionalFields = {
      cityosteophysio: {
        lastName: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        lastName: false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        lastName: true
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // chirosg
        lastName: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        lastName: true
      }
    };

    const result = optionalFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCustomerFieldLabels = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    preferredName: 'First / Preferred Name',
    lastName: 'Last / Family Name',
    fullName: 'Full Name',
    mobileCountryCode: 'Mobile Country Code',
    mobile: 'Mobile',
    email: 'Email'
  };

  try {
    const fieldLabels = {
      cityosteophysio: {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        fullName: 'Full Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        fullName: 'Full Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Customer Mobile',
        email: 'Customer Email'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        preferredName: 'Patient Full Name as NRIC/Passport',
        lastName: 'Last / Family Name',
        fullName: 'Patient Full Name as NRIC/Passport',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        fullName: 'Full Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        fullName: 'Full Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      }
    };

    const result = fieldLabels[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const useCustomerPreferredNameInTerminal = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = true;

  try {
    const terminalPins = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true
    };

    const result = terminalPins[tenantIdLowerCase];

    if (result !== undefined) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

import { format, parseISO } from 'date-fns';

export const createOnlineBookingSuccessEmailTemplate = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>
    Dear {{{patient.preferredName}}},
    </p>
    <p>
    Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
    </p>
    <p>
    You may call {{location.telephones.[0]}} if you need help with your appointment.
    </p>
    <p>
    We look forward to seeing you soon!
    </p>
    <p>
    Thank you.
    </p>
    <br/>
    <p>
    Sincerely,<br/>
    {{{location.name}}}<br/>
    {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
    {{location.addressCountry}} {{location.addressPostalCode}}<br/>
    {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
    {{location.emails.[0]}}<br/>
    </p>
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
      <p>
      Dear {{{patient.preferredName}}},
      </p>
      <p>
      Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
      </p>
      <p>
      <b>Reschedule / Cancel Appointment:</b>
      </p>
      <p>
      If you need to reschedule or cancel your reservation, please click <a href="https://app.gethelm.io/booking/reschedule/{{tenantId}}/appointment?id={{appointmentId}}">here</a>.
      </p>
      <p>
      <b>Late Notice Fee:</b>
      </p>
      <p>
      If you need to reschedule an appointment, you must provide us with at least 24 hours' notice, otherwise, <b>a late notice fee will be charged.</b> Please click <a href="https://cityosteophysio.com/cancellation-policy">here</a> for more details.
      </p>
      <p>
      <b>Patients under the age of 18 must be accompanied by an adult.</b>
      </p>
      <p>
      You may call {{location.telephones.[0]}} if you need help with your appointment.
      </p>
      <p>
      We look forward to seeing you soon!
      </p>
      <p>
      Thank you.
      </p>
      <br/>
      <p>
      Sincerely,<br/>
      {{{location.name}}}<br/>
      {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
      {{location.addressCountry}} {{location.addressPostalCode}}<br/>
      {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
      {{location.emails.[0]}}<br/>
      </p>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
      <p> Dear {{{patient.preferredName}}}, </p>
      <p> Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked. Please arrive 15 minutes early to complete necessary registrations.  </p>
      <p> For bone setting we recommend that you wear comfortable and loose-fitting clothing during your treatment as there will be a lot of stretching involved. For female clients, we suggest wearing comfortable pants or slacks and well-covered tops to ensure maximum comfort during the treatment process. </p>
      <p> For acupuncture we recommend wearing loose, comfortable clothing during your visit. You may have a light meal at least 30 minutes before your treatment to avoid attending the session on an empty stomach. </p>
      <p> You may reschedule your appointment with minimum 72 hours' notice via the following <a href="https://app.gethelm.io/booking/reschedule/{{tenantId}}/appointment?id={{appointmentId}}">link</a>.
      </p>
      <p> For more information, please visit our frequently asked questions <a href="https://www.sgtittar.com/faqs/">here</a>. </p>
      <p> We look forward to seeing you soon! </p>
      <p> Thank you. </p>
      <br />
      <p> Sincerely, <br />
        {{{location.name}}} <br />
        {{{location.addressStreetAddress}}} {{location.addressUnitNo}}
        <br />
        {{location.addressCountry}} {{location.addressPostalCode}}
        <br />
        {{location.whatsapp.[0]}} (WhatsApp message only) <br />
        {{location.emails.[0]}}
        <br />
      </p>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
      <p>
      Dear {{{patient.preferredName}}},
      </p>
      <p>
      Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
      </p>
      <p>
      You may reschedule your appointment <a href="https://app.gethelm.io/booking/reschedule/{{tenantId}}/appointment?id={{appointmentId}}">here</a> or call {{location.telephones.[0]}} if you need help with your appointment.
      </p>
      <p>
      We look forward to seeing you soon!
      </p>
      <p>
      Thank you.
      </p>
      <br/>
      <p>
      Sincerely,<br/>
      {{{location.name}}}<br/>
      {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
      {{location.addressCountry}} {{location.addressPostalCode}}<br/>
      {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
      {{location.emails.[0]}}<br/>
      </p>
      `,
      '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': `
      <p>
      Dear {{{patient.preferredName}}},
      </p>
      <p>
      Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
      </p>
      <p>
      You may call {{location.telephones.[0]}} if you need help with your appointment.
      </p>
      <p>
      We look forward to seeing you soon!
      </p>
      <p>
      Thank you.
      </p>
      <br/>
      <p>
      Sincerely,<br/>
      {{{location.name}}}<br/>
      {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
      {{location.addressCountry}} {{location.addressPostalCode}}<br/>
      {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
      {{location.emails.[0]}}<br/>
      </p>
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>
        Dear {{{patient.preferredName}}},
        </p>
        <p>
        Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
        </p>
        <p>
        You may call {{location.telephones.[0]}} if you need help with your appointment.
        </p>
        <p>
        We look forward to seeing you soon!
        </p>
        <p>
        Thank you.
        </p>
        <br/>
        <p>
        Sincerely,<br/>
        {{{location.name}}}<br/>
        {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
        {{location.addressCountry}} {{location.addressPostalCode}}<br/>
        {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
        {{location.emails.[0]}}<br/>
        </p>
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>
        Dear {{{patient.preferredName}}},
        </p>
        <p>
        Your appointment with {{{practitioner.firstName}}} {{{practitioner.lastName}}} at {{{location.name}}} on {{appointmentTime}} has been booked.
        </p>
        <p>
        You may call {{location.telephones.[0]}} if you need help with your appointment.
        </p>
        <p>
        We look forward to seeing you soon!
        </p>
        <p>
        Thank you.
        </p>
        <br/>
        <p>
        Sincerely,<br/>
        {{{location.name}}}<br/>
        {{{location.addressStreetAddress}}} {{location.addressUnitNo}}<br/>
        {{location.addressCountry}} {{location.addressPostalCode}}<br/>
        {{location.telephones.[0]}} | {{location.whatsapp.[0]}} (WhatsApp)<br/>
        {{location.emails.[0]}}<br/>
        </p>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const createPatientSingleAppointmentEmailTemplate = (tenantId, data) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>Dear ${
      data?.patient?.piiProfile?.preferredName
    },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
    data?.practitioner?.lastName
  } at ${data?.location?.name} on ${format(
    parseISO(data?.appointmentStartTime),
    'EEEE, LLLL do, h:mm a'
  )} has been booked.</p><p>You may call ${
    data?.location?.telephones[0] || 'us'
  } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
    data?.location?.name
  }<br>${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br>${data?.location?.addressCountry} ${
    data?.location?.addressPostalCode
  }<br>${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br>${data?.location?.emails[0] || ''}<br></p>
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p><b>Late Notice Fee:</b></p><p>If you need to reschedule an appointment, you must provide us with at least 24 hours' notice, otherwise, <b>a late notice fee will be charged.</b> Please click <a href="https://cityosteophysio.com/cancellation-policy">here</a> for more details.</p><p><b>Patients under the age of 18 must be accompanied by an adult.</b></p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked. Please arrive 15 minutes early to complete necessary registrations.</p><p>For bone setting we recommend that you wear comfortable and loose-fitting clothing during your treatment as there will be a lot of stretching involved. For female clients, we suggest wearing comfortable pants or slacks and well-covered tops to ensure maximum comfort during the treatment process.</p><p>For acupuncture we recommend wearing loose, comfortable clothing during your visit. You may have a light meal at least 30 minutes before your treatment to avoid attending the session on an empty stomach.</p><p>For more information, please visit our frequently asked questions <a href="https://www.sgtittar.com/faqs/">here</a>.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>             
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>         
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
        Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.
      </p>                
      <p>
      You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.
      </p>
      <p>
      We look forward to seeing you soon!
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p> 
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const updatePatientSingleAppointmentEmailTemplate = (tenantId, data) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>Dear ${
      data?.patient?.piiProfile?.preferredName
    },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
    data?.practitioner?.lastName
  } at ${data?.location?.name} on ${format(
    parseISO(data?.appointmentStartTime),
    'EEEE, LLLL do, h:mm a'
  )} has been booked.</p><p>You may call ${
    data?.location?.telephones[0] || 'us'
  } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
    data?.location?.name
  }<br>${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br>${data?.location?.addressCountry} ${
    data?.location?.addressPostalCode
  }<br>${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br>${data?.location?.emails[0] || ''}<br></p>           
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p><b>Late Notice Fee:</b></p><p>If you need to reschedule an appointment, you must provide us with at least 24 hours' notice, otherwise, <b>a late notice fee will be charged.</b> Please click <a href="https://cityosteophysio.com/cancellation-policy">here</a> for more details.</p><p><b>Patients under the age of 18 must be accompanied by an adult.</b></p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked. Please arrive 15 minutes early to complete necessary registrations.</p><p>For bone setting we recommend that you wear comfortable and loose-fitting clothing during your treatment as there will be a lot of stretching involved. For female clients, we suggest wearing comfortable pants or slacks and well-covered tops to ensure maximum comfort during the treatment process.</p><p>For acupuncture we recommend wearing loose, comfortable clothing during your visit. You may have a light meal at least 30 minutes before your treatment to avoid attending the session on an empty stomach.</p><p>For more information, please visit our frequently asked questions <a href="https://www.sgtittar.com/faqs/">here</a>.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const cancelPatientSingleAppointmentEmailTemplate = (tenantId, data) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>Dear ${
      data?.patient?.piiProfile?.preferredName
    },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
    data?.practitioner?.lastName
  } at ${data?.location?.name} on ${format(
    parseISO(data?.appointmentStartTime),
    'EEEE, LLLL do, h:mm a'
  )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
    data?.location?.name
  }<br>${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br>${data?.location?.addressCountry} ${
    data?.location?.addressPostalCode
  }<br>${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br>${data?.location?.emails[0] || ''}<br></p>
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been cancelled.</p><p>If there is anything we can do to help, please feel free to reach out.</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const reschedulePatientSingleAppointmentEmailTemplate = (
  tenantId,
  data
) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>Dear ${
      data?.patient?.piiProfile?.preferredName
    },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
    data?.practitioner?.lastName
  } at ${data?.location?.name} has been rescheduled to ${format(
    parseISO(data?.appointmentStartTime),
    'EEEE, LLLL do, h:mm a'
  )}.</p><p>You may call ${
    data?.location?.telephones[0] || 'us'
  } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
    data?.location?.name
  }<br>${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br>${data?.location?.addressCountry} ${
    data?.location?.addressPostalCode
  }<br>${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br>${data?.location?.emails[0] || ''}<br></p>
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} has been rescheduled to ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )}.</p><p><b>Late Notice Fee:</b></p><p>If you need to reschedule an appointment, you must provide us with at least 24 hours' notice, otherwise,<b>a late notice fee will be charged.</b> Please click <a href="https://cityosteophysio.com/cancellation-policy">here</a> for more details.</p><p><b>Patients under the age of 18 must be accompanied by an adult.</b></p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} has been rescheduled to ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )}. Please arrive 15 minutes early to complete necessary registrations.</p><p>For bone setting we recommend that you wear comfortable and loose-fitting clothing during your treatment as there will be a lot of stretching involved. For female clients, we suggest wearing comfortable pants or slacks and well-covered tops to ensure maximum comfort during the treatment process.</p><p>For acupuncture we recommend wearing loose, comfortable clothing during your visit. You may have a light meal at least 30 minutes before your treatment to avoid attending the session on an empty stomach.</p><p>For more information, please visit our frequently asked questions <a href="https://www.sgtittar.com/faqs/">here</a>.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} on ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )} has been booked.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} has been rescheduled to ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )}.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>Dear ${
          data?.patient?.piiProfile?.preferredName
        },</p><p>Your appointment with ${data?.practitioner?.firstName} ${
        data?.practitioner?.lastName
      } at ${data?.location?.name} has been rescheduled to ${format(
        parseISO(data?.appointmentStartTime),
        'EEEE, LLLL do, h:mm a'
      )}.</p><p>You may call ${
        data?.location?.telephones[0] || 'us'
      } if you need help with your appointment.</p><p>We look forward to seeing you soon!</p><p>Thank you.</p><br><p>Sincerely,<br>${
        data?.location?.name
      }<br>${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br>${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br>${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br>${data?.location?.emails[0] || ''}<br></p>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const addCardEmailTemplate = (tenantId, data) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>
    Dear ${data?.patient?.piiProfile?.preferredName},
    </p>
    <p>
    To better facilitate the booking of your future appointments, kindly proceed to provide your card details through our payments provider.<br/>
    </p>
    <p style="text-align: center;">
    <a target="_blank" href=${
      data?.stripeURL
    } style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Provide Details Securely</a>
    </p>
    <p>Thank you.</p>
    <br/>
    <p>
    Sincerely,<br/>
    ${data?.location?.name}<br/>
    ${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br/>
    ${data?.location?.addressCountry} ${data?.location?.addressPostalCode}<br/>
    ${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br/>
    ${data?.location?.emails[0] || ''}<br/>
    </p>      
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
      <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
      To better facilitate the booking of your future appointments, kindly proceed to provide your card details through our payments provider.<br/>
      </p>
      <p style="text-align: center;">
      <a target="_blank" href=${
        data?.stripeURL
      } style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Provide Details Securely</a>
      </p>
      <p>
      Do note that the above link will be valid for 24 hours only. Read more <a href="https://cityosteophysio.com/credit-card-on-file-policy">here</a> for our credit card holding policy.
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>   
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
      <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
      To better facilitate the booking of your future appointments, kindly proceed to provide your card details through our payments provider.<br/>
      </p>
      <p style="text-align: center;">
      <a target="_blank" href=${
        data?.stripeURL
      } style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Provide Details Securely</a>
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>        
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>
        Dear ${data?.patient?.piiProfile?.preferredName},
        </p>
        <p>
        To better facilitate the booking of your future appointments, kindly proceed to provide your card details through our payments provider.<br/>
        </p>
        <p style="text-align: center;">
        <a target="_blank" href=${
          data?.stripeURL
        } style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Provide Details Securely</a>
        </p>
        <p>Thank you.</p>
        <br/>
        <p>
        Sincerely,<br/>
        ${data?.location?.name}<br/>
        ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
        ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
        ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
        ${data?.location?.emails[0] || ''}<br/>
        </p>      
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const sendPublicRegistrationEmailTemplate = (tenantId, data) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <p>
    Dear ${data?.patient?.piiProfile?.preferredName},
    </p>
    <p>
    As this is your first visit, we will need to register your details to serve you better. Skip the queue here:
    </p>
    <p style="text-align: center;">
    <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
    </p>
    <p>Thank you.</p>
    <br/>
    <p>
    Sincerely,<br/>
    ${data?.location?.name}<br/>
    ${data?.location?.addressStreetAddress} ${
    data?.location?.addressUnitNo
  }<br/>
      ${data?.location?.addressCountry} ${
    data?.location?.addressPostalCode
  }<br/>
      ${data?.location?.telephones[0] || ''} | ${
    data?.location?.whatsapp[0]
      ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
      : ''
  }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>      
    `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
      <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
      As this is your first visit, we wish to register your details ahead to serve you better. Skip the queue here:
      </p>
      <p style="text-align: center;">
      <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>   
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
      <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
      As this is your first visit, we wish to register your details ahead to serve you better. Skip the queue here:
      </p>
      <p style="text-align: center;">
      <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp message only)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>        
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
      <p>
      Dear ${data?.patient?.piiProfile?.preferredName},
      </p>
      <p>
      As this is your first visit, we wish to register your details ahead to serve you better. Skip the queue here:
      </p>
      <p style="text-align: center;">
      <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
      </p>
      <p>Thank you.</p>
      <br/>
      <p>
      Sincerely,<br/>
      ${data?.location?.name}<br/>
      ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
      ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
      ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
      ${data?.location?.emails[0] || ''}<br/>
      </p>  
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <p>
        Dear ${data?.patient?.piiProfile?.preferredName},
        </p>
        <p>
        As this is your first visit, we will need to register your details to serve you better. Skip the queue here:
        </p>
        <p style="text-align: center;">
        <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
        </p>
        <p>Thank you.</p>
        <br/>
        <p>
        Sincerely,<br/>
        ${data?.location?.name}<br/>
        ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
          ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
          ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
          ${data?.location?.emails[0] || ''}<br/>
        </p> 
      `,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': `
        <p>
        Dear ${data?.patient?.piiProfile?.preferredName},
        </p>
        <p>
        As this is your first visit, we will need to register your details to serve you better. Skip the queue here:
        </p>
        <p style="text-align: center;">
        <a target="_blank" href=${`${data?.registrationURL}`} style="background-color: #2542af;color: white;padding: 15px 25px;text-align: center;text-decoration: none;display: inline-block; margin-top: 30px; margin-bottom: 30px;">Register Securely</a>
        </p>
        <p>Thank you.</p>
        <br/>
        <p>
        Sincerely,<br/>
        ${data?.location?.name}<br/>
        ${data?.location?.addressStreetAddress} ${
        data?.location?.addressUnitNo
      }<br/>
              ${data?.location?.addressCountry} ${
        data?.location?.addressPostalCode
      }<br/>
              ${data?.location?.telephones[0] || ''} | ${
        data?.location?.whatsapp[0]
          ? data?.location?.whatsapp[0].concat(' (WhatsApp)')
          : ''
      }<br/>
        ${data?.location?.emails[0] || ''}<br/>
        </p> 
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);

    return fallback;
  }
};

export const getHandleAppointmentConfirmationEmailTemplate = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = null;

  try {
    const appointmentConfirmationEmailTemplateOptions = {
      cityosteophysio: {
        subject: 'Appointment Confirmation Request',
        templateId: '5b7a8bae-0c91-4f31-951d-a52e21e8daf9'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        subject: 'Appointment Reminder',
        templateId: '8973d2f3-d71f-4ca0-9277-91f4c8d74f3b' // SG Tittar
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        subject: 'Appointment Reminder',
        templateId: 'a40fb70d-d259-470f-b275-d80eceb879ec' // Q Menswear
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        subject: 'Appointment Reminder',
        templateId: 'cf17da3e-e276-4d49-8503-a5370c97639e' // CSG
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        subject: 'Appointment Reminder',
        templateId: 'a8df9909-5a00-455b-af4c-27b524077a54' // Keiki
      },
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': {
        subject: 'Appointment Reminder',
        templateId: 'a6992af5-b3e7-45c8-ac80-f7235227e85d' // The Rehab Lab
      }
    };

    const result =
      appointmentConfirmationEmailTemplateOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getHandleAppointmentConfirmationSMSTemplate = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = null;

  try {
    const appointmentConfirmationSMSTemplateOptions = {
      cityosteophysio: {
        subject: 'Appointment Confirmation Request',
        templateId: 'ae961936-867b-4d76-ad69-29092380b724'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        subject: 'Appointment Reminder',
        templateId: '5c7c507c-3a62-449a-8e95-ca5510e801b9' // SG Tittar
      }
    };

    const result = appointmentConfirmationSMSTemplateOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

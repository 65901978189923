import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';

import {
  listAllInventoriesAPI,
  listLocationInventoriesAPI,
  createInventoryAPI,
  getInventoryAPI,
  deleteInventoryAPI,
  updateInventoryAPI,
  listInventoryOnLoadAPI
} from '../content/Inventories/api';

interface InventoryState {
  isLoadingInventory: boolean;
  isEditingInventory: boolean;
  isCreatingInventory: boolean;
  isDeletingInventory: boolean;
  allInventory: any[];
  locationInventory: any[];
  inventoryDetail: any[];
  inventoryErrorMessage: string;
  inventorySuccessMessage: string;
}

const initialState: InventoryState = {
  isLoadingInventory: false,
  isEditingInventory: false,
  isCreatingInventory: false,
  isDeletingInventory: false,
  allInventory: [],
  locationInventory: [],
  inventoryDetail: [],
  inventoryErrorMessage: '',
  inventorySuccessMessage: ''
};

const slice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    getAllInventory(state: InventoryState, action: PayloadAction<any>) {
      const allInventory = action.payload;

      // IDEA: Sort the inventory by name here
      const sortByName = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      allInventory.sort(sortByName);

      state.allInventory = allInventory;

      state.isLoadingInventory = false;
    },

    getLocationInventory(state: InventoryState, action: PayloadAction<any>) {
      const locationInventory = action.payload;

      // IDEA: Sort the inventory by name here
      const sortByName = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      locationInventory.sort(sortByName);

      state.locationInventory = locationInventory;
    },

    createInventory(state: InventoryState, action: PayloadAction<any>) {
      const newInventory = action.payload;

      state.allInventory = [newInventory, ...state.allInventory];
    },

    getInventory(state: InventoryState, action: PayloadAction<any>) {
      if (action.payload) {
        // const inventory = action.payload;
        state.inventoryDetail = [action.payload];
      } else {
        state.inventoryDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedInventory(state: InventoryState, action: PayloadAction<any>) {
      state.inventoryDetail = [];
    },

    deleteInventory(state: InventoryState, action: PayloadAction<any>) {
      const delInventory = action.payload;

      let invIndex = state.allInventory.findIndex(
        (obj) => obj.id === delInventory.id
      );

      if (invIndex >= 0) {
        const newList = [...state.allInventory];
        newList.splice(invIndex, 1);
        state.allInventory = [...newList];
      } else {
        state.allInventory = [...state.allInventory];
      }

      let invLocIndex = state.locationInventory.findIndex(
        (obj) => obj.id === delInventory.id
      );

      if (invLocIndex >= 0) {
        const newList = [...state.locationInventory];
        newList.splice(invLocIndex, 1);
        state.locationInventory = [...newList];
      } else {
        state.locationInventory = [...state.locationInventory];
      }
    },

    editInventory(state: InventoryState, action: PayloadAction<any>) {
      const editedInventory = action.payload;

      let invIndex = state.allInventory.findIndex(
        (obj) => obj.id === editedInventory.id
      );

      if (invIndex >= 0) {
        const newList = [...state.allInventory];
        newList.splice(invIndex, 1);
        state.allInventory = [editedInventory, ...newList];
      } else {
        state.allInventory = [...state.allInventory];
      }

      let invLocIndex = state.locationInventory.findIndex(
        (obj) => obj.id === editedInventory.id
      );

      if (invLocIndex >= 0) {
        const newList = [...state.locationInventory];
        newList.splice(invLocIndex, 1);
        state.locationInventory = [editedInventory, ...newList];
      } else {
        state.locationInventory = [...state.locationInventory];
      }

      state.inventoryDetail = [editedInventory];
    },

    setIsLoadingInventory(state: InventoryState, action: PayloadAction<any>) {
      state.isLoadingInventory = action.payload;
    },

    setIsEditingInventory(state: InventoryState, action: PayloadAction<any>) {
      state.isEditingInventory = action.payload;
    },

    setIsCreatingInventory(state: InventoryState, action: PayloadAction<any>) {
      state.isCreatingInventory = action.payload;
    },

    setIsDeletingInventory(state: InventoryState, action: PayloadAction<any>) {
      state.isDeletingInventory = action.payload;
    },

    setErrorMessage(state: InventoryState, action: PayloadAction<any>) {
      state.inventoryErrorMessage = action.payload;
    },

    setSuccessMessage(state: InventoryState, action: PayloadAction<any>) {
      state.inventorySuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listInventoryOnLoad = (): AppThunk => async (dispatch) => {
  const response = await backOff(() => listInventoryOnLoadAPI(), {
    numOfAttempts: 3
  });

  if (!response.customErrorMessage) {
    dispatch(slice.actions.getAllInventory(response.data.listInventories));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const getAllInventory =
  (locationIds): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingAllInventory());

    const response = await backOff(() => listAllInventoriesAPI(locationIds), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getAllInventory(response.data.listInventories));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingAllInventory());
  };

export const getLocationInventory =
  (locationIds = ['']): AppThunk =>
  async (dispatch) => {
    const response = await backOff(
      () => listLocationInventoriesAPI(locationIds),
      { numOfAttempts: 3 }
    );
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getLocationInventory(response.data.listInventories)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const createInventory =
  (inventory): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingInventory());
    dispatch(startLoadingAllInventory());

    const response = await createInventoryAPI(inventory);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createInventory(response.data.createInventory));
      dispatch(
        slice.actions.setSuccessMessage('Inventory successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingInventory());
      dispatch(stopLoadingAllInventory());
    };

    return followUp();
  };

export const getInventory =
  (inventoryId): AppThunk =>
  async (dispatch) => {
    const response = await getInventoryAPI(inventoryId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getInventory(response.data.getInventory));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedInventory({}));
};

export const deleteInventory =
  (inventoryId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingAllInventory());
    dispatch(startDeletingInventory());

    const response = await deleteInventoryAPI(inventoryId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteInventory(response.data.deleteInventory));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Inventory successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingInventory());
      dispatch(stopLoadingAllInventory());
    };

    return followUp();
  };

export const editInventory =
  (inventory, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingInventory());

    const response = await updateInventoryAPI(inventory);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editInventory(response.data.updateInventory));

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage('Inventory successfully updated')
        );
      }
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingInventory());
  };

export const startLoadingAllInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingInventory(true));
};

export const stopLoadingAllInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingInventory(false));
};

export const startEditingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingInventory(true));
};

export const stopEditingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingInventory(false));
};

export const startCreatingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingInventory(true));
};

export const stopCreatingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingInventory(false));
};

export const startDeletingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingInventory(true));
};

export const stopDeletingInventory = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingInventory(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetPatientQuery,
  ListPatientPiiProfilesQuery,
  CreatePatientInput,
  UpdatePatientFullInput,
  DeletePatientMutationVariables,
  RemovePatientStripePaymentMethodMutation,
  RequestManualPatientStripeIntegrationMutation,
  GetCustomerByShortCodeQuery,
  MergeCustomerAccountsMutation, MergeCustomerAccountsMutationVariables
} from 'src/API';
import {
  createPatient,
  removePatientStripePaymentMethod,
  requestManualPatientStripeIntegration,
  mergeCustomerAccounts
} from 'src/graphql/mutations';
import {
  listReducedPatientPiiProfilesQuery,
  isExistingPatientQuery,
  getPatientInternalNote,
  customGetPatient,
  getPatientFlow,
  customGetCustomerByShortCode
} from './queries';
import {
  customUpdateCustomerFull,
  customUpdateCustomerInternalNote,
  customDeleteCustomer,
  customerUpdateCustomerProfilePicture,
  customUpdateCustomerEmail
} from './mutations';

export const createCustomerAPI = async (customer) => {
  try {
    const newCustomer = await callGraphQL<CreatePatientInput>(createPatient, {
      variables: {
        input: customer
      }
    });
    // console.log(newCustomer);
    return newCustomer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteCustomerAPI = async (customerId) => {
  try {
    const deletedCustomer = await callGraphQL<DeletePatientMutationVariables>(
      customDeleteCustomer,
      {
        variables: {
          patientId: customerId
        }
      }
    );
    // console.log(deletedCustomer);
    return deletedCustomer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePatientPersonalDetailsAPI = async (patient) => {
  try {
    const updatedPatient = await callGraphQL<UpdatePatientFullInput>(
      customUpdateCustomerFull,
      {
        variables: {
          input: patient
        }
      }
    );
    // console.log(updatedPatient);
    return updatedPatient;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCustomerEmailAPI = async (customer) => {
  try {
    const updatedPatient = await callGraphQL<UpdatePatientFullInput>(
      customUpdateCustomerEmail,
      {
        variables: {
          input: customer
        }
      }
    );
    // console.log(updatedPatient);
    return updatedPatient;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCustomerInternalNoteAPI = async (customer) => {
  try {
    const updatedCustomer = await callGraphQL<UpdatePatientFullInput>(
      customUpdateCustomerInternalNote,
      {
        variables: {
          input: customer
        }
      }
    );
    // console.log(updatedCustomer);
    return updatedCustomer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listReducedCustomerPiiProfilesAPI = async (queryTerm) => {
  try {
    let customersPiiData = await callGraphQL<ListPatientPiiProfilesQuery>(
      listReducedPatientPiiProfilesQuery,
      {
        variables: {
          searchTerm: queryTerm
        }
      }
    );
    return customersPiiData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const isExistingCustomerAPI = async (queryTerm) => {
  try {
    let customersPiiData = await callGraphQL<ListPatientPiiProfilesQuery>(
      isExistingPatientQuery,
      {
        variables: {
          searchTerm: queryTerm
        }
      }
    );
    return customersPiiData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCustomerAPI = async (customerId) => {
  try {
    const customerDetailData = await callGraphQL<GetPatientQuery>(
      customGetPatient,
      {
        variables: {
          id: customerId
        }
      }
    );
    // console.log(customerDetailData);
    return customerDetailData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCustomerInternalNoteAPI = async (customerId) => {
  try {
    const customerDetailData = await callGraphQL<GetPatientQuery>(
      getPatientInternalNote,
      {
        variables: {
          id: customerId
        }
      }
    );
    // console.log(customerDetailData);
    return customerDetailData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPatientFlowAPI = async (patientId) => {
  try {
    const patientDetailData = await callGraphQL<GetPatientQuery>(
      getPatientFlow,
      {
        variables: {
          id: patientId
        }
      }
    );
    // console.log(patientDetailData);
    return patientDetailData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteCustomerCreditCardAPI = async (
  patientId,
  paymentMethodId
) => {
  try {
    const deleteCustomerCreditCard =
      await callGraphQL<RemovePatientStripePaymentMethodMutation>(
        removePatientStripePaymentMethod,
        {
          variables: {
            input: {
              patientId,
              paymentMethodId
            }
          }
        }
      );
    // console.log(deleteCustomerCreditCard);
    return deleteCustomerCreditCard;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const addCustomerCreditCardAPI = async (customerId) => {
  try {
    const addCustomerCreditCard =
      await callGraphQL<RequestManualPatientStripeIntegrationMutation>(
        requestManualPatientStripeIntegration,
        {
          variables: {
            input: {
              patientId: customerId,
              successUrl: `https://app.gethelm.io/status/success`,
              failUrl: `https://app.gethelm.io/status/error`
            }
          }
        }
      );
    // console.log(addCustomerCreditCard);
    return addCustomerCreditCard;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCustomerProfilePictureAPI = async (customer) => {
  try {
    const updatedCustomer = await callGraphQL<UpdatePatientFullInput>(
      customerUpdateCustomerProfilePicture,
      {
        variables: {
          input: customer
        }
      }
    );
    // console.log(updatedCustomer);
    return updatedCustomer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCustomerByShortCodeAPI = async (customerShortCode) => {
  try {
    const customerData = await callGraphQL<GetCustomerByShortCodeQuery>(
      customGetCustomerByShortCode,
      {
        variables: {
          shortCode: customerShortCode
        }
      }
    );
    console.log(customerData);
    return customerData;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const mergeCustomerAPI = async (retainingCustomerId, removingCustomerId) => {
  try {
    const mergedCustomer = await callGraphQL<MergeCustomerAccountsMutationVariables>(mergeCustomerAccounts, {
      variables: {
        retainingCustomerId,
        removingCustomerId
      }
    });
    // console.log(mergedCustomer);
    return mergedCustomer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';
import { addDays, parseISO, formatISO } from 'date-fns';

import {
  createClassAppointmentAPI,
  getClassAppointmentAPI,
  getClassAppointmentSeriesAPI,
  listClassAppointmentsAPI,
  updateClassAppointmentAPI,
  updateClassAppointmentSeriesAPI,
  cancelClassAppointmentAPI,
  cancelClassAppointmentSeriesAPI,
  registerClassAppointmentPatientsAPI,
  unregisterClassAppointmentPatientsAPI,
  listPractitionerClassAppointmentsAPI
} from 'src/content/ClassAppointments/api';
import { updateClassEvent } from 'src/slices/calendar';

import { listClassApptDetailPatientDataAPI } from 'src/content/Appointments/api';

interface ClassAppointmentState {
  isLoadingClassAppointments: boolean;
  isLoadingClassParticipants: boolean;
  isEditingClassAppointment: boolean;
  isCreatingClassAppointment: boolean;
  isCancellingClassAppointment: boolean;
  isLoadingQueueClassAppointments: boolean;
  isRetrievingClassAppointment: boolean;
  isRetrievingQueueClassAppointment: boolean;
  allClassAppointmentsList: any[];
  queueClassAppointmentsList: any[];
  practitionerClassSchedule: any[];
  // selectedClassAppointment: any;
  queueSelectedClassAppointment: any;
  classAppointmentDetail: any[];
  classAppointmentDetailPatients: any[];
  classAppointmentSeries: any[];
  calendarSettings: any;
  classAppointmentErrorMessage: string;
  classAppointmentSuccessMessage: string;
}

const initialState: ClassAppointmentState = {
  isLoadingClassAppointments: false,
  isLoadingClassParticipants: false,
  isEditingClassAppointment: false,
  isCreatingClassAppointment: false,
  isCancellingClassAppointment: false,
  isLoadingQueueClassAppointments: false,
  isRetrievingClassAppointment: false,
  isRetrievingQueueClassAppointment: false,
  allClassAppointmentsList: [],
  queueClassAppointmentsList: [],
  practitionerClassSchedule: [],
  // selectedClassAppointment: {},
  queueSelectedClassAppointment: {},
  classAppointmentDetail: [],
  classAppointmentDetailPatients: [],
  classAppointmentSeries: [],
  calendarSettings: {},
  classAppointmentErrorMessage: '',
  classAppointmentSuccessMessage: ''
};

// IDEA: Sort the array of appointments by start time DESCending first before rendering below
const sortClassAppointments = (a, b) => {
  if (a.classAppointmentStartTime > b.classAppointmentStartTime) {
    return -1;
  }
  if (a.classAppointmentStartTime < b.classAppointmentStartTime) {
    return 1;
  }
  return 0;
};

// Sort by start time ASCending first
const sortClassAppointmentsAsc = (a, b) => {
  if (a.classAppointmentStartTime < b.classAppointmentStartTime) {
    return -1;
  }
  if (a.classAppointmentStartTime > b.classAppointmentStartTime) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'classAppointments',
  initialState,
  reducers: {
    listAllClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const allClassAppointments = action.payload;

      allClassAppointments.sort(sortClassAppointments);

      state.allClassAppointmentsList = [...allClassAppointments];
    },

    getQueueClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const queueClassAppointments = action.payload;

      queueClassAppointments.sort(sortClassAppointments);

      state.queueClassAppointmentsList = [...queueClassAppointments];
    },

    getPractitionerQueueClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const queueClassAppointments = action.payload;
      queueClassAppointments.sort(sortClassAppointments);

      state.queueClassAppointmentsList = [...queueClassAppointments];
    },

    getPractitionerClassSchedule(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const classAppointmentColourMapping =
        state.calendarSettings?.classAppointmentColourMapping;

      const practitionerClassAppts = action.payload;
      practitionerClassAppts.sort(sortClassAppointmentsAsc);

      // state.practitionerClassSchedule = practitionerClassAppts;

      const results = [...practitionerClassAppts].map((appt) => ({
        id: appt.id,
        title: `${appt?.classService?.name} (${appt?.patientIds?.length})`,
        description: `${appt?.classService?.name} at ${appt?.location?.code}`,
        start: parseISO(appt.classAppointmentStartTime),
        eventStartTime: appt.classAppointmentStartTime,
        eventEndTime: appt.classAppointmentEndTime,
        end: parseISO(appt.classAppointmentEndTime),
        allDay: false,
        color:
          classAppointmentColourMapping.find(
            (item) => item.category === appt?.classService?.category
          )?.colourCode || '#808080',
        status: appt?.status,

        patientId: appt?.patient?.id,
        type: appt?.recurFrequency,
        category: appt?.classService?.category,
        practitionerName: appt?.practitioner?.preferredName
      }));

      state.practitionerClassSchedule = results;
    },

    clearPractitionerClassSchedule(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.practitionerClassSchedule = [];
    },

    createClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const newClassAppointments = action.payload;

      state.allClassAppointmentsList = [
        ...newClassAppointments,
        ...state.allClassAppointmentsList
      ];
    },

    getClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.classAppointmentDetail = [action.payload];
      } else {
        state.classAppointmentDetail = [{ id: 'Not Found' }];
      }
    },

    getQueueSelectedClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.queueSelectedClassAppointment = action.payload;
      } else {
        state.queueSelectedClassAppointment = { id: 'Not Found' };
      }
    },

    clearSelectedClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.classAppointmentDetail = [];
      state.classAppointmentSeries = [];
      state.classAppointmentDetailPatients = [];
    },

    clearQueueSelectedClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.queueSelectedClassAppointment = {};
    },

    // Not in use currently
    // deleteClassAppointment(
    //   state: ClassAppointmentState,
    //   action: PayloadAction<any>
    // ) {
    //   const delClassAppointment = action.payload;

    //   let classIndex = state.allClassAppointmentsList.findIndex(
    //     (obj) => obj.id === delClassAppointment.id
    //   );

    //   if (classIndex >= 0) {
    //     const newList = [...state.allClassAppointmentsList];
    //     newList.splice(classIndex, 1);
    //     state.allClassAppointmentsList = [...newList];
    //   } else {
    //     state.allClassAppointmentsList = [...state.allClassAppointmentsList];
    //   }
    // },

    editClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const editedClassAppointment = action.payload;

      // if it is in queue, need to update
      if (
        state.queueSelectedClassAppointment.id === editedClassAppointment.id
      ) {
        state.queueSelectedClassAppointment = {
          ...state.queueSelectedClassAppointment,
          ...editedClassAppointment
        };
      }

      // refresh selected classAppointment
      const classIndex = state.allClassAppointmentsList.findIndex(
        (obj) => obj.id === editedClassAppointment.id
      );

      if (classIndex >= 0) {
        const newList = [...state.allClassAppointmentsList];
        newList.splice(classIndex, 1);
        state.allClassAppointmentsList = [editedClassAppointment, ...newList];
      } else {
        state.allClassAppointmentsList = [...state.allClassAppointmentsList];
      }

      // refresh selected classAppointment in the Queue
      const classQueueIndex = state.queueClassAppointmentsList.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (classQueueIndex >= 0) {
        state.queueClassAppointmentsList = state.queueClassAppointmentsList.map(
          (classAppt, i) => (i === classQueueIndex ? action.payload : classAppt)
        );
      }

      // refresh classAppointment series containing selected classAppointment
      const classSeriesIndex = state.classAppointmentSeries.findIndex(
        (item) => item.id === editedClassAppointment.id
      );

      if (classSeriesIndex >= 0) {
        const newList = [...state.classAppointmentSeries];
        newList.splice(classSeriesIndex, 1);
        state.classAppointmentSeries = [editedClassAppointment, ...newList];
      } else {
        state.classAppointmentSeries = [...state.classAppointmentSeries];
      }

      state.classAppointmentDetail = [editedClassAppointment];

      state.queueSelectedClassAppointment =
        state.queueSelectedClassAppointment?.id === editedClassAppointment?.id
          ? editedClassAppointment
          : state.queueSelectedClassAppointment;
    },

    cancelClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      const cancelledClassAppointment = action.payload;

      let classIndex = state.allClassAppointmentsList.findIndex(
        (obj) => obj.id === cancelledClassAppointment.id
      );

      if (classIndex >= 0) {
        const newList = [...state.allClassAppointmentsList];
        newList.splice(classIndex, 1);
        state.allClassAppointmentsList = [
          cancelledClassAppointment,
          ...newList
        ];
      } else {
        state.allClassAppointmentsList = [...state.allClassAppointmentsList];
      }

      let classSeriesIndex = state.classAppointmentSeries.findIndex(
        (item) => item.id === cancelledClassAppointment.id
      );

      if (classSeriesIndex >= 0) {
        const newList = [...state.classAppointmentSeries];
        newList.splice(classSeriesIndex, 1);
        state.classAppointmentSeries = [cancelledClassAppointment, ...newList];
      } else {
        state.classAppointmentSeries = [...state.classAppointmentSeries];
      }

      state.classAppointmentDetail = [cancelledClassAppointment];
    },

    getClassAppointmentSeries(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        // state.classAppointmentSeries = [...action.payload];

        const classAppointmentSeries = action.payload;

        classAppointmentSeries.sort(sortClassAppointmentsAsc);

        state.classAppointmentSeries = [...classAppointmentSeries];
      } else {
        state.classAppointmentSeries = [{ id: 'Not Found' }];
      }
    },

    editClassAppointmentSeries(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        const editedClassAppointmentSeries = [...action.payload];

        if (state.classAppointmentDetail.length > 0) {
          const selectedClassAppointment = state.classAppointmentDetail[0];

          let classIndex = editedClassAppointmentSeries.findIndex(
            (obj) => obj.id === selectedClassAppointment.id
          );

          if (classIndex >= 0) {
            const newList = [...editedClassAppointmentSeries].slice(
              classIndex,
              classIndex + 1
            );

            state.classAppointmentDetail = [...newList];
          }
        }

        state.classAppointmentSeries = [...action.payload];
      } else {
        state.classAppointmentSeries = [{ id: 'Not Found' }];
      }
    },

    cancelClassAppointmentSeries(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        const cancelledClassAppointmentSeries = [...action.payload];

        if (state.classAppointmentDetail.length > 0) {
          const selectedClassAppointment = state.classAppointmentDetail[0];

          let classIndex = cancelledClassAppointmentSeries.findIndex(
            (obj) => obj.id === selectedClassAppointment.id
          );

          if (classIndex >= 0) {
            const newList = [...cancelledClassAppointmentSeries].slice(
              classIndex,
              classIndex + 1
            );

            state.classAppointmentDetail = [...newList];
          }
        }

        state.classAppointmentSeries = [...action.payload];
      } else {
        state.classAppointmentSeries = [{ id: 'Not Found' }];
      }
    },

    getClassAppointmentPatientAppts(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.classAppointmentDetailPatients = action.payload;
      }
    },

    updateClassAppointmentPatientStatus(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        const patientApptIdsToUpdate = action.payload?.patientApptIds;
        const newStatus = action.payload?.newStatus;

        const newClassAppointmentDetailPatients = [
          ...state.classAppointmentDetailPatients
        ]?.map((appt) => {
          if (patientApptIdsToUpdate?.includes(appt.id)) {
            const updatedAppointment = { ...appt, status: newStatus };

            return updatedAppointment;
          } else {
            return appt;
          }
        });

        state.classAppointmentDetailPatients = [
          ...newClassAppointmentDetailPatients
        ];
      }
    },

    updatedClassAppointmentPatientData(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.classAppointmentDetailPatients =
          state.classAppointmentDetailPatients.map((appt) =>
            appt.id === action.payload.id &&
            appt.classAppointmentId === action.payload.classAppointmentId
              ? { ...appt, ...action.payload }
              : appt
          );
      }
    },

    updatedClassAppointmentQueueClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.queueClassAppointmentsList = state.queueClassAppointmentsList.map(
        (appt) => (appt.id === action.payload.id ? action.payload : appt)
      );

      if (state.queueSelectedClassAppointment.id === action.payload.id) {
        state.queueSelectedClassAppointment = {
          ...state.queueSelectedClassAppointment,
          ...action.payload
        };
      }
    },

    setIsLoadingClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingClassAppointments = action.payload;
    },

    setIsLoadingQueueClassAppointments(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingQueueClassAppointments = action.payload;
    },

    setIsRetrievingClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isRetrievingClassAppointment = action.payload;
    },

    setIsRetrievingQueueClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isRetrievingQueueClassAppointment = action.payload;
    },

    setIsLoadingClassParticipants(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingClassParticipants = action.payload;
    },

    setIsEditingClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isEditingClassAppointment = action.payload;
    },

    setIsCreatingClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCreatingClassAppointment = action.payload;
    },

    setIsCancellingClassAppointment(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCancellingClassAppointment = action.payload;
    },

    storeClassApptCalendarSettings(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      // state.calendarSettings = action.payload;

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings?.calendars?.formOptions);
      state.calendarSettings = { ...settings?.calendars?.formOptions };
    },

    setErrorMessage(state: ClassAppointmentState, action: PayloadAction<any>) {
      state.classAppointmentErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: ClassAppointmentState,
      action: PayloadAction<any>
    ) {
      state.classAppointmentSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listAllClassAppointments =
  (
    classAppointmentStartDate,
    classAppointmentEndDate,
    practitionerIds?,
    locationIds?,
    classServiceIds?,
    statuses?
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingClassAppointments());

    const response = await listClassAppointmentsAPI(
      classAppointmentStartDate,
      classAppointmentEndDate,
      locationIds,
      practitionerIds,
      classServiceIds,
      statuses
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listAllClassAppointments(
          response.data.listClassAppointments
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingClassAppointments());
  };

export const getQueueClassAppointments =
  (classAppointmentStartDate, classAppointmentEndDate, locationIds): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingQueueClassAppointments());

    const response = await listClassAppointmentsAPI(
      classAppointmentStartDate,
      classAppointmentEndDate,
      locationIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getQueueClassAppointments(
          response.data.listClassAppointments
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingQueueClassAppointments());
  };

export const getPractitionerQueueClassAppointments =
  (
    classAppointmentStartDate,
    classAppointmentEndDate,
    locationIds,
    practitionerIds
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingQueueClassAppointments());

    const response = await listClassAppointmentsAPI(
      classAppointmentStartDate,
      classAppointmentEndDate,
      locationIds,
      practitionerIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getPractitionerQueueClassAppointments(
          response.data.listClassAppointments
        )
      );
      // dispatch(stopQueueLoading());
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingQueueClassAppointments());
  };

export const createClassAppointment =
  (classAppt): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingClassAppointment());
    dispatch(startLoadingClassAppointments());

    const response = await createClassAppointmentAPI(classAppt);
    // console.log(response)
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createClassAppointment(
          response.data.createClassAppointment
        )
      );

      // this updates the calendar too
      dispatch(updateClassEvent(response.data.createClassAppointment));

      dispatch(slice.actions.setSuccessMessage('Classes successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingClassAppointment());
      dispatch(stopLoadingClassAppointments());
    };

    return followUp();
  };

export const getClassAppointment =
  (classAppointmentId): AppThunk =>
  async (dispatch) => {
    dispatch(startRetrievingClassAppointment());

    const response = await getClassAppointmentAPI(classAppointmentId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getClassAppointment(response.data.getClassAppointment)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopRetrievingClassAppointment());
  };

export const getQueueSelectedClassAppointment =
  (classAppointmentId): AppThunk =>
  async (dispatch) => {
    dispatch(startRetrievingQueueClassAppointment());

    const response = await getClassAppointmentAPI(classAppointmentId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getQueueSelectedClassAppointment(
          response.data.getClassAppointment
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopRetrievingQueueClassAppointment());
  };

export const getPractitionerClassSchedule =
  (fromDate, toDate, practitionerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingClassAppointments());

    const response = await listPractitionerClassAppointmentsAPI(
      fromDate,
      toDate,
      practitionerId
    );
    // console.log(response);

    const results = [...response?.data?.listClassAppointments];

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPractitionerClassSchedule(results));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingClassAppointments());
  };

export const clearPractitionerClassSchedule =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearPractitionerClassSchedule('clear'));
  };

export const getClassAppointmentSeries =
  (classAppointmentId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingClassAppointments());

    const response = await getClassAppointmentSeriesAPI(classAppointmentId);

    if (!response.customErrorMessage) {
      // console.log(response.data.getClassAppointmentSeries);
      dispatch(
        slice.actions.getClassAppointmentSeries(
          response.data.getClassAppointmentSeries
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingClassAppointments());
  };

export const clearSelectedClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedClassAppointment({}));
  };

export const clearQueueSelectedClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearQueueSelectedClassAppointment({}));
  };

export const editClassAppointment =
  (classAppointment): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingClassAppointment());

    const response = await updateClassAppointmentAPI(classAppointment);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editClassAppointment(response.data.updateClassAppointment)
      );

      // this updates the calendar too
      dispatch(updateClassEvent([response.data.updateClassAppointment]));

      dispatch(slice.actions.setSuccessMessage('Class successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingClassAppointment());
  };

export const editClassAppointmentClassNote =
  (classAppointment): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingClassAppointment());

    const response = await updateClassAppointmentAPI(classAppointment);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editClassAppointment(response.data.updateClassAppointment)
      );

      // this updates the calendar too
      dispatch(updateClassEvent([response.data.updateClassAppointment]));

      dispatch(
        slice.actions.setSuccessMessage('Class Note successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingClassAppointment());
  };

export const editClassAppointmentSeries =
  (classAppointmentSeries): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingClassAppointment());

    const response = await updateClassAppointmentSeriesAPI(
      classAppointmentSeries
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editClassAppointmentSeries(
          response.data.updateClassAppointmentSeries
        )
      );
      dispatch(slice.actions.setSuccessMessage('Classes successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingClassAppointment());
  };

export const cancelClassAppointment =
  (cancelledClassAppointmentId, cancellationNote, sendNotification): AppThunk =>
  async (dispatch) => {
    dispatch(startCancellingClassAppointment());
    dispatch(startLoadingClassAppointments());

    const response = await cancelClassAppointmentAPI(
      cancelledClassAppointmentId,
      cancellationNote,
      sendNotification
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.cancelClassAppointment(
          response.data.cancelClassAppointment
        )
      );

      dispatch(
        getClassAppointmentPatientAppts(
          [response.data.cancelClassAppointment.id],
          formatISO(
            parseISO(
              response.data.cancelClassAppointment.classAppointmentStartTime
            ),
            {
              representation: 'date'
            }
          ),
          formatISO(
            addDays(
              parseISO(
                response.data.cancelClassAppointment.classAppointmentEndTime
              ),
              1
            ),
            {
              representation: 'date'
            }
          )
        )
      );
      dispatch(slice.actions.setSuccessMessage('Class successfully cancelled'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCancellingClassAppointment());
      dispatch(stopLoadingClassAppointments());
    };

    return followUp();
  };

export const cancelClassAppointmentSeries =
  (cancelledClassAppointmentId, cancellationNote, sendNotification): AppThunk =>
  async (dispatch) => {
    dispatch(startCancellingClassAppointment());
    dispatch(startLoadingClassAppointments());

    const response = await cancelClassAppointmentSeriesAPI(
      cancelledClassAppointmentId,
      cancellationNote,
      sendNotification
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.cancelClassAppointmentSeries(
          response.data.cancelClassAppointmentSeries
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Classes successfully cancelled')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCancellingClassAppointment());
      dispatch(stopLoadingClassAppointments());
    };

    return followUp();
  };

export const registerClassApptPatients =
  (classApptPatients): AppThunk =>
  async (dispatch) => {
    // dispatch(startEditingClassAppointment());
    dispatch(startLoadingClassParticipants());

    const response = await registerClassAppointmentPatientsAPI(
      classApptPatients
    );
    // console.log(response);
    if (!response.customErrorMessage) {
      const classAppt = response.data.registerClassAppointmentPatients;

      dispatch(slice.actions.editClassAppointment(classAppt));

      setTimeout(function () {
        dispatch(
          getClassAppointmentPatientAppts(
            [classAppt.id],
            formatISO(parseISO(classAppt.classAppointmentStartTime), {
              representation: 'date'
            }),
            formatISO(addDays(parseISO(classAppt.classAppointmentEndTime), 1), {
              representation: 'date'
            })
          )
        );
      }, 1500);

      // if register for recurring classes, just force refresh page, else we can use redux state to update
      if (classApptPatients[0]?.recurFrequency !== null) {
        window.location.reload();
      } else {
        dispatch(
          slice.actions.setSuccessMessage('Participant successfully registered')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopLoadingClassParticipants());
      // dispatch(stopEditingClassAppointment());
    };
    return followUp();
  };

export const unregisterClassApptPatients =
  (classApptPatients): AppThunk =>
  async (dispatch) => {
    // dispatch(startEditingClassAppointment());
    dispatch(startLoadingClassParticipants());

    const response = await unregisterClassAppointmentPatientsAPI(
      classApptPatients
    );

    if (!response.customErrorMessage) {
      const classAppt = response.data.unregisterClassAppointmentPatients;

      dispatch(slice.actions.editClassAppointment(classAppt));

      setTimeout(function () {
        dispatch(
          getClassAppointmentPatientAppts(
            [classAppt.id],
            formatISO(parseISO(classAppt.classAppointmentStartTime), {
              representation: 'date'
            }),
            formatISO(addDays(parseISO(classAppt.classAppointmentEndTime), 1), {
              representation: 'date'
            })
          )
        );
      }, 1500);

      // if register for recurring classes, just force refresh page, else we can use redux state to update
      if (classApptPatients?.length > 1) {
        window.location.reload();
      } else {
        dispatch(
          slice.actions.setSuccessMessage('Participant successfully removed')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopLoadingClassParticipants());
      // dispatch(stopEditingClassAppointment());
    };
    return followUp();
  };

export const getClassAppointmentPatientAppts =
  (classApptId, apptStartTime, apptEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingClassParticipants());

    const response = await listClassApptDetailPatientDataAPI(
      classApptId,
      apptStartTime,
      apptEndTime
    );
    // console.log(response);
    if (!response.customErrorMessage) {
      // console.log('success');
      dispatch(
        slice.actions.getClassAppointmentPatientAppts(
          response.data.listAppointments
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingClassParticipants());
  };

export const updateClassAppointmentPatientStatus =
  (patientApptIds, newStatus): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updateClassAppointmentPatientStatus({
        patientApptIds: patientApptIds,
        newStatus: newStatus
      })
    );
  };

export const updatedClassAppointmentPatientData =
  (patientData): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updatedClassAppointmentPatientData(patientData));
  };

export const updatedClassAppointmentQueueClassAppointments =
  (classAppointmentData): AppThunk =>
  async (dispatch) => {
    // console.log(classAppointmentData);
    dispatch(
      slice.actions.updatedClassAppointmentQueueClassAppointments(
        classAppointmentData
      )
    );
  };

export const startLoadingClassAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingClassAppointments(true));
  };

export const stopLoadingClassAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingClassAppointments(false));
  };

export const startLoadingQueueClassAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingQueueClassAppointments(true));
  };

export const stopLoadingQueueClassAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingQueueClassAppointments(false));
  };

export const startRetrievingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsRetrievingClassAppointment(true));
  };

export const stopRetrievingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsRetrievingClassAppointment(false));
  };

export const startRetrievingQueueClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsRetrievingQueueClassAppointment(true));
  };

export const stopRetrievingQueueClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsRetrievingQueueClassAppointment(false));
  };

export const startLoadingClassParticipants =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsEditingClassAppointment(true));
    dispatch(slice.actions.setIsLoadingClassParticipants(true));
  };

export const stopLoadingClassParticipants =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsEditingClassAppointment(false));
    dispatch(slice.actions.setIsLoadingClassParticipants(false));
  };

export const startEditingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsEditingClassAppointment(true));
  };

export const stopEditingClassAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingClassAppointment(false));
};

export const startCreatingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingClassAppointment(true));
  };

export const stopCreatingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingClassAppointment(false));
  };

export const startCancellingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCancellingClassAppointment(true));
  };

export const stopCancellingClassAppointment =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCancellingClassAppointment(false));
  };

export const storeClassApptCalendarSettings = function (
  calendarSettings: any
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.storeClassApptCalendarSettings(calendarSettings));
  };
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

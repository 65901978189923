import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { CustomerFeedback } from '../API';

import {
  listCustomerFeedbackAPI,
  getCustomerFeedbackAPI,
  voidCustomerFeedbackAPI
} from '../content/Feedback/api';

interface FeedbackState {
  isLoadingCustomerFeedback: boolean;
  customerFeedback: CustomerFeedback[];
  locationFeedback: CustomerFeedback[];
  feedbackDetail: any[];
  feedbackErrorMessage: string;
  feedbackSuccessMessage: string;
}

const initialState: FeedbackState = {
  isLoadingCustomerFeedback: false,
  customerFeedback: [],
  locationFeedback: [],
  feedbackDetail: [],
  feedbackErrorMessage: '',
  feedbackSuccessMessage: ''
};

const sortFeedbacksDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    listFeedbackByCustomer(state: FeedbackState, action: PayloadAction<any>) {
      let feedbacks = [...action.payload];
      feedbacks.sort(sortFeedbacksDesc);

      state.customerFeedback = [...feedbacks];

      state.isLoadingCustomerFeedback = false;
    },

    listFeedbackByLocation(state: FeedbackState, action: PayloadAction<any>) {
      let feedbacks = [...action.payload];
      feedbacks.sort(sortFeedbacksDesc);

      state.locationFeedback = [...feedbacks];
    },

    getFeedback(state: FeedbackState, action: PayloadAction<any>) {
      if (action.payload) {
        state.feedbackDetail = [action.payload];
      } else {
        state.feedbackDetail = [{ id: 'Not Found' }];
      }
    },

    voidFeedback(state: FeedbackState, action: PayloadAction<any>) {
      // update feedback within customer feedback list
      let feedbackIndex = state.customerFeedback.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.customerFeedback = state.customerFeedback.map((note, i) =>
        i === feedbackIndex ? action.payload : note
      );

      // update feedback within location feedback list
      feedbackIndex = state.locationFeedback.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.locationFeedback = state.locationFeedback.map((note, i) =>
        i === feedbackIndex ? action.payload : note
      );
    },

    setIsLoadingCustomerFeedback(
      state: FeedbackState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerFeedback = action.payload;
    },

    setErrorMessage(state: FeedbackState, action: PayloadAction<any>) {
      state.feedbackErrorMessage = action.payload;
    },

    setSuccessMessage(state: FeedbackState, action: PayloadAction<any>) {
      state.feedbackSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listFeedbackByCustomer =
  (startDate, endDate, customerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerFeedback());

    const response = await backOff(
      () => listCustomerFeedbackAPI(startDate, endDate, customerId, ''),
      {
        numOfAttempts: 3
      }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listFeedbackByCustomer(
          response.data.listCustomerFeedbacks
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerFeedback());
  };

export const listFeedbackByLocation =
  (startDate, endDate, locationId): AppThunk =>
  async (dispatch) => {
    const response = await listCustomerFeedbackAPI(
      startDate,
      endDate,
      locationId
    );
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listFeedbackByLocation(
          response.data.listCustomerFeedbacks
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getFeedback =
  (feedbackId): AppThunk =>
  async (dispatch) => {
    const response = await getCustomerFeedbackAPI(feedbackId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getFeedback(response.data.getCustomerFeedback));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const voidFeedback =
  (voidFeedbackId, voidReason): AppThunk =>
  async (dispatch) => {
    const response = await voidCustomerFeedbackAPI(voidFeedbackId, voidReason);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidFeedback(response.data.voidCustomerFeedback));
      dispatch(slice.actions.setSuccessMessage('Feedback successfully voided'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const startLoadingCustomerFeedback =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerFeedback(true));
  };

export const stopLoadingCustomerFeedback = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerFeedback(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
// import LanguageSwitcher from './LanguageSwitcher';
import HeaderQuickActions from './QuickActions';
import LocationSwitcher from './LocationSwitcher';

function HeaderButtons() {
  return (
    <Box
      sx={{
        mr: 1
      }}
    >
      <HeaderQuickActions />
      <LocationSwitcher />
      <HeaderNotifications />
      {/* <LanguageSwitcher /> */}
    </Box>
  );
}

export default HeaderButtons;

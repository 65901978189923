export const listPatientAppointmentsQuery = /* GraphQL */ `
  query ListAppointments(
    $appointmentStartDate: AWSDate
    $appointmentEndDate: AWSDate
    $practitionerIds: [ID!]
    $patientIds: [ID!]
    $locationIds: [ID!]
    $serviceIds: [ID!]
    $type: String
    $status: String
  ) {
    listAppointments(
      appointmentStartDate: $appointmentStartDate
      appointmentEndDate: $appointmentEndDate
      practitionerIds: $practitionerIds
      patientIds: $patientIds
      locationIds: $locationIds
      serviceIds: $serviceIds
      type: $type
      status: $status
    ) {
      id
      appointmentDate
      appointmentStartTime
      appointmentEndTime
      type
      status
      appointmentNote
      emailReminderSent
      emailReminderAt
      smsReminderSent
      smsReminderSentAt
      confirmedAt
      service {
        id
        name
        category
      }
      location {
        id
        name
        code
      }
      practitioner {
        id
        firstName
        lastName
        preferredName
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      patient {
        id
        piiProfile {
          fullName
          preferredName
        }
      }
    }
  }
`;

import { mock } from 'src/utils/axios';
import type { Patient } from 'src/models/patient';

let patients: Patient[] = [
  {
    id: '9f725022-ca4e-4c71-98a8-800b7cfa5d31',
  tenant_id: 'csg',
  full_name: 'Aileen Seah',
  preferred_name: 'Aileen',
  family_id: 3,
  nric: 'S8933542C',
  nric_type: 'NRIC',
  date_of_birth: '28-08-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '97654411',
  email: 'aileenseahaining@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 123 Beauty World St 11',
  unit_no: '10-765',
  address_country: 'SG',
  postal_code: '544179',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Jun', contact: '91118765', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [{id: '1', content: 'This patient is FON'}],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Green'],
  created_at: '2022-03-07 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '908dcfbf-4edb-4041-90ce-1e67731d5ad0',
  tenant_id: 'csg',
  full_name: 'Amelia Lee',
  preferred_name: 'Amelia',
  family_id: 2,
  nric: 'S8992315D',
  nric_type: 'NRIC',
  date_of_birth: '26-09-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98775932',
  email: 'amelialeeqiuyan@gmail.com',
  alerts: 'Loves coffee',
  street_address: 'Blk 55 Lincoln Road',
  unit_no: '09-777',
  address_country: 'SG',
  postal_code: '145887',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Facebook Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Sean', contact: '87762341', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [],
  practitioner: ['Dr Siew Wei'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-07 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '9abcbe82-376b-49a7-9b09-21acec78c1df',
  tenant_id: 'csg',
  full_name: 'Teo Cheng Yong',
  preferred_name: 'Cheng Yong',
  family_id: 2,
  nric: 'S8687393I',
  nric_type: 'NRIC',
  date_of_birth: '01-11-1986',
  marital_status: 'Married',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Malaysia',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '88887755',
  email: 'teochengyong@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 76 Punggol',
  unit_no: '05-325',
  address_country: 'SG',
  postal_code: '098332',
  telephone2: '',
  telephone3: '',
  consent: {'sms': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Software Engineer',
  next_of_kin: {name: 'James', contact: '87792376', relation: 'brother'},
  employer: 'GovTech',
  patient_notes: [],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna', 'MMR'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-06 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-06 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '1',
  tenant_id: 'csg',
  full_name: 'James Wong',
  preferred_name: 'James',
  family_id: 7,
  nric: 'S9684515D',
  nric_type: 'NRIC',
  date_of_birth: '23-05-1996',
  marital_status: 'Single',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98832251',
  email: 'jameswongtest@gmail.com',
  alerts: 'Loves sports',
  street_address: 'Blk 411 Bishan Road',
  unit_no: '06-932',
  address_country: 'SG',
  postal_code: '748399',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Website',
  referral_source: 'Website',
  referral_category: 'Website',
  occupation: 'HR Executive',
  next_of_kin: {name: 'Jane', contact: '90037765', relation: 'wife'},
  employer: 'Shopee',
  patient_notes:  [],
  practitioner: ['Dr Siew Wei'],
  location: 'Novena',
  vaccines: [],
  insurance: [],
  created_at: '2022-03-05 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-05 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '2',
  tenant_id: 'csg',
  full_name: 'Aileen Seah2',
  preferred_name: 'Aileen',
  family_id: 3,
  nric: 'S8933542C',
  nric_type: 'NRIC',
  date_of_birth: '28-08-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '97654411',
  email: 'aileenseahaining@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 123 Beauty World St 11',
  unit_no: '10-765',
  address_country: 'SG',
  postal_code: '544179',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Jun', contact: '91118765', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [{id: '1', content: 'This patient is FON'}],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Green'],
  created_at: '2022-03-07 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '3',
  tenant_id: 'csg',
  full_name: 'Amelia Lee2',
  preferred_name: 'Amelia',
  family_id: 2,
  nric: 'S8992315D',
  nric_type: 'NRIC',
  date_of_birth: '26-09-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98775932',
  email: 'amelialeeqiuyan@gmail.com',
  alerts: 'Loves coffee',
  street_address: 'Blk 55 Lincoln Road',
  unit_no: '09-777',
  address_country: 'SG',
  postal_code: '145887',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Facebook Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Sean', contact: '87762341', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [],
  practitioner: ['Dr Siew Wei'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-07 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '4',
  tenant_id: 'csg',
  full_name: 'Teo Cheng Yong2',
  preferred_name: 'Cheng Yong',
  family_id: 2,
  nric: 'S8687393I',
  nric_type: 'NRIC',
  date_of_birth: '01-11-1986',
  marital_status: 'Married',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Malaysia',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '88887755',
  email: 'teochengyong@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 76 Punggol',
  unit_no: '05-325',
  address_country: 'SG',
  postal_code: '098332',
  telephone2: '',
  telephone3: '',
  consent: {'sms': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Software Engineer',
  next_of_kin: {name: 'James', contact: '87792376', relation: 'brother'},
  employer: 'GovTech',
  patient_notes: [],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna', 'MMR'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-06 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-06 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '8',
  tenant_id: 'csg',
  full_name: 'James Wong2',
  preferred_name: 'James',
  family_id: 7,
  nric: 'S9684515D',
  nric_type: 'NRIC',
  date_of_birth: '23-05-1996',
  marital_status: 'Single',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98832251',
  email: 'jameswongtest@gmail.com',
  alerts: 'Loves sports',
  street_address: 'Blk 411 Bishan Road',
  unit_no: '06-932',
  address_country: 'SG',
  postal_code: '748399',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Website',
  referral_source: 'Website',
  referral_category: 'Website',
  occupation: 'HR Executive',
  next_of_kin: {name: 'Jane', contact: '90037765', relation: 'wife'},
  employer: 'Shopee',
  patient_notes:  [],
  practitioner: ['Dr Siew Wei'],
  location: 'Novena',
  vaccines: [],
  insurance: [],
  created_at: '2022-03-05 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-05 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '9',
  tenant_id: 'csg',
  full_name: 'Aileen Seah3',
  preferred_name: 'Aileen',
  family_id: 3,
  nric: 'S8933542C',
  nric_type: 'NRIC',
  date_of_birth: '28-08-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '97654411',
  email: 'aileenseahaining@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 123 Beauty World St 11',
  unit_no: '10-765',
  address_country: 'SG',
  postal_code: '544179',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Jun', contact: '91118765', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [{id: '1', content: 'This patient is FON'}],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Green'],
  created_at: '2022-03-07 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '10',
  tenant_id: 'csg',
  full_name: 'Amelia Lee3',
  preferred_name: 'Amelia',
  family_id: 2,
  nric: 'S8992315D',
  nric_type: 'NRIC',
  date_of_birth: '26-09-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98775932',
  email: 'amelialeeqiuyan@gmail.com',
  alerts: 'Loves coffee',
  street_address: 'Blk 55 Lincoln Road',
  unit_no: '09-777',
  address_country: 'SG',
  postal_code: '145887',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Facebook Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Sean', contact: '87762341', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [],
  practitioner: ['Dr Siew Wei'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-07 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '11',
  tenant_id: 'csg',
  full_name: 'Teo Cheng Yong3',
  preferred_name: 'Cheng Yong',
  family_id: 2,
  nric: 'S8687393I',
  nric_type: 'NRIC',
  date_of_birth: '01-11-1986',
  marital_status: 'Married',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Malaysia',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '88887755',
  email: 'teochengyong@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 76 Punggol',
  unit_no: '05-325',
  address_country: 'SG',
  postal_code: '098332',
  telephone2: '',
  telephone3: '',
  consent: {'sms': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Software Engineer',
  next_of_kin: {name: 'James', contact: '87792376', relation: 'brother'},
  employer: 'GovTech',
  patient_notes: [],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna', 'MMR'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-06 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-06 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '12',
  tenant_id: 'csg',
  full_name: 'James Wong3',
  preferred_name: 'James',
  family_id: 7,
  nric: 'S9684515D',
  nric_type: 'NRIC',
  date_of_birth: '23-05-1996',
  marital_status: 'Single',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98832251',
  email: 'jameswongtest@gmail.com',
  alerts: 'Loves sports',
  street_address: 'Blk 411 Bishan Road',
  unit_no: '06-932',
  address_country: 'SG',
  postal_code: '748399',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Website',
  referral_source: 'Website',
  referral_category: 'Website',
  occupation: 'HR Executive',
  next_of_kin: {name: 'Jane', contact: '90037765', relation: 'wife'},
  employer: 'Shopee',
  patient_notes:  [],
  practitioner: ['Dr Siew Wei'],
  location: 'Novena',
  vaccines: [],
  insurance: [],
  created_at: '2022-03-05 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-05 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '13',
  tenant_id: 'csg',
  full_name: 'Aileen Seah4',
  preferred_name: 'Aileen',
  family_id: 3,
  nric: 'S8933542C',
  nric_type: 'NRIC',
  date_of_birth: '28-08-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '97654411',
  email: 'aileenseahaining@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 123 Beauty World St 11',
  unit_no: '10-765',
  address_country: 'SG',
  postal_code: '544179',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Jun', contact: '91118765', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [{id: '1', content: 'This patient is FON'}],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Green'],
  created_at: '2022-03-07 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '14',
  tenant_id: 'csg',
  full_name: 'Amelia Lee4',
  preferred_name: 'Amelia',
  family_id: 2,
  nric: 'S8992315D',
  nric_type: 'NRIC',
  date_of_birth: '26-09-1989',
  marital_status: 'Married',
  gender: 'Female',
  title: 'Mrs',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98775932',
  email: 'amelialeeqiuyan@gmail.com',
  alerts: 'Loves coffee',
  street_address: 'Blk 55 Lincoln Road',
  unit_no: '09-777',
  address_country: 'SG',
  postal_code: '145887',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Direct',
  referral_source: 'Facebook Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Business Owner',
  next_of_kin: {name: 'Sean', contact: '87762341', relation: 'husband'},
  employer: 'The Health Collective',
  patient_notes: [],
  practitioner: ['Dr Siew Wei'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-07 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-07 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '15',
  tenant_id: 'csg',
  full_name: 'Teo Cheng Yong4',
  preferred_name: 'Cheng Yong',
  family_id: 2,
  nric: 'S8687393I',
  nric_type: 'NRIC',
  date_of_birth: '01-11-1986',
  marital_status: 'Married',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Malaysia',
  has_drug_allergies: false,
  drug_allergies: [],
  has_food_allergies: true,
  food_allergies: ['vinegar'],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '88887755',
  email: 'teochengyong@gmail.com',
  alerts: 'Loves bread',
  street_address: 'Blk 76 Punggol',
  unit_no: '05-325',
  address_country: 'SG',
  postal_code: '098332',
  telephone2: '',
  telephone3: '',
  consent: {'sms': '1'},
  referred_by: 'Direct',
  referral_source: 'Google Ads',
  referral_category: 'Digital Marketing',
  occupation: 'Software Engineer',
  next_of_kin: {name: 'James', contact: '87792376', relation: 'brother'},
  employer: 'GovTech',
  patient_notes: [],
  practitioner: ['Dr Ryan'],
  location: 'CBD',
  vaccines: ['Covid19 Moderna', 'MMR'],
  insurance: ['CHAS Blue'],
  created_at: '2022-03-06 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-06 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '16',
  tenant_id: 'csg',
  full_name: 'James Wong4',
  preferred_name: 'James',
  family_id: 7,
  nric: 'S9684515D',
  nric_type: 'NRIC',
  date_of_birth: '23-05-1996',
  marital_status: 'Single',
  gender: 'Male',
  title: 'Mr',
  nationality: 'Singapore',
  has_drug_allergies: true,
  drug_allergies: ['asprin'],
  has_food_allergies: false,
  food_allergies: [],
  has_other_allergies: false,
  other_allergies: [],
  mobile_country_code: '65',
  mobile: '98832251',
  email: 'jameswongtest@gmail.com',
  alerts: 'Loves sports',
  street_address: 'Blk 411 Bishan Road',
  unit_no: '06-932',
  address_country: 'SG',
  postal_code: '748399',
  telephone2: '',
  telephone3: '',
  consent: {'email': '1'},
  referred_by: 'Website',
  referral_source: 'Website',
  referral_category: 'Website',
  occupation: 'HR Executive',
  next_of_kin: {name: 'Jane', contact: '90037765', relation: 'wife'},
  employer: 'Shopee',
  patient_notes:  [],
  practitioner: ['Dr Siew Wei'],
  location: 'Novena',
  vaccines: [],
  insurance: [],
  created_at: '2022-03-05 13:00:00',
  created_by: 'ben',
  edited_at: '2022-03-05 13:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  
  
];

mock.onGet('/api/patients').reply(() => {
  return [200, { patients }];
});

mock.onGet('/api/patient').reply((config) => {
  const { patientId } = config.params;
  const patient = patients.find((_patient) => _patient.id === patientId);

  return [200, { patient }];
});

import { FC, ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Stack,
  useTheme
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ThemeSettings from 'src/components/ThemeSettings';
import { Offline, Online } from 'react-detect-offline';
import '@stripe/terminal-js';

import Sidebar from './Sidebar';
import Header from './Header';

import { useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';

import { getGlobalUISettings } from 'src/slices/settings';
import { getAllServices } from 'src/slices/services';
import { getAllClassServices } from 'src/slices/class_services';
// import { getAllInventory, listInventoryOnLoad } from 'src/slices/inventories';
import { getLoggedInUser, listUsersOnLoad } from 'src/slices/users';
import {
  // listLocationDetails,
  listLocationDetailsOnLoad
} from 'src/slices/locations';
import { listContactsOnLoad } from 'src/slices/contacts';
import {
  listCorporatesOnLoad
  // listInsuranceOptions
} from 'src/slices/corporates';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loggedInUser, userList } = useSelector((state) => state.users);
  const urlLocation = useLocation();
  const [isOffline, setIsOffline] = useState(false);

  let sxPadding = '';

  if (urlLocation.pathname.includes('/app/queue')) {
    sxPadding = '0';
  } else {
    sxPadding = theme.sidebar.width;
  }

  useEffect(() => {
    dispatch(getGlobalUISettings());
    dispatch(getLoggedInUser());
    dispatch(getAllServices());
    dispatch(getAllClassServices());
    // dispatch(getAllInventory());
    // dispatch(listUsers());

    dispatch(listLocationDetailsOnLoad());
    // dispatch(listInventoryOnLoad());
    dispatch(listUsersOnLoad());
    // dispatch(listContactsOnLoad());
    dispatch(listCorporatesOnLoad());
  }, []);

  if (urlLocation.pathname === '/app/integrations/googlecalendar') {
    return <Outlet />;
  }

  return (
    <>
      {/* <Offline
        polling={{ interval: 3000, timeout: 3000, enabled: false }}
        onChange={(onlineEvent) => {
          if (onlineEvent) {
            setIsOffline(false);
            location.reload();
          } else {
            window.scroll(0, 0);
            setIsOffline(true);
          }
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {
            return;
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </Offline> */}

      {isOffline && (
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: '100%', zIndex: 15, borderRadius: 0 }}
        >
          Network offline. Please check your internet connection and refresh the
          page.
        </Alert>
      )}

      <Header />
      <Sidebar />

      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: sxPadding
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            {loggedInUser?.type === 'None' ? (
              <></>
            ) : (
              <>
                <Outlet />
              </>
            )}
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentHeaderLayout;

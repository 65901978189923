export const getTerminalFeedbackQuestions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    header: 'General Feedback',
    sections: [
      {
        sectionHeader: 'Experience',
        sectionQuestions: [
          {
            name: 'general',
            label:
              'How was your visit today? Is there anything we have done well or can be done better?',
            type: 'multilineInput',
            required: true,
            options: []
          }
        ]
      }
    ]
  };

  try {
    const terminalFeedbackQuestions = {
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        header: 'General Feedback',
        sections: [
          {
            sectionHeader:
              'Experience (Rate on a scale of 1 to 5, with 5 being the best score)',
            sectionQuestions: [
              {
                name: 'waiting_time',
                label:
                  'How satisfied are you with the waiting time for your treatment?',
                type: 'rating',
                required: 'Select a rating',
                defaultValue: 0,
                max: 5,
                precision: 1,
                options: []
              },
              {
                name: 'attentiveness',
                label: 'How attentive was the therapist to your needs?',
                type: 'rating',
                required: 'Select a rating',
                defaultValue: 0,
                max: 5,
                precision: 1,
                options: []
              },
              {
                name: 'treatment_satisfaction',
                label: 'How satisfied are you with the bone setting treatment?',
                type: 'rating',
                required: 'Select a rating',
                defaultValue: 0,
                max: 5,
                precision: 1,
                options: []
              },
              {
                name: 'treatment_feeling',
                label:
                  'How much better do you feel after the treatment session?',
                type: 'rating',
                required: 'Select a rating',
                defaultValue: 0,
                max: 5,
                precision: 1,
                options: []
              },
              {
                name: 'overall_experience',
                label: 'How would you rate the overall experience for today?',
                type: 'rating',
                required: 'Select a rating',
                defaultValue: 0,
                max: 5,
                precision: 1,
                options: []
              }
            ]
          }
        ]
      }
    };

    const result = terminalFeedbackQuestions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPrefillServiceDateInItemDescriptionOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const prefillServiceDateOption = {
      cityosteophysio: false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': true,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': false,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false
    };

    const result = prefillServiceDateOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDisplayIdentityNumber = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const displayIdentityNumber = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': true,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true
    };

    const result = displayIdentityNumber[tenantIdLowerCase];

    if (result != null) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDisplayProvider = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const displayProvider = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true,
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true
    };

    const result = displayProvider[tenantIdLowerCase];

    if (result != null) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDisplayEmployer = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const displayEmployer = {
      cityosteophysio: false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': false,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false,
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': false
    };

    const result = displayEmployer[tenantIdLowerCase];

    if (result != null) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDisplayLogo = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = true;

  try {
    const displayLogo = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': true,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false,
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true
    };

    const result = displayLogo[tenantIdLowerCase];

    if (result != null) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getAccountingLockDate = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = 3;

  try {
    const accountingLockDates = {
      cityosteophysio: 3,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 3,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 3,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 3,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 3
    };

    const result = accountingLockDates[tenantIdLowerCase];

    if (result != null) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

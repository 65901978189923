export const listAllCustomerMemos = /* GraphQL */ `
  query ListCustomerMemos(
    $customerId: ID
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
  ) {
    listCustomerMemos(
      customerId: $customerId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      locationId
      customerId
      parentMemoId
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const TimeOffs = Loader(lazy(() => import('src/content/TimeOff')));
// const SingleTimeOff = Loader(lazy(() => import('src/content/TimeOff/single')));

const timeOffRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: '/list',
    element: <TimeOffs />
  }
  //   {
  //     path: ':timeOffId/',
  //     element: <SingleTimeOff />
  //   }
];

export default timeOffRoutes;

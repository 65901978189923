import { ExposedError, Reader, Terminal } from '@stripe/terminal-js';
import React, { createContext, useContext, useState } from 'react';

type StripeTerminalContext = {
  terminal: Terminal | null;
  setTerminal: (terminal: Terminal) => void;
  foundReaders: Reader[];
  setFoundReaders: (readers: Reader[]) => void;
  currentReader: Reader | null;
  setCurrentReader: (reader: Reader | null) => void;
  errorMessage: ExposedError | null;
  setErrorMessage: (errorMessage: ExposedError | null) => void;
};

export const StripeTerminalContext =
  createContext<StripeTerminalContext | null>(null);

export default function StripeTerminalContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [terminal, setTerminal] = useState<Terminal | null>(null);
  const [currentReader, setCurrentReader] = useState<Reader | null>(null);
  const [foundReaders, setFoundReaders] = useState<Reader[]>([]);
  const [errorMessage, setErrorMessage] = useState<ExposedError | null>(null);

  return (
    <StripeTerminalContext.Provider
      value={{
        terminal,
        setTerminal,
        foundReaders,
        setFoundReaders,
        currentReader,
        setCurrentReader,
        errorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </StripeTerminalContext.Provider>
  );
}

export function useStripe() {
  const context = useContext(StripeTerminalContext);
  if (!context) {
    throw new Error(
      'useStripeTerminal must be used within a StripeTerminalContextProvider',
    );
  }
  return context;
}

import {
  alpha,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Popover,
  Zoom,
  CircularProgress,
  useTheme,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'src/store';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Link from '@mui/material/Link';
import useRefMounted from 'src/hooks/useRefMounted';
import SpeakerNotesTwoToneIcon from '@mui/icons-material/SpeakerNotesTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { formatDistance, subHours, subSeconds, subDays } from 'date-fns';

import {
  listNoticeBoardMessages,
  createNoticeBoardMessage,
  setErrorMessage,
  setSuccessMessage
} from 'src/slices/noticeboard_messages';
import NoticeBoardMessageSingle from 'src/content/NoticeBoardMessages/single';
import CreateNoticeBoardMessageForm from 'src/content/NoticeBoardMessages/messageForm/CreateNoticeBoardMessageForm';

const AnimatedBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function HeaderNotifications() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((state) => state.users);
  const { locationDetails } = useSelector((state) => state.locations);
  const {
    noticeBoardMessages,
    noticeBoardMessageErrorMessage,
    noticeBoardMessageSuccessMessage
  } = useSelector((state) => state.noticeBoardMessages);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [postOpen, setPostOpen] = useState<boolean>(false);
  const [postContent, setPostContent] = useState('');

  const handleOpen = (): void => {
    setOpen(true);
    fetchNoticeBoard();
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleCreatePostClose = (): void => {
    setPostOpen(false);
    setPostContent('');
  };

  const onSubmit = () => {
    const finalData = {
      content: postContent,
      locationId: loggedInUser?.currentLocationId
    };

    dispatch(createNoticeBoardMessage(finalData));
    handleCreatePostClose();
  };

  const fetchNoticeBoard = useCallback(() => {
    if (loggedInUser?.id !== 'None') {
      dispatch(
        listNoticeBoardMessages(
          loggedInUser?.currentLocationId,
          subDays(new Date(), 14),
          new Date()
        )
      );
    }
  }, [loggedInUser]);

  useEffect(() => {
    fetchNoticeBoard();
  }, [loggedInUser]);

  useEffect(() => {
    if (noticeBoardMessageErrorMessage !== '') {
      enqueueSnackbar(noticeBoardMessageErrorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: 3000,
        TransitionComponent: Zoom
      });

      dispatch(setErrorMessage(''));
    }
  }, [noticeBoardMessageErrorMessage, isMountedRef]);

  useEffect(() => {
    if (noticeBoardMessageSuccessMessage !== '') {
      enqueueSnackbar(noticeBoardMessageSuccessMessage, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: 3000,
        TransitionComponent: Zoom
      });

      dispatch(setSuccessMessage(''));
    }
  }, [noticeBoardMessageSuccessMessage, isMountedRef]);

  // console.log(noticeBoardMessages);
  const locationCode = locationDetails?.find(
    (loc) => loc.id === loggedInUser?.currentLocationId
  )?.code;

  return (
    <>
      <Tooltip arrow title={t('Notice Board')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            // badgeContent={2}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {/* <NotificationsActiveTwoToneIcon /> */}
            <SpeakerNotesTwoToneIcon />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            p: 2
          }}
          display="flex"
          justifyContent="space-between"
          // justifyContent="flex-start"
        >
          <Typography variant="h4" display="flex" alignItems="center">
            {`${locationCode} Notice Board (last 2 weeks)`}
          </Typography>
          {/* <Link
            href="#"
            variant="caption"
            sx={{
              textTransform: 'none'
            }}
          >
            {t('View all messages')}
          </Link> */}
          <IconButton
            color="primary"
            // sx={{ mt: 1 }}
            onClick={handleClose}
          >
            <CloseTwoToneIcon />
          </IconButton>
        </Box>
        <Divider />
        <List
          sx={{
            p: 2
          }}
        >
          {noticeBoardMessages && noticeBoardMessages?.length > 0 ? (
            noticeBoardMessages.map((message) => (
              <NoticeBoardMessageSingle
                key={message.id}
                noticeBoardMessage={message}
              />
            ))
          ) : (
            <ListItem sx={{ justifyContent: 'center' }}>
              <Typography>No recent messages.</Typography>
            </ListItem>
          )}
        </List>
        <Divider />
        <Box m={1}>
          <Button color="secondary" fullWidth onClick={() => setPostOpen(true)}>
            {t('Post a message')}
          </Button>
        </Box>
      </Popover>
      <Dialog
        open={postOpen}
        maxWidth="sm"
        fullWidth
        onClose={handleCreatePostClose}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            p: 3,
            display: 'inline-flex',
            justifyContent: 'space-between'
          }}
        >
          {/* <Typography variant="h3">{'Post a message'}</Typography> */}

          <Box display="flex" flexDirection="column">
            <Typography variant="h3" gutterBottom>
              {t('Post Message')}
            </Typography>
            <Typography variant="subtitle2">
              {t('Location-wide announcements and notices')}
            </Typography>
          </Box>

          <IconButton
            color="primary"
            // sx={{ mt: 1 }}
            onClick={handleCreatePostClose}
          >
            <CloseTwoToneIcon />
          </IconButton>
        </DialogTitle>

        <CreateNoticeBoardMessageForm
          messageContent={postContent}
          setMessageContent={setPostContent}
        />

        <DialogActions
          sx={{
            p: 3
          }}
        >
          {/* <Button
            color="secondary"
            variant="outlined"
            onClick={handleCreatePostClose}
          >
            {t('Cancel')}
          </Button> */}
          <Button
            onClick={onSubmit}
            // startIcon={
            //   isSubmitting ? <CircularProgress size="1rem" /> : null
            // }
            // disabled={isSubmitting}
            disabled={postContent === '' || postContent === '<p><br></p>'}
            variant="contained"
          >
            {t('Post')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HeaderNotifications;

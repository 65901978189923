import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { listPatientPayments } from './payments';
import {
  getWalletByPatientAPI,
  walletDepositAPI,
  walletWithdrawAPI,
  voidWalletDepositAPI,
  getPatientWalletBalanceAPI
} from 'src/content/Wallet/api';

interface WalletState {
  isLoadingCustomerTransactions: boolean;
  isCreatingTransaction: boolean;
  isVoidingTransaction: boolean;
  patientWallets: any[];
  selectedWalletBalance: any;
  walletErrorMessage: string;
  walletSuccessMessage: string;
}

const initialState: WalletState = {
  isLoadingCustomerTransactions: false,
  isCreatingTransaction: false,
  isVoidingTransaction: false,
  patientWallets: [],
  selectedWalletBalance: {},
  walletErrorMessage: '',
  walletSuccessMessage: ''
};

const sortTransactionsDesc = (a, b) => {
  if (a.transactionTime > b.transactionTime) {
    return -1;
  }
  if (a.transactionTime < b.transactionTime) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    getPatientWallet(state: WalletState, action: PayloadAction<any>) {
      if (action.payload === null) {
        state.patientWallets = [...state.patientWallets];
      } else {
        // console.log(state.patientWallets);
        const patientWallet = action.payload;
        patientWallet?.transactions?.sort(sortTransactionsDesc);

        const otherWallets = state.patientWallets.filter(
          (wallet) => wallet.id !== action.payload.id
        );
        state.patientWallets = [patientWallet, ...otherWallets];
      }
    },

    fetchPatientWalletBalance(state: WalletState, action: PayloadAction<any>) {
      state.selectedWalletBalance = { ...action.payload };
    },

    setIsLoadingCustomerTransactions(
      state: WalletState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerTransactions = action.payload;
    },

    setIsCreatingTransaction(state: WalletState, action: PayloadAction<any>) {
      state.isCreatingTransaction = action.payload;
    },

    setIsVoidingTransaction(state: WalletState, action: PayloadAction<any>) {
      state.isVoidingTransaction = action.payload;
    },

    setErrorMessage(state: WalletState, action: PayloadAction<any>) {
      state.walletErrorMessage = action.payload;
    },

    setSuccessMessage(state: WalletState, action: PayloadAction<any>) {
      state.walletSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getPatientWallet =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerWalletTransactions());

    const response = await getWalletByPatientAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getPatientWallet(response.data.getWalletByPatient)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerWalletTransactions());
  };

export const addWalletDeposit =
  (payment, updatePaymentsInstantly = false): AppThunk =>
  async (dispatch) => {
    dispatch(startCreateWalletTransaction());
    dispatch(startLoadingCustomerWalletTransactions());

    const response = await walletDepositAPI(payment);

    if (!response.customErrorMessage) {
      if (updatePaymentsInstantly) {
        dispatch(listPatientPayments(response.data.createPayment.patientId));
      }

      if (response.data.createPayment.status !== 'Fail') {
        dispatch(
          slice.actions.setSuccessMessage('Deposit successfully processed')
        );

        const waitingTime =
          response.data.createPayment.mode === 'Stripe' ? 2000 : 1000;

        setTimeout(function () {
          dispatch(getPatientWallet(response.data.createPayment.patientId));
        }, waitingTime);
      } else if (
        response.data.createPayment.status === 'Fail' &&
        response.data.createPayment.mode === 'Stripe'
      ) {
        dispatch(slice.actions.setErrorMessage('The card was declined'));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreateWalletTransaction());
      dispatch(startLoadingCustomerWalletTransactions());
    };

    return followUp();
  };

export const addWalletWithdrawal =
  (payment, updatePaymentsInstantly = false): AppThunk =>
  async (dispatch) => {
    dispatch(startCreateWalletTransaction());
    dispatch(startLoadingCustomerWalletTransactions());

    const response = await walletWithdrawAPI(payment);

    if (!response.customErrorMessage) {
      if (updatePaymentsInstantly) {
        dispatch(listPatientPayments(response.data.createPayment.patientId));
      }

      if (response.data.createPayment.status !== 'Fail') {
        dispatch(getPatientWallet(response.data.createPayment.patientId));
        dispatch(
          slice.actions.setSuccessMessage('Withdrawal successfully processed')
        );
      } else {
        dispatch(slice.actions.setErrorMessage('Failed to process withdrawal'));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreateWalletTransaction());
      dispatch(startLoadingCustomerWalletTransactions());
    };

    return followUp();
  };

export const voidWalletDeposit =
  (payment): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingWalletTransaction());
    dispatch(startLoadingCustomerWalletTransactions());

    const response = await voidWalletDepositAPI(payment);

    if (!response.customErrorMessage) {
      dispatch(getPatientWallet(response.data.voidPayment.patientId));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingWalletTransaction());
      dispatch(startLoadingCustomerWalletTransactions());
    };

    return followUp();
  };

export const fetchPatientWalletBalance =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerWalletTransactions());

    const response = await getPatientWalletBalanceAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.fetchPatientWalletBalance(
          response.data.getWalletByPatient
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerWalletTransactions());
  };

export const startLoadingCustomerWalletTransactions =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerTransactions(true));
  };

export const stopLoadingCustomerWalletTransactions =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerTransactions(false));
  };

export const startCreateWalletTransaction =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingTransaction(true));
  };

export const stopCreateWalletTransaction = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingTransaction(false));
};

export const startVoidingWalletTransaction =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsVoidingTransaction(true));
  };

export const stopVoidingWalletTransaction =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsVoidingTransaction(false));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

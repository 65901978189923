import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';

import {
  listUsersAPI,
  getLoggedInUserAPI,
  updateUserAPI,
  createPractitionerAPI,
  listUsersOnLoadAPI,
  deactivateUserAPI,
  updateUserViewableCalAPI
} from 'src/content/Users/api';

interface UserState {
  isLoadingUsers: boolean;
  isEditingUser: boolean;
  isCreatingUser: boolean;
  isDeactivatingUser: boolean;
  userList: any[];
  activeUserList: any[];
  inactiveUserList: any[];
  providerList: any[];
  providerOptions: any[];
  loggedInUser: { [key: string]: any };
  userErrorMessage: string;
  userSuccessMessage: string;
}

const initialState: UserState = {
  isLoadingUsers: false,
  isEditingUser: false,
  isCreatingUser: false,
  isDeactivatingUser: false,
  userList: [],
  activeUserList: [],
  inactiveUserList: [],
  providerList: [],
  providerOptions: [],
  loggedInUser: {
    email: 'None',
    firstName: 'None',
    id: 'None',
    jobTitle: 'None',
    lastName: 'None',
    mobile: 'None',
    type: 'None',
    username: 'None',
    preferredName: 'None'
  },
  userErrorMessage: '',
  userSuccessMessage: ''
};

const sortUsersPreferredNames = (a, b) => {
  if (a.preferredName > b.preferredName) {
    return 1;
  }
  if (a.preferredName < b.preferredName) {
    return -1;
  }
  return 0;
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    listUsers(state: UserState, action: PayloadAction<any>) {
      const users = [...action.payload];

      // Sort user ASC by preferredName

      users.sort(sortUsersPreferredNames);
      state.userList = users;

      state.activeUserList = users.filter((user) => user.isActive);
      state.inactiveUserList = users.filter((user) => !user.isActive);

      const activeProviderList = [...state.activeUserList]?.filter((user) =>
        user.type?.toLowerCase()?.includes('practitioner')
      );

      const providerOptions = activeProviderList?.map((provider) => ({
        label: `${provider.preferredName}`,
        value: provider.id
      }));

      state.providerList = [...activeProviderList];
      state.providerOptions = [...providerOptions];
    },

    getLoggedInUser(state: UserState, action: PayloadAction<any>) {
      state.loggedInUser = action.payload;
    },

    editUser(state: UserState, action: PayloadAction<any>) {
      const userIndex = state.userList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (userIndex >= 0) {
        state.userList = state.userList.map((user, i) =>
          i === userIndex ? action.payload : user
        );
      }

      const providerIndex = state.providerList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (providerIndex >= 0) {
        state.providerList = state.providerList.map((user, i) =>
          i === providerIndex ? action.payload : user
        );

        const providerOptions = state.providerList?.map((provider) => ({
          label: `${provider.preferredName}`,
          value: provider.id
        }));

        state.providerOptions = [...providerOptions];
      }

      if (action.payload.id === state.loggedInUser.id) {
        state.loggedInUser = action.payload;
      }
    },

    createUser(state: UserState, action: PayloadAction<any>) {
      const newUser = action.payload;

      state.userList = [newUser, ...state.userList];

      if (newUser.isActive) {
        state.activeUserList = [newUser, ...state.activeUserList];
      }

      if (newUser?.type?.toLowerCase()?.includes('practitioner')) {
        state.providerList = [newUser, ...state.providerList];

        state.providerOptions = [
          {
            label: `${newUser.preferredName}`,
            value: newUser.id
          },
          ...state.providerOptions
        ];
      }
    },

    setIsLoadingUsers(state: UserState, action: PayloadAction<any>) {
      state.isLoadingUsers = action.payload;
    },

    setIsEditingUser(state: UserState, action: PayloadAction<any>) {
      state.isEditingUser = action.payload;
    },

    setIsCreatingUser(state: UserState, action: PayloadAction<any>) {
      state.isCreatingUser = action.payload;
    },

    setIsDeactivatingUser(state: UserState, action: PayloadAction<any>) {
      state.isDeactivatingUser = action.payload;
    },

    setErrorMessage(state: UserState, action: PayloadAction<any>) {
      state.userErrorMessage = action.payload;
    },

    setSuccessMessage(state: UserState, action: PayloadAction<any>) {
      state.userSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listUsersOnLoad = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingUsers());

  const response = await backOff(() => listUsersOnLoadAPI(), {
    numOfAttempts: 5,
    jitter: 'full'
  });

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listUsers(response.data.listUsers));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingUsers());
};

export const listUsers = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingUsers());

  const response = await backOff(() => listUsersAPI(), {
    numOfAttempts: 5,
    jitter: 'full'
  });

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listUsers(response.data.listUsers));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingUsers());
};

export const getLoggedInUser = (): AppThunk => async (dispatch) => {
  try {
    const response = await getLoggedInUserAPI();
    // console.log(response)
    dispatch(slice.actions.getLoggedInUser(response.data.getLoggedInUser));
  } catch (error) {
    console.log(error);
  }
};

export const createPractitioner =
  (practitioner): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingUser());
    dispatch(startLoadingUsers());

    const response = await createPractitionerAPI(practitioner);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createUser(response.data.createPractitioner));
      dispatch(slice.actions.setSuccessMessage('User successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingUser());
      dispatch(stopLoadingUsers());
    };

    return followUp();
  };

export const editUser =
  (user): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingUser());

    const response = await updateUserAPI(user);

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editUser(response.data.updatePractitioner));

      dispatch(
        slice.actions.setSuccessMessage('User details successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingUser());
  };

export const deactivateUser =
  (userId): AppThunk =>
  async (dispatch) => {
    dispatch(startDeactivatingUser());

    const response = await deactivateUserAPI(userId);

    if (!response.customErrorMessage) {
      // dispatch(slice.actions.deactivateUser(response.data.deactivateUser));

      dispatch(
        slice.actions.setSuccessMessage('User successfully deactivated')
      );

      // REFRESH PAGE HERE
      return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopDeactivatingUser());
  };

export const updateUserViewableCals =
  ({ userId, memberIds }): AppThunk =>
  async (dispatch) => {
    const response = await updateUserViewableCalAPI({ userId, memberIds });

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editUser(response.data.updateUserViewOnlyMemberCalendars)
      );
      dispatch(
        slice.actions.setSuccessMessage('User details successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const startLoadingUsers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingUsers(true));
};

export const stopLoadingUsers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingUsers(false));
};

export const startEditingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingUser(true));
};

export const stopEditingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingUser(false));
};

export const startCreatingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingUser(true));
};

export const stopCreatingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingUser(false));
};

export const startDeactivatingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeactivatingUser(true));
};

export const stopDeactivatingUser = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeactivatingUser(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

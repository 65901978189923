import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

// import { listOfficeLocationsNamesAPI } from 'src/content/Locations/api';
import { listDocumentsAPI, deleteDocumentAPI } from 'src/content/Documents/api';

interface DocumentState {
  patientDocs: any[];
  docErrorMessage: string;
  docSuccessMessage: string;
}

const initialState: DocumentState = {
  patientDocs: [],
  docErrorMessage: '',
  docSuccessMessage: ''
};

// IDEA: Sort the array of appointments by start time DESCending first before rendering below
const sortFiles = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    listPatientDocuments(state: DocumentState, action: PayloadAction<any>) {
      const documents = [...action.payload];
      documents.sort(sortFiles);
      state.patientDocs = documents;
    },

    deleteDocument(state: DocumentState, action: PayloadAction<any>) {
      state.patientDocs = state.patientDocs.filter(
        (doc) => doc.id !== action.payload.id
      );
    },

    setErrorMessage(state: DocumentState, action: PayloadAction<any>) {
      state.docErrorMessage = action.payload;
    },

    setSuccessMessage(state: DocumentState, action: PayloadAction<any>) {
      state.docSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listPatientDocuments =
  (patientId): AppThunk =>
  async (dispatch) => {
    const response = await listDocumentsAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.listPatientDocuments(response.data.listDocuments));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const deleteDocument =
  (docId): AppThunk =>
  async (dispatch) => {
    const response = await deleteDocumentAPI(docId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteDocument(response.data.deleteDocument));
      dispatch(
        slice.actions.setSuccessMessage('Document successfully removed')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

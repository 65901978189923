import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const CreditNotes = Loader(lazy(() => import('src/content/CreditNotes')));
const SingleCreditNote = Loader(
  lazy(() => import('src/content/CreditNotes/search/CreditNoteDetail'))
);
const Search = Loader(lazy(() => import('src/content/CreditNotes/search')));

const creditNotesRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <CreditNotes />
  },
  {
    path: ':creditNoteId',
    element: <SingleCreditNote />
  },
  {
    path: 'search',
    element: <Search />
  }
];

export default creditNotesRoutes;

import { useRef, useState, useEffect, useCallback } from 'react';
import {
  alpha,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  styled
} from '@mui/material';
import { useDispatch, useSelector } from 'src/store';
import useRefMounted from 'src/hooks/useRefMounted';
import PersonPinCircleTwoToneIcon from '@mui/icons-material/PersonPinCircleTwoTone';
import { updatePersonalDetailsAPI } from 'src/content/Settings/api';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
      
      .MuiBadge-badge {
          background-color: ${theme.palette.error.main};
          color: ${theme.palette.error.contrastText};
          min-width: 18px; 
          height: 18px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
      margin-left: ${theme.spacing(1)};
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]};
      padding: 0;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      transition: ${theme.transitions.create(['background', 'color'])};
  
      &.active,
      &:active,
      &:hover {
        background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
        color: ${theme.colors.alpha.trueWhite[100]};
      }
  `
);

function LocationSwitcher() {
  const isMountedRef = useRefMounted();
  //   const dispatch = useDispatch();
  const { loggedInUser, userList } = useSelector((state) => state.users);
  const { locationDetails } = useSelector((state) => state.locations);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [locationChanging, setLocationChanging] = useState(false);

  const handleLocationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLocationClose = () => {
    setAnchorEl(null);
  };

  const handleLocationChange = async (locationId) => {
    setLocationChanging(true);
    const response = await updatePersonalDetailsAPI({
      user: {
        id: loggedInUser?.id,
        preferredName: loggedInUser?.preferredName,
        currentLocationId: locationId
      }
    });

    window.location.reload();
  };

  if (userList.length <= 1 || loggedInUser.id === 'None') {
    return <></>;
  }

  return (
    <>
      <Tooltip
        arrow
        title={`Current Location: ${
          locationDetails.find(
            (loc) => loc.id === loggedInUser?.currentLocationId
          )?.code
        }`}
      >
        <IconButtonPrimary
          data-testid="navbar-current-location-button"
          color="primary"
          size="small"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleLocationClick}
        >
          <NotificationsBadge
            // badgeContent={2}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <PersonPinCircleTwoToneIcon />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLocationClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {loggedInUser?.allowedLocationIds.map((locId, index) => (
          <MenuItem
            data-testid={`navbar-current-location-${
              locationDetails.find((loc) => loc.id === locId)?.code
            }`}
            key={locId}
            onClick={() => handleLocationChange(locId)}
            disabled={
              locId === loggedInUser?.currentLocationId || locationChanging
            }
          >
            {locationDetails.find((loc) => loc.id === locId)?.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LocationSwitcher;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const OnlineBookingTransactions = Loader(
  lazy(() => import('src/content/OnlineBookingTransactions'))
);
// const SingleOnlineBookingTransaction = Loader(
//   lazy(() => import('src/content/OnlineBookingTransactions/single'))
// );

const onlineBookingTransactionRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <OnlineBookingTransactions />
  }
  //   {
  //     path: ':onlineBookingTransactionId',
  //     element: <SingleOnlineBookingTransaction />
  //   }
];

export default onlineBookingTransactionRoutes;

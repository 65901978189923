import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetPassPurchaseQuery,
  ListPassPurchasesQuery,
  GetPatientPassQuery,
  ListPatientPassesQuery,
  RedeemPassMutation,
  CreatePatientPassMutation,
  UpdatePatientPassMutation,
  PurchasePassMutation,
  VoidPassPurchaseMutation,
  VoidPassRedemptionMutation,
  VoidPatientPassMutation
} from 'src/API';
import {
  redeemPass,
  createPatientPass,
  updatePatientPass,
  purchasePass,
  voidPassPurchase,
  voidPassRedemption
} from 'src/graphql/mutations';
import { getPassPurchase, listPassPurchases } from 'src/graphql/queries';
import { customListPatientPasses, customGetPatientPass } from './queries';
import { customVoidPatientPass } from './mutations';

export const purchasePassAPI = async (purchasePassDetails) => {
  try {
    const purchasedPass = await callGraphQL<PurchasePassMutation>(
      purchasePass,
      {
        variables: {
          input: purchasePassDetails
        }
      }
    );

    // console.log(purchasedPass);
    return purchasedPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPassPurchaseAPI = async (passPurchaseId) => {
  try {
    const passPurchase = await callGraphQL<GetPassPurchaseQuery>(
      getPassPurchase,
      {
        variables: {
          id: passPurchaseId
        }
      }
    );

    // console.log(passPurchase);
    return passPurchase;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPassPurchasesAPI = async (
  invoiceId,
  patientId,
  corporateId,
  passId,
  practitionerId,
  salePersonId,
  purchasedAfter,
  purchasedBefore
) => {
  try {
    const passPurchases = await callGraphQL<ListPassPurchasesQuery>(
      listPassPurchases,
      {
        variables: {
          invoiceId: invoiceId,
          patientId: patientId,
          corporateId: corporateId,
          passId: passId,
          practitionerId: practitionerId,
          salePersonId: salePersonId,
          purchasedAfter: purchasedAfter,
          purchasedBefore: purchasedBefore
        }
      }
    );

    // console.log(passPurchases);
    return passPurchases;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createPatientPassAPI = async (patientPass) => {
  try {
    const createdPatientPass = await callGraphQL<CreatePatientPassMutation>(
      createPatientPass,
      {
        variables: {
          input: patientPass
        }
      }
    );

    // console.log(createdPatientPass);
    return createdPatientPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPatientPassAPI = async (patientPassId) => {
  try {
    const patientPass = await callGraphQL<GetPatientPassQuery>(
      customGetPatientPass,
      {
        variables: {
          id: patientPassId
        }
      }
    );

    // console.log(patientPass);
    return patientPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientPassesAPI = async (patientId) => {
  try {
    const patientPasses = await callGraphQL<ListPatientPassesQuery>(
      customListPatientPasses,
      {
        variables: {
          patientId: patientId
        }
      }
    );

    // console.log(patientPasses);
    return patientPasses;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePatientPassAPI = async (patientPassDetails) => {
  try {
    const updatedPatientPass = await callGraphQL<UpdatePatientPassMutation>(
      updatePatientPass,
      {
        variables: {
          input: patientPassDetails
        }
      }
    );

    // console.log(updatedPatientPass);
    return updatedPatientPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidPatientPassAPI = async (patientPass) => {
  try {
    const voidedPatientPass = await callGraphQL<VoidPatientPassMutation>(
      customVoidPatientPass,
      {
        variables: {
          id: patientPass.id,
          reason: patientPass.reason
        }
      }
    );
    // console.log(voidedPayment)
    if (!voidedPatientPass.data.voidPatientPass) {
      throw new Error();
    }
    return voidedPatientPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const redeemPatientPassAPI = async (redeemPassDetails) => {
  try {
    const redeemedPass = await callGraphQL<RedeemPassMutation>(redeemPass, {
      variables: {
        input: redeemPassDetails
      }
    });

    // console.log(redeemedPass);
    return redeemedPass;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidPatientPassRedemptionAPI = async (
  voidPatientPassRedemption
) => {
  try {
    const voidedRedemption = await callGraphQL<VoidPassRedemptionMutation>(
      voidPassRedemption,
      {
        variables: {
          redemptionId: voidPatientPassRedemption.redemptionId,
          reason: voidPatientPassRedemption.reason
        }
      }
    );

    // console.log(voidedRedemption);
    return voidedRedemption;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

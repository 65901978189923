import {
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
  Ref,
  ReactElement,
  ChangeEvent
} from 'react';
import useAuth from 'src/hooks/useAuth';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'src/store';
import useRefMounted from 'src/hooks/useRefMounted';
import { useSnackbar } from 'notistack';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import htmlParse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { format } from 'date-fns';
import {
  alpha,
  Grid,
  Alert,
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Slide,
  Zoom,
  Tooltip,
  Typography,
  styled
} from '@mui/material';

import OfflineBoltTwoTone from '@mui/icons-material/OfflineBoltTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import {
  createAppointment,
  setQuickActionErrorMessage as setAppointmentErrorMessage,
  setQuickActionSuccessMessage as setAppointmentSuccessMessage
} from 'src/slices/appointments';
import {
  setQuickActionErrorMessage as setPatientErrorMessage,
  setQuickActionSuccessMessage as setPatientSuccessMessage
} from 'src/slices/patients';

import TabsWrapper from 'src/components/TabsWrapper';
import CreateAppointmentAction from './CreateAppointmentAction';
import CreatePatientAction from './CreatePatientAction';
import SimpleSearch from 'src/content/Patients/SimpleSearch';

import tenantMappings from 'src/utils/tenantMappings';
import { Mixpanel } from 'src/contexts/MixPanelContext';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
      margin-left: ${theme.spacing(1)};
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]};
      padding: 0;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      transition: ${theme.transitions.create(['background', 'color'])};
  
      &.active,
      &:active,
      &:hover {
        background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
        color: ${theme.colors.alpha.trueWhite[100]};
      }
  `
);

const dictionaryMappings = tenantMappings?.dictionaryMappings;

function HeaderQuickActions() {
  const ref = useRef<any>(null);
  const isMountedRef = useRefMounted();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();

  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const mappedWordsToUse =
    dictionaryMappings?.getMappedWordToUse(tenantIdLowerCase);
  const getMappedWordToUse = (word) => {
    const fallback = word;

    const result = mappedWordsToUse[word];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  };

  const [isOpen, setOpen] = useState<boolean>(false);
  const [hasCreated, setHasCreated] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [appointmentData, setAppointmentData] = useState<any>({});
  const [notify, setNotify] = useState<boolean>(true);
  const [selectedPatient, setSelectedPatient] = useState({
    id: '',
    fullName: '',
    preferredName: '',
    internalNote: ''
  });

  useHotkeys('command+k, ctrl+k', () => {
    if (loggedInUser?.type?.toLowerCase() !== 'None') {
      handleOpen();
      Mixpanel.identify(loggedInUser?.id);
      Mixpanel.track('Quick Action - Create Appointment');
    }
  });

  const { activeLocationDetails } = useSelector((state) => state.locations);
  const { loggedInUser, activeUserList, providerList } = useSelector(
    (state) => state.users
  );
  const { quickActionApptErrorMessage, quickActionApptSuccessMessage } =
    useSelector((state) => state.appointments);
  const { quickActionCustomerErrorMessage, quickActionCustomerSuccessMessage } =
    useSelector((state) => state.patients);

  const [practitionerOptions, setPractitionerOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const tabs = [
    { value: 'existingPatient', label: 'Existing' },
    { value: 'newPatient', label: 'New' }
  ];

  const customEnqueueSnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleOpen = (): void => {
    setOpen(true);
    setCurrentTab('createAppointment');
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelectedPatient({
      id: '',
      fullName: '',
      preferredName: '',
      internalNote: ''
    });
    setAppointmentData({});
    setCurrentTab('');
  };

  const handleExistingPatientBooking = () => {
    // const semiData = { ...appointmentData, ...selectedPatient}
    const finalData = {
      patientId: selectedPatient.id,
      serviceId: appointmentData.serviceId,
      appointmentDate: appointmentData.appointmentDate,
      appointmentStartTime: appointmentData.appointmentStartTime,
      appointmentEndTime: appointmentData.appointmentEndTime,
      type: appointmentData.type,
      appointmentNote:
        appointmentData.appointmentNote?.length > 0
          ? appointmentData.appointmentNote
          : '',
      status: appointmentData.status,
      recurFrequency: appointmentData?.recurFrequency || null,
      recurInstances: appointmentData?.recurFrequency ? appointmentData?.recurInstances || 1 : null,
      sendNotification: notify
    };

    console.log('Final data to be submitted');
    console.log(finalData);

    setHasCreated(true);
    // dispatch(createAppointment(tenantIdLowerCase, finalData, notify, true)); // removed notification flag from api call
    dispatch(createAppointment(tenantIdLowerCase, finalData, true));
    handleClose();
  };

  const getPractitionerOptions = useCallback(async () => {
    try {
      const allPractitioners =
        loggedInUser?.type?.toLowerCase() === 'practitioner'
          ? activeUserList.filter((user) => user.id === loggedInUser.id)
          : [...providerList];

      // console.log(allPractitioners);

      const optionsReceived = allPractitioners.map((practitioner) => ({
        label: `${practitioner.preferredName}`,
        value: practitioner.id
      }));

      if (isMountedRef.current) {
        // console.log(optionsReceived);
        setPractitionerOptions([
          ...optionsReceived,
          { label: '', value: null }
        ]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef, activeUserList]);

  // For Create Appointment Form later
  useEffect(() => {
    getPractitionerOptions();
  }, [isMountedRef, activeUserList]);

  useEffect(() => {
    // console.log('this useEffect fired to set locations');
    const allowedLocationIds = loggedInUser?.allowedLocationIds;
    // console.log('allowedLocationIds', allowedLocationIds);
    // console.log('activeLocationDetails', activeLocationDetails);

    const optionsReceived = activeLocationDetails
      ?.filter((loc) => allowedLocationIds?.includes(loc.id))
      ?.map((loc) => ({
        label: loc.name,
        value: loc.id
      }));
    // console.log('locationOptions', optionsReceived);
    if (isMountedRef.current) {
      setLocationOptions([...optionsReceived]);
    }
  }, [isMountedRef, activeLocationDetails, loggedInUser]);

  useEffect(() => {
    if (quickActionApptErrorMessage !== '') {
      customEnqueueSnackbar(quickActionApptErrorMessage, 'error');

      if (hasCreated) {
        setHasCreated(false);
      }
      dispatch(setAppointmentErrorMessage(''));
    }
  }, [quickActionApptErrorMessage, isMountedRef]);

  useEffect(() => {
    if (quickActionApptSuccessMessage !== '') {
      customEnqueueSnackbar(quickActionApptSuccessMessage, 'success');

      if (hasCreated) {
        setHasCreated(false);
      }
      dispatch(setAppointmentSuccessMessage(''));
    }
  }, [quickActionApptSuccessMessage, isMountedRef]);

  useEffect(() => {
    if (quickActionCustomerErrorMessage !== '') {
      customEnqueueSnackbar(quickActionCustomerErrorMessage, 'error');

      if (hasCreated) {
        setHasCreated(false);
      }
      dispatch(setPatientErrorMessage(''));
    }
  }, [quickActionCustomerErrorMessage, isMountedRef]);

  useEffect(() => {
    if (quickActionCustomerSuccessMessage !== '') {
      customEnqueueSnackbar(quickActionCustomerSuccessMessage, 'success');

      if (hasCreated) {
        setHasCreated(false);
      }
      dispatch(setPatientSuccessMessage(''));
    }
  }, [quickActionCustomerSuccessMessage, isMountedRef]);

  if (activeUserList?.length < 1 || loggedInUser?.id === 'None') {
    return null;
  }

  // console.log(locationOptions);

  return (
    <>
      <Tooltip arrow title={t(`Quick Actions`)}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          <OfflineBoltTwoTone />
        </IconButtonPrimary>
      </Tooltip>

      <Dialog
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        open={isOpen}
        maxWidth="md"
        // onClose={handleClose}
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick') {
            return;
          }
          handleClose();
        }}
      >
        <DialogTitle
          sx={{
            p: 2,
            display: 'inline-flex',
            justifyContent: 'space-between'
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Typography variant="h3" gutterBottom>
              {`Book Appointment for ${getMappedWordToUse('Patient')}`}
            </Typography>
            <Typography variant="subtitle2">
              {t('Fill in the details to book an appointment')}
            </Typography>
          </Box>

          <Box
            display={{ xs: 'flex', sm: 'block' }}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <IconButton onClick={handleClose} color="primary">
              <CloseTwoToneIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            px: 2, // needed to create padding for entire dialog content
            pt: 2
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
            rowGap={2}
          >
            {appointmentData &&
            Object.keys(appointmentData).length === 0 ? null : (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  // sx={{ px: 2, pt: 2 }}
                >
                  {/* <Typography variant="h4" pb={2}>
                  {`Select patient for the appointment:`}
                </Typography> */}
                  <Typography variant="body2">
                    <b>{`${getMappedWordToUse('Practitioner')}`}: </b>
                    {`${appointmentData.practitionerName}`}
                  </Typography>
                  <Typography variant="body2">
                    <b>Service: </b>
                    {`${appointmentData.serviceName}`}
                  </Typography>
                  <Typography variant="body2">
                    <b>Location: </b>
                    {`${appointmentData.locationCode}`}
                  </Typography>
                  <Typography variant="body2">
                    <b>Appointment Start Time: </b>
                    {`${format(
                      appointmentData.appointmentStartTime,
                      "EEE, dd LLLL yyyy - hh:mm aaaaa'm'"
                    )}`}
                  </Typography>
                </Box>
              </Grid>
            )}

            {currentTab === 'existingPatient' && selectedPatient?.id !== '' && (
              <>
                <Grid item xs={12}>
                  <Grid container spacing={0} rowGap={2}>
                    <Grid item xs={12} md={8}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h3">
                          {`You have selected: `}
                          {selectedPatient.fullName &&
                          selectedPatient.fullName?.length > 0 ? (
                            <b>{selectedPatient.fullName}</b>
                          ) : (
                            <b>{selectedPatient.preferredName}</b>
                          )}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            setSelectedPatient({
                              id: '',
                              fullName: '',
                              preferredName: '',
                              internalNote: ''
                            })
                          }
                          sx={{ mx: 2 }}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleExistingPatientBooking}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Alert variant="outlined" severity="warning">
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      {/* <b>{patient?.piiProfile?.internalNote}</b> */}
                      {htmlParse(
                        sanitizeHtml(selectedPatient?.internalNote, {
                          allowedTags: false,
                          allowedAttributes: false,
                          disallowedTagsMode: 'escape'
                        }) || ''
                      )}
                    </Typography>
                  </Alert>
                </Grid>
              </>
            )}

            {currentTab !== 'createAppointment' &&
              currentTab !== '' &&
              selectedPatient?.id === '' && (
                <Grid item xs={12}>
                  <TabsWrapper
                    currentTab={currentTab}
                    tabs={tabs}
                    handleTabsChange={handleTabsChange}
                  />
                </Grid>
              )}

            {currentTab === 'createAppointment' && (
              <Grid item xs={12}>
                <CreateAppointmentAction
                  setCurrentTab={setCurrentTab}
                  setAppointmentData={setAppointmentData}
                  // locationOptions={locationOptions}
                  // practitionerOptions={practitionerOptions}
                  // patientLocation={loggedInUser?.currentLocationId}
                  setNotify={setNotify}
                />
              </Grid>
            )}

            {currentTab === 'newPatient' && (
              <Grid item xs={12}>
                <CreatePatientAction
                  setSelectedPatient={setSelectedPatient}
                  appointmentData={appointmentData}
                  handleClose={handleClose}
                  setHasCreated={setHasCreated}
                  notify={notify}
                />
              </Grid>
            )}

            {currentTab === 'existingPatient' && selectedPatient?.id === '' && (
              <Grid item xs={12}>
                <SimpleSearch
                  selectedCustomer={selectedPatient}
                  setSelectedCustomer={setSelectedPatient}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default HeaderQuickActions;

import callGraphQL, { subscribeGraphQL } from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateAppointmentPlanTemplateMutation,
  ListAppointmentPlanTemplatesQuery,
  GetAppointmentPlanTemplateQuery
} from 'src/API';
import {
  createAppointmentPlanTemplate,
  updateAppointmentPlanTemplate,
  deleteAppointmentPlanTemplate
} from 'src/graphql/mutations';
import {
  getAppointmentPlanTemplate,
  listAppointmentPlanTemplates
} from 'src/graphql/queries';
import { customListAppointmentPlanTemplates } from './queries';

export const getAppointmentPlanTemplateAPI = async (planTemplateId) => {
  try {
    const planTemplate = await callGraphQL<GetAppointmentPlanTemplateQuery>(
      getAppointmentPlanTemplate,
      {
        variables: {
          id: planTemplateId
        }
      }
    );
    // console.log(planTemplate);
    return planTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentPlanTemplatesAPI = async (
  minCreatedTime?,
  maxCreatedTime?
) => {
  try {
    const planTemplates = await callGraphQL<ListAppointmentPlanTemplatesQuery>(
      customListAppointmentPlanTemplates,
      {
        variables: {
          minCreatedTime: minCreatedTime,
          maxCreatedTime: maxCreatedTime
        }
      }
    );
    // console.log(planTemplates);
    return planTemplates;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createAppointmentPlanTemplateAPI = async (planTemplate) => {
  try {
    const newPlanTemplate =
      await callGraphQL<CreateAppointmentPlanTemplateMutation>(
        createAppointmentPlanTemplate,
        {
          variables: {
            input: planTemplate
          }
        }
      );
    // console.log(newPlanTemplate);
    return newPlanTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateAppointmentPlanTemplateAPI = async (planTemplate) => {
  try {
    const updatedPlanTemplate =
      await callGraphQL<CreateAppointmentPlanTemplateMutation>(
        updateAppointmentPlanTemplate,
        {
          variables: {
            input: planTemplate
          }
        }
      );
    // console.log(updatedPlanTemplate);
    return updatedPlanTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteAppointmentPlanTemplateAPI = async (planTemplateId) => {
  try {
    const deletedPlanTemplate =
      await callGraphQL<CreateAppointmentPlanTemplateMutation>(
        deleteAppointmentPlanTemplate,
        {
          variables: {
            id: planTemplateId
          }
        }
      );
    // console.log(deletedPlanTemplate);
    return deletedPlanTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

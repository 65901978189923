import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';

import {
  getAppointmentPlanAPI,
  listCustomerAppointmentPlansAPI,
  listTemplateAppointmentPlansAPI,
  createAppointmentPlanAPI,
  updateAppointmentPlanAPI,
  deleteAppointmentPlanAPI
} from 'src/content/AppointmentPlans/api';
import { getCustomerAppointments } from './appointments';

interface AppointmentPlanState {
  isLoadingCustomerPlans: boolean;
  isLoadingTemplatePlans: boolean;
  isEditingAppointmentPlan: boolean;
  isCreatingAppointmentPlan: boolean;
  isDeletingAppointmentPlan: boolean;
  allAppointmentPlansList: any[];
  appointmentPlanDetail: any[];
  appointmentPlanErrorMessage: string;
  appointmentPlanSuccessMessage: string;
}

const initialState: AppointmentPlanState = {
  isLoadingCustomerPlans: false,
  isLoadingTemplatePlans: false,
  isEditingAppointmentPlan: false,
  isCreatingAppointmentPlan: false,
  isDeletingAppointmentPlan: false,
  allAppointmentPlansList: [],
  appointmentPlanDetail: [],
  appointmentPlanErrorMessage: '',
  appointmentPlanSuccessMessage: ''
};

// Sort by created time DSCending first
const sortAppointmentPlansDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

// Sort by created time ASCending first
const sortAppointmentPlansAsc = (a, b) => {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'appointmentPlans',
  initialState,
  reducers: {
    listAppointmentPlans(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      const appointmentPlans = action.payload;
      appointmentPlans.sort(sortAppointmentPlansDesc);

      state.allAppointmentPlansList = [...appointmentPlans];

      state.isLoadingCustomerPlans = false;
      state.isLoadingTemplatePlans = false;
    },

    getAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.appointmentPlanDetail = [action.payload];

        if (state.allAppointmentPlansList.length > 0) {
          state.allAppointmentPlansList = state.allAppointmentPlansList.map(
            (appointmentPlan) =>
              appointmentPlan.id === action.payload.id
                ? action.payload
                : appointmentPlan
          );
        }
      } else {
        state.appointmentPlanDetail = [{ id: 'Not Found' }];
      }
    },

    createAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.allAppointmentPlansList = [
        action.payload,
        ...state.allAppointmentPlansList
      ];
    },

    updateAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      const editedAppointmentPlan = action.payload;

      state.appointmentPlanDetail =
        state.appointmentPlanDetail[0]?.id === editedAppointmentPlan.id
          ? [editedAppointmentPlan]
          : [...state.appointmentPlanDetail];

      let appointmentPlanIndex = state.allAppointmentPlansList.findIndex(
        (obj) => obj.id === editedAppointmentPlan.id
      );

      if (appointmentPlanIndex >= 0) {
        state.allAppointmentPlansList = state.allAppointmentPlansList.map(
          (appointmentPlan, i) =>
            i === appointmentPlanIndex ? editedAppointmentPlan : appointmentPlan
        );
      }
    },

    // voidAppointmentPlan(state: AppointmentPlanState, action: PayloadAction<any>) {
    //   const voidedAppointmentPlan = action.payload;

    //   let appointmentPlanIndex = state.appointmentPlans.findIndex(
    //     (obj) => obj.id === voidedAppointmentPlan.id
    //   );

    //   if (appointmentPlanIndex >= 0) {
    //     state.appointmentPlans = state.appointmentPlans.map((appointmentPlan, i) =>
    //       i === appointmentPlanIndex ? voidedAppointmentPlan : appointmentPlan
    //     );
    //   }

    //   if (action.payload) {
    //     state.appointmentPlanDetail = [action.payload];
    //   } else {
    //     state.appointmentPlanDetail = [{ id: 'Not Found' }];
    //   }
    // },

    deleteAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.allAppointmentPlansList = state.allAppointmentPlansList.filter(
        (template) => template.id !== action.payload.id
      );
    },

    clearSelectedAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.appointmentPlanDetail = [];
    },

    setIsLoadingCustomerPlans(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerPlans = action.payload;
    },

    setIsLoadingTemplatePlans(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.isLoadingTemplatePlans = action.payload;
    },

    setIsEditingAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.isEditingAppointmentPlan = action.payload;
    },

    setIsCreatingAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.isCreatingAppointmentPlan = action.payload;
    },

    setIsDeletingAppointmentPlan(
      state: AppointmentPlanState,
      action: PayloadAction<any>
    ) {
      state.isDeletingAppointmentPlan = action.payload;
    },

    setErrorMessage(state: AppointmentPlanState, action: PayloadAction<any>) {
      state.appointmentPlanErrorMessage = action.payload;
    },

    setSuccessMessage(state: AppointmentPlanState, action: PayloadAction<any>) {
      state.appointmentPlanSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listCustomerAppointmentPlans =
  (customerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerPlans());

    const response = await backOff(
      () => listCustomerAppointmentPlansAPI(customerId),
      {
        numOfAttempts: 3
      }
    );
    if (!response.customErrorMessage) {
      // console.log(response);
      dispatch(
        slice.actions.listAppointmentPlans(
          response.data.listCustomerAppointmentPlans
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listTemplateAppointmentPlans =
  (templateId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingTemplatePlans());

    const response = await backOff(
      () => listTemplateAppointmentPlansAPI(templateId),
      {
        numOfAttempts: 3
      }
    );
    if (!response.customErrorMessage) {
      // console.log(response);
      dispatch(
        slice.actions.listAppointmentPlans(
          response.data.listTemplateAppointmentPlans
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerPlans());
  };

export const getAppointmentPlan =
  (appointmentPlanId): AppThunk =>
  async (dispatch) => {
    const response = await getAppointmentPlanAPI(appointmentPlanId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getAppointmentPlan(response.data.getAppointmentPlan)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedAppointmentPlan =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedAppointmentPlan({}));
  };

export const createAppointmentPlan =
  (appointmentPlan): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingAppointmentPlan());
    dispatch(startLoadingCustomerPlans());

    const response = await createAppointmentPlanAPI(appointmentPlan);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createAppointmentPlan(response.data.createAppointmentPlan)
      );
      dispatch(slice.actions.setSuccessMessage('Plan successfully assigned'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingAppointmentPlan());
      dispatch(stopLoadingCustomerPlans());
    };

    return followUp();
  };

export const updateAppointmentPlan =
  (appointmentPlan, customerId = ''): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointmentPlan());

    const response = await updateAppointmentPlanAPI(appointmentPlan);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateAppointmentPlan(response.data.updateAppointmentPlan)
      );

      if (customerId !== '') {
        dispatch(getCustomerAppointments(customerId));
      }

      dispatch(slice.actions.setSuccessMessage('Plan successfully updated'));
    } else {
      // dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
      // console.log(response.customErrorMessage);
      dispatch(
        slice.actions.setErrorMessage(
          'Something went wrong and transaction was cancelled. Please refresh and try again later.'
        )
      );
    }

    return dispatch(stopEditingAppointmentPlan());
  };

export const deleteAppointmentPlanTemplate =
  (appointmentPlanId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerPlans());
    dispatch(startDeletingAppointmentPlan());

    const response = await deleteAppointmentPlanAPI(appointmentPlanId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteAppointmentPlan(response.data.deleteAppointmentPlan)
      );
      if (!bulkDelete) {
        dispatch(slice.actions.setSuccessMessage('Plan successfully deleted'));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingAppointmentPlan());
      dispatch(stopLoadingCustomerPlans());
    };

    return followUp();
  };

export const startLoadingCustomerPlans = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerPlans(true));
};

export const stopLoadingCustomerPlans = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerPlans(false));
};

export const startLoadingTemplatePlans = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingTemplatePlans(true));
};

export const stopLoadingTemplatePlans = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingTemplatePlans(false));
};

export const startEditingAppointmentPlan = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingAppointmentPlan(true));
};

export const stopEditingAppointmentPlan = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingAppointmentPlan(false));
};

export const startCreatingAppointmentPlan =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingAppointmentPlan(true));
  };

export const stopCreatingAppointmentPlan = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingAppointmentPlan(false));
};

export const startDeletingAppointmentPlan =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsDeletingAppointmentPlan(true));
  };

export const stopDeletingAppointmentPlan = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingAppointmentPlan(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreatePatientNoteMutation,
  UpdatePatientNoteMutation,
  DeletePatientNoteMutation,
  PublishPatientNoteMutation,
  VoidPatientNoteMutation,
  GetPatientNoteQuery
} from 'src/API';
// import { getPatientNote, listPatientNotes } from 'src/graphql/queries';
import {
  createPatientNote,
  updatePatientNote,
  publishPatientNote,
  voidPatientNote,
  deletePatientNote
} from 'src/graphql/mutations';
import { listPatientNotesQuery } from './queries';

export const createPatientNoteAPI = async (note) => {
  try {
    const newNote = await callGraphQL<CreatePatientNoteMutation>(
      createPatientNote,
      {
        variables: {
          input: note
        }
      }
    );

    return newNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientNotesAPI = async (
  patientId,
  isNewestFirst = false,
  limit = 5
) => {
  try {
    const notes = await callGraphQL<any>(listPatientNotesQuery, {
      variables: {
        patientId,
        isNewestFirst,
        limit
      }
    });
    // console.log(notes);
    return notes;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientNotesByTypeAPI = async (
  patientId,
  type,
  isNewestFirst = false,
  limit = 5
) => {
  try {
    const notes = await callGraphQL<any>(listPatientNotesQuery, {
      variables: {
        patientId,
        type,
        isNewestFirst,
        limit
      }
    });
    // console.log(notes);
    return notes;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const editPatientNoteAPI = async (note) => {
  try {
    const editedNote = await callGraphQL<UpdatePatientNoteMutation>(
      updatePatientNote,
      {
        variables: {
          input: note
        }
      }
    );
    // console.log(editedNote);
    return editedNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const publishPatientNoteAPI = async (noteId) => {
  try {
    const publishedNote = await callGraphQL<PublishPatientNoteMutation>(
      publishPatientNote,
      {
        variables: {
          id: noteId
        }
      }
    );
    // console.log(editedNote);
    return publishedNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidPatientNoteAPI = async (voidNote) => {
  try {
    const voidedNote = await callGraphQL<VoidPatientNoteMutation>(
      voidPatientNote,
      {
        variables: {
          input: voidNote
        }
      }
    );
    // console.log(editedNote);
    return voidedNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deletePatientNoteAPI = async (noteId) => {
  try {
    const deletedNote = await callGraphQL<DeletePatientNoteMutation>(
      deletePatientNote,
      {
        variables: {
          id: noteId
        }
      }
    );
    // console.log(editedNote);
    return deletedNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getUserTypeLabel = (
  userType: string
): { text: string; color: string } => {
  try {
    const map = {
      Admin: {
        text: 'Admin',
        color: 'success'
      },
      'Admin-Practitioner': {
        text: 'Admin',
        color: 'success'
      },
      'Admin-Practitioner-Owner': {
        text: 'Admin',
        color: 'success'
      },
      'Admin-Owner': {
        text: 'Admin',
        color: 'success'
      },
      Practitioner: {
        text: 'Practitioner',
        color: 'primary'
      },
      Designer: {
        text: 'Designer',
        color: 'primary'
      },
      Operations: {
        text: 'Operations',
        color: 'warning'
      },
      Terminal: {
        text: 'Terminal',
        color: 'warning'
      },
      Terminated: {
        text: 'Terminated',
        color: 'error'
      },
      ONLINE_BOOKING_BOT: {
        text: 'System',
        color: 'info'
      },
      CLASSPASS_BOT: {
        text: 'System',
        color: 'info'
      },
      STRIPE_BOT: {
        text: 'System',
        color: 'info'
      },
      APPOINTMENT_BOT: {
        text: 'System',
        color: 'info'
      },
      COMMUNICATION_BOT: {
        text: 'System',
        color: 'info'
      },
      S3_EVENT_LISTENER_BOT: {
        text: 'System',
        color: 'info'
      },
      GOOGLE_CALENDAR_LISTENER_BOT: {
        text: 'System',
        color: 'info'
      }
    };

    const { text, color }: any = map[userType];

    return { text, color };
  } catch {
    return { text: 'Unknown', color: 'error' };
  }
};

export const getUserStatusLabel = (
  userStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Active: {
        text: 'ACTIVE',
        color: 'success'
      },
      Inactive: {
        text: 'INACTIVE',
        color: 'error'
      }
    };

    const { text, color }: any = map[userStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

export const customUpdateOnlineBookingPage = /* GraphQL */ `
  mutation UpdateOnlineBookingPage($input: UpdateOnlineBookingPageInput!) {
    updateOnlineBookingPage(input: $input) {
      id
      name
      urlSlug
      isActive
      allowedDaysInFuture
      appointmentHoldTime
      serviceIds
      services {
        id
        locationId
        practitionerId
        name
        code
        duration
        onlineBookingCategory
        onlineBookingName
      }
      classServiceIds
      classServices {
        id
        locationId
        practitionerId
        name
        code
        duration
        maxParticipants
        onlineBookingName
        onlineBookingCategory
        onlineBookingDescription
        isActive
      }
      practitionerIds
      practitioners {
        id
        preferredName
      }
      locationIds
      locations {
        id
        code
      }
      headHTML
      contentHTML
      bookingFinePrint
      bookingConfirmationEmailTemplate
      emailVerificationEmailTemplate
      notificationEmailFromName
      notificationEmailAddress
      bookingEmailBccList
      customFields
      createInvoiceUpfront
      promoCodeIds
      promoCodes {
        id
        name
        code
        type
        promoCodeValue {
          type
          amount {
            amount
            currency
            precision
            scale
          }
        }
        isActive
        startTime
        endTime
      }
      paymentType
      paymentRequired
      paymentAmount {
        amount
        currency
        precision
        scale
      }
      paymentAmountType
      validFrom
      validTo
      thirdPartyTools
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customCreateOnlineBookingPage = /* GraphQL */ `
  mutation CreateOnlineBookingPage($input: CreateOnlineBookingPageInput!) {
    createOnlineBookingPage(input: $input) {
      id
      tenantId
      name
      urlSlug
      serviceIds
      # services {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   category
      #   duration
      #   workingHours
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      classServiceIds
      # classServices {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   onlineBookingDescription
      #   category
      #   duration
      #   maxParticipants
      #   onlineBookingName
      #   onlineBookingCategory
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      practitionerIds
      # practitioners {
      #   id
      #   username
      #   type
      #   preferredName
      #   firstName
      #   lastName
      #   middleName
      #   jobTitle
      #   profilePicture
      #   email
      #   mobile
      #   primaryLocationId
      #   currentLocationId
      #   allowedLocationIds
      #   timezone
      #   country
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      #   identityNumber
      #   identityType
      #   onboardDate
      #   resignationDate
      #   isActive
      #   registrationAuthority
      #   registrationNumber
      #   discipline
      #   qualifications
      # }
      locationIds
      # locations {
      #   id
      #   name
      #   code
      #   addressStreetAddress
      #   addressUnitNo
      #   addressCountry
      #   addressPostalCode
      #   taxStatus
      #   registeredName
      #   registrationNumber
      #   operatingHours
      #   telephones
      #   whatsapp
      #   emails
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        #  name
        #  isActive
        #  startTime
        #  endTime
      }
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customDeleteOnlineBookingPage = /* GraphQL */ `
  mutation DeleteOnlineBookingPage($id: ID!) {
    deleteOnlineBookingPage(id: $id) {
      id
      tenantId
      name
      urlSlug
      serviceIds
      # services {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   category
      #   duration
      #   workingHours
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      classServiceIds
      # classServices {
      #   id
      #   locationId
      #   practitionerId
      #   name
      #   code
      #   description
      #   onlineBookingDescription
      #   category
      #   duration
      #   maxParticipants
      #   onlineBookingName
      #   onlineBookingCategory
      #   isActive
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      practitionerIds
      # practitioners {
      #   id
      #   username
      #   type
      #   preferredName
      #   firstName
      #   lastName
      #   middleName
      #   jobTitle
      #   profilePicture
      #   email
      #   mobile
      #   primaryLocationId
      #   currentLocationId
      #   allowedLocationIds
      #   timezone
      #   country
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      #   identityNumber
      #   identityType
      #   onboardDate
      #   resignationDate
      #   isActive
      #   registrationAuthority
      #   registrationNumber
      #   discipline
      #   qualifications
      # }
      locationIds
      # locations {
      #   id
      #   name
      #   code
      #   addressStreetAddress
      #   addressUnitNo
      #   addressCountry
      #   addressPostalCode
      #   taxStatus
      #   registeredName
      #   registrationNumber
      #   operatingHours
      #   telephones
      #   whatsapp
      #   emails
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      isActive
      validFrom
      validTo
      headHTML
      contentHTML
      bookingFinePrint
      paymentRequired
      paymentType
      promoCodeIds
      promoCodes {
        id
        code
        #  name
        #  isActive
        #  startTime
        #  endTime
      }
      customFields
      notificationEmailFromName
      notificationEmailAddress
      emailVerificationEmailTemplate
      bookingConfirmationEmailTemplate
      bookingEmailBccList
      allowedDaysInFuture
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

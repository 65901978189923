import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetClassAppointmentQuery,
  GetClassAppointmentSeriesQuery,
  ListClassAppointmentsQuery,
  CreateClassAppointmentMutation,
  UpdateClassAppointmentMutation,
  UpdateClassAppointmentSeriesMutation,
  CancelClassAppointmentMutation,
  CancelClassAppointmentSeriesMutation,
  RegisterClassAppointmentPatientsMutation,
  UnregisterClassAppointmentPatientsMutation
} from 'src/API';
import {
  getClassAppointment,
  getClassAppointmentSeries,
  listClassAppointments
} from 'src/graphql/queries';
import {
  createClassAppointment,
  updateClassAppointment,
  updateClassAppointmentSeries,
  cancelClassAppointment,
  cancelClassAppointmentSeries,
  registerClassAppointmentPatients,
  unregisterClassAppointmentPatients
} from 'src/graphql/mutations';
import {
  customGetClassAppointment,
  customGetClassAppointmentInternalNote
} from './queries';
import { customUpdateClassAppointment } from './mutations';

export const createClassAppointmentAPI = async (classAppointment) => {
  try {
    const newClassAppt = await callGraphQL<CreateClassAppointmentMutation>(
      createClassAppointment,
      {
        variables: {
          input: classAppointment
        }
      }
    );

    // console.log(newClassAppt);
    return newClassAppt;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getClassAppointmentAPI = async (classAppointmentId) => {
  try {
    const classAppt = await callGraphQL<GetClassAppointmentQuery>(
      customGetClassAppointment,
      {
        variables: {
          id: classAppointmentId
        }
      }
    );

    // console.log(classAppt);
    return classAppt;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getClassAppointmentSeriesAPI = async (classAppointmentId) => {
  try {
    const classApptSeries = await callGraphQL<GetClassAppointmentSeriesQuery>(
      getClassAppointmentSeries,
      {
        variables: {
          id: classAppointmentId
        }
      }
    );
    // console.log(classApptSeries);
    return classApptSeries;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getClassAppointmentInternalNoteAPI = async (
  classAppointmentId
) => {
  try {
    const classInternalNote = await callGraphQL<GetClassAppointmentQuery>(
      customGetClassAppointmentInternalNote,
      {
        variables: {
          id: classAppointmentId
        }
      }
    );
    // console.log(patientDetailData);
    return classInternalNote;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listClassAppointmentsAPI = async (
  classAppointmentStartDate,
  classAppointmentEndDate,
  locationIds?,
  practitionerIds?,
  classServiceIds?,
  statuses?
) => {
  try {
    const classAppts = await callGraphQL<ListClassAppointmentsQuery>(
      listClassAppointments,
      {
        variables: {
          classAppointmentStartDate,
          classAppointmentEndDate,
          practitionerIds,
          locationIds,
          classServiceIds,
          statuses
        }
      }
    );
    // console.log(classAppts);
    return classAppts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPractitionerClassAppointmentsAPI = async (
  classAppointmentStartDate,
  classAppointmentEndDate,
  practitionerId
) => {
  try {
    const classAppts = await callGraphQL<ListClassAppointmentsQuery>(
      listClassAppointments,
      {
        variables: {
          classAppointmentStartDate,
          classAppointmentEndDate,
          practitionerIds: [practitionerId]
        }
      }
    );
    // console.log(classAppts);
    return classAppts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateClassAppointmentAPI = async (classAppointment) => {
  try {
    const updatedClassAppt = await callGraphQL<UpdateClassAppointmentMutation>(
      customUpdateClassAppointment,
      {
        variables: {
          input: classAppointment
        }
      }
    );

    // console.log(updatedClassAppt);
    return updatedClassAppt;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateClassAppointmentSeriesAPI = async (
  classAppointmentSeries
) => {
  try {
    const updatedClassApptSeries =
      await callGraphQL<UpdateClassAppointmentSeriesMutation>(
        updateClassAppointmentSeries,
        {
          variables: {
            input: classAppointmentSeries
          }
        }
      );

    // console.log(updatedClassApptSeries);
    return updatedClassApptSeries;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const cancelClassAppointmentAPI = async (
  classAppointmentId,
  cancellationNote,
  sendNotification
) => {
  try {
    const cancelledClassAppt =
      await callGraphQL<CancelClassAppointmentMutation>(
        cancelClassAppointment,
        {
          variables: {
            input: {
              id: classAppointmentId,
              cancellationNote: cancellationNote,
              sendNotification: sendNotification
            }
          }
        }
      );

    // console.log(updatedClassAppt);
    return cancelledClassAppt;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const cancelClassAppointmentSeriesAPI = async (
  classAppointmentSeriesId,
  cancellationNote,
  sendNotification
) => {
  try {
    const cancelledClassAppt =
      await callGraphQL<CancelClassAppointmentSeriesMutation>(
        cancelClassAppointmentSeries,
        {
          variables: {
            input: {
              id: classAppointmentSeriesId,
              cancellationNote: cancellationNote,
              sendNotification: sendNotification
            }
          }
        }
      );

    // console.log(updatedClassAppt);
    return cancelledClassAppt;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const registerClassAppointmentPatientsAPI = async (
  classApppointmentPatients
) => {
  try {
    // console.log(classApppointmentPatients)
    const classApptPatients =
      await callGraphQL<RegisterClassAppointmentPatientsMutation>(
        registerClassAppointmentPatients,
        {
          variables: {
            patientInputs: classApppointmentPatients
          }
        }
      );

    return classApptPatients;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const unregisterClassAppointmentPatientsAPI = async (
  classApppintmentPatients
) => {
  try {
    const classApptPatients =
      await callGraphQL<UnregisterClassAppointmentPatientsMutation>(
        unregisterClassAppointmentPatients,
        {
          variables: {
            patientInputs: classApppintmentPatients
          }
        }
      );

    return classApptPatients;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

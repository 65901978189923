import {
  blue,
  orange,
  grey,
  yellow,
  deepOrange,
  lime,
  red,
  amber,
  indigo,
  purple,
  green
} from '@mui/material/colors';

export const getAppointmentStatuses = (): any[] => {
  try {
    const statuses = [
      {
        label: 'Review',
        value: 'Review'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'Confirmed',
        value: 'Confirmed'
      },
      {
        label: 'Arrived',
        value: 'Arrived'
      },
      {
        label: 'Session',
        value: 'InSession'
      },
      {
        label: 'Payment',
        value: 'Payment'
      },
      {
        label: 'Completed',
        value: 'Completed'
      },
      {
        label: 'Missed',
        value: 'NoShow'
      },
      {
        label: 'Cancelled',
        value: 'Cancelled'
      }
    ];

    return statuses;
  } catch {
    return [];
  }
};

export const getAppointmentStatusLabel = (
  appointmentStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      PaymentPending: {
        text: 'Review',
        color: 'warning'
      },
      Review: {
        text: 'Review',
        color: 'warning'
      },
      Pending: {
        text: 'Pending',
        color: 'secondary'
      },
      Confirmed: {
        text: 'Confirmed',
        color: 'warning'
      },
      Arrived: {
        text: 'Arrived',
        color: 'primary'
      },
      InSession: {
        text: 'Session',
        color: 'info'
      },
      Payment: {
        text: 'Payment',
        color: 'warning'
      },
      Completed: {
        text: 'Completed',
        color: 'success'
      },
      NoShow: {
        text: 'Missed',
        color: 'error'
      },
      Cancelled: {
        text: 'Cancelled',
        color: 'error'
      }
    };

    const { text, color }: any = map[appointmentStatus];

    return { text, color };
  } catch {
    return { text: 'Other', color: 'error' };
  }
};

export const getAppointmentTypeDetails = (
  appointmentType: string
): { text: string; bgcolor: string } => {
  try {
    const map = {
      'Initial Visit': {
        text: 'Initial Visit',
        // bgcolor: orange[50]
        bgcolor: amber[50]
        // wrapper: 'wrapper-warning'
      },
      'Follow-Up Visit': {
        text: 'Follow-Up Visit',
        bgcolor: 'transparent'
        // wrapper: 'wrapper-primary'
      },
      Legacy: {
        text: 'Legacy',
        bgcolor: 'transparent'
        // wrapper: 'wrapper-primary'
      },
      Class: {
        text: 'Class',
        bgcolor: blue[50]
        // wrapper: 'wrapper-primary'
      }
    };

    const { text, bgcolor }: any = map[appointmentType];
    return { text, bgcolor };
  } catch {
    return { text: 'General', bgcolor: grey[50] };
  }
};

export const getClassAppointmentStatusLabel = (
  classAppointmentStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Pending: {
        text: 'PENDING',
        color: 'secondary'
      },
      InSession: {
        text: 'SESSION',
        color: 'primary'
      },
      Completed: {
        text: 'COMPLETED',
        color: 'success'
      },
      Cancelled: {
        text: 'CANCELLED',
        color: 'error'
      }
    };

    const { text, color }: any = map[classAppointmentStatus];

    return { text, color };
  } catch {
    return { text: 'OTHER', color: 'error' };
  }
};

export const getClassAppointmentBookingAllowedLabel = (
  isBookingAllowed: string
): { text: string; color: string } => {
  try {
    const map = {
      Yes: {
        text: 'Yes',
        color: 'success'
      },
      No: {
        text: 'No',
        color: 'error'
      }
    };

    const { text, color }: any = map[isBookingAllowed];

    return { text, color };
  } catch {
    return { text: 'Unknown', color: 'error' };
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PromoCodes = Loader(lazy(() => import('src/content/PromoCodes')));
const SinglePromoCode = Loader(
  lazy(() => import('src/content/PromoCodes/single'))
);

const promoCodeRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: '/list',
    element: <PromoCodes />
  },
  {
    path: ':promoCodeId/',
    element: <SinglePromoCode />
  }
];

export default promoCodeRoutes;

export const listAllInventoriesQuery = /* GraphQL */ `
  query ListInventories($locationIds: [ID!]) {
    listInventories(locationIds: $locationIds) {
      id
      name
      description
      quantity
      batchQuantity
      reorderQuantity
      restockThreshold
      category
      type
      code
      isUnlimited
      locationId
      location {
        code
        name
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      dosage {
        dosage
        dosageFrequency
        dosageDuration
        dosageUsage
        dosageBase
        dosageUnit
        dosageInstructions
        dosagePrecautions
        unit
      }
      quickbooksId
    }
  }
`;

export const listInventoryOnLoad = /* GraphQL */ `
  query ListInventories($locationIds: [ID!]) {
    listInventories(locationIds: $locationIds) {
      id
      name
      description
      quantity
      category
      type
      code
      isUnlimited
      locationId
      location {
        code
        name
      }
      costPrice {
        amount
        currency
        precision
        scale
      }
      sellingPrice {
        amount
        currency
        precision
        scale
      }
      quickbooksId
    }
  }
`;

export const listPatientNotesQuery = /* GraphQL */ `
  query listPatientNotes(
    $patientId: ID!
    $appointmentId: ID
    $createdStartTime: AWSDateTime
    $createdEndTime: AWSDateTime
    $isNewestFirst: Boolean
    $type: String
    $limit: Int
  ) {
    listPatientNotes(
      patientId: $patientId
      appointmentId: $appointmentId
      createdStartTime: $createdStartTime
      createdEndTime: $createdEndTime
      isNewestFirst: $isNewestFirst
      type: $type
      limit: $limit
    ) {
      id
      appointmentId
      content
      title
      type
      noteFolder
      isPublished
      publishedAt
      voidedAt
      voidedBy
      voidedReason
      timezone
      createdAt
      createdBy
      updatedBy
      updatedAt
    }
  }
`;

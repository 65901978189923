import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Customers = Loader(lazy(() => import('src/content/Patients')));
const SingleCustomer = Loader(
  lazy(() => import('src/content/Patients/single'))
);

const customerRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Customers />
  },
  {
    path: ':patientId/:tabName/:financeTabName',
    element: <SingleCustomer />
  },
  {
    path: ':patientId/:tabName/:communicationTabName',
    element: <SingleCustomer />
  },
  {
    path: ':patientId/:tabName/:appointmentTabName',
    element: <SingleCustomer />
  },
  {
    path: ':patientId/:tabName',
    element: <SingleCustomer />
  },
  {
    path: ':patientId/',
    element: <SingleCustomer />
  }
];

export default customerRoutes;

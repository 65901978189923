// TODO: find out how to remove optional variables from graphql calls
// Duplicating this file as the sequence of variables for the query is different
import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetAppointmentQuery,
  ListAppointmentsQuery,
  CreateAppointmentMutation,
  UpdateAppointmentMutation,
  DeleteAppointmentMutation,
  UpdateConfirmStatusAppointmentMutation,
  UpdateCancelStatusAppointmentMutation
} from 'src/API';
import { getAppointment, listAppointments } from 'src/graphql/queries';
import {
  createAppointment,
  updateAppointment,
  deleteAppointment,
  updateConfirmStatusAppointment,
  updateCancelStatusAppointment
} from 'src/graphql/mutations';

import { listPatientAppointmentsQuery } from './queries';

export const createAppointmentAPI = async (appointment) => {
  try {
    const newAppointment = await callGraphQL<CreateAppointmentMutation>(
      createAppointment,
      {
        variables: {
          input: appointment
        }
      }
    );
    // console.log(newAppointment);
    return newAppointment;
  } catch (error) {
    // console.log(error);
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentstAPI = async (
  appointmentEndDate,
  appointmentStartDate,
  locationIds?,
  practitionerIds?,
  serviceIds?,
  patientIds?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      listAppointments,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          locationIds,
          patientIds,
          practitionerIds,
          serviceIds,
          status: '',
          type: ''
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientAppointmentsAPI = async (
  patientIds,
  appointmentEndDate?,
  appointmentStartDate?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      listPatientAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          patientIds
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentsAPI = async (
  appointmentEndDate,
  appointmentStartDate,
  locationIds?,
  practitionerIds?,
  patientIds?,
  serviceIds?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      listPatientAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          locationIds,
          practitionerIds,
          patientIds,
          serviceIds
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteAppointmentAPI = async (appointmentId) => {
  try {
    const delAppointment = await callGraphQL<DeleteAppointmentMutation>(
      deleteAppointment,
      {
        variables: {
          appointmentId
        }
      }
    );
    // console.log(delAppointment);
    return delAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Templates = Loader(
  lazy(() => import('src/content/AppointmentPlanTemplates'))
);
const SingleTemplate = Loader(
  lazy(() => import('src/content/AppointmentPlanTemplates/single'))
);

const appointmentPlanTemplatesRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Templates />
  },
  {
    path: ':appointmentPlanTemplateId/',
    element: <SingleTemplate />
  }
];

export default appointmentPlanTemplatesRoutes;

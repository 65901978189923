import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetClassServiceQuery,
  ListClassServicesQuery,
  DeleteClassServiceMutation,
  CreateClassServiceMutation,
  UpdateClassServiceMutation
} from 'src/API';
// import { listServices } from 'src/graphql/queries';
import {
  createClassService,
  deleteClassService,
  updateClassService
} from 'src/graphql/mutations';
import { getClassService, listClassServices } from 'src/graphql/queries';
import { listAvailableClassServicesQuery } from './queries';

export const createClassServiceAPI = async (classService) => {
  try {
    const newClassService = await callGraphQL<CreateClassServiceMutation>(createClassService, {
      variables: {
        input: classService
      }
    });

    // console.log(newLocation);
    return newClassService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getClassServiceAPI = async (classServiceId) => {
  try {
    const classService = await callGraphQL<GetClassServiceQuery>(getClassService, {
      variables: {
        id: classServiceId
      }
    });

    // console.log(classService);
    return classService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAvailableClassServicesAPI = async (
  locationId = '',
  practitionerId = ''
) => {
  try {
    const classServices = await callGraphQL<ListClassServicesQuery>(
      listAvailableClassServicesQuery,
      {
        variables: {
          locationId,
          practitionerId
        }
      }
    );
    // console.log(classServices);
    return classServices;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateClassServiceAPI = async (classService) => {
  try {
    const updatedClassService = await callGraphQL<UpdateClassServiceMutation>(
      updateClassService,
      {
        variables: {
          input: classService
        }
      }
    );

    // console.log(updatedClassService);
    return updatedClassService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteClassServiceAPI = async (classServiceId) => {
  try {
    const deletedClassService = await callGraphQL<DeleteClassServiceMutation>(
      deleteClassService,
      {
        variables: {
          classServiceId: classServiceId
        }
      }
    );

    return deletedClassService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { useMemo } from 'react';
import { Grid, DialogContent, Box, Tabs, Tab, styled } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import { useSelector } from 'src/store';


const CreateNoticeBoardMessageForm = ({
//   control,
//   errors,
  messageContent,
  setMessageContent,
}: any) => {

  const modules = useMemo(() => {return {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      // ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      // ['link', 'image', 'video'],
      ['link'],
      ['clean']
    ]}
  }, []);
  


  return (
    <DialogContent
      dividers
      sx={{
        p: 3
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: 'grid',
                  gridAutoRows: '200px',
                  pb: 3,
                  mb: 3
                }}
              >
                <ReactQuill
                  value={messageContent}
                  onChange={(value) => setMessageContent(value)}
                  modules={modules}
                  theme="snow"
                  placeholder="Write your message here..."
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default CreateNoticeBoardMessageForm;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createPatientNoteAPI,
  listPatientNotesAPI,
  editPatientNoteAPI,
  publishPatientNoteAPI,
  voidPatientNoteAPI,
  deletePatientNoteAPI,
  listPatientNotesByTypeAPI
} from '../content/Notes/api';

interface NoteState {
  isFetchingNotes: boolean;
  patientNotes: any[];
  pinnedNotes: any[];
  noteFiles: any[];
  selectedNote: any;
  editedNoteId: string;
  imageFormOpen: boolean;
  imageFormType: string;
  structuredNoteFormOpen: boolean;
  structuredNoteFormType: string;
  noteErrorMessage: string;
  noteSuccessMessage: string;
}

const initialState: NoteState = {
  isFetchingNotes: false,
  patientNotes: [],
  pinnedNotes: [],
  noteFiles: [],
  selectedNote: {},
  editedNoteId: '',
  imageFormOpen: false,
  imageFormType: '',
  structuredNoteFormOpen: false,
  structuredNoteFormType: '',
  noteErrorMessage: '',
  noteSuccessMessage: ''
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getPatientNotes(state: NoteState, action: PayloadAction<any>) {
      const notes = action.payload;

      // IDEA: Sort the array of notes by created_at DESCending first before rendering below
      // const sortPatientNotes = (a, b) => {
      //   if (a.createdAt > b.createdAt) {
      //     return -1;
      //   }
      //   if (a.createdAt < b.createdAt) {
      //     return 1;
      //   }
      //   return 0;
      // };
      // notes.sort(sortPatientNotes);

      state.patientNotes = notes;
    },

    createNote(state: NoteState, action: PayloadAction<any>) {
      const newNote = action.payload;

      state.patientNotes = [newNote, ...state.patientNotes];
    },

    editNote(state: NoteState, action: PayloadAction<any>) {
      let noteIndex = state.patientNotes.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.patientNotes = state.patientNotes.map((note, i) =>
        i === noteIndex ? action.payload : note
      );
    },

    voidNote(state: NoteState, action: PayloadAction<any>) {
      let noteIndex = state.patientNotes.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.patientNotes = state.patientNotes.map((note, i) =>
        i === noteIndex ? action.payload : note
      );
    },

    deleteNote(state: NoteState, action: PayloadAction<any>) {
      state.patientNotes = state.patientNotes.filter(
        (note) => note.id !== action.payload.id
      );
    },

    clearPatientNotes(state: NoteState, action: PayloadAction<any>) {
      state.patientNotes = [];
    },

    setPinnedNotes(state: NoteState, action: PayloadAction<any>) {
      const notes = action.payload;

      state.pinnedNotes = notes;
    },

    clearPinnedNotes(state: NoteState, action: PayloadAction<any>) {
      state.pinnedNotes = [];
    },

    setEditedNoteId(state: NoteState, action: PayloadAction<any>) {
      state.editedNoteId = action.payload;
    },

    setImageFormOpen(state: NoteState, action: PayloadAction<any>) {
      state.imageFormOpen = action.payload;
    },

    setImageFormType(state: NoteState, action: PayloadAction<any>) {
      state.imageFormType = action.payload;
    },

    setStructuredNoteFormOpen(state: NoteState, action: PayloadAction<any>) {
      state.structuredNoteFormOpen = action.payload;
    },

    setStructuredNoteFormType(state: NoteState, action: PayloadAction<any>) {
      state.structuredNoteFormType = action.payload;
    },

    setIsFetchingNotes(state: NoteState, action: PayloadAction<any>) {
      state.isFetchingNotes = action.payload;
    },

    setErrorMessage(state: NoteState, action: PayloadAction<any>) {
      state.noteErrorMessage = action.payload;
    },

    setSuccessMessage(state: NoteState, action: PayloadAction<any>) {
      state.noteSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getPatientNotes =
  (patientId, limit = 5): AppThunk =>
  async (dispatch) => {
    dispatch(startFetchingNotes());

    const latestFirst = true;
    const response = await listPatientNotesAPI(patientId, latestFirst, limit);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPatientNotes(response.data.listPatientNotes));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopFetchingNotes());
  };

  export const getPatientNotesByType =
  (patientId, limit = 5, type = 'appointment'): AppThunk =>
  async (dispatch) => {
    dispatch(startFetchingNotes());

    const latestFirst = true;
    const response = await listPatientNotesByTypeAPI(patientId, type, latestFirst, limit);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPatientNotes(response.data.listPatientNotes));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopFetchingNotes());
  };

export const createNote =
  (newNote): AppThunk =>
  async (dispatch) => {
    const response = await createPatientNoteAPI(newNote);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createNote(response.data.createPatientNote));
      dispatch(slice.actions.setSuccessMessage('Note successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const createAndEditNote =
  (newNote): AppThunk =>
  async (dispatch) => {
    const response = await createPatientNoteAPI(newNote);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createNote(response.data.createPatientNote));
      return dispatch(
        slice.actions.setEditedNoteId(response.data.createPatientNote?.id)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const editNote =
  (note, publish): AppThunk =>
  async (dispatch) => {
    // if (publish === true) {
    //   const response = await editPatientNoteAPI(note);
    //   const pubResponse = await publishPatientNoteAPI(note.id);

    //   dispatch(slice.actions.editNote(pubResponse.data.publishPatientNote));
    // } else {
    //   const response = await editPatientNoteAPI(note);
    //   dispatch(slice.actions.editNote(response.data.updatePatientNote));
    // }

    const response = await editPatientNoteAPI(note);

    if (!response.customErrorMessage) {
      if (publish) {
        const pubResponse = await publishPatientNoteAPI(note.id);

        if (!pubResponse.customErrorMessage) {
          dispatch(slice.actions.editNote(pubResponse.data.publishPatientNote));
        } else {
          dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
        }
      } else {
        dispatch(slice.actions.editNote(response.data.updatePatientNote));
        dispatch(slice.actions.setSuccessMessage('Note successfully updated'));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const voidNote =
  (voidNote): AppThunk =>
  async (dispatch) => {
    const response = await voidPatientNoteAPI(voidNote);

    console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidNote(response.data.voidPatientNote));
      dispatch(slice.actions.setSuccessMessage('Note successfully voided'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const deleteNote =
  (noteId): AppThunk =>
  async (dispatch) => {
    const response = await deletePatientNoteAPI(noteId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteNote(response.data.deletePatientNote));
      dispatch(slice.actions.setSuccessMessage('Note successfully deleted'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearPatientNotes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearPatientNotes(''));
};

export const setPinnedNotes =
  (notes): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPinnedNotes(notes));
  };

export const clearPinnedNotes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearPinnedNotes([]));
};

export const setEditedNoteId =
  (noteId): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setEditedNoteId(noteId));
  };

export const setImageFormOpen =
  (open): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setImageFormOpen(open));
  };

export const setImageFormType =
  (type): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setImageFormType(type));
  };

export const setStructuredNoteFormOpen =
  (open): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setStructuredNoteFormOpen(open));
  };

export const setStructuredNoteFormType =
  (type): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setStructuredNoteFormType(type));
  };

export const startFetchingNotes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsFetchingNotes(true));
};

export const stopFetchingNotes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsFetchingNotes(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

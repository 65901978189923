import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListCorporatesQuery,
  ListCorporatesByCategoryQuery,
  GetCorporateQuery,
  CreateCorporateMutation,
  UpdateCorporateMutation,
  DeleteCorporateMutation
} from 'src/API';
import {
  createCorporate,
  updateCorporate,
  deleteCorporate
} from 'src/graphql/mutations';
import {
  listCorporates,
  getCorporate,
  listCorporatesByCategory
} from 'src/graphql/queries';
import {
  customGetCorporate,
  customGetCorporatePricing,
  customListCorporatesOnLoad
} from './queries';
import { customUpdateCorporate } from './mutations';

export const listCorporatesOnLoadAPI = async () => {
  try {
    const corporates = await callGraphQL<ListCorporatesQuery>(
      customListCorporatesOnLoad,
      {
        variables: {}
      }
    );

    return corporates;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCorporatesAPI = async () => {
  try {
    const corporates = await callGraphQL<ListCorporatesQuery>(listCorporates, {
      variables: {}
    });

    return corporates;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCorporatesByCategoryAPI = async (category) => {
  try {
    const corporates = await callGraphQL<ListCorporatesByCategoryQuery>(
      listCorporatesByCategory,
      {
        variables: { category }
      }
    );

    return corporates;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createCorporateAPI = async (corporate) => {
  try {
    const newCorporate = await callGraphQL<CreateCorporateMutation>(
      createCorporate,
      {
        variables: {
          input: corporate
        }
      }
    );

    // console.log(newCorporate);
    return newCorporate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCorporateAPI = async (corporateId) => {
  try {
    const corporate = await callGraphQL<GetCorporateQuery>(customGetCorporate, {
      variables: {
        id: corporateId
      }
    });

    // console.log(corporate);
    return corporate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCorporatePricingAPI = async (corporateId) => {
  try {
    const corporate = await callGraphQL<GetCorporateQuery>(
      customGetCorporatePricing,
      {
        variables: {
          id: corporateId
        }
      }
    );

    // console.log(corporate);
    return corporate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCorporateAPI = async (corporate) => {
  try {
    const updatedCorporate = await callGraphQL<UpdateCorporateMutation>(
      customUpdateCorporate,
      {
        variables: {
          input: corporate
        }
      }
    );

    // console.log(updatedCorporate);
    return updatedCorporate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteCorporateAPI = async (corporateId) => {
  try {
    const deletedCorporate = await callGraphQL<DeleteCorporateMutation>(
      deleteCorporate,
      {
        variables: {
          id: corporateId
        }
      }
    );
    return deletedCorporate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

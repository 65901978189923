import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
// import HeaderNotifications from './Notifications';
// import LanguageSwitcher from './LanguageSwitcher';
import HeaderControlScreen from './ControlScreen';
import HeaderCheckInScreen from './CheckInScreen';
import HeaderLockScreen from './LockScreen';
import TerminalAppLauncher from './TerminalAppLauncher';

function HeaderButtons() {
  const location = useLocation();

  const currentPage = location.pathname.split('/').slice(-1)[0];
  console.log(currentPage);

  return (
    <Box>
      {/* <HeaderNotifications />
      <LanguageSwitcher /> */}
      <TerminalAppLauncher />
      {currentPage === 'checkin' ? (
        <HeaderControlScreen />
      ) : (
        <HeaderCheckInScreen />
      )}
      {/* <HeaderLockScreen /> */}
    </Box>
  );
}

export default HeaderButtons;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  listOfficeLocationsAPI,
  listOfficeLocationsOnLoadAPI,
  getOfficeLocationAPI,
  updateOfficeLocationAPI
} from 'src/content/Locations/api';

interface LocationState {
  isLoadingLocations: boolean;
  isEditingLocation: boolean;
  locationDetails: any[];
  activeLocationDetails: any[];
  locationOperatingHours: any[];
  selectedLocation: any[];
  locationErrorMessage: string;
  locationSuccessMessage: string;
}

const initialState: LocationState = {
  isLoadingLocations: false,
  isEditingLocation: false,
  locationDetails: [],
  activeLocationDetails: [],
  locationOperatingHours: [],
  selectedLocation: [],
  locationErrorMessage: '',
  locationSuccessMessage: ''
};

const sortLocationCodesAsc = (a, b) => {
  if (a.code?.charAt(0) < b.code?.charAt(0)) {
    return -1;
  } else if (a.code?.charAt(0) > b.code?.charAt(0)) {
    return 1;
  } else if (a.code?.charAt(1) < b.code?.charAt(1)) {
    return -1;
  } else if (a.code?.charAt(1) > b.code?.charAt(1)) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    // listLocationNames(state: LocationState, action: PayloadAction<any>) {
    //   state.locationNames = [...action.payload];
    // },

    listLocationDetails(state: LocationState, action: PayloadAction<any>) {
      const allLocations = [...action.payload];

      allLocations.sort(sortLocationCodesAsc);

      state.locationDetails = allLocations;

      state.activeLocationDetails = [
        ...allLocations.filter((loc) => loc.isActive === true)
      ];
    },

    getOfficeLocation(state: LocationState, action: PayloadAction<any>) {
      if (action.payload) {
        state.selectedLocation = [action.payload];
      } else {
        state.selectedLocation = [{ id: 'Not Found' }];
      }
    },

    editOfficeLocation(state: LocationState, action: PayloadAction<any>) {
      const editedLocation = action.payload;

      let locationIndex = state.locationDetails.findIndex(
        (obj) => obj.id === editedLocation.id
      );

      if (locationIndex >= 0) {
        const newList = [...state.locationDetails];
        newList.splice(locationIndex, 1);
        state.locationDetails = [editedLocation, ...newList];
      } else {
        state.locationDetails = [...state.locationDetails];
      }

      state.selectedLocation = [editedLocation];
    },

    clearSelectedOfficeLocation(
      state: LocationState,
      action: PayloadAction<any>
    ) {
      state.selectedLocation = [];
    },

    setIsLoadingLocations(state: LocationState, action: PayloadAction<any>) {
      state.isLoadingLocations = action.payload;
    },

    setIsEditingLocation(state: LocationState, action: PayloadAction<any>) {
      state.isEditingLocation = action.payload;
    },

    setErrorMessage(state: LocationState, action: PayloadAction<any>) {
      state.locationErrorMessage = action.payload;
    },

    setSuccessMessage(state: LocationState, action: PayloadAction<any>) {
      state.locationSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listLocationDetailsOnLoad = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingLocations());

  const response = await listOfficeLocationsOnLoadAPI();

  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.listLocationDetails(response.data.listOfficeLocations)
    );
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingLocations());
};

export const listLocationDetails = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingLocations());

  const response = await listOfficeLocationsAPI();

  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.listLocationDetails(response.data.listOfficeLocations)
    );
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingLocations());
};

export const getOfficeLocation =
  (locationId): AppThunk =>
  async (dispatch) => {
    const response = await getOfficeLocationAPI(locationId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getOfficeLocation(response.data.getOfficeLocation)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedOfficeLocation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedOfficeLocation({}));
};

export const updateOfficeLocation =
  (officeLocation): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingLocation());
    dispatch(startLoadingLocations());

    const response = await updateOfficeLocationAPI(officeLocation);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editOfficeLocation(response.data.updateOfficeLocation)
      );
      dispatch(
        slice.actions.setSuccessMessage('Location successfully updated')
      );
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopEditingLocation());
      dispatch(stopLoadingLocations());
    };

    return followUp();
  };

export const startLoadingLocations = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingLocations(true));
};

export const stopLoadingLocations = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingLocations(false));
};

export const startEditingLocation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingLocation(true));
};

export const stopEditingLocation = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingLocation(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

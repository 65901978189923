import { IconButton, Tooltip, styled } from '@mui/material';
import CastConnectedTwoToneIcon from '@mui/icons-material/CastConnectedTwoTone';
import ComputerTwoToneIcon from '@mui/icons-material/ComputerTwoTone';
import DvrTwoToneIcon from '@mui/icons-material/DvrTwoTone';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

function TerminalAppLauncher() {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow placement="bottom-end" title={t('Switch to Terminal App')}>
        <IconButtonWrapper
          color="primary"
          // onClick={() => navigate('/terminal/terminal/home')}
          onClick={() => window.location.replace('/terminal/terminal/home')}
        >
          <DvrTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default TerminalAppLauncher;

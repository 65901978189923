import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateCustomerFeedbackMutation,
  GetCustomerFeedbackQuery,
  ListCustomerFeedbacksQuery,
  VoidCustomerFeedbackMutation
} from 'src/API';
import {
  createCustomerFeedback,
  voidCustomerFeedback
} from 'src/graphql/mutations';
import {
  listCustomerFeedbacks,
  getCustomerFeedback
} from 'src/graphql/queries';

export const createCustomerFeedbackAPI = async (feedback) => {
  try {
    const createdCustomerFeedback =
      await callGraphQL<CreateCustomerFeedbackMutation>(
        createCustomerFeedback,
        {
          variables: { input: feedback }
        }
      );

    return createdCustomerFeedback;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCustomerFeedbackAPI = async (feedbackId) => {
  try {
    const customerFeedback = await callGraphQL<GetCustomerFeedbackQuery>(
      getCustomerFeedback,
      {
        variables: {
          feedbackId
        }
      }
    );

    return customerFeedback;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCustomerFeedbackAPI = async (
  startTime,
  endTime,
  customerId = '',
  locationId = ''
) => {
  try {
    const customerFeedback = await callGraphQL<ListCustomerFeedbacksQuery>(
      listCustomerFeedbacks,
      {
        variables: {
          customerId,
          locationId,
          startTime,
          endTime
        }
      }
    );

    return customerFeedback;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidCustomerFeedbackAPI = async (
  customerFeedbackId,
  voidReason
) => {
  try {
    const voidedCustomerFeedback =
      await callGraphQL<VoidCustomerFeedbackMutation>(voidCustomerFeedback, {
        variables: {
          id: customerFeedbackId,
          voidReason: voidReason
        }
      });

    return voidedCustomerFeedback;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

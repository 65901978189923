import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const StripeTerminals = Loader(
  lazy(() => import('src/content/StripeTerminals'))
);

const stripeTerminalRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: '/list',
    element: <StripeTerminals />
  }
];

export default stripeTerminalRoutes;

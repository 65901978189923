import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import {
  parseISO,
  isToday,
  startOfToday,
  addMonths,
  subMonths
} from 'date-fns';

import {
  getInvoiceByIdAPI,
  getInvoiceByNumberAPI,
  getInvoiceAPI,
  listPossibleCustomerAppointmentInvoicesAPI,
  listAppointmentsInvoicesAPI,
  listRelatedInvoicesAPI,
  listAllInvoicesAPI,
  listAllCustomerInvoicesByIssueDateAPI,
  listInvoicesInDateRangeAPI,
  listShortInvoicesInDateRangeAPI,
  listCorporateInvoicesAPI,
  createInvoiceAPI,
  voidInvoiceAPI,
  updateInvoiceItemDescriptionAPI,
  updateInvoiceReferenceAPI,
  updateInvoiceNoteAPI,
  updateInvoiceAPI
} from '../content/Invoices/api';
import { createPaymentAPI, voidPaymentAPI } from 'src/content/Payments/api';
import { listPatientPayments } from './payments';

import { getPatientWallet } from 'src/slices/wallets';

interface InvoiceState {
  isCreatingInvoice: boolean;
  isUpdatingInvoice: boolean;
  isVoidingInvoice: boolean;
  isSearchingInvoice: boolean;
  isLoadingCustomerInvoices: boolean;
  isLoadingCorporateInvoices: boolean;
  isLoadingQueueInvoices: boolean;
  isLoadingOverviewInvoices: boolean;
  isCreatingPayment: boolean;
  isVoidingPayment: boolean;
  invoiceItemList: any[];
  patientInvoices: any[];
  corporateInvoices: any[];
  patientOutstandingInvoices: any[];
  overviewInvoices: any[];
  listInvoices: any[];
  queueAppointmentsInvoices: any[];
  invoiceSearchResults: any[];
  relatedInvoices: any[];
  invoiceErrorMessage: string;
  invoiceSuccessMessage: string;
}

const initialState: InvoiceState = {
  isCreatingInvoice: false,
  isUpdatingInvoice: false,
  isVoidingInvoice: false,
  isSearchingInvoice: false,
  isLoadingCustomerInvoices: false,
  isLoadingCorporateInvoices: false,
  isLoadingQueueInvoices: false,
  isLoadingOverviewInvoices: false,
  isCreatingPayment: false,
  isVoidingPayment: false,
  invoiceItemList: [],
  patientInvoices: [],
  corporateInvoices: [],
  patientOutstandingInvoices: [],
  overviewInvoices: [],
  listInvoices: [],
  queueAppointmentsInvoices: [],
  invoiceSearchResults: [],
  relatedInvoices: [],
  invoiceErrorMessage: '',
  invoiceSuccessMessage: ''
};

const sortInvoicesDesc = (a, b) => {
  if (a.issueDate > b.issueDate) {
    return -1;
  }
  if (a.issueDate < b.issueDate) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    addInvoiceItem(state: InvoiceState, action: PayloadAction<any>) {
      const newItem = action.payload;

      state.invoiceItemList = [...state.invoiceItemList, newItem];
    },

    deleteInvoiceItem(state: InvoiceState, action: PayloadAction<any>) {
      const itemIndex = action.payload;
      const newList = [...state.invoiceItemList];
      newList.splice(itemIndex, 1);

      state.invoiceItemList = [...newList];
    },

    clearInvoiceItem(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceItemList = [];
    },

    createInvoice(state: InvoiceState, action: PayloadAction<any>) {
      const newInvoice = action.payload;

      state.patientInvoices = [newInvoice, ...state.patientInvoices];

      if (
        isToday(parseISO(newInvoice.issueDate)) ||
        isToday(parseISO(newInvoice.dueDate))
      ) {
        state.queueAppointmentsInvoices = [
          newInvoice,
          ...state.queueAppointmentsInvoices
        ];
      }

      state.isCreatingInvoice = false;
    },

    voidInvoice(state: InvoiceState, action: PayloadAction<any>) {
      const invIndex = state.patientInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (invIndex >= 0) {
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex ? action.payload : inv
        );
      }

      const corpInvIndex = state.corporateInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (corpInvIndex >= 0) {
        const newList = [...state.corporateInvoices];
        newList.splice(corpInvIndex, 1);
        state.corporateInvoices = [...newList];
      }

      const queueInvIndex = state.queueAppointmentsInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (queueInvIndex >= 0) {
        const newList = [...state.queueAppointmentsInvoices];
        newList.splice(queueInvIndex, 1);
        state.queueAppointmentsInvoices = [...newList];
      }

      const searchInvIndex = state.invoiceSearchResults.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (searchInvIndex >= 0) {
        const newList = [...state.invoiceSearchResults];
        newList.splice(searchInvIndex, 1);
        state.invoiceSearchResults = [...newList];
      }

      state.isSearchingInvoice = false;
    },

    getRefreshedInvoice(state: InvoiceState, action: PayloadAction<any>) {
      const invIndex = state.patientInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (invIndex >= 0) {
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex ? action.payload : inv
        );
      }

      const invSearchIndex = state.invoiceSearchResults.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (invSearchIndex >= 0) {
        state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
          i === invSearchIndex ? action.payload : inv
        );
      }

      const corpInvIndex = state.corporateInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (corpInvIndex >= 0) {
        state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
          i === corpInvIndex ? action.payload : inv
        );
      }

      const queueInvIndex = state.queueAppointmentsInvoices.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (queueInvIndex >= 0) {
        state.queueAppointmentsInvoices = state.queueAppointmentsInvoices.map(
          (inv, i) => (i === queueInvIndex ? action.payload : inv)
        );
      }

      state.isLoadingCustomerInvoices = false;
    },

    getPatientInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.patientInvoices = invoices;

      state.isLoadingCustomerInvoices = false;
    },

    getExtendedPatientInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.patientInvoices = [...state.patientInvoices, ...invoices];

      state.isLoadingCustomerInvoices = false;
    },

    getCorporateInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.corporateInvoices = invoices;

      state.isLoadingCorporateInvoices = false;
    },

    getExtendedCorporateInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.corporateInvoices = [...state.corporateInvoices, ...invoices];

      state.isLoadingCorporateInvoices = false;
    },

    getPatientTodayInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.patientInvoices = invoices;

      state.isLoadingCustomerInvoices = false;
    },

    listQueueAppointmentsInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      let invoices = [...action.payload];
      // invoices.sort(sortInvoicesDesc);

      const validInvoices = invoices?.filter((inv) => inv.voidedAt === null);

      state.queueAppointmentsInvoices = validInvoices;

      state.isLoadingQueueInvoices = false;
    },

    listPossibleCustomerAppointmentInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.patientInvoices = invoices;

      state.isLoadingCustomerInvoices = false;
    },

    listOverviewInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      // invoices.sort(sortInvoicesDesc);

      state.overviewInvoices = invoices;
    },

    listInvoicesInDateRange(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.listInvoices = invoices;
    },

    createPayment(state: InvoiceState, action: PayloadAction<any>) {
      if (state.patientInvoices.length > 0) {
        const invoiceList = [...state.patientInvoices];
        const otherInvoices = invoiceList.filter(
          (inv) => inv.id !== action.payload.invoiceId
        );
        let affectedInv = invoiceList.find(
          (inv) => inv.id === action.payload.invoiceId
        );

        if (affectedInv) {
          affectedInv.payments = [action.payload, ...affectedInv.payments];

          state.patientInvoices = [affectedInv, ...otherInvoices];
        }
      }

      if (state.invoiceSearchResults.length > 0) {
        const invoiceList = [...state.invoiceSearchResults];
        const otherInvoices = invoiceList.filter(
          (inv) => inv.id !== action.payload.invoiceId
        );
        let affectedInv = invoiceList.find(
          (inv) => inv.id === action.payload.invoiceId
        );

        if (
          affectedInv &&
          affectedInv?.payments &&
          affectedInv?.payments?.length > 0
        ) {
          affectedInv.payments = [action.payload, ...affectedInv.payments];
          state.invoiceSearchResults = [affectedInv, ...otherInvoices];
        } else if (affectedInv && affectedInv?.payments?.length === 0) {
          affectedInv.payments = [action.payload];
          state.invoiceSearchResults = [affectedInv, ...otherInvoices];
        }
      }
      state.isCreatingPayment = false;
    },

    voidPayment(state: InvoiceState, action: PayloadAction<any>) {
      if (state.patientInvoices.length > 0) {
        const invoiceList = [...state.patientInvoices];
        const otherInvoices = invoiceList.filter(
          (inv) => inv.id !== action.payload.invoiceId
        );
        let affectedInv = invoiceList.find(
          (inv) => inv.id === action.payload.invoiceId
        );

        if (affectedInv) {
          affectedInv.payments = affectedInv.payments.map((payment) => {
            if (payment.id === action.payload.id) {
              return action.payload;
            } else {
              return payment;
            }
          });

          state.patientInvoices = [affectedInv, ...otherInvoices];
        }
      }

      if (state.invoiceSearchResults.length > 0) {
        const invoiceList = [...state.invoiceSearchResults];
        const otherInvoices = invoiceList.filter(
          (inv) => inv.id !== action.payload.invoiceId
        );
        let affectedInv = invoiceList.find(
          (inv) => inv.id === action.payload.invoiceId
        );

        if (affectedInv) {
          affectedInv.payments = affectedInv.payments.map((payment) => {
            if (payment.id === action.payload.id) {
              return action.payload;
            } else {
              return payment;
            }
          });

          state.invoiceSearchResults = [affectedInv, ...otherInvoices];
        }
      }

      state.isVoidingPayment = false;
    },

    getPatientOutstandingInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      state.patientOutstandingInvoices = action.payload;
    },

    setErrorMessage(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceErrorMessage = action.payload;
    },

    setSuccessMessage(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceSuccessMessage = action.payload;
    },

    searchInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceSearchResults = [action.payload];

      state.isSearchingInvoice = false;
    },

    clearSearchInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceSearchResults = [];
    },

    setIsCreatingInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.isCreatingInvoice = action.payload;
    },

    setIsUpdatingInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.isUpdatingInvoice = action.payload;
    },

    setIsVoidingInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.isVoidingInvoice = action.payload;
    },

    setIsLoadingCustomerInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerInvoices = action.payload;
    },

    setIsLoadingCorporateInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCorporateInvoices = action.payload;
    },

    setIsLoadingQueueInvoices(state: InvoiceState, action: PayloadAction<any>) {
      state.isLoadingQueueInvoices = action.payload;
    },

    setIsLoadingOverviewInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      state.isLoadingOverviewInvoices = action.payload;
    },

    clearPatientInvoices(state: InvoiceState, action: PayloadAction<any>) {
      state.patientInvoices = [];
    },

    setIsCreatingPayment(state: InvoiceState, action: PayloadAction<any>) {
      state.isCreatingPayment = action.payload;
    },

    setIsVoidingPayment(state: InvoiceState, action: PayloadAction<any>) {
      state.isVoidingPayment = action.payload;
    },

    setIsSearchingInvoice(state: InvoiceState, action: PayloadAction<any>) {
      state.isSearchingInvoice = action.payload;
    },

    listRelatedInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.relatedInvoices = invoices;
    },

    updateCustomerInvoiceItemDescription(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      // let invoices = [...action.payload];
      if (state.patientInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.patientInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                items: inv.items.map((item) => {
                  return {
                    ...item,
                    name: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).name,
                    description: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).description
                  };
                })
              }
            : inv
        );
      }

      if (state.corporateInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.corporateInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                items: inv.items.map((item) => {
                  return {
                    ...item,
                    name: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).name,
                    description: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).description
                  };
                })
              }
            : inv
        );
      }

      if (
        state.invoiceSearchResults.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.invoiceSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                items: inv.items.map((item) => {
                  return {
                    ...item,
                    name: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).name,
                    description: action.payload.items.find(
                      (newItem) => newItem.id === item.id
                    ).description
                  };
                })
              }
            : inv
        );
      }
    },

    updateCustomerInvoiceReference(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      // let invoices = [...action.payload];
      if (state.patientInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.patientInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                reference: action.payload.reference
              }
            : inv
        );
      }

      if (state.corporateInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.corporateInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );

        state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                reference: action.payload.reference
              }
            : inv
        );
      }

      if (
        state.invoiceSearchResults.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.invoiceSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                reference: action.payload.reference
              }
            : inv
        );
      }
    },

    updateCustomerInvoiceNote(state: InvoiceState, action: PayloadAction<any>) {
      // let invoices = [...action.payload];
      if (state.patientInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.patientInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                note: action.payload.note
              }
            : inv
        );
      }

      if (state.corporateInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.corporateInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                note: action.payload.note
              }
            : inv
        );
      }

      if (
        state.invoiceSearchResults.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.invoiceSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                note: action.payload.note
              }
            : inv
        );
      }
    },

    updateCustomerInvoice(state: InvoiceState, action: PayloadAction<any>) {
      // let invoices = [...action.payload];
      if (state.patientInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.patientInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.patientInvoices = state.patientInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                practitionerId: action.payload.practitionerId,
                reference: action.payload.reference,
                note: action.payload.note
              }
            : inv
        );
      }

      if (state.corporateInvoices.find((inv) => inv.id === action.payload.id)) {
        let invIndex = state.patientInvoices.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                practitionerId: action.payload.practitionerId,
                reference: action.payload.reference,
                note: action.payload.note
              }
            : inv
        );
      }

      if (
        state.invoiceSearchResults.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.invoiceSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );
        // invoice is in list
        state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                practitionerId: action.payload.practitionerId,
                reference: action.payload.reference,
                note: action.payload.note
              }
            : inv
        );
      }
    }
  }
});

export const reducer = slice.reducer;

export const addInvoiceItem =
  (invoiceItem): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addInvoiceItem(invoiceItem));
  };

export const deleteInvoiceItem =
  (itemIndex): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.deleteInvoiceItem(itemIndex));
  };

export const clearInvoiceItem = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearInvoiceItem([]));
};

export const createInvoice =
  (newInvoice): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingInvoice());

    const response = await createInvoiceAPI(newInvoice);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.createInvoice(response.data.createInvoice));
      dispatch(slice.actions.setSuccessMessage('Invoice successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopCreatingInvoice());
  };

export const voidInvoice =
  (voidInv): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingInvoice());

    const response = await voidInvoiceAPI(voidInv);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidInvoice(response.data.voidInvoice));
      dispatch(slice.actions.setSuccessMessage('Invoice successfully voided'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopVoidingInvoice());
  };

export const getRefreshedInvoice =
  (invoiceId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    const response = await backOff(() => getInvoiceAPI(invoiceId), {
      numOfAttempts: 3
    });

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getRefreshedInvoice(response.data.getInvoice));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerInvoices());
  };

export const getPatientInvoices =
  (
    patientId,
    appointmentId = '',
    issueStartTime = subMonths(startOfToday(), 3),
    issueEndTime = addMonths(startOfToday(), 12)
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    const response = await backOff(
      () =>
        listAllInvoicesAPI(
          patientId,
          appointmentId,
          issueStartTime,
          issueEndTime
        ),
      { numOfAttempts: 3 }
    );

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPatientInvoices(response.data.listInvoices));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerInvoices());
  };


  export const getExtendedPatientInvoices =
  (
    patientId,
    issueStartTime = subMonths(startOfToday(), 12),
    issueEndTime = addMonths(startOfToday(), 18)
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    const response = await backOff(
      () =>
        listAllInvoicesAPI(
          patientId,
          '',
          issueStartTime,
          issueEndTime
        ),
      { numOfAttempts: 3 }
    );

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getExtendedPatientInvoices(response.data.listInvoices));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerInvoices());
  };

export const getCustomerTodayInvoices =
  (patientId, issueStartTime, issueEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    const response = await backOff(
      () =>
        listAllCustomerInvoicesByIssueDateAPI(
          patientId,
          issueStartTime,
          issueEndTime
        ),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPatientInvoices(response.data.listInvoices));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerInvoices());
  };

export const listPossibleCustomerAppointmentInvoices =
  (patientId, appointmentId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    const response = await backOff(
      () =>
        listPossibleCustomerAppointmentInvoicesAPI(patientId, appointmentId),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listPossibleCustomerAppointmentInvoices(
          response.data.listPossibleCustomerAppointmentInvoices
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerInvoices());
  };

export const listQueueAppointmentsInvoices =
  (appointmentIds): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingQueueInvoices());

    const response = await backOff(
      () => listAppointmentsInvoicesAPI(appointmentIds),
      { numOfAttempts: 3 }
    );

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listQueueAppointmentsInvoices(response.data.listInvoices)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingQueueInvoices());
  };

export const createPayment =
  (payment): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());
    dispatch(startCreatingPayment());

    const response = await createPaymentAPI(payment);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.createPayment(response.data.createPayment));

      const followUpWithInvoice = () => {
        dispatch(getRefreshedInvoice(response.data.createPayment.invoiceId));
        dispatch(stopCreatingPayment());
        dispatch(stopUpdatingInvoice());
      };

      if (response.data.createPayment.mode === 'Wallet') {
        dispatch(getPatientWallet(response.data.createPayment.patientId));

        if (response.data.createPayment.status === 'Success') {
          dispatch(
            slice.actions.setSuccessMessage('Payment successfully created')
          );
        }

        return followUpWithInvoice();
      } else {
        if (
          response.data.createPayment.mode === 'Stripe' &&
          response.data.createPayment.status === 'Fail'
        ) {
          dispatch(slice.actions.setErrorMessage('Card declined'));
        }

        if (response.data.createPayment.status === 'Success') {
          dispatch(
            slice.actions.setSuccessMessage('Payment successfully created')
          );
        }

        return followUpWithInvoice();
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingPayment());
      dispatch(stopUpdatingInvoice());
    };

    return followUp();
  };

export const voidPayment =
  (payment): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());
    dispatch(startVoidingPayment());

    const response = await voidPaymentAPI(payment);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidPayment(response.data.voidPayment));

      const followUpWithInvoice = () => {
        // dispatch(getPatientInvoices(response.data.voidPayment.patientId));
        dispatch(listPatientPayments(response.data.voidPayment.patientId));

        if (response.data.voidPayment.invoiceId) {
          dispatch(getRefreshedInvoice(response.data.voidPayment.invoiceId));
        }

        if (response.data.voidPayment.mode === 'Wallet') {
          dispatch(getPatientWallet(response.data.voidPayment.patientId));
        }

        if (response.data.voidPayment.type === 'TopUpWallet') {
          dispatch(getPatientWallet(response.data.voidPayment.patientId));
        }

        if (response.data.voidPayment.type === 'WithdrawWallet') {
          dispatch(getPatientWallet(response.data.voidPayment.patientId));
        }

        dispatch(
          slice.actions.setSuccessMessage('Payment successfully voided')
        );

        dispatch(stopVoidingPayment());
        dispatch(stopUpdatingInvoice());
      };

      return followUpWithInvoice();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingPayment());
      dispatch(stopUpdatingInvoice());
    };

    return followUp();
  };

export const stopLoadingCustomerInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerInvoices(false));
};

export const startLoadingCustomerInvoices =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerInvoices(true));
  };

export const stopLoadingCorporateInvoices =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCorporateInvoices(false));
  };

export const startLoadingCorporateInvoices =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCorporateInvoices(true));
  };

export const stopLoadingQueueInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingQueueInvoices(false));
};

export const startLoadingQueueInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingQueueInvoices(true));
};

export const stopLoadingOverviewInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingOverviewInvoices(false));
};

export const startLoadingOverviewInvoices =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOverviewInvoices(true));
  };

export const clearPatientInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearPatientInvoices(true));
};

export const startCreatingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingInvoice(true));
};

export const stopCreatingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingInvoice(false));
};

export const startUpdatingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingInvoice(true));
};

export const stopUpdatingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingInvoice(false));
};

export const startVoidingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingInvoice(true));
};

export const stopVoidingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingInvoice(false));
};

export const startSearchingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingInvoice(true));
};

export const stopSearchingInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingInvoice(false));
};

export const startCreatingPayment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPayment(true));
};

export const stopCreatingPayment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPayment(false));
};

export const startVoidingPayment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingPayment(true));
};

export const stopVoidingPayment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingPayment(false));
};

export const listOverviewInvoices =
  (invoiceStartTime, invoiceEndTime, locationIds = []): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingOverviewInvoices());

    const response = await listShortInvoicesInDateRangeAPI(
      invoiceStartTime,
      invoiceEndTime,
      locationIds
    );

    if (!response.customErrorMessage) {
      dispatch(slice.actions.listOverviewInvoices(response.data.listInvoices));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingOverviewInvoices());
  };

export const listInvoicesInDateRange =
  (invoiceStartTime, invoiceEndTime, locationIds = []): AppThunk =>
  async (dispatch) => {
    const response = await listInvoicesInDateRangeAPI(
      invoiceStartTime,
      invoiceEndTime,
      locationIds
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listInvoicesInDateRange(response.data.listInvoices)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const getCorporateInvoices =
  (corporateId, issueStartTime, issueEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCorporateInvoices());

    const response = await backOff(
      () =>
        listCorporateInvoicesAPI({ corporateId, issueStartTime, issueEndTime }),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getCorporateInvoices(response.data.listInvoices));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCorporateInvoices());
  };

export const getExtendedCorporateInvoices =
  (corporateId, issueStartTime, issueEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCorporateInvoices());

    const response = await backOff(
      () =>
        listCorporateInvoicesAPI({ corporateId, issueStartTime, issueEndTime }),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getExtendedCorporateInvoices(response.data.listInvoices)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCorporateInvoices());
  };

export const searchInvoiceById =
  (invoiceId): AppThunk =>
  async (dispatch) => {
    dispatch(startSearchingInvoice());

    const response = await getInvoiceByIdAPI(invoiceId);

    if (!response.customErrorMessage) {
      // dispatch(slice.actions.searchInvoice(response.data.getInvoice));
      if (response.data.getInvoice) {
        dispatch(slice.actions.searchInvoice(response.data.getInvoice));
      } else {
        dispatch(slice.actions.clearSearchInvoice([]));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopSearchingInvoice());
  };

export const searchInvoiceByNumber =
  (invoiceNumber): AppThunk =>
  async (dispatch) => {
    dispatch(startSearchingInvoice());

    const response = await getInvoiceByNumberAPI(invoiceNumber);
    // console.log('searching for invoice');

    if (!response.customErrorMessage) {
      if (response.data.getInvoiceByNumber) {
        dispatch(slice.actions.searchInvoice(response.data.getInvoiceByNumber));
      } else {
        // dispatch(slice.actions.clearSearchInvoice([]));
        dispatch(clearSearchInvoice());

        dispatch(stopSearchingInvoice());
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopSearchingInvoice());
  };

export const clearSearchInvoice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSearchInvoice([]));
};

export const listRelatedInvoices =
  (appointmentId): AppThunk =>
  async (dispatch) => {
    const response = await listRelatedInvoicesAPI(appointmentId);
    // console.log('searching for invoice');

    if (!response.customErrorMessage) {
      if (response.data.listInvoices) {
        dispatch(slice.actions.listRelatedInvoices(response.data.listInvoices));
      } else {
        dispatch(slice.actions.listRelatedInvoices([]));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const updateCustomerInvoiceItemDescription =
  ({ invoiceId, invoiceItemId, name, description }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());

    const response = await updateInvoiceItemDescriptionAPI({
      invoiceId,
      invoiceItemId,
      name,
      description
    });

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCustomerInvoiceItemDescription(
          response.data.updateInvoiceItemDescription
        )
      );
      dispatch(slice.actions.setSuccessMessage('Invoice successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingInvoice());
  };

export const updateCustomerInvoiceReference =
  ({ invoiceId, reference }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());

    const response = await updateInvoiceReferenceAPI({
      invoiceId,
      reference
    });

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCustomerInvoiceReference(
          response.data.updateInvoiceReference
        )
      );
      dispatch(slice.actions.setSuccessMessage('Invoice successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingInvoice());
  };

export const updateCustomerInvoiceNote =
  ({ invoiceId, note }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());

    const response = await updateInvoiceNoteAPI({
      invoiceId,
      note
    });
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCustomerInvoiceNote(response.data.updateInvoiceNote)
      );
      dispatch(slice.actions.setSuccessMessage('Invoice successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingInvoice());
  };

export const updateCustomerInvoice =
  ({ invoiceId, providerId, reference = '', note = '' }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingInvoice());

    const response = await updateInvoiceAPI({
      invoiceId,
      providerId,
      reference,
      note
    });
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCustomerInvoice(response.data.adminUpdateInvoice)
      );
      dispatch(slice.actions.setSuccessMessage('Invoice successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingInvoice());
  };

export default slice;

import { IconButton, Tooltip, styled } from '@mui/material';
import { useDispatch } from 'src/store';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { useTranslation } from 'react-i18next';
import { setIsUnlocked } from 'src/slices/terminal';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

function HeaderLockScreen() {
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow placement="bottom-end" title={t('Lock Screen')}>
        <IconButtonWrapper
          color="primary"
          onClick={() => dispatch(setIsUnlocked(false))}
        >
          <LockTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default HeaderLockScreen;

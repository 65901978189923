import { useState } from 'react';
import { format, subDays, formatDistance, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import htmlParse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useDispatch, useSelector } from 'src/store';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  Paper,
  useTheme,
  Tooltip,
  Typography
} from '@mui/material';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import DeleteNoticeBoardMessage from './DeleteNoticeBoardMessage';

function NoticeBoardMessageSingle({ noticeBoardMessage }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { userList, loggedInUser } = useSelector((state) => state.users);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleDeleteConfirm = () => {
    setOpenConfirmDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteSuccess = () => {
    // dispatch(deleteNoticeBoardMessage(noticeBoardMessage.id));
    setOpenConfirmDelete(false);
  };

  if (!noticeBoardMessage) {
    return null;
  }

  return (
    <>
      <ListItem
        sx={{
          display: { xs: 'block', sm: 'flex' }
        }}
        alignItems="flex-start"
      >
        <ListItemAvatar
          sx={{
            mb: { xs: 1, sm: 0 }
          }}
        >
          <Avatar
            alt={
              userList.find((user) => user.id === noticeBoardMessage?.createdBy)
                ?.preferredName
            }
            // src="/static/images/avatars/user.png"
            src={
              userList.find((user) => user.id === noticeBoardMessage?.createdBy)
                ?.profilePicture || '/static/images/avatars/user.png'
            }
          />
          {/* <Avatar
                sx={{
                  background: theme.colors.success.main
                }}
              >
                <CommentTwoToneIcon />
              </Avatar> */}
        </ListItemAvatar>

        <Box flex={1}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontWeight: 'bold'
              }}
            >
              {
                userList.find(
                  (user) => user.id === noticeBoardMessage?.createdBy
                )?.preferredName
              }
            </Typography>
            <Typography
              variant="caption"
              sx={{
                textTransform: 'none'
              }}
            >
              {formatDistance(
                parseISO(noticeBoardMessage?.createdAt),
                new Date(),
                {
                  addSuffix: true
                }
              )}
            </Typography>
          </Box>
          {/* <Typography component="span" variant="body2" color="text.secondary">
            {t('Brought some baby pillows and left them at reception.')}
          </Typography> */}
          <Paper
            elevation={2}
            sx={{
              p: 1,
              mt: 2,
              maxWidth: 400,
              flexWrap: 'wrap',
              display: 'flex',
              background: theme.colors.alpha.black[5]
            }}
          >
            {htmlParse(
              sanitizeHtml(noticeBoardMessage?.content, {
                allowedTags: false,
                allowedAttributes: false,
                disallowedTagsMode: 'escape'
              })
            )}
          </Paper>
          <Typography
            component="span"
            variant="body2"
            color="text.secondary"
            fontSize={8}
          >
            {`${format(
              parseISO(noticeBoardMessage?.createdAt),
              "EEE, dd LLLL yyyy - hh:mm aaaaa'm' (z)"
            )}`}
          </Typography>
          {loggedInUser.id === noticeBoardMessage.createdBy && (
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={t('Delete')} arrow>
                <IconButton onClick={handleDeleteConfirm} color="primary">
                  <DeleteTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </ListItem>
      <Divider
        variant="inset"
        sx={{
          my: 1
        }}
        component="li"
      />

      <DeleteNoticeBoardMessage
        noticeBoardMessageToDelete={noticeBoardMessage}
        openConfirmDelete={openConfirmDelete}
        handleDeleteClose={handleDeleteClose}
        handleDeleteSuccess={handleDeleteSuccess}
      />
    </>
  );
}

export default NoticeBoardMessageSingle;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListPaymentsQuery,
  ListPaymentsInDateRangeQuery,
  CreatePaymentMutation,
  VoidPaymentMutation
  // GetPractitionerPaymentReportsQuery,
  // GetMyPaymentReportsQuery
} from 'src/API';
import { createPayment, voidPayment } from 'src/graphql/mutations';
// import {
//   getPractitionerPaymentReports,
//   getMyPaymentReports
// } from 'src/graphql/queries';
import { customCreatePayment, customVoidPayment } from './mutations';
import {
  listPatientPaymentsQuery,
  listAllPaymentsInDateRangeQuery,
  customListShortPaymentsInDateRange,
  getMyPaymentReports,
  getPractitionerPaymentReports
} from './queries';
import {
  GetMyPaymentReportsQuery,
  GetPractitionerPaymentReportsQuery
} from './types';

export const listPatientPaymentsAPI = async (patientId) => {
  try {
    const payments = await callGraphQL<ListPaymentsQuery>(
      listPatientPaymentsQuery,
      {
        variables: {
          patientId
        }
      }
    );
    // console.log(payments);
    return payments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAllPaymentsInDateRangeAPI = async (
  paymentStartTime,
  paymentEndTime,
  locationIds
) => {
  try {
    const payments = await callGraphQL<ListPaymentsInDateRangeQuery>(
      listAllPaymentsInDateRangeQuery,
      {
        variables: {
          paymentStartTime,
          paymentEndTime,
          locationIds
        }
      }
    );
    // console.log(payments);
    return payments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listShortPaymentsInDateRangeAPI = async (
  paymentStartTime,
  paymentEndTime,
  locationIds
) => {
  try {
    const payments = await callGraphQL<ListPaymentsInDateRangeQuery>(
      customListShortPaymentsInDateRange,
      {
        variables: {
          paymentStartTime,
          paymentEndTime,
          locationIds
        }
      }
    );
    // console.log(payments);
    return payments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPractitionerPaymentReportsAPI = async (
  practitionerIds,
  startTime,
  endTime
) => {
  try {
    const payments = await callGraphQL<GetPractitionerPaymentReportsQuery>(
      getPractitionerPaymentReports,
      {
        variables: {
          practitionerIds,
          startTime,
          endTime
        }
      }
    );
    // console.log(payments);
    return payments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getMyPaymentReportsAPI = async (startTime, endTime) => {
  try {
    const payments = await callGraphQL<GetMyPaymentReportsQuery>(
      getMyPaymentReports,
      {
        variables: {
          startTime,
          endTime
        }
      }
    );
    // console.log(payments);
    return payments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createPaymentAPI = async (payment) => {
  try {
    const newPayment = await callGraphQL<CreatePaymentMutation>(
      customCreatePayment,
      {
        variables: {
          input: payment
        }
      }
    );
    if (!newPayment.data.createPayment) {
      throw new Error();
    }
    // console.log(newPayment);
    return newPayment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidPaymentAPI = async (payment) => {
  try {
    const voidedPayment = await callGraphQL<VoidPaymentMutation>(
      customVoidPayment,
      {
        variables: {
          input: {
            id: payment.id,
            invoiceId: payment.invoiceId,
            isRefunded: payment.isRefunded,
            voidReason: payment.voidReason,
            voidEffectiveAt: payment.voidEffectiveAt
          }
        }
      }
    );
    // console.log(voidedPayment)
    if (!voidedPayment.data.voidPayment) {
      throw new Error();
    }
    return voidedPayment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

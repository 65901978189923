export const getAllowRescheduleOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const recordRescheduleCancellationOption = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true,

      //Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true,
      '46ef088a-d044-4000-8a53-578e6be74ef1': true
    };

    const result = recordRescheduleCancellationOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDefaultRescheduleCancellationOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const recordRescheduleCancellationOption = {
      cityosteophysio: true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true,

      //Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true,
      '46ef088a-d044-4000-8a53-578e6be74ef1': true
    };

    const result = recordRescheduleCancellationOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const FlowApp = Loader(
  lazy(() => import('src/content/TerminalApp/FlowAppCheckIn'))
);

const FlowCheckIn = Loader(
  lazy(() => import('src/content/TerminalApp/FlowAppCheckIn/FlowCheckIn'))
);

const FlowTrafficControl = Loader(
  lazy(
    () => import('src/content/TerminalApp/FlowAppCheckIn/FlowTrafficControl')
  )
);

const flowRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <FlowApp />
  },
  {
    path: 'checkin',
    element: <FlowCheckIn />
  },
  {
    path: 'control',
    element: <FlowTrafficControl />
  }
  // {
  //   path: ':patientId',
  //   element: <SinglePatient />
  // },

  // {
  //   path: 'patient',
  //   children: [
  //     {
  //       path: ':patientId',
  //       element: <SingleEditPatient />
  //     },
  //     {
  //       path: ':patientId/:tabName',
  //       element: <SingleEditPatient />
  //     }
  //   ]
  // },

  // {
  //   path: 'customer',
  //   children: [
  //     {
  //       path: ':patientId',
  //       element: <SingleEditPatient />
  //     },
  //     {
  //       path: ':patientId/:tabName',
  //       element: <SingleEditPatient />
  //     }
  //   ]
  // }
];

export default flowRoutes;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createMedicalCertificateAPI,
  listMedicalCertificatesByPatientAPI,
  listMedicalCertificatesByLocationAPI,
  voidMedicalCertificateAPI
} from 'src/content/MedicalCertificates/api';

interface MedicalCertState {
  isLoadingPatientMCs: boolean;
  isCreatingPatientMC: boolean;
  isVoidingPatientMC: boolean;
  patientMCs: any[];
  mcErrorMessage: string;
  mcSuccessMessage: string;
}

const initialState: MedicalCertState = {
  isLoadingPatientMCs: false,
  isCreatingPatientMC: false,
  isVoidingPatientMC: false,
  patientMCs: [],
  mcErrorMessage: '',
  mcSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortMCsDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'medicalcerts',
  initialState,
  reducers: {
    createMedicalCert(state: MedicalCertState, action: PayloadAction<any>) {
      state.patientMCs = [action.payload, ...state.patientMCs];
    },

    listMedicalCertByPatient(
      state: MedicalCertState,
      action: PayloadAction<any>
    ) {
      let mcs = [...action.payload];
      mcs.sort(sortMCsDesc);

      state.patientMCs = [...mcs];

      state.isLoadingPatientMCs = false;
    },

    voidMedicalCert(state: MedicalCertState, action: PayloadAction<any>) {
      let noteIndex = state.patientMCs.findIndex(
        (obj) => obj.id === action.payload.id
      );

      state.patientMCs = state.patientMCs.map((note, i) =>
        i === noteIndex ? action.payload : note
      );
    },

    setIsloadingPatientMCs(
      state: MedicalCertState,
      action: PayloadAction<any>
    ) {
      state.isLoadingPatientMCs = action.payload;
    },

    setIsCreatingPatientMC(
      state: MedicalCertState,
      action: PayloadAction<any>
    ) {
      state.isCreatingPatientMC = action.payload;
    },

    setIsVoidingPatientMC(state: MedicalCertState, action: PayloadAction<any>) {
      state.isVoidingPatientMC = action.payload;
    },

    setErrorMessage(state: MedicalCertState, action: PayloadAction<any>) {
      state.mcErrorMessage = action.payload;
    },

    setSuccessMessage(state: MedicalCertState, action: PayloadAction<any>) {
      state.mcSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createMedicalCert =
  (medicalCert): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingPatientMC());
    dispatch(startLoadingPatientMCs());

    const response = await createMedicalCertificateAPI(medicalCert);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createMedicalCert(response.data.createMedicalCertificate)
      );
      dispatch(
        slice.actions.setSuccessMessage('MC / Time Chit successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingPatientMC());
      dispatch(stopLoadingPatientMCs());
    };

    return followUp();
  };

export const listMedicalCertByPatient =
  (patientId, startDate, endDate): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPatientMCs());

    const response = await listMedicalCertificatesByPatientAPI(
      patientId,
      startDate,
      endDate
    );
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listMedicalCertByPatient(
          response.data.listMedicalCertificatesByPatient
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingPatientMCs());
  };

export const voidMedicalCert =
  (voidMC): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingPatientMC());
    dispatch(startLoadingPatientMCs());

    const response = await voidMedicalCertificateAPI(voidMC);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.voidMedicalCert(response.data.voidMedicalCertificate)
      );
      dispatch(
        slice.actions.setSuccessMessage('MC / Time Chit successfully voided')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingPatientMC());
      dispatch(stopLoadingPatientMCs());
    };

    return followUp();
  };

export const startLoadingPatientMCs = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsloadingPatientMCs(true));
};

export const stopLoadingPatientMCs = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsloadingPatientMCs(false));
};

export const startCreatingPatientMC = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPatientMC(true));
};

export const stopCreatingPatientMC = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPatientMC(false));
};

export const startVoidingPatientMC = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingPatientMC(true));
};

export const stopVoidingPatientMC = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingPatientMC(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const GoogleCalIntegration = Loader(lazy(() => import('src/content/GoogleCalendar')));
const GoogleCalAuth = Loader(lazy(() => import('src/content/GoogleCalendar/GoogleCalAuth')));

const integrationsRoutes = [
//   {
//     path: '/',
//     element: <Navigate to="list" replace />
//   },
  {
    path: 'googlecalendar',
    element: <GoogleCalIntegration />
  },
  {
    path: 'googlecalendar/auth',
    element: <GoogleCalAuth />
  },

 
];

export default integrationsRoutes;

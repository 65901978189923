import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListMedicalCertificatesByPatientQuery,
  ListMedicalCertificatesByLocationQuery,
  GetMedicalCertificateQuery,
  CreateMedicalCertificateMutation,
  VoidMedicalCertificateMutation,
  DeleteMedicalCertificateMutation
} from 'src/API';
import {
  listMedicalCertificatesByPatient,
  listMedicalCertificatesByLocation
} from 'src/graphql/queries';
import {
  createMedicalCertificate,
  voidMedicalCertificate,
  deleteMedicalCertificate
} from 'src/graphql/mutations';

export const createMedicalCertificateAPI = async (medicalCert) => {
  try {
    const newMC = await callGraphQL<CreateMedicalCertificateMutation>(
      createMedicalCertificate,
      {
        variables: {
          input: medicalCert
        }
      }
    );

    return newMC;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listMedicalCertificatesByPatientAPI = async (
  patientId,
  startDate,
  endDate
) => {
  try {
    let inputVars = {};
    if (startDate === null || endDate === null) {
      inputVars = { patientId };
    } else {
      inputVars = {
        patientId,
        startDate,
        endDate
      };
    }

    const patientMCs = await callGraphQL<ListMedicalCertificatesByPatientQuery>(
      listMedicalCertificatesByPatient,
      {
        variables: inputVars
      }
    );
    return patientMCs;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listMedicalCertificatesByLocationAPI = async (
  locationIds,
  startDate,
  endDate
) => {
  try {
    const locationMCs =
      await callGraphQL<ListMedicalCertificatesByLocationQuery>(
        listMedicalCertificatesByLocation,
        {
          variables: {
            locationIds,
            startDate,
            endDate
          }
        }
      );

    return locationMCs;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidMedicalCertificateAPI = async (voidMC) => {
  try {
    const voidedMC = await callGraphQL<VoidMedicalCertificateMutation>(
      voidMedicalCertificate,
      {
        variables: {
          input: {
            id: voidMC.id,
            voidReason: voidMC.voidReason
          }
        }
      }
    );
      
    return voidedMC;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

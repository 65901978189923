import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { useWatch } from 'react-hook-form';
import { Alert, DialogContent, Typography, Switch, Grid } from '@mui/material';

import {
  HookFormAutocompleteField,
  HookFormCountryField,
  HookFormInputField
} from 'src/components/FormFields';
import Label from 'src/components/Label';

import tenantMappings from 'src/utils/tenantMappings';

const patientMappings = tenantMappings?.patientMappings;

const QuickCreatePatientForm = ({
  control,
  errors,
  isCustomerEmailRequired,
  setIsCustomerEmailRequired
}) => {
  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const formOptions = useSelector(
    (state) => state.settings.globalUISettings?.patients?.formOptions
  );

  const quickActionCustomerFieldsToShow =
    patientMappings?.getQuickActionCustomerOptionalFieldsToShow(
      tenantIdLowerCase
    );
  const getQuickActionCustomerOptionalFieldsToShow = (fieldName) => {
    const fallback = false;

    const result = quickActionCustomerFieldsToShow[fieldName];

    if (result !== null) {
      return result;
    } else {
      return fallback;
    }
  };

  const customerFieldLabels =
    patientMappings?.getCustomerFieldLabels(tenantIdLowerCase);
  const getCustomerFieldLabels = (fieldName) => {
    const fallback = fieldName;

    const result = customerFieldLabels[fieldName];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  };

  const customerFieldMandatoryRequirement =
    patientMappings?.getCustomerFieldMandatoryRequirement(tenantIdLowerCase);
  const getCustomerFieldMandatoryRequirement = (patientField) => {
    const fallback = false;

    const result = customerFieldMandatoryRequirement[patientField];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Quick Create New Record</Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <HookFormAutocompleteField
            name="title"
            label="Title"
            options={formOptions.titleOptions}
            control={control}
            errors={errors}
            rules={{
              required: getCustomerFieldMandatoryRequirement('title')
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4.5} md={4.5}>
          <HookFormInputField
            name="preferredName"
            label={getCustomerFieldLabels('preferredName')}
            control={control}
            errors={errors}
            rules={{
              required: getCustomerFieldMandatoryRequirement('preferredName')
            }}
            autoFocus={true}
          />
        </Grid>

        {getQuickActionCustomerOptionalFieldsToShow('lastName') ? (
          <Grid item xs={12} sm={4.5} md={4.5}>
            <HookFormInputField
              name="lastName"
              label={getCustomerFieldLabels('lastName')}
              control={control}
              errors={errors}
              rules={{
                required: getCustomerFieldMandatoryRequirement('lastName')
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={0} sm={4.5} md={4.5}></Grid>
        )}

        <Grid item xs={12} sm={7} md={3}>
          <HookFormCountryField
            name="mobileCountryCode"
            label={getCustomerFieldLabels('mobileCountryCode')}
            control={control}
            errors={errors}
            result="phone"
            rules={{
              required:
                getCustomerFieldMandatoryRequirement('mobileCountryCode')
            }}
          />
        </Grid>

        <Grid item xs={12} sm={5} md={2.5}>
          <HookFormInputField
            name="mobile"
            label={getCustomerFieldLabels('mobile')}
            control={control}
            errors={errors}
            rules={{
              required: getCustomerFieldMandatoryRequirement('mobile'),
              pattern: {
                value: /^\d{8,12}$/,
                message: 'Please enter a valid mobile number.'
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2.5}>
          <Label>
            {isCustomerEmailRequired ? 'Email Required' : 'No Email Required'}
          </Label>
          <Switch
            color="primary"
            checked={isCustomerEmailRequired}
            onChange={() =>
              setIsCustomerEmailRequired(!isCustomerEmailRequired)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          {isCustomerEmailRequired ? (
            <HookFormInputField
              name="email"
              label={getCustomerFieldLabels('email')}
              control={control}
              errors={errors}
              rules={{
                required: getCustomerFieldMandatoryRequirement('email'),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email address'
                }
              }}
            />
          ) : (
            <Alert variant="outlined" severity="info" sx={{ width: '100%' }}>
              <Typography variant="body2">no-email@gethelm.io</Typography>
            </Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default QuickCreatePatientForm;

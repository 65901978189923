import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'src/hooks/useAuth';
import { format, parseISO, startOfDay } from 'date-fns';
import { useSelector, useDispatch } from 'src/store';
import {
  Box,
  Button,
  DialogActions,
  CircularProgress,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';

import {
  createCustomerAPI,
  isExistingCustomerAPI
} from 'src/content/Patients/api';
import { createAppointment } from 'src/slices/appointments';
import {
  setQuickActionErrorMessage,
  setQuickActionSuccessMessage
} from 'src/slices/patients';

import SuspenseLoader from 'src/components/SuspenseLoader';
import QuickCreatePatientForm from 'src/content/Patients/patientForm/QuickCreatePatientForm';

import { Mixpanel } from 'src/contexts/MixPanelContext';

const CreatePatientAction = ({
  setSelectedPatient,
  appointmentData,
  handleClose,
  setHasCreated,
  notify
}) => {
  const dispatch = useDispatch();

  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const { loggedInUser } = useSelector((state) => state.users);

  const [searchedEmail, setSearchedEmail] = useState('');
  const [searchedMobile, setSearchedMobile] = useState('');
  const [acknowledgeDuplicate, setAcknowledgeDuplicate] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState('');
  const [duplicateData, setDuplicateData] = useState([]);

  const [isCustomerEmailRequired, setIsCustomerEmailRequired] = useState(true);

  // Registering React Hook Form
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      lastName: '',
      title: 'Mr',
      fullName: '',
      preferredName: '',
      birthday: format(new Date('1900-01-01T00:00:00'), 'yyyy-MM-dd'),
      gender: '',
      nationality: 'Singapore',
      maritalStatus: '',
      identityNumber: '',
      identityType: '',
      occupation: '',
      employer: '',
      internalNote: '',
      mobileCountryCode: '65',
      mobile: '',
      email: '',
      telephone2: '',
      telephone3: '',
      addressStreetAddress: '',
      addressUnitNo: '',
      addressCountry: 'Singapore',
      addressPostalCode: '',
      nextOfKin: { name: '', contact: '', relation: null },
      drugAllergies: [],
      noKnownDrugAllergy: false,
      foodAllergies: [],
      noKnownFoodAllergy: false,
      otherAllergies: '',
      noKnownOtherAllergy: false,
      medicalConditions: '',
      noKnownMedicalConditions: false,
      vaccines: [],
      locationId: '',
      insurance: [],
      subsidySchemes: [],
      referredBy: null,
      referralSource: null,
      referralCategory: null,
      membershipTier: null,
      marketingConsent: {
        email: true,
        sms: true,
        whatsapp: true,
        phone: true
      }
      // isInitialized: false
    }
  });

  const resetDuplicateStates = () => {
    setSearchedEmail('');
    setSearchedMobile('');
    setAcknowledgeDuplicate(false);
    setDuplicateMessage('');
    setDuplicateData([]);
  };

  const onSubmit = async (data) => {
    Mixpanel.identify(loggedInUser?.id);
    Mixpanel.track('Quick Action - Create Customer');
    // console.log(data);

    if (
      acknowledgeDuplicate === false ||
      searchedEmail !== data.email.trim().toLowerCase() ||
      searchedMobile !== data.mobile.trim()
    ) {
      setSearchedEmail(data.email.trim().toLowerCase());
      setSearchedMobile(data.mobile.trim());

      const emailResponse =
        data?.email?.trim() === ''
          ? undefined
          : await isExistingCustomerAPI(data.email.trim().toLowerCase());

      // console.log(emailResponse);

      if (emailResponse?.data?.listPatientPiiProfiles?.items?.length > 0) {
        setDuplicateMessage(
          `Found ${emailResponse?.data?.listPatientPiiProfiles?.items?.length} possible duplicates. Please review the list before you proceed.`
        );
        setDuplicateData([
          ...emailResponse?.data?.listPatientPiiProfiles?.items
        ]);
        setAcknowledgeDuplicate(true);
        return;
      }

      const mobileResponse = await isExistingCustomerAPI(data.mobile.trim());

      if (mobileResponse?.data?.listPatientPiiProfiles?.items?.length > 0) {
        setDuplicateMessage(
          `Found ${mobileResponse?.data?.listPatientPiiProfiles?.items?.length} possible duplicates. Please review the list before you proceed.`
        );
        setDuplicateData([
          ...mobileResponse?.data?.listPatientPiiProfiles?.items
        ]);
        setAcknowledgeDuplicate(true);
        return;
      }
    }

    const interimData = {
      ...data,
      locationId: loggedInUser?.currentLocation?.id,
      preferredName: data.preferredName ? data.preferredName.trim() : '',
      fullName:
        data.preferredName && data.lastName
          ? (data.preferredName.trim() + ' ' + data.lastName.trim()).trim()
          : data.preferredName.trim(),
      mobileCountryCode: data.mobileCountryCode,
      mobile: data.mobile ? data.mobile?.trim() : '',
      email:
        isCustomerEmailRequired && data.email
          ? data.email.trim().toLowerCase()
          : 'no-email@gethelm.io',
      // birthday: data?.birthday?.toISOString().split('T')[0]
      birthday: format(new Date(data?.birthday), 'yyyy-MM-dd')
    };

    const { lastName, ...finalData } = interimData;
    setHasCreated(true);
    // console.log(finalData);

    const response = await createCustomerAPI(finalData);
    // console.log(response);

    if (response?.data?.createPatient) {
      dispatch(setQuickActionSuccessMessage('Record successfully created'));

      const apptFinalData = {
        patientId: response.data.createPatient.id,
        serviceId: appointmentData.serviceId,
        appointmentDate: appointmentData.appointmentDate,
        appointmentStartTime: appointmentData.appointmentStartTime,
        appointmentEndTime: appointmentData.appointmentEndTime,
        type: appointmentData.type,
        appointmentNote:
          appointmentData.appointmentNote?.length > 0
            ? appointmentData.appointmentNote
            : '',
        status: appointmentData.status,
        recurFrequency: data?.recurFrequency || null,
        recurInstances: data?.recurFrequency ? data?.recurInstances || 1 : null,
        sendNotification: notify
      };
      dispatch(
        // createAppointment(tenantIdLowerCase, apptFinalData, notify, true) // removed notification flag from api call
        createAppointment(tenantIdLowerCase, apptFinalData, true)
      );
      resetDuplicateStates();
      handleClose();
    } else {
      console.log(
        'There was an error. Terminating and not creating appointment.'
      );
      dispatch(setQuickActionErrorMessage(response.customErrorMessage));
      resetDuplicateStates();
      handleClose();
    }
  };

  if (!loggedInUser) {
    return <SuspenseLoader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0} rowGap={1}>
        <Grid item xs={12}>
          <QuickCreatePatientForm
            control={control}
            errors={errors}
            isCustomerEmailRequired={isCustomerEmailRequired}
            setIsCustomerEmailRequired={setIsCustomerEmailRequired}
          />
        </Grid>

        {duplicateMessage !== '' && (
          <>
            <Grid item xs={12}>
              <Typography color="error" gutterBottom>
                {' '}
                {duplicateMessage}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{'Full Name'}</TableCell>
                      <TableCell>{'ID'}</TableCell>
                      <TableCell>{'Mobile'}</TableCell>
                      <TableCell>{'Email'}</TableCell>
                      <TableCell>{'Birthdate'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {duplicateData?.length > 0 &&
                      duplicateData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align={'left'}>
                            <Typography>{item?.fullName}</Typography>
                          </TableCell>

                          <TableCell align={'left'}>
                            {`${
                              item?.identityNumber ? item?.identityNumber : ''
                            }`}
                          </TableCell>

                          <TableCell align={'left'}>{`${
                            item?.mobile ? item?.mobile : ''
                          }`}</TableCell>

                          <TableCell align={'left'}>{`${
                            item?.email ? item?.email : ''
                          }`}</TableCell>

                          <TableCell align={'left'}>
                            {`${item?.birthday ? item?.birthday : ''}`}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <Typography color="error" sx={{ pt: 1 }}>
                Please double check! If you wish to proceed and ignore this
                warning, just click Save again.
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            // sx={{ width: 1}}
          >
            <Button
              type="submit"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              variant="contained"
            >
              {'Save'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreatePatientAction;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListTodayCustomerClassAppointmentsQuery,
  GetFlowActivitySessionQuery,
  ListFlowActivitySessionsQuery,
  CreateFlowActivitySessionMutation,
  UpdateFlowActivitySessionCustomerOrdersMutation,
  UpdateFlowActivitySessionStationsMutation,
  StartFlowActivitySessionMutation,
  PauseFlowActivitySessionMutation,
  ResumeFlowActivitySessionMutation,
  CompleteFlowActivitySessionMutation,
  CheckInFlowActivitySessionCustomerActivityMutation,
  AddFlowActivitySessionCustomerActivityMutation,
  RemoveFlowActivitySessionCustomerActivityMutation,
  ProcessFlowActivitySessionMutation,
  MoveCustomerActivityBackToWaitingMutation,
  ForceRefreshFlowActivitySessionMutation
} from 'src/API';
import {
  listTodayCustomerClassAppointments
  // getFlowActivitySession,
  // listFlowActivitySessions
} from 'src/graphql/queries';
// import {
//   createFlowActivitySession,
//   updateFlowActivitySessionCustomerOrders,
//   updateFlowActivitySessionStations,
//   startFlowActivitySession,
//   pauseFlowActivitySession,
//   resumeFlowActivitySession,
//   completeFlowActivitySession,
//   checkInFlowActivitySessionCustomerActivity,
//   addFlowActivitySessionCustomerActivity,
//   removeFlowActivitySessionCustomerActivity,
//   processFlowActivitySession,
//   moveCustomerActivityBackToWaiting,
//   forceRefreshFlowActivitySession
// } from 'src/graphql/mutations';

import {
  customListFlowActivitySessions,
  customGetFlowActivitySession
} from './queries';
import {
  customCreateFlowActivitySession,
  customUpdateFlowActivitySessionCustomerOrders,
  customUpdateFlowActivitySessionStations,
  customStartFlowActivitySession,
  customPauseFlowActivitySession,
  customResumeFlowActivitySession,
  customCompleteFlowActivitySession,
  customCheckInFlowActivitySessionCustomerActivity,
  customAddFlowActivitySessionCustomerActivity,
  customRemoveFlowActivitySessionCustomerActivity,
  customProcessFlowActivitySession,
  customMoveCustomerActivityBackToWaiting,
  customForceRefreshFlowActivitySession
} from './mutations';

export const listTodayCustomerClassAppointmentsQueryAPI = async (
  customerShortCode
) => {
  try {
    const cust_today_appts =
      await callGraphQL<ListTodayCustomerClassAppointmentsQuery>(
        listTodayCustomerClassAppointments,
        {
          variables: {
            customerShortCode
          }
        }
      );
    // console.log(cust_today_appts);
    return cust_today_appts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getFlowActivitySessionAPI = async (flowActivitySessionId) => {
  try {
    const activitySession = await callGraphQL<GetFlowActivitySessionQuery>(
      customGetFlowActivitySession,
      {
        variables: {
          id: flowActivitySessionId
        }
      }
    );
    // console.log(activitySession);
    return activitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listFlowActivitySessionsAPI = async (
  locationId,
  startTime,
  endTime
) => {
  try {
    const activitySessions = await callGraphQL<ListFlowActivitySessionsQuery>(
      customListFlowActivitySessions,
      {
        variables: {
          locationId,
          startTime,
          endTime
        }
      }
    );
    // console.log(activitySessions);
    return activitySessions;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createFlowActivitySessionAPI = async (
  activityId,
  activityType = 'ClassAppointment',
  stations = [],
  otherProviderIds = []
) => {
  try {
    const newActivitySession =
      await callGraphQL<CreateFlowActivitySessionMutation>(
        customCreateFlowActivitySession,
        {
          // ActivityId is an appointmentId or classAppointmentId. Right now only supports classAppointmentId
          variables: {
            input: {
              activityId,
              activityType,
              stations,
              otherProviderIds
            }
          }
        }
      );
    // console.log(newActivitySession);
    return newActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateFlowActivitySessionCustomerOrdersAPI = async (
  activitySessionId,
  customerActivityIds
) => {
  try {
    const updatedActivitySession =
      await callGraphQL<UpdateFlowActivitySessionCustomerOrdersMutation>(
        customUpdateFlowActivitySessionCustomerOrders,
        {
          variables: {
            activitySessionId,
            customerActivityIds
          }
        }
      );
    // console.log(updatedActivitySession);
    return updatedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateFlowActivitySessionStationsAPI = async (
  activitySessionId,
  stations
) => {
  try {
    const updatedActivitySession =
      await callGraphQL<UpdateFlowActivitySessionStationsMutation>(
        customUpdateFlowActivitySessionStations,
        {
          variables: {
            input: {
              id: activitySessionId,
              stations
            }
          }
        }
      );
    // console.log(updatedActivitySession);
    return updatedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const startFlowActivitySessionAPI = async (flowActivitySessionId) => {
  try {
    const startedActivitySession =
      await callGraphQL<StartFlowActivitySessionMutation>(
        customStartFlowActivitySession,
        {
          variables: {
            activitySessionId: flowActivitySessionId
          }
        }
      );
    // console.log(startedActivitySession);
    return startedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const pauseFlowActivitySessionAPI = async (flowActivitySessionId) => {
  try {
    const pausedActivitySession =
      await callGraphQL<PauseFlowActivitySessionMutation>(
        customPauseFlowActivitySession,
        {
          variables: {
            activitySessionId: flowActivitySessionId
          }
        }
      );
    // console.log(pausedActivitySession);
    return pausedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const resumeFlowActivitySessionAPI = async (flowActivitySessionId) => {
  try {
    const resumedActivitySession =
      await callGraphQL<ResumeFlowActivitySessionMutation>(
        customResumeFlowActivitySession,
        {
          variables: {
            activitySessionId: flowActivitySessionId
          }
        }
      );
    // console.log(resumedActivitySession);
    return resumedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const completeFlowActivitySessionAPI = async (flowActivitySessionId) => {
  try {
    const completedActivitySession =
      await callGraphQL<CompleteFlowActivitySessionMutation>(
        customCompleteFlowActivitySession,
        {
          variables: {
            activitySessionId: flowActivitySessionId
          }
        }
      );
    // console.log(completedActivitySession);
    return completedActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const checkInFlowActivitySessionCustomerActivityAPI = async (
  activityId,
  customerShortCode
) => {
  try {
    const checkedInCustomerActivitySession =
      await callGraphQL<CheckInFlowActivitySessionCustomerActivityMutation>(
        customCheckInFlowActivitySessionCustomerActivity,
        {
          variables: {
            activityId,
            customerShortCode
          }
        }
      );
    // console.log(checkedInCustomerActivitySession);
    return checkedInCustomerActivitySession;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// this is used to force add a customer into a station.
export const addFlowActivitySessionCustomerActivityAPI = async (
  activitySessionId,
  customerActivityId,
  stationId
) => {
  try {
    const addedFlowSessionCustomerActivity =
      await callGraphQL<AddFlowActivitySessionCustomerActivityMutation>(
        customAddFlowActivitySessionCustomerActivity,
        {
          variables: {
            activitySessionId,
            customerActivityId,
            stationId
          }
        }
      );
    // console.log(addedFlowSessionCustomerActivity);
    return addedFlowSessionCustomerActivity;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// this is used to force remove a customer from a station.
export const removeFlowActivitySessionCustomerActivityAPI = async (
  activitySessionId,
  customerActivityId
) => {
  try {
    const removedFlowSessionCustomerActivity =
      await callGraphQL<RemoveFlowActivitySessionCustomerActivityMutation>(
        customRemoveFlowActivitySessionCustomerActivity,
        {
          variables: {
            activitySessionId,
            customerActivityId
          }
        }
      );
    // console.log(removedFlowSessionCustomerActivity);
    return removedFlowSessionCustomerActivity;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const processFlowActivitySessionAPI = async (activitySessionId) => {
  try {
    const processedFlowSessionCustomerActivity =
      await callGraphQL<ProcessFlowActivitySessionMutation>(
        customProcessFlowActivitySession,
        {
          variables: {
            activitySessionId
          }
        }
      );
    // console.log(processedFlowSessionCustomerActivity);
    return processedFlowSessionCustomerActivity;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const moveCustomerActivityBackToWaitingAPI = async (
  activitySessionId,
  customerActivityId
) => {
  try {
    const customer =
      await callGraphQL<MoveCustomerActivityBackToWaitingMutation>(
        customMoveCustomerActivityBackToWaiting,
        {
          variables: {
            activitySessionId,
            customerActivityId
          }
        }
      );
    // console.log(customer);
    return customer;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const forceRefreshFlowActivitySessionAPI = async (activitySessionId) => {
  try {
    const refreshedFlowSessionCustomerActivity =
      await callGraphQL<ForceRefreshFlowActivitySessionMutation>(
        customForceRefreshFlowActivitySession,
        {
          variables: {
            activitySessionId
          }
        }
      );
    // console.log(processedFlowSessionCustomerActivity);
    return refreshedFlowSessionCustomerActivity;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const customCreateFlowActivitySession = /* GraphQL */ `
  mutation CreateFlowActivitySession($input: CreateFlowActivitySessionInput!) {
    createFlowActivitySession(input: $input) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customUpdateFlowActivitySessionStations = /* GraphQL */ `
  mutation UpdateFlowActivitySessionStations(
    $input: UpdateFlowActivitySessionStationsInput!
  ) {
    updateFlowActivitySessionStations(input: $input) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customUpdateFlowActivitySessionCustomerOrders = /* GraphQL */ `
  mutation UpdateFlowActivitySessionCustomerOrders(
    $activitySessionId: ID!
    $customerActivityIds: [ID!]!
  ) {
    updateFlowActivitySessionCustomerOrders(
      activitySessionId: $activitySessionId
      customerActivityIds: $customerActivityIds
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customStartFlowActivitySession = /* GraphQL */ `
  mutation StartFlowActivitySession($activitySessionId: ID!) {
    startFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customPauseFlowActivitySession = /* GraphQL */ `
  mutation PauseFlowActivitySession($activitySessionId: ID!) {
    pauseFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customResumeFlowActivitySession = /* GraphQL */ `
  mutation ResumeFlowActivitySession($activitySessionId: ID!) {
    resumeFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customCompleteFlowActivitySession = /* GraphQL */ `
  mutation CompleteFlowActivitySession($activitySessionId: ID!) {
    completeFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customCheckInFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation CheckInFlowActivitySessionCustomerActivity(
    $activityId: ID!
    $customerShortCode: String!
  ) {
    checkInFlowActivitySessionCustomerActivity(
      activityId: $activityId
      customerShortCode: $customerShortCode
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customAddFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation AddFlowActivitySessionCustomerActivity(
    $activitySessionId: ID!
    $customerActivityId: ID!
    $stationId: ID!
  ) {
    addFlowActivitySessionCustomerActivity(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
      stationId: $stationId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customRemoveFlowActivitySessionCustomerActivity = /* GraphQL */ `
  mutation RemoveFlowActivitySessionCustomerActivity(
    $activitySessionId: ID!
    $customerActivityId: ID!
  ) {
    removeFlowActivitySessionCustomerActivity(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customMoveCustomerActivityBackToWaiting = /* GraphQL */ `
  mutation MoveCustomerActivityBackToWaiting(
    $activitySessionId: ID!
    $customerActivityId: ID!
  ) {
    moveCustomerActivityBackToWaiting(
      activitySessionId: $activitySessionId
      customerActivityId: $customerActivityId
    ) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;

export const customProcessFlowActivitySession = /* GraphQL */ `
  mutation ProcessFlowActivitySession($activitySessionId: ID!) {
    processFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;
export const customForceRefreshFlowActivitySession = /* GraphQL */ `
  mutation ForceRefreshFlowActivitySession($activitySessionId: ID!) {
    forceRefreshFlowActivitySession(activitySessionId: $activitySessionId) {
      id
      locationId
      providerId
      otherProviderIds
      status
      activityId
      activityType
      waitingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      servingCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      completedCustomerActivities {
        activityId
        activityType
        customerId
        customerName
        customerShortCode
        customerPreferredProviderIds
        strictlyProviders
        stationId
        stationName
        blocks {
          id
          classAppointmentId
          name
          category
          subCategory
          status
          flowActivityId
        }
        validPasses {
          id
          ownerId
          patientId
          passId
          status
          expiryDate
          assignedDate
          purchaseDate
          totalRedemptions
          redemptionsMade
          redemptionsRemaining
        }
        createdAt
        updatedAt
      }
      stationIds
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { Payment } from '../API';
import {
  listPatientPaymentsAPI,
  listAllPaymentsInDateRangeAPI,
  listShortPaymentsInDateRangeAPI
} from '../content/Payments/api';

interface PaymentState {
  isLoadingCustomerPayments: boolean;
  isLoadingOverviewPayments: boolean;
  customerPayments: any[];
  overviewPayments: any[];
  listPayments: Payment[];
  paymentSuccessMessage: string;
  paymentErrorMessage: string;
}

const initialState: PaymentState = {
  isLoadingCustomerPayments: false,
  isLoadingOverviewPayments: false,
  customerPayments: [],
  overviewPayments: [],
  listPayments: [],
  paymentSuccessMessage: '',
  paymentErrorMessage: ''
};

const sortPaymentsDesc = (a, b) => {
  if (a.paymentTime > b.paymentTime) {
    return -1;
  }
  if (a.paymentTime < b.paymentTime) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    listPatientPayments(state: PaymentState, action: PayloadAction<any>) {
      let payments = [...action.payload];
      payments.sort(sortPaymentsDesc);

      //   state.customerPayments = action.payload;
      state.customerPayments = [...payments];

      state.isLoadingCustomerPayments = false;
    },

    listOverviewPayments(state: PaymentState, action: PayloadAction<any>) {
      let payments = [...action.payload];
      // payments.sort(sortPaymentsDesc);

      //   state.overviewPayments = action.payload;
      state.overviewPayments = [...payments];

      state.isLoadingOverviewPayments = false;
    },

    listPaymentsInDateRange(state: PaymentState, action: PayloadAction<any>) {
      let payments = [...action.payload];
      payments.sort(sortPaymentsDesc);

      //   state.listPayments = action.payload;
      state.listPayments = [...payments];
    },

    setIsLoadingCustomerPayments(
      state: PaymentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerPayments = action.payload;
    },

    setIsLoadingOverviewPayments(
      state: PaymentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingOverviewPayments = action.payload;
    },

    setSuccessMessage(state: PaymentState, action: PayloadAction<any>) {
      state.paymentSuccessMessage = action.payload;
    },

    setErrorMessage(state: PaymentState, action: PayloadAction<any>) {
      state.paymentErrorMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listPatientPayments =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerPayments());

    const response = await backOff(() => listPatientPaymentsAPI(patientId), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(slice.actions.listPatientPayments(response.data.listPayments));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
      dispatch(stopLoadingCustomerPayments());
    }
  };

export const listPaymentsInDateRange =
  (paymentStartTime, paymentEndTime, locationIds): AppThunk =>
  async (dispatch) => {
    const response = await listAllPaymentsInDateRangeAPI(
      paymentStartTime,
      paymentEndTime,
      locationIds
    );
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listPaymentsInDateRange(
          response.data.listPaymentsInDateRange
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listOverviewPayments =
  (paymentStartTime, paymentEndTime, locationIds = []): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingOverviewPayments());

    const response = await listShortPaymentsInDateRangeAPI(
      paymentStartTime,
      paymentEndTime,
      locationIds
    );
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listOverviewPayments(
          response.data.listPaymentsInDateRange
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingOverviewPayments());
  };

export const startLoadingCustomerPayments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerPayments(true));
  };

export const stopLoadingCustomerPayments = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerPayments(false));
};

export const startLoadingOverviewPayments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOverviewPayments(true));
  };

export const stopLoadingOverviewPayments = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingOverviewPayments(false));
};

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export default slice;

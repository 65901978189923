export const getOnlineBookingPageStatusLabel = (
  onlineBookingPageStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Active: {
        text: 'ACTIVE',
        color: 'success'
      },
      Inactive: {
        text: 'INACTIVE',
        color: 'error'
      }
    };

    const { text, color }: any = map[onlineBookingPageStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

export const getOnlineBookingPaymentTypeLabel = (
  onlineBookingPaymentType: string
): String => {
  try {
    const map = {
      None: {
        text: 'None'
      },
      HelmPayCardDetails: {
        text: 'Collect Card Details Only'
      },
      HelmPayCapture: {
        text: 'Collect Payment Upfront via Card'
      },
      HelmPayNowCapture: {
        text: 'Collect Payment Upfront via PayNow / Card' // To be modified after we correctly implement HelmPayNowCardCapture
      },
      HelmPayNowCardCapture: {
        text: 'Collect Payment Upfront via PayNow / Card'
      },
      HelmPayAuthorisation: {
        text: 'Authorise Amount Only'
      }
    };

    const { text }: any = map[onlineBookingPaymentType];

    return text;
  } catch {
    return 'Unknown';
  }
};

export const getMappedWordToUse = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {};

  try {
    const tenantDictionary = {
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        Patient: 'Customer',
        Patients: 'Customers',
        patient: 'customer',
        patients: 'customers'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        'Next-Of-Kin': 'Alternative Contact',
        Patient: 'Customer',
        Patients: 'Customers',
        patient: 'customer',
        patients: 'customers',
        Practitioner: 'Designer',
        Practitioners: 'Designers',
        practitioner: 'designer',
        practitioners: 'designers',
        Treatment: 'Visit'
      }
    };

    // const result = dictionary[word][tenantIdLowerCase];
    const result = tenantDictionary[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    return fallback;
  }
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface TerminalState {
  isUnlocked: boolean;
  lockEnabled: boolean;
  // loggedInUser: { [key: string]: any };
}

const initialState: TerminalState = {
  isUnlocked: false,
  lockEnabled: true
};

const slice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setIsUnlocked(state: TerminalState, action: PayloadAction<any>) {
      if (!state.lockEnabled) {
        state.isUnlocked = true
      } else {
        state.isUnlocked = action.payload;
      }
      
    },
    setLockEnabled(state: TerminalState, action: PayloadAction<any>) {
      state.lockEnabled = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export default slice;

export const setIsUnlocked =
  (unlockSetting): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsUnlocked(unlockSetting));
  };

export const setLockEnabled =
  (lockSetting): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setLockEnabled(lockSetting));
  };

export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        createdAt
        createdBy
        updatedAt
        updatedBy
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      # appointments {
      #   id
      #   patientId
      #   serviceId
      #   locationId
      #   practitionerId
      #   appointmentDate
      #   appointmentStartTime
      #   appointmentEndTime
      #   appointmentCheckInTime
      #   appointmentCompleteTime
      #   type
      #   status
      #   emailReminderSent
      #   emailReminderAt
      #   smsReminderSent
      #   smsReminderSentAt
      #   appointmentNote
      #   confirmedAt
      #   confirmedBy
      #   cancellationAt
      #   cancellationBy
      #   cancellationNote
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        payments {
          amountPaid {
            amount
            currency
            precision
            scale
          }
          allocations {
            amountAllocated {
              amount
              currency
              precision
              scale
            }
            invoiceItemId
          }
        }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        # service {
        #   id
        # }
        # inventory {
        #   id
        # }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        type
        mode
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        amount {
          amount
          currency
          precision
          scale
        }
        reference
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const voidInvoice = /* GraphQL */ `
  mutation VoidInvoice($input: VoidInvoiceInput!) {
    voidInvoice(input: $input) {
      id
      type
      patientId
      appointmentId
      practitionerId
      locationId
      corporateId
      patient {
        id
        fullName
        createdAt
        createdBy
        updatedAt
        updatedBy
        medicalProfile {
          corporates {
            corporateId
            corporate {
              id
              companyName
              code
              category
            }
            paymentSchemeId
            membershipDetails
            isActive
            notes
            start
            expiry
            number
          }
          insurance
          subsidySchemes
        }
      }
      # appointments {
      #   id
      #   patientId
      #   serviceId
      #   locationId
      #   practitionerId
      #   appointmentDate
      #   appointmentStartTime
      #   appointmentEndTime
      #   appointmentCheckInTime
      #   appointmentCompleteTime
      #   type
      #   status
      #   emailReminderSent
      #   emailReminderAt
      #   smsReminderSent
      #   smsReminderSentAt
      #   appointmentNote
      #   confirmedAt
      #   confirmedBy
      #   cancellationAt
      #   cancellationBy
      #   cancellationNote
      #   createdAt
      #   createdBy
      #   updatedAt
      #   updatedBy
      # }
      items {
        id
        name
        type
        category
        adjustments {
          amount {
            amount
            currency
            precision
            scale
          }
        }
        payments {
          amountPaid {
            amount
            currency
            precision
            scale
          }
          allocations {
            amountAllocated {
              amount
              currency
              precision
              scale
            }
            invoiceItemId
          }
        }
        amountOutstanding {
          amount
          currency
          precision
          scale
        }
        unitSellingPrice {
          amount
          currency
          precision
          scale
        }
        description
        quantity
        reference
        discountCodes
        # service {
        #   id
        # }
        # inventory {
        #   id
        # }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      payments {
        id
        patientId
        invoiceId
        type
        mode
        amountPaid {
          amount
          currency
          precision
          scale
        }
        status
        paymentNumber
        paymentTime
        settlementTime
        reference
        isInsurance
        isRefunded
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      invoiceNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      postTaxAdjustments {
        id
        type
        reference
        amount {
          amount
          currency
          precision
          scale
        }
        voidedAt
        voidedBy
        voidedReason
        createdAt
        createdBy
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      totalAmountOutstanding {
        amount
        currency
        precision
        scale
      }
      issueDate
      dueDate
      reference
      note
      voidedAt
      voidedBy
      voidedReason
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateInvoiceNote = /* GraphQL */ `
  mutation UpdateInvoiceNote($invoiceId: ID!, $note: String!) {
    updateInvoiceNote(invoiceId: $invoiceId, note: $note) {
      id
      note
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateInvoiceReference = /* GraphQL */ `
  mutation UpdateInvoiceReference($invoiceId: ID!, $reference: String!) {
    updateInvoiceReference(invoiceId: $invoiceId, reference: $reference) {
      id
      reference
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateInvoiceItemDescription = /* GraphQL */ `
  mutation UpdateInvoiceItemDescription(
    $input: UpdateInvoiceItemDescriptionInput!
  ) {
    updateInvoiceItemDescription(input: $input) {
      id
      corporateId
      type
      items {
        id
        name
        type
        category
        description
      }
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdateInvoice = /* GraphQL */ `
  mutation AdminUpdateInvoice($input: AdminUpdateInvoiceInput!) {
    adminUpdateInvoice(input: $input) {
      id
      practitionerId
      reference
      note
      updatedAt
      updatedBy
    }
  }
`;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Services = Loader(lazy(() => import('src/content/Services')));
const SingleService = Loader(lazy(() => import('src/content/Services/single')));

const serviceRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Services />
  },
  {
    path: ':serviceId/',
    element: <SingleService />
  }
];

export default serviceRoutes;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// const Patients = Loader(lazy(() => import('src/content/Patients')));
// const SinglePatient = Loader(
//   lazy(() => import('src/content/Patients/single'))
// );
const ReportsListIndex = Loader(lazy(() => import('src/content/Reports')));
const ReportIndex = Loader(lazy(() => import('src/content/Reports/single')));

const reportsRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },

  {
    path: '/list',
    element: <ReportsListIndex />
  },
  {
    path: '/:reportName',
    element: <ReportIndex />
  }

  // {
  //   path: ':patientId',
  //   element: <SinglePatient />
  // },

  // {
  //   path: 'users',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <Users />
  //     },
  //     {
  //       path: 'single',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="1" replace />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     }
  //   ]
  // },
];

export default reportsRoutes;

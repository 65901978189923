import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';

import {
  purchasePassAPI,
  listPassPurchasesAPI,
  listPatientPassesAPI,
  createPatientPassAPI,
  updatePatientPassAPI,
  getPatientPassAPI,
  redeemPatientPassAPI,
  voidPatientPassAPI,
  voidPatientPassRedemptionAPI
} from 'src/content/PatientPasses/api';

import { editAppointmentAPI } from 'src/content/Appointments/api';
import { getAppointment } from 'src/slices/appointments';

interface PatientPassState {
  isLoadingCustomerPasses: boolean;
  isEditingCustomerPass: boolean;
  isCreatingCustomerPass: boolean;
  isCreatingCustomerPassRedemption: boolean;
  isVoidingCustomerPass: boolean;
  isVoidingCustomerPassRedemption: boolean;
  patientPasses: any[];
  patientPassDetail: any[];
  purchasedCustomerPasses: any[];
  patientPassErrorMessage: string;
  patientPassSuccessMessage: string;
}

const initialState: PatientPassState = {
  isLoadingCustomerPasses: false,
  isEditingCustomerPass: false,
  isCreatingCustomerPass: false,
  isCreatingCustomerPassRedemption: false,
  isVoidingCustomerPass: false,
  isVoidingCustomerPassRedemption: false,
  patientPasses: [],
  purchasedCustomerPasses: [],
  patientPassDetail: [],
  patientPassErrorMessage: '',
  patientPassSuccessMessage: ''
};

const sortPatientPassDesc = (a, b) => {
  if (a.assignedDate > b.assignedDate) {
    return -1;
  }
  if (a.assignedDate < b.assignedDate) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'passes',
  initialState,
  reducers: {
    listSpecificCustomerPasses(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      const patientPasses = action.payload;
      patientPasses.sort(sortPatientPassDesc);

      state.patientPasses = [...patientPasses];
    },

    getCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      if (action.payload) {
        state.patientPassDetail = [action.payload];

        if (state.patientPasses.length > 0) {
          state.patientPasses = state.patientPasses.map((pass) =>
            pass.id === action.payload.id ? action.payload : pass
          );
        }
      } else {
        state.patientPassDetail = [{ id: 'Not Found' }];
      }
    },

    purchaseCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      state.purchasedCustomerPasses = [
        action.payload,
        ...state.purchasedCustomerPasses
      ];
    },

    createCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      state.patientPasses = [action.payload, ...state.patientPasses];
    },

    updateCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      const editedPass = action.payload;

      let passIndex = state.patientPasses.findIndex(
        (obj) => obj.id === editedPass.id
      );

      if (passIndex >= 0) {
        state.patientPasses = state.patientPasses.map((pass, i) =>
          i === passIndex ? editedPass : pass
        );
      }

      if (action.payload) {
        state.patientPassDetail = [action.payload];
      } else {
        state.patientPassDetail = [{ id: 'Not Found' }];
      }
    },

    voidCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      const voidedPass = action.payload;

      let passIndex = state.patientPasses.findIndex(
        (obj) => obj.id === voidedPass.id
      );

      if (passIndex >= 0) {
        state.patientPasses = state.patientPasses.map((pass, i) =>
          i === passIndex ? voidedPass : pass
        );
      }

      if (action.payload) {
        state.patientPassDetail = [action.payload];
      } else {
        state.patientPassDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedCustomerPass(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.patientPassDetail = [];
    },

    redeemCustomerPass(state: PatientPassState, action: PayloadAction<any>) {
      console.log(
        'need to decrement the pass that was redeemed if session pass'
      );
      console.log('maybe no need if we can just navigate to another page lol');
    },

    voidCustomerPassRedemption(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      const passesList = [...state.patientPasses];
      const otherPasses = passesList.filter(
        (pass) => pass.id !== action.payload.patientPassId
      );
      let affectedPass = passesList.find(
        (pass) => pass.id === action.payload.patientPassId
      );

      if (affectedPass) {
        affectedPass.passRedemptions = affectedPass.passRedemptions.map(
          (redemption) => {
            return redemption.id === action.payload.id
              ? action.payload
              : redemption;
          }
        );

        state.patientPasses = [affectedPass, ...otherPasses];
      }
    },

    setIsLoadingCustomerPasses(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerPasses = action.payload;
    },

    setIsEditingCustomerPass(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isEditingCustomerPass = action.payload;
    },

    setIsCreatingCustomerPass(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isCreatingCustomerPass = action.payload;
    },

    setIsVoidingCustomerPass(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isVoidingCustomerPass = action.payload;
    },

    setIsCreatingCustomerPassRedemption(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isCreatingCustomerPassRedemption = action.payload;
    },

    setIsVoidingCustomerPassRedemption(
      state: PatientPassState,
      action: PayloadAction<any>
    ) {
      state.isVoidingCustomerPassRedemption = action.payload;
    },

    setErrorMessage(state: PatientPassState, action: PayloadAction<any>) {
      state.patientPassErrorMessage = action.payload;
    },

    setSuccessMessage(state: PatientPassState, action: PayloadAction<any>) {
      state.patientPassSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listSpecificCustomerPasses =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerPasses());

    const response = await backOff(() => listPatientPassesAPI(patientId), {
      numOfAttempts: 3
    });
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listSpecificCustomerPasses(
          response.data.listPatientPasses
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerPasses());
  };

export const getCustomerPass =
  (customerPassId): AppThunk =>
  async (dispatch) => {
    const response = await getPatientPassAPI(customerPassId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getCustomerPass(response.data.getPatientPass));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const purchaseCustomerPass =
  (customerPass): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingCustomerPass());
    dispatch(startLoadingCustomerPasses());

    const response = await purchasePassAPI(customerPass);
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.purchaseCustomerPass(response.data.purchasePass));

      dispatch(slice.actions.setSuccessMessage('Pass successfully purchased'));

      return dispatch(
        listSpecificCustomerPasses(response.data.purchasePass.patientId)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingCustomerPass());
      dispatch(stopLoadingCustomerPasses());
    };

    return followUp();
  };

export const createCustomerPass =
  (customerPass): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingCustomerPass());
    dispatch(startLoadingCustomerPasses());

    const response = await createPatientPassAPI(customerPass);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createCustomerPass(response.data.createPatientPass)
      );
      dispatch(slice.actions.setSuccessMessage('Pass successfully assigned'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingCustomerPass());
      dispatch(stopLoadingCustomerPasses());
    };

    return followUp();
  };

export const updateCustomerPass =
  (customerPass): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingCustomerPass());

    const response = await updatePatientPassAPI(customerPass);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCustomerPass(response.data.updatePatientPass)
      );
      dispatch(slice.actions.setSuccessMessage('Pass successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingCustomerPass());
  };

export const voidCustomerPass =
  (customerPass): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingCustomerPass());
    dispatch(startLoadingCustomerPasses());

    const response = await voidPatientPassAPI(customerPass);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidCustomerPass(response.data.voidPatientPass));
      dispatch(slice.actions.setSuccessMessage('Pass successfully voided'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingCustomerPass());
      dispatch(stopLoadingCustomerPasses());
    };

    return followUp();
  };

export const redeemCustomerPass =
  (redeemPassInput, completeAppt = false): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingCustomerPassRedemption());
    dispatch(startEditingCustomerPass());

    const response = await redeemPatientPassAPI(redeemPassInput);
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.setSuccessMessage('Pass successfully redeemed'));

      if (completeAppt) {
        // Will need to update Appointment Status to Completed
        const apptResponse = await editAppointmentAPI({
          id: redeemPassInput.appointmentId,
          status: 'Completed',
          appointmentCompleteTime: new Date()
        });

        // console.log(apptResponse);

        dispatch(getAppointment(redeemPassInput.appointmentId));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingCustomerPassRedemption());
      dispatch(stopEditingCustomerPass());
    };

    return followUp();
  };

export const voidCustomerPassRedemption =
  (voidPassRedemptionInput): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingCustomerPassRedemption());
    dispatch(startEditingCustomerPass());

    const response = await voidPatientPassRedemptionAPI(
      voidPassRedemptionInput
    );
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.voidCustomerPassRedemption(
          response.data.voidPassRedemption
        )
      );
      dispatch(getAppointment(response.data.voidPassRedemption.appointmentId));
      dispatch(
        slice.actions.setSuccessMessage('Pass Redemption successfully voided')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopVoidingCustomerPassRedemption());
      dispatch(stopEditingCustomerPass());
    };

    return followUp();
  };

export const startLoadingCustomerPasses = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerPasses(true));
};

export const stopLoadingCustomerPasses = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerPasses(false));
};

export const startEditingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerPass(true));
};

export const stopEditingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerPass(false));
};

export const startCreatingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerPass(true));
};

export const stopCreatingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerPass(false));
};

export const startVoidingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingCustomerPass(true));
};

export const stopVoidingCustomerPass = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingCustomerPass(false));
};

export const startCreatingCustomerPassRedemption =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingCustomerPassRedemption(true));
  };

export const stopCreatingCustomerPassRedemption =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingCustomerPassRedemption(false));
  };

export const startVoidingCustomerPassRedemption =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsVoidingCustomerPassRedemption(true));
  };

export const stopVoidingCustomerPassRedemption =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsVoidingCustomerPassRedemption(false));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateProviderNoteTemplateMutation,
  UpdateProviderNoteTemplateMutation,
  DeleteProviderNoteTemplateMutation,
  GetProviderNoteTemplateQuery,
  ListProviderNoteTemplatesQuery,
  AdminCreateProviderNoteTemplateMutation,
  AdminUpdateProviderNoteTemplate,
  AdminDeleteProviderNoteTemplateMutation,
  ListGlobalProviderNoteTemplatesQuery
} from 'src/API';
import {
  getProviderNoteTemplate,
  listProviderNoteTemplates,
  listGlobalProviderNoteTemplates
} from 'src/graphql/queries';
import {
  createProviderNoteTemplate,
  updateProviderNoteTemplate,
  deleteProviderNoteTemplate,
  adminCreateProviderNoteTemplate,
  adminUpdateProviderNoteTemplate,
  adminDeleteProviderNoteTemplate
} from 'src/graphql/mutations';

export const listProviderNoteTemplatesAPI = async (providerId) => {
  try {
    const noteTemplates = await callGraphQL<ListProviderNoteTemplatesQuery>(
      listProviderNoteTemplates,
      {
        variables: {
          providerId
        }
      }
    );
    // console.log(noteTemplates);
    return noteTemplates;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getProviderNoteTemplateAPI = async (templateId) => {
  try {
    const template = await callGraphQL<GetProviderNoteTemplateQuery>(
      getProviderNoteTemplate,
      {
        variables: {
          id: templateId
        }
      }
    );
    // console.log(template);
    return template;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createProviderNoteTemplateAPI = async (templateInput) => {
  try {
    const newTemplate = await callGraphQL<CreateProviderNoteTemplateMutation>(
      createProviderNoteTemplate,
      {
        variables: {
          input: templateInput
        }
      }
    );

    return newTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateProviderNoteTemplateAPI = async (updateTemplateInput) => {
  try {
    const updatedTemplate =
      await callGraphQL<UpdateProviderNoteTemplateMutation>(
        updateProviderNoteTemplate,
        {
          variables: {
            input: updateTemplateInput
          }
        }
      );

    return updatedTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteProviderNoteTemplateAPI = async (templateId) => {
  try {
    const deletedTemplate =
      await callGraphQL<DeleteProviderNoteTemplateMutation>(
        deleteProviderNoteTemplate,
        {
          variables: {
            id: templateId
          }
        }
      );
    // console.log(deletedTemplate);
    return deletedTemplate;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreatePaymentMutation,
  VoidPaymentMutation,
  GetWalletByPatientQuery
} from 'src/API';
import {
  customGetWalletByPatient,
  customGetPatientWalletBalance
} from './queries';
import { createPayment, voidPayment } from 'src/graphql/mutations';
import { customCreatePaymentWalletTransaction } from './mutations';

export const getWalletByPatientAPI = async (patientId) => {
  try {
    const wallet = await callGraphQL<GetWalletByPatientQuery>(
      customGetWalletByPatient,
      {
        variables: {
          patientId
        }
      }
    );
    // console.log(wallet)
    return wallet;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPatientWalletBalanceAPI = async (patientId) => {
  try {
    const wallet = await callGraphQL<GetWalletByPatientQuery>(
      customGetPatientWalletBalance,
      {
        variables: {
          patientId
        }
      }
    );
    // console.log(wallet)
    return wallet;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const walletDepositAPI = async (deposit) => {
  try {
    const newPayment = await callGraphQL<CreatePaymentMutation>(
      customCreatePaymentWalletTransaction,
      {
        variables: {
          input: {
            amountPaid: deposit.amountPaid,
            mode: deposit.mode,
            patientId: deposit.patientId,
            locationId: deposit.locationId,
            invoiceId: '',
            reference: deposit.reference,
            paymentTime: deposit.paymentTime,
            type: 'TopUpWallet',
            paymentMethodId: deposit?.paymentMethodId
          }
        }
      }
    );

    // console.log(newPayment);
    return newPayment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidWalletDepositAPI = async (payment) => {
  try {
    const voidedPayment = await callGraphQL<VoidPaymentMutation>(voidPayment, {
      variables: {
        input: {
          id: payment.id,
          isRefunded: payment.isRefunded,
          voidReason: payment.voidReason
        }
      }
    });
    return voidedPayment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const walletWithdrawAPI = async (withdrawal) => {
  try {
    const newWithdrawal = await callGraphQL<CreatePaymentMutation>(
      customCreatePaymentWalletTransaction,
      {
        variables: {
          input: {
            amountPaid: withdrawal.amountPaid,
            mode: withdrawal.mode,
            patientId: withdrawal.patientId,
            locationId: withdrawal.locationId,
            invoiceId: '',
            reference: withdrawal.reference,
            paymentTime: withdrawal.paymentTime,
            type: 'WithdrawWallet'
          }
        }
      }
    );

    // console.log(newWithdrawal);
    return newWithdrawal;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

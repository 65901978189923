import { mock } from 'src/utils/axios';
import type { Appointment } from 'src/models/appointment';

let appointments: Appointment[] = [
  {
  id: '1',
  tenant_id: 'csg',
  patient_id: '1',
  location_id: '1',
  practitioner_id: '1',
  class_id: '',
  appointment_date: '2022-03-07',
  appointment_start_time: '2022-03-07 12:00:00',
  appointment_end_time: '2022-03-07 13:00:00',
  appointment_type: 'Initial Visit',
  appointment_type_id: '1',
  status_id: '1',
  status: 'Completed',
  email_reminder_sent: false,
  sms_reminder_sent: true,
  note: '',
  invoice_ids: [],
  pass_id: '',
  confirmed_at: null,
  confirmed_by: null,
  cancellation_at: null,
  cancellation_by: null,
  created_at: '2022-03-06 12:00:00',
  created_by: 'ben',
  edited_at: '2022-03-06 12:00:00',
  edited_by: 'ben',
  deleted_at: null,
  deleted_by: null
  },
  {
    id: '2',
    tenant_id: 'csg',
    patient_id: '1',
    location_id: '2',
    practitioner_id: '1',
    class_id: '',
    appointment_date: '2022-03-08',
    appointment_start_time: '2022-03-08 17:00:00',
    appointment_end_time: '2022-03-08 18:00:00',
    appointment_type: 'Follow Up',
    appointment_type_id: '1',
    status_id: '1',
    status: 'Pending',
    email_reminder_sent: true,
    sms_reminder_sent: false,
    note: '',
    invoice_ids: [],
    pass_id: '',
    confirmed_at: null,
    confirmed_by: null,
    cancellation_at: null,
    cancellation_by: null,
    created_at: '2022-03-07 13:00:00',
    created_by: 'ben',
    edited_at: '2022-03-07 13:00:00',
    edited_by: 'ben',
    deleted_at: null,
    deleted_by: null
    },
    {
      id: '3',
      tenant_id: 'csg',
      patient_id: '2',
      location_id: '1',
      practitioner_id: '4',
      class_id: '',
      appointment_date: '2022-03-11',
      appointment_start_time: '2022-03-11 17:00:00',
      appointment_end_time: '2022-03-11 18:00:00',
      appointment_type: 'Follow Up',
      appointment_type_id: '1',
      status_id: '1',
      status: 'Pending',
      email_reminder_sent: true,
      sms_reminder_sent: false,
      note: '',
      invoice_ids: [],
      pass_id: '',
      confirmed_at: null,
      confirmed_by: null,
      cancellation_at: null,
      cancellation_by: null,
      created_at: '2022-03-07 13:00:00',
      created_by: 'ben',
      edited_at: '2022-03-07 13:00:00',
      edited_by: 'ben',
      deleted_at: null,
      deleted_by: null
      },
      {
        id: '4',
        tenant_id: 'csg',
        patient_id: '4',
        location_id: '2',
        practitioner_id: '3',
        class_id: '',
        appointment_date: '2022-03-12',
        appointment_start_time: '2022-03-12 10:00:00',
        appointment_end_time: '2022-03-12 12:00:00',
        appointment_type: 'Follow Up',
        appointment_type_id: '1',
        status_id: '1',
        status: 'Confirmed',
        email_reminder_sent: true,
        sms_reminder_sent: false,
        note: '',
        invoice_ids: [],
        pass_id: '',
        confirmed_at: null,
        confirmed_by: null,
        cancellation_at: null,
        cancellation_by: null,
        created_at: '2022-03-10 13:00:00',
        created_by: 'ben',
        edited_at: '2022-03-10 13:00:00',
        edited_by: 'ben',
        deleted_at: null,
        deleted_by: null
        },
 
      
  
  
];

mock.onGet('/api/appointments').reply(() => {
  return [200, { appointments }];
});

mock.onGet('/api/appointment').reply((config) => {
  const { appointmentId } = config.params;
  const appointment = appointments.filter((_appointment) => _appointment.id === appointmentId);

  return [200, { appointment }];
});



mock.onGet('/api/patient/appointments').reply((config) => {
  const { patientId } = config.params;
  const patientAppointments = appointments.filter((_appointment) => _appointment.patient_id === patientId);

  return [200, { patientAppointments }];
});
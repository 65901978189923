export const getOnlineBookingTransactionStatusLabel = (
  transactionStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Pending: {
        text: 'Pending',
        color: 'secondary'
      },
      Confirmed: {
        text: 'Booked',
        color: 'primary'
      },
      Timeout: {
        text: 'Timeout',
        color: 'error'
      }
    };

    const { text, color }: any = map[transactionStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as patientsReducer } from 'src/slices/patients';
import { reducer as notesReducer } from 'src/slices/notes';
import { reducer as appointmentsReducer } from 'src/slices/appointments';
import { reducer as invoicesReducer } from 'src/slices/invoices';
import { reducer as servicesReducer } from 'src/slices/services';
import { reducer as walletsReducer } from 'src/slices/wallets';
import { reducer as usersReducer } from 'src/slices/users';
import { reducer as inventoriesReducer } from 'src/slices/inventories';
import { reducer as locationsReducer } from 'src/slices/locations';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as communicationsReducer } from 'src/slices/communications';
import { reducer as paymentsReducer } from 'src/slices/payments';
import { reducer as documentsReducer } from 'src/slices/documents';
import { reducer as corporatesReducer } from 'src/slices/corporates';
import { reducer as contactsReducer } from 'src/slices/contacts';
import { reducer as medicalCertsReducer } from 'src/slices/medicalcerts';
import { reducer as terminalReducer } from 'src/slices/terminal';
import { reducer as timeOffReducer } from 'src/slices/timeOff';
import { reducer as signaturesReducer } from 'src/slices/signatures';
import { reducer as onlineBookingTransactionsReducer } from 'src/slices/online_booking_transactions';
import { reducer as onlineBookingPagesReducer } from 'src/slices/online_booking_pages';
import { reducer as noticeBoardMessagesReducer } from 'src/slices/noticeboard_messages';
import { reducer as classServicesReducer } from 'src/slices/class_services';
import { reducer as classAppointmentsReducer } from 'src/slices/class_appointments';
import { reducer as passesReducer } from 'src/slices/passes';
import { reducer as patientPassesReducer } from 'src/slices/patient_passes';
import { reducer as feedbackReducer } from 'src/slices/feedback';
import { reducer as memosReducer } from 'src/slices/memos';
import { reducer as flowReducer } from 'src/slices/flow';
import { reducer as promoCodesReducer } from 'src/slices/promo_codes';
import { reducer as blocksReducer } from 'src/slices/blocks';
import { reducer as appointmentPlanTemplatesReducer } from 'src/slices/appointment_plan_templates';
import { reducer as appointmentPlansReducer } from 'src/slices/appointment_plans';
import { reducer as stationsReducer } from 'src/slices/stations';
import { reducer as creditNotesReducer } from 'src/slices/credit_notes';
import { reducer as providerNoteTemplatesReducer } from 'src/slices/provider_note_templates';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  patients: patientsReducer,
  notes: notesReducer,
  appointments: appointmentsReducer,
  invoices: invoicesReducer,
  services: servicesReducer,
  wallets: walletsReducer,
  users: usersReducer,
  inventories: inventoriesReducer,
  locations: locationsReducer,
  settings: settingsReducer,
  communications: communicationsReducer,
  payments: paymentsReducer,
  documents: documentsReducer,
  corporates: corporatesReducer,
  contacts: contactsReducer,
  medicalcerts: medicalCertsReducer,
  terminal: terminalReducer,
  timeoff: timeOffReducer,
  signatures: signaturesReducer,
  onlineBookingTransactions: onlineBookingTransactionsReducer,
  onlineBookingPages: onlineBookingPagesReducer,
  noticeBoardMessages: noticeBoardMessagesReducer,
  classServices: classServicesReducer,
  classAppointments: classAppointmentsReducer,
  passes: passesReducer,
  patientPasses: patientPassesReducer,
  feedback: feedbackReducer,
  memos: memosReducer,
  flow: flowReducer,
  promoCodes: promoCodesReducer,
  blocks: blocksReducer,
  appointmentPlanTemplates: appointmentPlanTemplatesReducer,
  appointmentPlans: appointmentPlansReducer,
  stations: stationsReducer,
  creditNotes: creditNotesReducer,
  providerNoteTemplates: providerNoteTemplatesReducer
});

export default rootReducer;

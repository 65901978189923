import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateTimeOffMutation,
  UpdateTimeOffMutation,
  DeleteTimeOffMutation,
  GetTimeOffQuery,
  ListTimeOffsQuery
} from 'src/API';
import { getTimeOff, listTimeOffs } from 'src/graphql/queries';
import {
  createTimeOff,
  updateTimeOff,
  deleteTimeOff
} from 'src/graphql/mutations';

// export const listPatientNotesAPI = async (patientId, isNewestFirst = false) => {
//   try {
//     const notes = await callGraphQL<any>(listPatientNotesQuery, {
//       variables: {
//         patientId,
//         isNewestFirst
//       }
//     });
//     // console.log(notes);
//     return notes;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };

export const listTimeOffAPI = async (
  practitionerIds,
  startTime,
  endTime,
  locationIds
) => {
  try {
    const timeOffs = await callGraphQL<ListTimeOffsQuery>(listTimeOffs, {
      variables: {
        practitionerIds,
        startTime,
        endTime,
        locationIds
      }
    });
    // console.log(timeOffs);
    return timeOffs;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listTimeOffReportAPI = async (startTime, endTime, locationIds) => {
  try {
    const timeOffs = await callGraphQL<ListTimeOffsQuery>(listTimeOffs, {
      variables: {
        startTime,
        endTime,
        locationIds
      }
    });
    // console.log(timeOffs);
    return timeOffs;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createTimeOffAPI = async ({
  practitionerId,
  title,
  description,
  eventStartTime,
  eventEndTime,
  allDay,
  colourCode,
  category,
  type,
  isRecurring,
  recurringFrequency,
  recurringEndDate,
  recurringInstances,
  locationIds
}) => {
  try {
    const newTimeOff = await callGraphQL<CreateTimeOffMutation>(createTimeOff, {
      variables: {
        input: {
          practitionerId,
          title,
          description,
          eventStartTime,
          eventEndTime,
          allDay,
          colourCode,
          category,
          type,
          isRecurring,
          recurringFrequency,
          recurringEndDate,
          recurringInstances,
          locationIds
        }
      }
    });

    return newTimeOff;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const editTimeOffAPI = async (timeOff) => {
  try {
    const updatedTimeOff = await callGraphQL<UpdateTimeOffMutation>(
      updateTimeOff,
      {
        variables: {
          input: timeOff
        }
      }
    );
    // console.log(updatedTimeOff);
    return updatedTimeOff;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteTimeOffAPI = async (timeOffId) => {
  try {
    const deletedTimeOff = await callGraphQL<DeleteTimeOffMutation>(
      deleteTimeOff,
      {
        variables: {
          id: timeOffId
        }
      }
    );
    // console.log(deletedTimeOff);
    return deletedTimeOff;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

const nationalityOptions = [
  { value: 'Singapore', label: 'SG', phone: '65' },
  { value: 'Afghanistan', label: 'AF', phone: '93' },
  { value: 'Alland Islands', label: 'AX', phone: '358' },
  { value: 'Albania', label: 'AL', phone: '355' },
  { value: 'Algeria', label: 'DZ', phone: '213' },
  { value: 'American Samoa', label: 'AS', phone: '1-684' },
  { value: 'Andorra', label: 'AD', phone: '376' },
  { value: 'Angola', label: 'AO', phone: '244' },
  { value: 'Anguilla', label: 'AI', phone: '1-264' },
  { value: 'Antarctica', label: 'AQ', phone: '672' },
  { value: 'Antigua and Barbuda', label: 'AG', phone: '1-268' },
  { value: 'Argentina', label: 'AR', phone: '54' },
  { value: 'Armenia', label: 'AM', phone: '374' },
  { value: 'Aruba', label: 'AW', phone: '297' },
  { value: 'Australia', label: 'AU', phone: '61' },
  { value: 'Austria', label: 'AT', phone: '43' },
  { value: 'Azerbaijan', label: 'AZ', phone: '994' },
  { value: 'Bahamas', label: 'BS', phone: '1-242' },
  { value: 'Bahrain', label: 'BH', phone: '973' },
  { value: 'Bangladesh', label: 'BD', phone: '880' },
  { value: 'Barbados', label: 'BB', phone: '1-246' },
  { value: 'Belarus', label: 'BY', phone: '375' },
  { value: 'Belgium', label: 'BE', phone: '32' },
  { value: 'Belize', label: 'BZ', phone: '501' },
  { value: 'Benin', label: 'BJ', phone: '229' },
  { value: 'Bermuda', label: 'BM', phone: '1-441' },
  { value: 'Bhutan', label: 'BT', phone: '975' },
  { value: 'Bolivia', label: 'BO', phone: '591' },
  { value: 'Bosnia and Herzegovina', label: 'BA', phone: '387' },
  { value: 'Botswana', label: 'BW', phone: '267' },
  { value: 'Bouvet Island', label: 'BV', phone: '47' },
  { value: 'Brazil', label: 'BR', phone: '55' },
  {
    value: 'British Indian Ocean Territory',
    label: 'IO',
    phone: '246'
  },
  { value: 'British Virgin Islands', label: 'VG', phone: '1-284' },
  { value: 'Brunei Darussalam', label: 'BN', phone: '673' },
  { value: 'Bulgaria', label: 'BG', phone: '359' },
  { value: 'Burkina Faso', label: 'BF', phone: '226' },
  { value: 'Burundi', label: 'BI', phone: '257' },
  { value: 'Cambodia', label: 'KH', phone: '855' },
  { value: 'Cameroon', label: 'CM', phone: '237' },
  { value: 'Canada', label: 'CA', phone: '01' },
  { value: 'Cape Verde', label: 'CV', phone: '238' },
  { value: 'Cayman Islands', label: 'KY', phone: '1-345' },
  { value: 'Central African Republic', label: 'CF', phone: '236' },
  { value: 'Chad', label: 'TD', phone: '235' },
  { value: 'Chile', label: 'CL', phone: '56' },
  { value: 'China', label: 'CN', phone: '86' },
  { value: 'Christmas Island', label: 'CX', phone: '61' },
  { value: 'Cocos (Keeling) Islands', label: 'CC', phone: '61' },
  { value: 'Colombia', label: 'CO', phone: '57' },
  { value: 'Comoros', label: 'KM', phone: '269' },
  {
    value: 'Congo, Democratic Republic of the',
    label: 'CG',
    phone: '243'
  },
  {
    value: 'Congo, Republic of the',
    label: 'CD',
    phone: '242'
  },
  { value: 'Cook Islands', label: 'CK', phone: '682' },
  { value: 'Costa Rica', label: 'CR', phone: '506' },
  { value: "Cote d'Ivoire", label: 'CI', phone: '225' },
  { value: 'Croatia', label: 'HR', phone: '385' },
  { value: 'Cuba', label: 'CU', phone: '53' },
  { value: 'Curacao', label: 'CW', phone: '599' },
  { value: 'Cyprus', label: 'CY', phone: '357' },
  { value: 'Czech Republic', label: 'CZ', phone: '420' },
  { value: 'Denmark', label: 'DK', phone: '45' },
  { value: 'Djibouti', label: 'DJ', phone: '253' },
  { value: 'Dominica', label: 'DM', phone: '1-767' },
  { value: 'Dominican Republic', label: 'DO', phone: '1-809' },
  { value: 'Ecuador', label: 'EC', phone: '593' },
  { value: 'Egypt', label: 'EG', phone: '20' },
  { value: 'El Salvador', label: 'SV', phone: '503' },
  { value: 'Equatorial Guinea', label: 'GQ', phone: '240' },
  { value: 'Eritrea', label: 'ER', phone: '291' },
  { value: 'Estonia', label: 'EE', phone: '372' },
  { value: 'Ethiopia', label: 'ET', phone: '251' },
  { value: 'Falkland Islands (Malvinas)', label: 'FK', phone: '500' },
  { value: 'Faroe Islands', label: 'FO', phone: '298' },
  { value: 'Fiji', label: 'FJ', phone: '679' },
  { value: 'Finland', label: 'FI', phone: '358' },
  { value: 'France', label: 'FR', phone: '33' },
  { value: 'French Guiana', label: 'GF', phone: '594' },
  { value: 'French Polynesia', label: 'PF', phone: '689' },
  { value: 'French Southern Territories', label: 'TF', phone: '262' },
  { value: 'Gabon', label: 'GA', phone: '241' },
  { value: 'Gambia', label: 'GM', phone: '220' },
  { value: 'Georgia', label: 'GE', phone: '995' },
  { value: 'Germany', label: 'DE', phone: '49' },
  { value: 'Ghana', label: 'GH', phone: '233' },
  { value: 'Gibraltar', label: 'GI', phone: '350' },
  { value: 'Greece', label: 'GR', phone: '30' },
  { value: 'Greenland', label: 'GL', phone: '299' },
  { value: 'Grenada', label: 'GD', phone: '1-473' },
  { value: 'Guadeloupe', label: 'GP', phone: '590' },
  { value: 'Guam', label: 'GU', phone: '1-671' },
  { value: 'Guatemala', label: 'GT', phone: '502' },
  { value: 'Guernsey', label: 'GG', phone: '44' },
  { value: 'Guinea-Bissau', label: 'GW', phone: '245' },
  { value: 'Guinea', label: 'GN', phone: '224' },
  { value: 'Guyana', label: 'GY', phone: '592' },
  { value: 'Haiti', label: 'HT', phone: '509' },
  {
    value: 'Heard Island and McDonald Islands',
    label: 'HM',
    phone: '672'
  },
  {
    value: 'Holy See (Vatican City State)',
    label: 'VA',
    phone: '379'
  },
  { value: 'Honduras', label: 'HN', phone: '504' },
  { value: 'Hong Kong', label: 'HK', phone: '852' },
  { value: 'Hungary', label: 'HU', phone: '36' },
  { value: 'Iceland', label: 'IS', phone: '354' },
  { value: 'India', label: 'IN', phone: '91' },
  { value: 'Indonesia', label: 'ID', phone: '62' },
  { value: 'Iran, Islamic Republic of', label: 'IR', phone: '98' },
  { value: 'Iraq', label: 'IQ', phone: '964' },
  { value: 'Ireland', label: 'IE', phone: '353' },
  { value: 'Isle of Man', label: 'IM', phone: '44' },
  { value: 'Israel', label: 'IL', phone: '972' },
  { value: 'Italy', label: 'IT', phone: '39' },
  { value: 'Jamaica', label: 'JM', phone: '1-876' },
  { value: 'Japan', label: 'JP', phone: '81' },
  { value: 'Jersey', label: 'JE', phone: '44' },
  { value: 'Jordan', label: 'JO', phone: '962' },
  { value: 'Kazakhstan', label: 'KZ', phone: '7' },
  { value: 'Kenya', label: 'KE', phone: '254' },
  { value: 'Kiribati', label: 'KI', phone: '686' },
  {
    value: "Korea, Democratic People's Republic of",
    label: 'KP',
    phone: '850'
  },
  { value: 'Korea, Republic of', label: 'KR', phone: '82' },
  { value: 'Kosovo', label: 'XK', phone: '383' },
  { value: 'Kuwait', label: 'KW', phone: '965' },
  { value: 'Kyrgyzstan', label: 'KG', phone: '996' },
  {
    value: "Lao People's Democratic Republic",
    label: 'LA',
    phone: '856'
  },
  { value: 'Latvia', label: 'LV', phone: '371' },
  { value: 'Lebanon', label: 'LB', phone: '961' },
  { value: 'Lesotho', label: 'LS', phone: '266' },
  { value: 'Liberia', label: 'LR', phone: '231' },
  { value: 'Libya', label: 'LY', phone: '218' },
  { value: 'Liechtenstein', label: 'LI', phone: '423' },
  { value: 'Lithuania', label: 'LT', phone: '370' },
  { value: 'Luxembourg', label: 'LU', phone: '352' },
  { value: 'Macao', label: 'MO', phone: '853' },
  {
    value: 'Macedonia, the Former Yugoslav Republic of',
    label: 'MK',
    phone: '389'
  },
  { value: 'Madagascar', label: 'MG', phone: '261' },
  { value: 'Malawi', label: 'MW', phone: '265' },
  { value: 'Malaysia', label: 'MY', phone: '60' },
  { value: 'Maldives', label: 'MV', phone: '960' },
  { value: 'Mali', label: 'ML', phone: '223' },
  { value: 'Malta', label: 'MT', phone: '356' },
  { value: 'Marshall Islands', label: 'MH', phone: '692' },
  { value: 'Martinique', label: 'MQ', phone: '596' },
  { value: 'Mauritania', label: 'MR', phone: '222' },
  { value: 'Mauritius', label: 'MU', phone: '230' },
  { value: 'Mayotte', label: 'YT', phone: '262' },
  { value: 'Mexico', label: 'MX', phone: '52' },
  {
    value: 'Micronesia, Federated States of',
    label: 'FM',
    phone: '691'
  },
  { value: 'Moldova, Republic of', label: 'MD', phone: '373' },
  { value: 'Monaco', label: 'MC', phone: '377' },
  { value: 'Mongolia', label: 'MN', phone: '976' },
  { value: 'Montenegro', label: 'ME', phone: '382' },
  { value: 'Montserrat', label: 'MS', phone: '1-664' },
  { value: 'Morocco', label: 'MA', phone: '212' },
  { value: 'Mozambique', label: 'MZ', phone: '258' },
  { value: 'Myanmar', label: 'MM', phone: '95' },
  { value: 'Namibia', label: 'NA', phone: '264' },
  { value: 'Nauru', label: 'NR', phone: '674' },
  { value: 'Nepal', label: 'NP', phone: '977' },
  { value: 'Netherlands', label: 'NL', phone: '31' },
  { value: 'New Caledonia', label: 'NC', phone: '687' },
  { value: 'New Zealand', label: 'NZ', phone: '64' },
  { value: 'Nicaragua', label: 'NI', phone: '505' },
  { value: 'Niger', label: 'NE', phone: '227' },
  { value: 'Nigeria', label: 'NG', phone: '234' },
  { value: 'Niue', label: 'NU', phone: '683' },
  { value: 'Norfolk Island', label: 'NF', phone: '672' },
  { value: 'Northern Mariana Islands', label: 'MP', phone: '1-670' },
  { value: 'Norway', label: 'NO', phone: '47' },
  { value: 'Oman', label: 'OM', phone: '968' },
  { value: 'Pakistan', label: 'PK', phone: '92' },
  { value: 'Palau', label: 'PW', phone: '680' },
  { value: 'Palestine, State of', label: 'PS', phone: '970' },
  { value: 'Panama', label: 'PA', phone: '507' },
  { value: 'Papua New Guinea', label: 'PG', phone: '675' },
  { value: 'Paraguay', label: 'PY', phone: '595' },
  { value: 'Peru', label: 'PE', phone: '51' },
  { value: 'Philippines', label: 'PH', phone: '63' },
  { value: 'Pitcairn', label: 'PN', phone: '870' },
  { value: 'Poland', label: 'PL', phone: '48' },
  { value: 'Portugal', label: 'PT', phone: '351' },
  { value: 'Puerto Rico', label: 'PR', phone: '1' },
  { value: 'Qatar', label: 'QA', phone: '974' },
  { value: 'Reunion', label: 'RE', phone: '262' },
  { value: 'Romania', label: 'RO', phone: '40' },
  { value: 'Russian Federation', label: 'RU', phone: '7' },
  { value: 'Rwanda', label: 'RW', phone: '250' },
  { value: 'Saint Barthelemy', label: 'BL', phone: '590' },
  { value: 'Saint Helena', label: 'SH', phone: '290' },
  { value: 'Saint Kitts and Nevis', label: 'KN', phone: '1-869' },
  { value: 'Saint Lucia', label: 'LC', phone: '1-758' },
  { value: 'Saint Martin (French part)', label: 'MF', phone: '590' },
  { value: 'Saint Pierre and Miquelon', label: 'PM', phone: '508' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'VC',
    phone: '1-784'
  },
  { value: 'Samoa', label: 'WS', phone: '685' },
  { value: 'San Marino', label: 'SM', phone: '378' },
  { value: 'Sao Tome and Principe', label: 'ST', phone: '239' },
  { value: 'Saudi Arabia', label: 'SA', phone: '966' },
  { value: 'Senegal', label: 'SN', phone: '221' },
  { value: 'Serbia', label: 'RS', phone: '381' },
  { value: 'Seychelles', label: 'SC', phone: '248' },
  { value: 'Sierra Leone', label: 'SL', phone: '232' },

  { value: 'Sint Maarten (Dutch part)', label: 'SX', phone: '1-721' },
  { value: 'Slovakia', label: 'SK', phone: '421' },
  { value: 'Slovenia', label: 'SI', phone: '386' },
  { value: 'Solomon Islands', label: 'SB', phone: '677' },
  { value: 'Somalia', label: 'SO', phone: '252' },
  { value: 'South Africa', label: 'ZA', phone: '27' },
  {
    value: 'South Georgia and the South Sandwich Islands',
    label: 'GS',
    phone: '500'
  },
  { value: 'South Sudan', label: 'SS', phone: '211' },
  { value: 'Spain', label: 'ES', phone: '34' },
  { value: 'Sri Lanka', label: 'LK', phone: '94' },
  { value: 'Sudan', label: 'SD', phone: '249' },
  { value: 'Surivalue', label: 'SR', phone: '597' },
  { value: 'Svalbard and Jan Mayen', label: 'SJ', phone: '47' },
  { value: 'Swaziland', label: 'SZ', phone: '268' },
  { value: 'Sweden', label: 'SE', phone: '46' },
  { value: 'Switzerland', label: 'CH', phone: '41' },
  { value: 'Syrian Arab Republic', label: 'SY', phone: '963' },
  { value: 'Taiwan, Province of China', label: 'TW', phone: '886' },
  { value: 'Tajikistan', label: 'TJ', phone: '992' },
  { value: 'Thailand', label: 'TH', phone: '66' },
  { value: 'Timor-Leste', label: 'TL', phone: '670' },
  { value: 'Togo', label: 'TG', phone: '228' },
  { value: 'Tokelau', label: 'TK', phone: '690' },
  { value: 'Tonga', label: 'TO', phone: '676' },
  { value: 'Trinidad and Tobago', label: 'TT', phone: '1-868' },
  { value: 'Tunisia', label: 'TN', phone: '216' },
  { value: 'Turkey', label: 'TR', phone: '90' },
  { value: 'Turkmenistan', label: 'TM', phone: '993' },
  { value: 'Turks and Caicos Islands', label: 'TC', phone: '1-649' },
  { value: 'Tuvalu', label: 'TV', phone: '688' },
  { value: 'Uganda', label: 'UG', phone: '256' },
  { value: 'Ukraine', label: 'UA', phone: '380' },
  { value: 'United Arab Emirates', label: 'AE', phone: '971' },
  { value: 'United Kingdom', label: 'GB', phone: '44' },
  { value: 'United Republic of Tanzania', label: 'TZ', phone: '255' },
  { value: 'United States', label: 'US', phone: '1' },
  { value: 'Uruguay', label: 'UY', phone: '598' },
  { value: 'US Virgin Islands', label: 'VI', phone: '1-340' },
  { value: 'Uzbekistan', label: 'UZ', phone: '998' },
  { value: 'Vanuatu', label: 'VU', phone: '678' },
  { value: 'Venezuela', label: 'VE', phone: '58' },
  { value: 'Vietnam', label: 'VN', phone: '84' },
  { value: 'Wallis and Futuna', label: 'WF', phone: '681' },
  { value: 'Western Sahara', label: 'EH', phone: '212' },
  { value: 'Yemen', label: 'YE', phone: '967' },
  { value: 'Zambia', label: 'ZM', phone: '260' },
  { value: 'Zimbabwe', label: 'ZW', phone: '263' },
  { value: '', label: '', phone: '' }
];

export default nationalityOptions;

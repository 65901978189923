export const getInventorySectionsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    inventoryDetails: true,
    inventoryDosage: true
  };

  try {
    const inventorySectionsToShow = {
      cityosteophysio: {
        inventoryDetails: true,
        inventoryDosage: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        inventoryDetails: true,
        inventoryDosage: true
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        inventoryDetails: true,
        inventoryDosage: false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // csg
        inventoryDetails: true,
        inventoryDosage: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        inventoryDetails: true,
        inventoryDosage: true
      }
    };

    const result = inventorySectionsToShow[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

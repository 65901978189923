import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { CustomerMemo } from '../API';

import {
  listAllCustomerMemosAPI,
  deleteCustomerMemoAPI,
  createCustomerMemoAPI,
  getCustomerMemoAPI
} from '../content/Memos/api';

interface MemoState {
  customerMemos: CustomerMemo[];
  // locationMemos: CustomerMemo[];
  memoDetail: any[];
  memoErrorMessage: string;
  memoSuccessMessage: string;
}

const initialState: MemoState = {
  customerMemos: [],
  // locationMemos: [],
  memoDetail: [],
  memoErrorMessage: '',
  memoSuccessMessage: ''
};

const sortMemosDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'memos',
  initialState,
  reducers: {
    listAllCustomerMemos(state: MemoState, action: PayloadAction<any>) {
      let memos = [...action.payload];
      memos.sort(sortMemosDesc);

      state.customerMemos = [...memos];
    },

    // not needed until we implement location memos state variable
    // listMemosByCustomerLocation(state: MemoState, action: PayloadAction<any>) {
    //   let memos = [...action.payload];
    //   // memos.sort(sortMemosDesc);

    //   state.locationMemos = [...memos];
    // },

    getCustomerMemo(state: MemoState, action: PayloadAction<any>) {
      if (action.payload) {
        state.memoDetail = [action.payload];
      } else {
        state.memoDetail = [{ id: 'Not Found' }];
      }
    },

    createCustomerMemo(state: MemoState, action: PayloadAction<any>) {
      const newMemo = action.payload;

      state.customerMemos = [newMemo, ...state.customerMemos];
    },

    deleteCustomerMemo(state: MemoState, action: PayloadAction<any>) {
      // remove memo within customer feedback list
      let memoIndex = state.customerMemos.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (memoIndex >= 0) {
        const newList = [...state.customerMemos];
        newList.splice(memoIndex, 1);
        state.customerMemos = [...newList];
      } else {
        state.customerMemos = [...state.customerMemos];
      }

      // not needed until we implement location memos state variable
      // let memoLocIndex = state.locationMemos.findIndex(
      //   (obj) => obj.id === action.payload.id
      // );

      // if (memoLocIndex >= 0) {
      //   const newList = [...state.locationMemos];
      //   newList.splice(memoLocIndex, 1);
      //   state.locationMemos = [...newList];
      // } else {
      //   state.locationMemos = [...state.locationMemos];
      // }
    },

    clearCustomerMemos(state: MemoState, action: PayloadAction<any>) {
      state.customerMemos = [];
    },

    setErrorMessage(state: MemoState, action: PayloadAction<any>) {
      state.memoErrorMessage = action.payload;
    },

    setSuccessMessage(state: MemoState, action: PayloadAction<any>) {
      state.memoSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listAllCustomerMemos =
  (startTime, endTime, customerId): AppThunk =>
  async (dispatch) => {
    const response = await backOff(
      () => listAllCustomerMemosAPI(startTime, endTime, customerId),
      {
        numOfAttempts: 3
      }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listAllCustomerMemos(response.data.listCustomerMemos)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getCustomerMemo =
  (customerMemoId): AppThunk =>
  async (dispatch) => {
    const response = await getCustomerMemoAPI(customerMemoId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getCustomerMemo(response.data.getCustomerMemo));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const createCustomerMemo =
  (customerMemo): AppThunk =>
  async (dispatch) => {
    const response = await createCustomerMemoAPI(customerMemo);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createCustomerMemo(response.data.createCustomerMemo)
      );
      dispatch(slice.actions.setSuccessMessage('Memo successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const editCustomerMemo =
  (newCustomerMemo, oldCustomerMemoId): AppThunk =>
  async (dispatch) => {
    const delOldMemoResponse = await deleteCustomerMemoAPI(oldCustomerMemoId);

    if (!delOldMemoResponse.customErrorMessage) {
      dispatch(
        slice.actions.deleteCustomerMemo(
          delOldMemoResponse.data.deleteCustomerMemo
        )
      );

      const createNewMemoResponse = await createCustomerMemoAPI(
        newCustomerMemo
      );

      if (!createNewMemoResponse.customErrorMessage) {
        dispatch(
          slice.actions.createCustomerMemo(
            createNewMemoResponse.data.createCustomerMemo
          )
        );
      } else {
        dispatch(
          slice.actions.setErrorMessage(
            createNewMemoResponse.customErrorMessage
          )
        );
      }
    } else {
      dispatch(
        slice.actions.setErrorMessage(delOldMemoResponse.customErrorMessage)
      );
    }
  };

export const deleteCustomerMemo =
  (customerMemoId): AppThunk =>
  async (dispatch) => {
    const response = await deleteCustomerMemoAPI(customerMemoId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteCustomerMemo(response.data.deleteCustomerMemo)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearCustomerMemos = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCustomerMemos({}));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

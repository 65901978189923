const apiErrorHandler = (error) => {
  let errorMessage = '';

  console.log(error);

  try {
    if (
      error?.errors &&
      error?.errors?.[0]?.message?.toLowerCase()?.includes('not authorized')
    ) {
      errorMessage =
        'You do not have enough privileges to perform this action. Please check with your administrator.';
    } else if (
      (error?.errors &&
        error?.errors?.[0]?.message
          ?.toLowerCase()
          .includes('exceeds outstanding amount')) ||
      error?.errors?.[0]?.message
        ?.toLowerCase()
        ?.includes('less than allocation')
    ) {
      errorMessage =
        'Error, invoice is outdated. Please refresh the page and try again';
    } else if (error?.errors) {
      console.log('Named Error:');
      errorMessage = error?.errors?.[0]?.message;
    } else {
      try {
        if (error?.toLowerCase()?.includes('no current user')) {
          location.reload();
        }
      } catch (e) {
        // console.log('Unexpected failure: routing to login page');
        console.log(window.location.href);
        const regex = /^(https?:\/\/[^/]+)/;

        const currentUrl = window.location.href;

        const newUrl = currentUrl.match(regex)[1];
        // window.location.href = newUrl;
        // window.location.href = 'https://app.gethelm.io/';
      }

      console.log('Unknown Error:');
      errorMessage =
        'You do not have enough privileges to perform this action. Please check with your administrator.';
      location.reload();
    }
  } catch (e) {
    console.log(e);
  }

  // console.log(errorMessage);
  return errorMessage;
};

export default apiErrorHandler;

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateOnlineBookingTransactionMutation,
  GetOnlineBookingTransactionQuery,
  ListOnlineBookingTransactionsQuery
} from 'src/API';
import { createOnlineBookingTransaction } from 'src/graphql/mutations';
import {
  getOnlineBookingTransaction,
  listOnlineBookingTransactions
} from 'src/graphql/queries';
import {
  customGetOnlineBookingTransaction,
  customListOnlineBookingTransactions,
  customListCustomerOnlineBookingTransactions
} from './queries';

export const createOnlineBookingTransactionAPI = async (
  onlineBookingTransaction
) => {
  try {
    const newTransaction =
      await callGraphQL<CreateOnlineBookingTransactionMutation>(
        createOnlineBookingTransaction,
        {
          variables: {
            input: onlineBookingTransaction
          }
        }
      );

    return newTransaction;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getOnlineBookingTransactionAPI = async (
  onlineBookingTransactionId
) => {
  try {
    const onlineBookingTransaction =
      await callGraphQL<GetOnlineBookingTransactionQuery>(
        customGetOnlineBookingTransaction,
        {
          variables: {
            id: onlineBookingTransactionId
          }
        }
      );

    return onlineBookingTransaction;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listOnlineBookingTransactionsAPI = async (
  locationIds,
  startTime?,
  endTime?
) => {
  try {
    const onlineBookingTransactions =
      await callGraphQL<ListOnlineBookingTransactionsQuery>(
        customListOnlineBookingTransactions,
        {
          variables: {
            locationIds,
            startTime,
            endTime
          }
        }
      );
    // console.log(onlineBookingTransactions);
    return onlineBookingTransactions;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCustomerOnlineBookingTransactionsAPI = async (
  customerId,
  startTime?,
  endTime?
) => {
  try {
    const onlineBookingTransactions =
      await callGraphQL<ListOnlineBookingTransactionsQuery>(
        customListCustomerOnlineBookingTransactions,
        {
          variables: {
            patientId: customerId,
            startTime: startTime,
            endTime: endTime
          }
        }
      );
    // console.log(onlineBookingTransactions);
    return onlineBookingTransactions;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const SettingsIndex = Loader(lazy(() => import('src/content/Settings')));
const AccountSettings = Loader(
  lazy(() => import('src/content/Settings/AccountSettings'))
);

const settingsRoutes = [
  {
    path: '/',
    element: <Navigate to="system/general" replace />
  },
  {
    path: 'system/:tabName',
    element: <SettingsIndex />
  }
  // {
  //   path: '/googlecalendar',
  //   element: <GoogleCalIntegration />
  // },

  // {
  //   path: ':patientId',
  //   element: <SinglePatient />
  // },

  // {
  //   path: 'users',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <Users />
  //     },
  //     {
  //       path: 'single',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="1" replace />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     }
  //   ]
  // },
];

export default settingsRoutes;

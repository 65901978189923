import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateCustomerMemoMutation,
  GetCustomerMemoQuery,
  ListCustomerMemosQuery,
  DeleteCustomerMemoMutation
} from 'src/API';
import { createCustomerMemo, deleteCustomerMemo } from 'src/graphql/mutations';
import {
  // listCustomerMemos,
  getCustomerMemo
} from 'src/graphql/queries';

import { listAllCustomerMemos } from './queries';

// let tempMemoList = [
//   {
//     id: '869b3502-9ac1-4607-a82b-0f803cf0b0c5',
//     customerId: 'a18b6904-54dc-4a79-ba71-2f59272b3c7e',
//     content: 'First comment',
//     // username: 'Jack',
//     parentId: null,
//     createdAt: '2021-08-16T23:00:33.010+02:00',
//     createdBy: 'b4ee474b-d781-49c4-9ace-c97da63d39c7',
//     locationId: 'cf4cbd04-712e-42e8-bc38-2eaf65bef4fd'
//   },
//   {
//     id: 'b02442b5-111e-4146-b797-1c27ee419445',
//     customerId: 'a18b6904-54dc-4a79-ba71-2f59272b3c7e',
//     content: 'Second comment',
//     // username: 'John',
//     parentId: null,
//     createdAt: '2021-08-16T23:00:33.010+02:00',
//     createdBy: '18496917-5cd6-4420-bc7b-c8eefca616e8',
//     locationId: 'e67a0b00-2435-4a7a-b634-7d446578b7a9'
//   },
//   {
//     id: '4d22722f-97a5-42e3-a249-262c35a10a34',
//     customerId: 'a18b6904-54dc-4a79-ba71-2f59272b3c7e',
//     content: 'First comment first child',
//     // username: 'John',
//     parentId: '869b3502-9ac1-4607-a82b-0f803cf0b0c5',
//     createdAt: '2021-08-16T23:00:33.010+02:00',
//     createdBy: '18496917-5cd6-4420-bc7b-c8eefca616e8',
//     locationId: 'e67a0b00-2435-4a7a-b634-7d446578b7a9'
//   },
//   {
//     id: '94f56a00-1bda-4e2c-ad1e-4f8e3629d0e0',
//     customerId: 'a18b6904-54dc-4a79-ba71-2f59272b3c7e',
//     content: 'Second comment second child',
//     // username: 'John',
//     parentId: 'b02442b5-111e-4146-b797-1c27ee419445',
//     createdAt: '2021-08-16T23:00:33.010+02:00',
//     createdBy: 'b4ee474b-d781-49c4-9ace-c97da63d39c7',
//     locationId: 'cf4cbd04-712e-42e8-bc38-2eaf65bef4fd'
//   }
// ];

export const listAllCustomerMemosAPI = async (
  startTime,
  endTime,
  customerId
) => {
  try {
    const customerMemos = await callGraphQL<ListCustomerMemosQuery>(
      listAllCustomerMemos,
      {
        variables: {
          startTime,
          endTime,
          customerId
        }
      }
    );

    return customerMemos;

    // return tempMemoList;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getCustomerMemoAPI = async (customerMemoId) => {
  try {
    const customerMemo = await callGraphQL<GetCustomerMemoQuery>(
      getCustomerMemo,
      {
        variables: {
          customerMemoId
        }
      }
    );

    return customerMemo;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createCustomerMemoAPI = async (customerMemo) => {
  try {
    const newCustomerMemo = await callGraphQL<CreateCustomerMemoMutation>(
      createCustomerMemo,
      {
        variables: {
          input: customerMemo
        }
      }
    );

    return newCustomerMemo;

    // to remove after setting up the store
    // const newTempMemoList = [customerMemo, ...tempMemoList];
    // tempMemoList = [...newTempMemoList];

    // return customerMemo;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// No update mutation for now
// export const updateCustomerMemoAPI = async (customerMemo) => {
//   try {
//     const updatedCustomerMemo = await callGraphQL<UpdateCustomerMemoMutation>(
//       updatedCustomerMemo,
//       {
//         variables: {
//           input: customerMemo
//         }
//       }
//     );

//     return updatedCustomerMemo;

//     // to remove after setting up the store
//     // const newTempMemoList = tempMemoList?.filter(
//     //   (memo) => memo.id !== customerMemo?.id
//     // );
//     // tempMemoList = [customerMemo, ...newTempMemoList];

//     // return customerMemo;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

export const deleteCustomerMemoAPI = async (customerMemoId) => {
  try {
    const deletedCustomerMemo = await callGraphQL<DeleteCustomerMemoMutation>(
      deleteCustomerMemo,
      {
        variables: {
          id: customerMemoId
        }
      }
    );

    return deletedCustomerMemo;

    // to remove after setting up the store
    // const newTempMemoList = tempMemoList?.filter(
    //   (memo) => memo.id !== customerMemoId
    // );
    // tempMemoList = [...newTempMemoList];

    // return {};
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

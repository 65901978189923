import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Patients = Loader(lazy(() => import('src/content/Patients')));
const SingleEditPatient = Loader(
  lazy(() => import('src/content/TerminalApp/IntakeApp/TerminalFormSelector'))
);

const PatientIntake = Loader(
  lazy(() => import('src/content/TerminalApp/IntakeApp'))
);

const terminalRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <PatientIntake />
  },
  // {
  //   path: ':patientId',
  //   element: <SinglePatient />
  // },

  {
    path: 'patient',
    children: [
      {
        path: ':patientId',
        element: <SingleEditPatient />
      },
      {
        path: ':patientId/:tabName',
        element: <SingleEditPatient />
      }
    ]
  },

  {
    path: 'customer',
    children: [
      {
        path: ':patientId',
        element: <SingleEditPatient />
      },
      {
        path: ':patientId/:tabName',
        element: <SingleEditPatient />
      }
    ]
  }
];

export default terminalRoutes;

// this is promo code api index file

import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreatePromoCodeMutation,
  UpdatePromoCodeMutation,
  DeletePromoCodeMutation,
  GetPromoCodeQuery,
  ListPromoCodesQuery,
  ListPromoCodeRedemptionsQuery,
  VoidPromoCodeRedemptionMutation
} from 'src/API';
// import { getPromoCode, listPromoCodes } from 'src/graphql/queries';
import {
  createPromoCode
  // updatePromoCode,
  // deletePromoCode,
} from 'src/graphql/mutations';
import {
  customGetPromoCode,
  customListPromoCodes,
  customListPromoCodeRedemptions,
  customListCustomerPromoCodeRedemptions
} from './queries';
import {
  customCreatePromoCode,
  customUpdatePromoCode,
  customDeletePromoCode,
  customVoidPromoCodeRedemption
} from './mutations';

export const listPromoCodesAPI = async (
  validStartTime,
  validEndTime
  // locationId,
  // startTime,
  // endTime,
  // contactId = '',
  // corporateId = ''
) => {
  try {
    const promoCodes = await callGraphQL<ListPromoCodesQuery>(
      customListPromoCodes,
      {
        variables: {
          validStartTime,
          validEndTime
          // locationId,
          // startTime,
          // endTime,
          // contactId,
          // corporateId
        }
      }
    );
    // console.log(promoCodes);
    return promoCodes;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getPromoCodeAPI = async (promoCodeId) => {
  try {
    const promoCode = await callGraphQL<GetPromoCodeQuery>(customGetPromoCode, {
      variables: {
        id: promoCodeId
      }
    });
    // console.log(promoCode);
    return promoCode;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createPromoCodeAPI = async (promoCodeInput) => {
  try {
    const newPromoCode = await callGraphQL<CreatePromoCodeMutation>(
      customCreatePromoCode,
      {
        variables: {
          input: promoCodeInput
        }
      }
    );

    return newPromoCode;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePromoCodeAPI = async (updatePromoCodeInput) => {
  try {
    const updatedPromoCode = await callGraphQL<UpdatePromoCodeMutation>(
      customUpdatePromoCode,
      {
        variables: {
          input: updatePromoCodeInput
        }
      }
    );

    return updatedPromoCode;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deletePromoCodeAPI = async (promoCodeId) => {
  try {
    const deletedPromoCode = await callGraphQL<DeletePromoCodeMutation>(
      customDeletePromoCode,
      {
        variables: {
          id: promoCodeId
        }
      }
    );
    // console.log(deletedPromoCode);
    return deletedPromoCode;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPromoCodeRedemptionsAPI = async (
  promoCodeId,
  redemptionStartTime,
  redemptionEndTime
) => {
  try {
    const promoCodeRedemptions =
      await callGraphQL<ListPromoCodeRedemptionsQuery>(
        customListPromoCodeRedemptions,
        {
          variables: {
            promoCodeId,
            redemptionStartTime,
            redemptionEndTime
          }
        }
      );
    // console.log(promoCodeRedemptions);
    return promoCodeRedemptions;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCustomerPromoCodeRedemptionsAPI = async (
  promoCodeId,
  customerId,
  redemptionStartTime,
  redemptionEndTime
) => {
  try {
    const promoCodeRedemptions =
      await callGraphQL<ListPromoCodeRedemptionsQuery>(
        customListCustomerPromoCodeRedemptions,
        {
          variables: {
            promoCodeId,
            customerId,
            redemptionStartTime,
            redemptionEndTime
          }
        }
      );
    // console.log(promoCodeRedemptions);
    return promoCodeRedemptions;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidPromoCodeRedemptionAPI = async (voidPromoCodeRedemption) => {
  try {
    const voidedPromoCodeRedemption =
      await callGraphQL<VoidPromoCodeRedemptionMutation>(
        customVoidPromoCodeRedemption,
        {
          variables: {
            id: voidPromoCodeRedemption.id,
            reason: voidPromoCodeRedemption.reason
          }
        }
      );
    // console.log(deletedPromoCode);
    return voidedPromoCodeRedemption;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

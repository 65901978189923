import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetServiceQuery,
  ListServicesQuery,
  DeleteServiceMutation,
  CreateServiceMutation,
  UpdateServiceMutation,
  GetResourceScheduleQuery,
  GetResourceScheduleSlotsQuery,
  PutResourceScheduleDayMutation,
  UpsertResourceScheduleMutation,
  GetResourceSchedulesInRangeQuery
} from 'src/API';
// import { listServices } from 'src/graphql/queries';
import {
  createService,
  deleteService,
  updateService,
  putResourceScheduleDay,
  upsertResourceSchedule
} from 'src/graphql/mutations';
import {
  getService,
  getResourceSchedule,
  getResourceScheduleSlots
} from 'src/graphql/queries';
import {
  listAvailableServicesQuery,
  customGetResourceScheduleQuery,
  customGetResourceSchedulesInRange
} from './queries';
import {
  customPutResourceScheduleDay,
  customUpsertResourceSchedule
} from './mutations';

export const createServiceAPI = async (service) => {
  try {
    const newService = await callGraphQL<CreateServiceMutation>(createService, {
      variables: {
        input: service
      }
    });

    // console.log(newService);
    return newService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getServiceAPI = async (serviceId) => {
  try {
    const service = await callGraphQL<GetServiceQuery>(getService, {
      variables: {
        id: serviceId
      }
    });

    // console.log(service);
    return service;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAvailableServicesAPI = async (
  locationId = '',
  practitionerId = ''
) => {
  try {
    const services = await callGraphQL<ListServicesQuery>(
      listAvailableServicesQuery,
      {
        variables: {
          locationId,
          practitionerId
        }
      }
    );
    // console.log(services);
    return services;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateServiceAPI = async (service) => {
  try {
    const updatedService = await callGraphQL<UpdateServiceMutation>(
      updateService,
      {
        variables: {
          input: service
        }
      }
    );

    // console.log(updatedService);
    return updatedService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteServiceAPI = async (serviceId) => {
  try {
    const deletedService = await callGraphQL<DeleteServiceMutation>(
      deleteService,
      {
        variables: {
          serviceId: serviceId
        }
      }
    );

    return deletedService;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getServiceScheduleAPI = async (serviceId, year) => {
  try {
    const serviceSchedule = await callGraphQL<GetResourceScheduleQuery>(
      customGetResourceScheduleQuery,
      {
        variables: {
          resourceId: serviceId,
          resourceType: 'Service',
          year: year
        }
      }
    );

    // console.log(serviceSchedule);
    return serviceSchedule;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getServiceSchedulesInRangeAPI = async (
  serviceId,
  startTime,
  endTime
) => {
  try {
    const serviceSchedule = await callGraphQL<GetResourceScheduleQuery>(
      customGetResourceSchedulesInRange,
      {
        variables: {
          resourceId: serviceId,
          resourceType: 'Service',
          startTime: startTime,
          endTime: endTime
        }
      }
    );

    // console.log(serviceSchedule);
    return serviceSchedule;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getServiceScheduleSlotsAPI = async (
  serviceId,
  startTime,
  endTime
) => {
  try {
    const serviceScheduleSlots =
      await callGraphQL<GetResourceScheduleSlotsQuery>(
        getResourceScheduleSlots,
        {
          variables: {
            resourceId: serviceId,
            resourceType: 'Service',
            startTime: startTime,
            endTime: endTime
          }
        }
      );

    // console.log(serviceSchedule);
    return serviceScheduleSlots;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const putServiceScheduleDayAPI = async (newServiceScheduleDay) => {
  try {
    const serviceSchedule = await callGraphQL<PutResourceScheduleDayMutation>(
      customPutResourceScheduleDay,
      {
        variables: {
          input: newServiceScheduleDay
        }
      }
    );

    // console.log(serviceSchedule);
    return serviceSchedule;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const upsertServiceScheduleAPI = async (newServiceSchedule) => {
  try {
    const serviceSchedule = await callGraphQL<UpsertResourceScheduleMutation>(
      customUpsertResourceSchedule,
      {
        variables: {
          input: newServiceSchedule
        }
      }
    );

    // console.log(serviceSchedule);
    return serviceSchedule;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

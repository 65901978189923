import callGraphQL, { subscribeGraphQL } from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetAppointmentQuery,
  ListAppointmentsQuery,
  CreateAppointmentMutation,
  UpdateAppointmentMutation,
  UpdateAppointmentsStatusMutation,
  DeleteAppointmentMutation,
  UpdateConfirmStatusAppointmentMutation,
  UpdateCheckinStatusAppointmentMutation,
  ListFreeAppointmentBookingSlotsQuery,
  TransferAppointmentMutation
} from 'src/API';
import { CustomUpdateCancelStatusAppointmentMutation } from './types';
import { getAppointment } from 'src/graphql/queries';
import {
  createAppointment,
  deleteAppointment,
  updateAppointmentsStatus
} from 'src/graphql/mutations';

import {
  customListPatientAppointmentsQuery,
  customListCustomerUpcomingAppointmentsQuery,
  customListFreeAppointmentBookingSlots,
  customListPatientAppointmentsReportingQuery,
  customListPatientAppointmentsFlowQuery,
  customGetAppointment
} from './queries';
import {
  updateAppointment,
  customUpdateCancelStatusAppointment,
  customUpdateConfirmStatusAppointment,
  customUpdateCheckInStatusAppointment,
  customCreateAppointment,
  customTransferAppointment
} from './mutations';

import { appointmentAdded } from 'src/graphql/subscriptions';

export const getAppointmentAPI = async (appointmentId) => {
  try {
    const appointment = await callGraphQL<GetAppointmentQuery>(
      customGetAppointment,
      {
        variables: {
          id: appointmentId
        }
      }
    );
    // console.log(appointment);
    return appointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createAppointmentAPI = async (appointment) => {
  try {
    const newAppointment = await callGraphQL<CreateAppointmentMutation>(
      customCreateAppointment,
      {
        variables: {
          input: appointment
        }
      }
    );
    // console.log(newAppointment);
    return newAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const editAppointmentAPI = async (appointment) => {
  try {
    const editedAppointment = await callGraphQL<UpdateAppointmentMutation>(
      updateAppointment,
      {
        variables: {
          input: appointment
        }
      }
    );
    // console.log(editedAppointment);
    return editedAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const transferAppointmentAPI = async (
  appointmentId,
  currentCustomerId,
  newCustomerId,
  cascadeInvoice = true
) => {
  try {
    const transferredAppointment =
      await callGraphQL<TransferAppointmentMutation>(
        customTransferAppointment,
        {
          variables: {
            appointmentId,
            currentCustomerId,
            newCustomerId,
            cascadeInvoice
          }
        }
      );
    // console.log(transferredAppointment);
    return transferredAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientAppointmentsAPI = async (
  patientIds,
  appointmentEndDate?,
  appointmentStartDate?,
  includeClasses = true
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          patientIds,
          includeClasses
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCustomerUpcomingAppointmentsAPI = async (
  customerIds,
  appointmentEndDate?,
  appointmentStartDate?,
  includeClasses = true
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListCustomerUpcomingAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          patientIds: customerIds,
          includeClasses
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// ToDos: Refractor to a normal function so that we can iterate the arguments to remove null variables for graphql queries
export const listAppointmentsAPI = async (
  includeClasses = false,
  appointmentEndDate,
  appointmentStartDate,
  locationIds?,
  practitionerIds?,
  patientIds?,
  serviceIds?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          locationIds,
          patientIds,
          practitionerIds,
          serviceIds,
          includeClasses: includeClasses
          // status: '',
          // type: ''
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentsReportingAPI = async (
  appointmentEndDate,
  appointmentStartDate,
  locationIds?,
  practitionerIds?,
  patientIds?,
  serviceIds?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsReportingQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          locationIds,
          patientIds,
          practitionerIds,
          serviceIds
          // status: '',
          // type: ''
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// ToDos: Refractor to a normal function so that we can iterate the arguments to remove null variables for graphql queries
export const listPractitionerScheduleAPI = async (
  appointmentEndDate,
  appointmentStartDate,
  practitionerIds
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          practitionerIds
          // status: '',
          // type: ''
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteAppointmentAPI = async (appointmentId) => {
  try {
    const delAppointment = await callGraphQL<DeleteAppointmentMutation>(
      deleteAppointment,
      {
        variables: {
          appointmentId
        }
      }
    );
    // console.log(delAppointment);
    return delAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const confirmAppointmentAPI = async (
  appointmentId,
  isConfirmed,
  newStatus?
) => {
  try {
    const confirmAppointment =
      await callGraphQL<UpdateConfirmStatusAppointmentMutation>(
        customUpdateConfirmStatusAppointment,
        {
          variables: {
            appointmentId,
            isConfirmed,
            newStatus
          }
        }
      );
    // console.log(confirmAppointment);
    return confirmAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const checkinAppointmentAPI = async (
  appointmentId,
  isCheckin,
  newStatus?
) => {
  try {
    const checkinAppointment =
      await callGraphQL<UpdateCheckinStatusAppointmentMutation>(
        customUpdateCheckInStatusAppointment,
        {
          variables: {
            appointmentId,
            isCheckin,
            newStatus
          }
        }
      );

    return checkinAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const cancelAppointmentAPI = async (
  appointmentId,
  isCancelled,
  cancellationNote,
  unlinkAppointmentPlan,
  sendNotification = false
) => {
  try {
    const cancelAppointment =
      await callGraphQL<CustomUpdateCancelStatusAppointmentMutation>(
        customUpdateCancelStatusAppointment,
        {
          variables: {
            appointmentId,
            isCancelled,
            cancellationNote,
            unlinkAppointmentPlan,
            sendNotification
          }
        }
      );
    // console.log(cancelAppointment);
    return cancelAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listFreeAppointmentBookingSlotsAPI = async (
  serviceIds,
  classServiceIds,
  startDate,
  endDate
) => {
  try {
    const freeSlots = await callGraphQL<ListFreeAppointmentBookingSlotsQuery>(
      customListFreeAppointmentBookingSlots,
      {
        variables: {
          serviceIds,
          classServiceIds,
          startDate,
          endDate
        }
      }
    );
    // console.log(freeSlots);
    return freeSlots;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

// WIP need to be updated when appointments model has been updated
export const listClassApptDetailPatientDataAPI = async (
  classAppointmentIds,
  appointmentStartDate,
  appointmentEndDate,
  locationIds?,
  practitionerIds?
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsQuery,
      {
        variables: {
          classAppointmentIds,
          includeClasses: true,
          appointmentEndDate,
          appointmentStartDate
          // locationIds,
          // practitionerIds,
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePatientAppointmentsStatusAPI = async (
  appointmentIds,
  newStatus?
) => {
  try {
    const checkinAppointment =
      await callGraphQL<UpdateAppointmentsStatusMutation>(
        updateAppointmentsStatus,
        {
          variables: {
            appointmentIds,
            newStatus
          }
        }
      );

    return checkinAppointment;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientAppointmentsFlowAPI = async (
  appointmentEndDate,
  appointmentStartDate,
  patientIds
) => {
  try {
    const appointments = await callGraphQL<ListAppointmentsQuery>(
      customListPatientAppointmentsFlowQuery,
      {
        variables: {
          appointmentEndDate,
          appointmentStartDate,
          patientIds,
          includeClasses: true
        }
      }
    );
    // console.log(appointments);
    return appointments;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
// export const updateAppointmentNoteAPI = async (appointment) => {
//   try {
//     const editedAppointment = await callGraphQL<UpdateAppointmentMutation>(
//       updateAppointmentNote,
//       {
//         variables: {
//           input: appointment
//         }
//       }
//     );
//     // console.log(editedAppointment);
//     return editedAppointment;
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

// export const onAppointmentAddedSub = async (
//   locationId,
//   onCreateApptHandler
// ) => {
//   try {
//     return subscribeGraphQL<AppointmentAddedSubscription>(
//       appointmentAdded,
//       onCreateApptHandler,
//       {
//         variables: {
//           locationId
//         }
//       }
//     );
//   } catch (error) {
//     return { ...error, customErrorMessage: apiErrorHandler(error) };
//   }
// };

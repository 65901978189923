import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Stations = Loader(lazy(() => import('src/content/Stations')));
const SingleStation = Loader(lazy(() => import('src/content/Stations/single')));

const stationRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: '/list',
    element: <Stations />
  },
  {
    path: ':stationId/',
    element: <SingleStation />
  }
];

export default stationRoutes;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';

import { format, parseISO, isToday } from 'date-fns';

import {
  createAppointmentAPI,
  listAppointmentsAPI,
  getAppointmentAPI,
  listPatientAppointmentsAPI,
  listCustomerUpcomingAppointmentsAPI,
  deleteAppointmentAPI,
  editAppointmentAPI,
  cancelAppointmentAPI,
  confirmAppointmentAPI,
  checkinAppointmentAPI,
  listFreeAppointmentBookingSlotsAPI,
  listPractitionerScheduleAPI,
  transferAppointmentAPI
} from '../content/Appointments/api';
import { createCommunicationAPI } from 'src/content/Communications/api';
import { updateEvent } from 'src/slices/calendar';
import {
  getPatientInvoices,
  listQueueAppointmentsInvoices
} from 'src/slices/invoices';

import tenantMappings from 'src/utils/tenantMappings';

interface AppointmentState {
  isLoadingOverviewAppointments: boolean;
  isLoadingCustomerAppointments: boolean;
  isEditingAppointment: boolean;
  isCreatingAppointment: boolean;
  isCancellingAppointment: boolean;
  isLoadingQueueAppointments: boolean;
  customerAppointments: any[];
  customerAppointmentDetail: any[];
  queueAppointments: any[];
  appointmentFreeSlots: any[];
  providerSchedule: any[];
  overviewAppointments: any[];
  customerUpcomingAppointments: any[];
  calendarSettings: any;
  apptErrorMessage: string;
  apptSuccessMessage: string;
  quickActionApptErrorMessage: string;
  quickActionApptSuccessMessage: string;
}

const initialState: AppointmentState = {
  isLoadingOverviewAppointments: false,
  isLoadingCustomerAppointments: false,
  isEditingAppointment: false,
  isCreatingAppointment: false,
  isCancellingAppointment: false,
  isLoadingQueueAppointments: false,
  customerAppointments: [],
  customerAppointmentDetail: [],
  queueAppointments: [],
  appointmentFreeSlots: [],
  providerSchedule: [],
  overviewAppointments: [],
  customerUpcomingAppointments: [],
  calendarSettings: {},
  apptErrorMessage: '',
  apptSuccessMessage: '',
  quickActionApptErrorMessage: '',
  quickActionApptSuccessMessage: ''
};

const userMappings = tenantMappings?.userMappings;
const communicationMappings = tenantMappings?.communicationMappings;

// IDEA: Sort the array of appointments by start time DESCending first before rendering below
const sortPatientAppointments = (a, b) => {
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

// IDEA: Sort the array of appointments by start time ASCending first before rendering below
const sortPatientAppointmentsAsc = (a, b) => {
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

// Sort Ascending
const sortQueueAppointments = (a, b) => {
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    getAppointment(state: AppointmentState, action: PayloadAction<any>) {
      if (action.payload) {
        state.customerAppointmentDetail = [action.payload];

        const apptIndex = state.customerAppointments.findIndex(
          (obj) => obj.id === action.payload.id
        );
        if (apptIndex >= 0) {
          state.customerAppointments = state.customerAppointments.map(
            (appt, i) => (i === apptIndex ? action.payload : appt)
          );
        }
      } else {
        state.customerAppointments = [{ id: 'Not Found' }];
      }
    },

    getCustomerAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const customerAppointments = action.payload;
      customerAppointments.sort(sortPatientAppointments);

      state.customerAppointments = customerAppointments;

      state.isLoadingCustomerAppointments = false;
    },

    createAppointment(state: AppointmentState, action: PayloadAction<any>) {
      const newAppointments = [action.payload, ...state.customerAppointments];
      newAppointments.sort(sortPatientAppointments);

      state.customerAppointments = newAppointments;
    },

    editAppointment(state: AppointmentState, action: PayloadAction<any>) {
      const apptIndex = state.customerAppointments.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (apptIndex >= 0) {
        state.customerAppointments = state.customerAppointments.map((appt, i) =>
          i === apptIndex ? action.payload : appt
        );
      }

      const queueApptIndex = state.queueAppointments.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (queueApptIndex >= 0) {
        state.queueAppointments = state.queueAppointments.map((appt, i) =>
          i === queueApptIndex ? action.payload : appt
        );
      }

      const upcomingApptIndex = state.customerUpcomingAppointments.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (upcomingApptIndex >= 0) {
        state.customerUpcomingAppointments =
          state.customerUpcomingAppointments.map((appt, i) =>
            i === upcomingApptIndex ? action.payload : appt
          );
      }
    },

    transferAppointment(state: AppointmentState, action: PayloadAction<any>) {
      state.customerAppointments = state.customerAppointments.filter(
        (appt) => appt.id !== action.payload.id
      );

      state.customerUpcomingAppointments =
        state.customerUpcomingAppointments.filter(
          (appt) => appt.id !== action.payload.id
        );
    },

    // Not in use currently
    // deleteAppointment(state: AppointmentState, action: PayloadAction<any>) {
    //   state.customerAppointments = state.customerAppointments.filter(
    //     (appt) => appt.id !== action.payload.id
    //   );
    // },

    getQueueAppointments(state: AppointmentState, action: PayloadAction<any>) {
      const queueAppointments = action.payload;
      queueAppointments.sort(sortQueueAppointments);

      state.queueAppointments = [...queueAppointments];

      // state.queueAppointments = queueAppointments.filter(
      //   (appt) => appt?.service
      // );
    },

    getOverviewAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const overviewAppointments = action.payload;
      overviewAppointments.sort(sortQueueAppointments);

      state.overviewAppointments = overviewAppointments;
    },

    getProviderQueueAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const queueAppointments = action.payload;
      queueAppointments.sort(sortQueueAppointments);

      state.queueAppointments = queueAppointments;
    },

    getCustomerUpcomingAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const customerAppointments = action.payload;
      customerAppointments.sort(sortQueueAppointments);

      state.customerUpcomingAppointments = customerAppointments;

      state.isLoadingCustomerAppointments = false;
    },

    setIsLoadingCustomerAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerAppointments = action.payload;
    },

    setIsLoadingOverviewAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingOverviewAppointments = action.payload;
    },

    setisLoadingQueueAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingQueueAppointments = action.payload;
    },

    setIsEditingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isEditingAppointment = action.payload;
    },

    setIsCreatingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCreatingAppointment = action.payload;
    },

    setIsCancellingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCancellingAppointment = action.payload;
    },

    listFreeAppointmentBookingSlots(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.appointmentFreeSlots = [...action.payload];
    },

    clearFreeAppointmentBookingSlots(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.appointmentFreeSlots = [];
    },

    getPractitionerSchedule(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const appointmentColourMapping =
        state.calendarSettings?.appointmentColourMapping;

      const providerAppts = action.payload;
      providerAppts.sort(sortQueueAppointments);

      // state.providerSchedule = providerAppts;

      const results = [...providerAppts].map((appt) => ({
        id: appt.id,
        title: `${appt?.patient?.fullName}`,
        description: `${appt?.service?.name} - ${appt?.status} at ${appt?.location?.code}`,
        start: parseISO(appt.appointmentStartTime),
        eventStartTime: appt.appointmentStartTime,
        eventEndTime: appt.appointmentEndTime,
        end: parseISO(appt.appointmentEndTime),
        allDay: false,
        // color:
        //   CalendarAppointmentColourMapping.find(
        //     (item) =>
        //       item.type === appt?.type &&
        //       item.category === appt?.service?.category
        //   )?.colourCode || '#808080',
        color:
          appointmentColourMapping.find(
            (item) =>
              item.type === appt?.type &&
              item.category === appt?.service?.category
          )?.colourCode || '#808080',
        status: appt?.status,

        patientId: appt?.patient?.id,
        type: appt?.type,
        category: appt?.service?.category,
        practitionerName: appt?.practitioner?.preferredName
      }));

      state.providerSchedule = results;
    },

    clearPractitionerSchedule(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.providerSchedule = [];
    },

    appointmentAddedQueueAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const newQueueAppts = [action.payload, ...state.queueAppointments];
      newQueueAppts.sort(sortQueueAppointments);

      state.queueAppointments = newQueueAppts;
    },

    appointmentDeletedQueueAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.queueAppointments = state.queueAppointments.filter(
        (appt) => appt.id !== action.payload.id
      );
    },

    appointmentUpdatedQueueAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const apptIndex = state.queueAppointments.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (apptIndex >= 0) {
        state.queueAppointments = state.queueAppointments.map((appt, i) =>
          i === apptIndex ? action.payload : appt
        );
      }
    },

    markQueueAppointmentRead(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const apptIndex = state.queueAppointments.findIndex(
        (obj) => obj.id === action.payload
      );

      if (apptIndex >= 0) {
        state.queueAppointments = state.queueAppointments.map((appt, i) =>
          i === apptIndex ? { ...appt, isUnread: false } : appt
        );
      }
    },

    setQueueAppointmentConfirmationEmailSent(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const apptIndex = state.queueAppointments.findIndex(
        (obj) => obj.id === action.payload.appointmentId
      );

      if (apptIndex >= 0) {
        state.queueAppointments = state.queueAppointments.map((appt, i) =>
          i === apptIndex
            ? {
                ...appt,
                emailReminderSent: true,
                emailReminderAt: action.payload.createdAt
              }
            : appt
        );
      }
    },

    setQueueAppointmentConfirmationSMSSent(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const apptIndex = state.queueAppointments.findIndex(
        (obj) => obj.id === action.payload.appointmentId
      );

      if (apptIndex >= 0) {
        state.queueAppointments = state.queueAppointments.map((appt, i) =>
          i === apptIndex
            ? {
                ...appt,
                smsReminderSent: true,
                smsReminderSentAt: action.payload.createdAt
              }
            : appt
        );
      }
    },

    storeApptCalendarSettings(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      // state.calendarSettings = action.payload;

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings?.calendars?.formOptions);
      state.calendarSettings = { ...settings?.calendars?.formOptions };
    },

    setErrorMessage(state: AppointmentState, action: PayloadAction<any>) {
      state.apptErrorMessage = action.payload;
    },

    setSuccessMessage(state: AppointmentState, action: PayloadAction<any>) {
      state.apptSuccessMessage = action.payload;
    },

    setQuickActionErrorMessage(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.quickActionApptErrorMessage = action.payload;
    },

    setQuickActionSuccessMessage(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.quickActionApptSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getAppointment =
  (appointmentId): AppThunk =>
  async (dispatch) => {
    const response = await backOff(() => getAppointmentAPI(appointmentId), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getAppointment(response.data.getAppointment));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getCustomerAppointments =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerAppointments());

    const response = await backOff(
      () => listPatientAppointmentsAPI(patientId),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getCustomerAppointments(response.data.listAppointments)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerAppointments());
  };

export const getCustomerAppointmentsByDateRange =
  (patientIds, appointmentStartDate, appointmentEndDate): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerAppointments());

    const response = await backOff(
      () =>
        listPatientAppointmentsAPI(
          patientIds,
          appointmentEndDate,
          appointmentStartDate
        ),
      { numOfAttempts: 3 }
    );

    // console.log(response)
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getCustomerAppointments(response.data.listAppointments)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerAppointments());
  };

export const createAppointment =
  (
    tenantIdLowerCase,
    appointment,
    // notify = true, // disable this after moving to backend
    quickAction = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingAppointment());
    dispatch(startLoadingCustomerAppointments());

    const response = await createAppointmentAPI(appointment);
    // console.log(response);

    if (!response.customErrorMessage) {
      if (
        appointment?.recurFrequency &&
        appointment?.recurInstances &&
        appointment?.recurInstances > 1
      ) {
        window.location.reload();
      } else {
        // updates the appointments array above
        dispatch(
          slice.actions.createAppointment(response.data.createAppointment)
        );

        // this updates the calendar too
        dispatch(updateEvent(response.data.createAppointment));
      }

      // commenting this out after moving to backend
      // if (notify) {
      //   if (response.data.createAppointment) {
      //     const data = response.data.createAppointment;

      //     const bookingEmailTemplate =
      //       communicationMappings?.createPatientSingleAppointmentEmailTemplate(
      //         tenantIdLowerCase,
      //         data
      //       );

      //     const emailResponse = await createCommunicationAPI({
      //       appointmentId: data?.id,
      //       body: bookingEmailTemplate,
      //       cc: [],
      //       bcc: [],
      //       destination: data?.patient.piiProfile.email,
      //       from: `booking+${userMappings?.getMappedTenantName(
      //         tenantIdLowerCase
      //       )}@gethelm.io`,
      //       fromName: data?.location?.name,
      //       medium: 'Email',
      //       patientId: data?.patient.id,
      //       replyTo: {
      //         email:
      //           data?.location?.emails[0] ||
      //           `contact+${userMappings?.getMappedTenantName(
      //             tenantIdLowerCase
      //           )}@gethelm.io`,
      //         name: data?.location?.name
      //       },
      //       subject: `Appointment Booking - ${format(
      //         parseISO(data?.appointmentStartTime),
      //         'EEEE, LLLL do, h:mm a'
      //       )}`,
      //       type: 'Html'
      //     });
      //   }
      // }
      if (quickAction) {
        dispatch(
          slice.actions.setQuickActionSuccessMessage(
            'Appointment successfully created'
          )
        );
      } else {
        dispatch(
          slice.actions.setSuccessMessage('Appointment successfully created')
        );
      }
    } else {
      if (quickAction) {
        dispatch(
          slice.actions.setQuickActionErrorMessage(response.customErrorMessage)
        );
      } else {
        dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
      }
    }

    const followUp = () => {
      dispatch(stopCreatingAppointment());
      dispatch(stopLoadingCustomerAppointments());
    };

    return followUp();
  };

export const editAppointment =
  (tenantIdLowerCase, appointment, notify = false, snackBar = true): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    const response = await editAppointmentAPI({...appointment, sendNotification: notify});

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editAppointment(response.data.updateAppointment));

      dispatch(updateEvent(response.data.updateAppointment));

      // Comment out because this is moved to BE
      // if (notify) {
      //   if (response.data.updateAppointment) {
      //     const data = response.data.updateAppointment;

      //     const bookingEmailTemplate =
      //       communicationMappings?.updatePatientSingleAppointmentEmailTemplate(
      //         tenantIdLowerCase,
      //         data
      //       );

      //     const emailResponse = await createCommunicationAPI({
      //       appointmentId: data?.id,
      //       body: bookingEmailTemplate,
      //       cc: [],
      //       bcc: [],
      //       destination: data?.patient.piiProfile.email,
      //       from: `booking+${userMappings?.getMappedTenantName(
      //         tenantIdLowerCase
      //       )}@gethelm.io`,
      //       fromName: data?.location?.name,
      //       medium: 'Email',
      //       patientId: data?.patient.id,
      //       replyTo: {
      //         email:
      //           data?.location?.emails[0] ||
      //           `contact+${userMappings?.getMappedTenantName(
      //             tenantIdLowerCase
      //           )}@gethelm.io`,
      //         name: data?.location?.name
      //       },
      //       subject: `Appointment Update - ${format(
      //         parseISO(data?.appointmentStartTime),
      //         'EEEE, LLLL do, h:mm a'
      //       )}`,
      //       type: 'Html'
      //     });
      //   }
      // }
      if (snackBar) {
        dispatch(
          slice.actions.setSuccessMessage('Appointment successfully updated')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingAppointment());
  };

export const editAppointmentNote =
  (appointment): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    const response = await editAppointmentAPI(appointment);

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editAppointment(response.data.updateAppointment));

      dispatch(updateEvent(response.data.updateAppointment));

      dispatch(
        slice.actions.setSuccessMessage(
          'Appointment Notes successfully updated'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingAppointment());
  };

export const transferAppointment =
  (
    appointmentId,
    currentCustomerId,
    newCustomerId,
    cascadeInvoice?
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    const response = await transferAppointmentAPI(
      appointmentId,
      currentCustomerId,
      newCustomerId,
      cascadeInvoice
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.transferAppointment(response.data.transferAppointment)
      );

      // might have a bug if the appointment transferred is older than 60 days, the invoice wont refresh on FE
      dispatch(getPatientInvoices(currentCustomerId));

      dispatch(
        slice.actions.setSuccessMessage(
          'Appointment successfully transferred. If invoice is not reflected, please refresh the page.'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingAppointment());
  };

export const confirmAppointment =
  (appointmentId, isConfirmed, newStatus?): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    const response = await confirmAppointmentAPI(
      appointmentId,
      isConfirmed,
      newStatus
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editAppointment(
          response.data.updateConfirmStatusAppointment
        )
      );

      dispatch(
        slice.actions.setSuccessMessage('Appointment successfully confirmed')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingAppointment());
  };

export const checkinAppointment =
  (appointmentId, isCheckin, newStatus?): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    const response = await checkinAppointmentAPI(
      appointmentId,
      isCheckin,
      newStatus
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editAppointment(
          response.data.updateCheckinStatusAppointment
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingAppointment());
  };

export const cancelAppointment =
  (
    tenantIdLowerCase,
    appointmentId,
    isCancelled,
    cancellationNote,
    unlinkAppointmentPlan,
    notify = false,
    bulkCancel = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startCancellingAppointment());
    dispatch(startLoadingCustomerAppointments());

    const response = await cancelAppointmentAPI(
      appointmentId,
      isCancelled,
      cancellationNote,
      unlinkAppointmentPlan,
      notify
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editAppointment(
          response.data.updateCancelStatusAppointment
        )
      );

      // moving notify to BE
      // if (notify) {
      //   if (response.data.updateCancelStatusAppointment) {
      //     const data = response.data.updateCancelStatusAppointment;

      //     const bookingEmailTemplate =
      //       communicationMappings?.cancelPatientSingleAppointmentEmailTemplate(
      //         tenantIdLowerCase,
      //         data
      //       );

      //     const emailResponse = await createCommunicationAPI({
      //       appointmentId: data?.id,
      //       body: bookingEmailTemplate,
      //       cc: [],
      //       bcc: [],
      //       destination: data?.patient?.piiProfile.email,
      //       from: `booking+${userMappings?.getMappedTenantName(
      //         tenantIdLowerCase
      //       )}@gethelm.io`,
      //       fromName: data?.location?.name,
      //       medium: 'Email',
      //       patientId: data?.patient.id,
      //       replyTo: {
      //         email:
      //           data?.location?.emails[0] ||
      //           `contact+${userMappings?.getMappedTenantName(
      //             tenantIdLowerCase
      //           )}@gethelm.io`,
      //         name: data?.location?.name
      //       },
      //       subject: `Appointment Cancellation - ${format(
      //         parseISO(data?.appointmentStartTime),
      //         'EEEE, LLLL do, h:mm a'
      //       )}`,
      //       type: 'Html'
      //     });
      //   }
      // }

      if (!bulkCancel) {
        dispatch(
          slice.actions.setSuccessMessage('Appointment successfully cancelled')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCancellingAppointment());
      dispatch(stopLoadingCustomerAppointments());
    };

    return followUp();
  };

// export const deleteAppointment =
//   (apptId): AppThunk =>
//   async (dispatch) => {
//     dispatch(startEditingAppointment());

//     const response = await deleteAppointmentAPI(apptId);

//     if (!response.customErrorMessage) {
//       dispatch(
//         slice.actions.deleteAppointment(response.data.deleteAppointment)
//       );
//       dispatch(
//         slice.actions.setSuccessMessage('Appointment successfully deleted')
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }

//     return dispatch(stopEditingAppointment());
//   };

export const rescheduleAppointment =
  (
    tenantIdLowerCase,
    appointmentId,
    isCancelled,
    cancellationNote,
    newAppointment,
    notify = false,
    unlinkAppointmentPlan = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());
    dispatch(startCancellingAppointment());
    dispatch(startCreatingAppointment());

    const cancelResponse = await cancelAppointmentAPI(
      appointmentId,
      isCancelled,
      cancellationNote,
      unlinkAppointmentPlan,
    );

    // console.log(cancelResponse);

    if (!cancelResponse.customErrorMessage) {
      dispatch(
        slice.actions.editAppointment(
          cancelResponse.data.updateCancelStatusAppointment
        )
      );
      dispatch(updateEvent(cancelResponse.data.updateCancelStatusAppointment));
    }

    const response = await createAppointmentAPI(newAppointment);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createAppointment(response.data.createAppointment)
      );

      // this updates the calendar too
      dispatch(updateEvent(response.data.createAppointment));

      if (notify) {
        if (response?.data?.createAppointment) {
          const data = response.data.createAppointment;

          const bookingEmailTemplate =
            communicationMappings?.reschedulePatientSingleAppointmentEmailTemplate(
              tenantIdLowerCase,
              data
            );

          const emailResponse = await createCommunicationAPI({
            appointmentId: data?.id,
            body: bookingEmailTemplate,
            cc: [],
            bcc: [],
            destination: data?.patient.piiProfile.email,
            from: `booking+${userMappings?.getMappedTenantName(
              tenantIdLowerCase
            )}@gethelm.io`,
            fromName: data?.location?.name,
            medium: 'Email',
            patientId: data?.patient.id,
            replyTo: {
              email:
                data?.location?.emails[0] ||
                `contact+${userMappings?.getMappedTenantName(
                  tenantIdLowerCase
                )}@gethelm.io`,
              name: data?.location?.name
            },
            subject: `Appointment Update - ${format(
              parseISO(data?.appointmentStartTime),
              'EEEE, LLLL do, h:mm a'
            )}`,
            type: 'Html'
          });
        }
      }

      dispatch(
        slice.actions.setSuccessMessage('Appointment successfully rescheduled.')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopEditingAppointment());
      dispatch(stopCancellingAppointment());
      dispatch(stopCreatingAppointment());
    };

    return followUp();
  };

export const getQueueAppointments =
  (includeClasses, toDate, fromDate, locations, getInvoices = true): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingQueueAppointments());

    // const response = await listAppointmentsAPI(toDate, fromDate, locations);
    const response = await backOff(
      () => listAppointmentsAPI(includeClasses, toDate, fromDate, locations),
      { numOfAttempts: 3, jitter: 'full' }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getQueueAppointments(response.data.listAppointments)
      );

      const queueAppointments = response.data.listAppointments || [];
      const queueAppointmentIds = [...queueAppointments]
        ?.filter(
          (appt) => appt.status !== 'Cancelled' && appt.status !== 'NoShow'
        )
        ?.map((appt) => appt.id);

      if (
        queueAppointmentIds.length > 0 &&
        // 13 May 24 - decided to show for all days instead of today only. COP request
        // isToday(parseISO(fromDate)) &&
        getInvoices
      ) {
        dispatch(listQueueAppointmentsInvoices(queueAppointmentIds));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingQueueAppointments());
  };

export const getOverviewAppointments =
  (toDate, fromDate, locations): AppThunk =>
  async (dispatch) => {
    // console.log('General Overview');

    dispatch(startLoadingOverviewAppointments());

    const response = await backOff(
      () => listAppointmentsAPI(false, toDate, fromDate, locations),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getOverviewAppointments(response.data.listAppointments)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingOverviewAppointments());
  };

export const getPractitionerOverviewAppointments =
  (toDate, fromDate, locations, practitioners): AppThunk =>
  async (dispatch) => {
    // console.log('Practitioner Overview');

    dispatch(startLoadingOverviewAppointments());

    const response = await backOff(
      () =>
        listAppointmentsAPI(false, toDate, fromDate, locations, practitioners),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getOverviewAppointments(response.data.listAppointments)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingOverviewAppointments());
  };

export const getProviderQueueAppointments =
  (includeClasses, toDate, fromDate, locations, practitioners): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingQueueAppointments());

    const response = await listAppointmentsAPI(
      includeClasses,
      toDate,
      fromDate,
      locations,
      // [],
      practitioners
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getProviderQueueAppointments(
          response.data.listAppointments
        )
      );

      const queueAppointments = response.data.listAppointments;
      const queueAppointmentIds = [...queueAppointments]
        ?.filter(
          (appt) => appt.status !== 'Cancelled' && appt.status !== 'NoShow'
        )
        ?.map((appt) => appt.id);
      dispatch(listQueueAppointmentsInvoices(queueAppointmentIds));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingQueueAppointments());
  };

export const listFreeAppointmentBookingSlots =
  (serviceIds, startDate, endDate, classServiceIds = null): AppThunk =>
  async (dispatch) => {
    const response = await listFreeAppointmentBookingSlotsAPI(
      serviceIds,
      classServiceIds,
      startDate,
      endDate
    );
    // console.log(response)
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listFreeAppointmentBookingSlots(
          response.data.listFreeAppointmentBookingSlots
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearFreeAppointmentBookingSlots =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearFreeAppointmentBookingSlots('clear'));
  };

export const getPractitionerSchedule =
  (toDate, fromDate, practitionerId): AppThunk =>
  async (dispatch) => {
    const response = await listPractitionerScheduleAPI(
      toDate,
      fromDate,
      practitionerId
    );
    // console.log(response)
    // const results = [...response?.data?.listAppointments].map((appt) => ({
    //   id: appt.id,
    //   title: `${appt?.patient?.fullName}`,
    //   description: `${appt?.service?.name} - ${appt?.status} at ${appt?.location?.code}`,
    //   start: parseISO(appt.appointmentStartTime),
    //   eventStartTime: appt.appointmentStartTime,
    //   eventEndTime: appt.appointmentEndTime,
    //   end: parseISO(appt.appointmentEndTime),
    //   allDay: false,
    //   color:
    //     CalendarAppointmentColourMapping.find(
    //       (item) =>
    //         item.type === appt?.type &&
    //         item.category === appt?.service?.category
    //     )?.colourCode || '#808080',
    //   status: appt?.status,

    //   patientId: appt?.patient?.id,
    //   type: appt?.type,
    //   category: appt?.service?.category,
    //   practitionerName: appt?.practitioner?.preferredName
    // }));

    const results = [...response?.data?.listAppointments];

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPractitionerSchedule(results));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearPractitionerSchedule = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearPractitionerSchedule('clear'));
};

export const getCustomerUpcomingAppointments =
  (
    customerIds,
    appointmentEndDate,
    appointmentStartDate = format(new Date(), 'yyyy-MM-dd')
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerAppointments());

    const response = await backOff(
      () =>
        listCustomerUpcomingAppointmentsAPI(
          customerIds,
          appointmentEndDate,
          appointmentStartDate
        ),
      { numOfAttempts: 3 }
    );

    // console.log(response)
    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getCustomerUpcomingAppointments(
          response.data.listAppointments
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerAppointments());
  };

export const appointmentAddedQueueAppointments =
  (addedAppointment): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.appointmentAddedQueueAppointments({
        ...addedAppointment,
        isUnread: true
      })
    );
  };

export const appointmentDeletedQueueAppointments =
  (deletedAppointment): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.appointmentDeletedQueueAppointments(deletedAppointment)
    );
  };

export const appointmentUpdatedQueueAppointments =
  (updatedAppointment): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.appointmentUpdatedQueueAppointments({
        ...updatedAppointment,
        isUnread: true
      })
    );
  };

export const markQueueAppointmentRead =
  (appointmentId): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.markQueueAppointmentRead(appointmentId));
  };

export const setQueueAppointmentConfirmationEmailSent =
  ({ appointmentId, createdAt }): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setQueueAppointmentConfirmationEmailSent({
        appointmentId,
        createdAt
      })
    );
  };

export const setQueueAppointmentConfirmationSMSSent =
  ({ appointmentId, createdAt }): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setQueueAppointmentConfirmationSMSSent({
        appointmentId,
        createdAt
      })
    );
  };

export const startLoadingCustomerAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerAppointments(true));
  };

export const stopLoadingCustomerAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerAppointments(false));
  };

export const startLoadingOverviewAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOverviewAppointments(true));
  };

export const stopLoadingOverviewAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOverviewAppointments(false));
  };

export const startLoadingQueueAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setisLoadingQueueAppointments(true));
  };

export const stopLoadingQueueAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setisLoadingQueueAppointments(false));
  };

export const startEditingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerAppointment(true));
};

export const stopEditingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerAppointment(false));
};

export const startCreatingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerAppointment(true));
};

export const stopCreatingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerAppointment(false));
};

export const startCancellingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCancellingCustomerAppointment(true));
};

export const stopCancellingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCancellingCustomerAppointment(false));
};

export const storeApptCalendarSettings = function (
  calendarSettings: any
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.storeApptCalendarSettings(calendarSettings));
  };
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const setQuickActionErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setQuickActionErrorMessage(message));
  };

export const setQuickActionSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setQuickActionSuccessMessage(message));
  };

export default slice;

import {
  FC,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useState,
  useCallback,
  useEffect,
  ReactElement,
  Ref,
  useRef,
  forwardRef
} from 'react';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'src/store';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled
} from '@mui/material';

import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';

import { PatientProfilePii } from 'src/models/patient';
import {
  getCustomersResults,
  startSearchingCustomers
} from 'src/slices/patients';

import ResultsLoader from 'src/components/loaders/ResultsLoader';

import tenantMappings from 'src/utils/tenantMappings';

const applyFilters = (
  patients: PatientProfilePii[],
  query: string
  // filters: Filters
): PatientProfilePii[] => {
  return patients.filter((patient) => {
    let matches = true;

    if (query) {
      const properties = [
        'email',
        'fullName',
        'preferredName',
        'mobile',
        'identityNumber',
        'birthday',
        'addressPostalCode'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (patient[property]?.toLowerCase()?.includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  patients: PatientProfilePii[],
  page: number,
  limit: number
): PatientProfilePii[] => {
  return patients.slice(page * limit, page * limit + limit);
};

const dictionaryMappings = tenantMappings?.dictionaryMappings;

// Component STARTS HERE
const SimpleSearch: FC<any> = ({ selectedCustomer, setSelectedCustomer }) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMountedRef = useRefMounted();
  const firstRender = useRef(true);

  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const mappedWordsToUse =
    dictionaryMappings?.getMappedWordToUse(tenantIdLowerCase);
  const getMappedWordToUse = (word) => {
    const fallback = word;

    const result = mappedWordsToUse[word];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  };

  const { customersResults, isSearchingCustomers } = useSelector(
    (state) => state.patients
  );

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [isFirstSearch, setIsFirstSearch] = useState<boolean>(true);

  const listPatients = useCallback(async () => {
    try {
      dispatch(getCustomersResults(query));
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef, query]);

  useEffect(() => {
    // On initial render, do nothing. Don't load everything
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // If search term is cleared out, do nothing
    // if (query === '' || query.length < 4) {
    //   return;
    // }

    // Feb 2024 - Disable AutoSearch
    // Wait for 300ms or 0.5s before commencing search. Avg human reaction is 250ms
    // dispatch(startSearchingCustomers());
    // const delayDebounceFn = setTimeout(() => {
    //   listPatients();
    // }, 250);

    // return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);

    if (page > 0) {
      setPage(0);
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));

    if (page > 0) {
      setPage(0);
    }
  };

  const paginatedUsers = applyPagination(customersResults, page, limit);

  return (
    <>
      {/* <Card variant="outlined" sx={{ minWidth: props?.minWidth || '40vw' }}> */}
      <Card variant="outlined">
        <CardContent
          sx={{
            px: 3,
            py: 3
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {`Search for a ${getMappedWordToUse('patient')}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t(
                  'Search by Name, Identification Number, Email, Mobile, Postal Code...'
                )}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                onKeyDown={(ev) => {
                  // console.log(`Pressed keyCode ${ev.key}`);
                  if (ev.key === 'Enter') {
                    ev.preventDefault();

                    if (query?.length < 3) {
                      return;
                    } else {
                      dispatch(startSearchingCustomers());
                      listPatients();
                      if (isFirstSearch) {
                        setIsFirstSearch(false);
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 1 }} />

          {isSearchingCustomers ? (
            <ResultsLoader dataset={'Patients'} />
          ) : !isSearchingCustomers && isFirstSearch ? (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {query?.length < 3
                ? t('Provide more information for our search')
                : t('Press Enter to Search')}
            </Typography>
          ) : !isSearchingCustomers &&
            !isFirstSearch &&
            // query !== '' &&
            paginatedUsers.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t(
                  "We couldn't find any record matching your search criteria."
                )}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '30%' }}>
                        <Typography fontWeight="bold">{t('Name')}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '5%' }}>
                        <Typography fontWeight="bold">{t('NRIC')}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '20%' }}>
                        <Typography fontWeight="bold">{t('Email')}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '10%' }}>
                        <Typography fontWeight="bold">{t('Mobile')}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '10%' }}>
                        <Typography fontWeight="bold">
                          {t('Postal Code')}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: '15%' }}>
                        <Typography fontWeight="bold">
                          {t('Date of Birth')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ width: '10%' }}>
                        <Typography fontWeight="bold">
                          {t('Actions')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedUsers?.map((patient) => {
                      return (
                        <TableRow
                          hover
                          key={patient.id}
                          selected={selectedCustomer?.id === patient.id}
                          onClick={() => {
                            setSelectedCustomer(patient);
                          }}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{
                                  mr: 1
                                }}
                                src={
                                  patient?.gender?.toLowerCase() === 'male'
                                    ? '/static/images/avatars/Patient-Male.png'
                                    : patient?.gender?.toLowerCase() ===
                                      'female'
                                    ? '/static/images/avatars/Patient-Female.png'
                                    : '/static/images/avatars/Patient-Undisclosed.png'
                                }
                              />
                              <Box>
                                <Box display="flex" alignItems="center">
                                  <Typography fontWeight="bold">
                                    {patient?.fullName?.length > 18
                                      ? `${patient?.fullName.slice(0, 18)}..`
                                      : patient?.fullName}
                                  </Typography>
                                  {/* {patient?.stripePaymentMethods &&
                                patient?.stripePaymentMethods?.length > 0 ? (
                                  <CreditScoreTwoToneIcon
                                    color="success"
                                    sx={{ ml: 1 }}
                                  />
                                ) : (
                                  <></>
                                )} */}
                                </Box>
                                <Typography noWrap variant="subtitle2">
                                  {patient?.occupation?.length > 18
                                    ? `${patient?.occupation.slice(0, 18)}..`
                                    : patient?.occupation}
                                </Typography>
                              </Box>
                              {patient?.stripePaymentMethods &&
                              patient?.stripePaymentMethods?.length > 0 ? (
                                <CreditScoreTwoToneIcon
                                  color="success"
                                  sx={{ ml: 1 }}
                                />
                              ) : null}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight="bold">
                              ***
                              {patient?.identityNumber?.slice(
                                patient?.identityNumber?.length - 4
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography fontWeight="bold">
                              {patient?.email?.slice(0, 3)}***
                              {patient?.email?.slice(
                                patient?.email?.length -
                                  patient?.email?.indexOf('@') +
                                  1
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight="bold">
                              ***
                              {patient?.mobile?.slice(
                                patient?.mobile?.length - 4
                              )}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography>
                              {patient?.addressPostalCode}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography>{patient?.birthday}</Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('View')} arrow>
                                <IconButton
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  component={RouterLink}
                                  to={
                                    `/${
                                      location.pathname.split('/')[1]
                                    }/${getMappedWordToUse('patients')}/` +
                                    patient.id
                                  }
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={customersResults.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SimpleSearch;

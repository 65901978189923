import { IconButton, Tooltip, styled } from '@mui/material';
import CastConnectedTwoToneIcon from '@mui/icons-material/CastConnectedTwoTone';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

function HeaderControlScreen() {
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow placement="bottom-end" title={t('Flow Control')}>
        <IconButtonWrapper
          color="primary"
          onClick={() => navigate('/flow/flow/control')}
        >
          <CastConnectedTwoToneIcon />
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default HeaderControlScreen;

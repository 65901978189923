import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  StoreGlobalUISettingsMutation,
  UpdatePractitionerMutation
} from 'src/API';
import {
  storeGlobalUISettings,
  updatePractitioner
} from 'src/graphql/mutations';

export const storeGlobalUISettingsAPI = async (setting) => {
  try {
    // console.log(setting);

    const newSettings = await callGraphQL<StoreGlobalUISettingsMutation>(
      storeGlobalUISettings,
      {
        variables: {
          input: setting
        }
      }
    );

    // console.log(newSettings);
    return newSettings;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getGlobalUISettingsAPI = async () => {
  try {
    const getSettings = /* GraphQL */ `
      query GetGlobalUISettings {
        getGlobalUISettings {
          content
          createdAt
          createdBy
          id
          updatedAt
          updatedBy
        }
      }
    `;

    const settings = await callGraphQL<any>(getSettings, {});

    // console.log(settings);
    return settings;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updatePersonalDetailsAPI = async (user) => {
  try {
    const updatedUser = await callGraphQL<UpdatePractitionerMutation>(
      updatePractitioner,
      {
        variables: {
          input: user
        }
      }
    );

    console.log(updatedUser);
    return updatedUser;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

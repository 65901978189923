import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const HealthcareDoctor = Loader(
  lazy(() => import('src/content/HelmOverview/'))
);

const overviewRoutes = [
  {
    path: '/',
    element: <Navigate to="home" replace />
  },
  {
    path: 'home',
    element: <HealthcareDoctor />
  }

  // {
  //   path: 'users',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <Users />
  //     },
  //     {
  //       path: 'single',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="1" replace />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     }
  //   ]
  // },
];

export default overviewRoutes;

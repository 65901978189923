import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  ListContactsByCategoryQuery,
  ListContactsQuery,
  GetContactQuery,
  CreateContactMutation,
  UpdateContactMutation,
  DeleteContactMutation
} from 'src/API';
import {
  createContact,
  updateContact,
  deleteContact
} from 'src/graphql/mutations';
import {
  getContact,
  listContacts,
  listContactsByCategory
} from 'src/graphql/queries';
import { customListContactsOnLoad } from './queries';

export const listContactsOnLoadAPI = async () => {
  try {
    const contacts = await callGraphQL<ListContactsQuery>(
      customListContactsOnLoad,
      {
        variables: {}
      }
    );

    return contacts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listContactsAPI = async () => {
  try {
    const contacts = await callGraphQL<ListContactsQuery>(listContacts, {
      variables: {}
    });

    return contacts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listContactsByCategoryAPI = async (category) => {
  try {
    const contacts = await callGraphQL<ListContactsByCategoryQuery>(
      listContactsByCategory,
      {
        variables: { category }
      }
    );

    return contacts;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createContactAPI = async (contact) => {
  try {
    const newContact = await callGraphQL<CreateContactMutation>(createContact, {
      variables: {
        input: contact
      }
    });

    // console.log(newContact);
    return newContact;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getContactAPI = async (contactId) => {
  try {
    const contact = await callGraphQL<GetContactQuery>(getContact, {
      variables: {
        id: contactId
      }
    });

    // console.log(contact);
    return contact;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateContactAPI = async (contact) => {
  try {
    const updatedContact = await callGraphQL<UpdateContactMutation>(
      updateContact,
      {
        variables: {
          input: contact
        }
      }
    );

    // console.log(updatedContact);
    return updatedContact;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteContactAPI = async (contactId) => {
  try {
    const deletedContact = await callGraphQL<DeleteContactMutation>(
      deleteContact,
      {
        variables: {
          id: contactId
        }
      }
    );
    return deletedContact;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

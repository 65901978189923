export const customGetOnlineBookingTransaction = /* GraphQL */ `
  query GetOnlineBookingTransaction($id: ID!) {
    getOnlineBookingTransaction(id: $id) {
      id
      serviceId
      classServiceId
      locationId
      patientId
      appointmentId
      startTime
      endTime
      onlineBookingPageId
      adjustmentCodes {
        id
        code
        type
        name
        description
        # applicableLocationIds
        # applicableServiceIds
        # applicableClassServiceIds
        # applicableInventoryIds
        # startTime
        # endTime
        # isActive
        # maxTotalRedemptions
        # maxPerCustomerRedemptions
        # currentRedemptions
        # ruleTemplateType
        # ruleTemplateVariables
        # rules
        # isStackable
        # stackingPriorityLevel
        # contactId
        # corporateId
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
        amount {
          amount
          currency
          precision
          scale
        }
        # voidedAt
        # voidedBy
        # voidedReason
        # createdAt
        # createdBy
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
    }
  }
`;

export const customListOnlineBookingTransactions = /* GraphQL */ `
  query ListOnlineBookingTransactions(
    $locationIds: [ID!]!
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listOnlineBookingTransactions(
      locationIds: $locationIds
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      locationId
      startTime
      endTime
      patientId
      appointmentId
      originalPrice {
        amount
        currency
        precision
        scale
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
        amount {
          amount
          currency
          precision
          scale
        }
        # voidedAt
        # voidedBy
        # voidedReason
        # createdAt
        # createdBy
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
      stripeIntegrated
      stripeSetupIntentUrl
      multiplePatients
    }
  }
`;

export const customListCustomerOnlineBookingTransactions = /* GraphQL */ `
  query ListOnlineBookingTransactions(
    $patientId: ID
    $startTime: AWSDateTime
    $endTime: AWSDateTime
  ) {
    listOnlineBookingTransactions(
      patientId: $patientId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      locationId
      startTime
      endTime
      patientId
      appointmentId
      adjustmentCodes {
        id
        code
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
    }
  }
`;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const PatientIntakeIndex = Loader(
  lazy(() => import('src/content/Intake/PatientIntake'))
);
// const SuccessPage = Loader(lazy(() => import('src/content/OnlineBooking/SuccessPage')));
// const ErrorPage = Loader(lazy(() => import('src/content/OnlineBooking/ErrorPage')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const onlineBookingRoutes = [
  {
    path: '/',
    // element: <Overview />
    // Force navingation to Home Page of the App.
    element: <Status404 replace />
  },
  {
    path: '/patient',
    element: <PatientIntakeIndex />
  },
  {
    path: '/customer',
    element: <PatientIntakeIndex />
  },

  // {
  //   path: '/success',
  //   element: <SuccessPage />
  // },

  // {
  //   path: '/error',
  //   element: <ErrorPage />
  // },

  {
    path: '*',
    element: <Status404 />
  }
];

export default onlineBookingRoutes;

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import PaidIcon from '@mui/icons-material/Paid';
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import TodayIcon from '@mui/icons-material/Today';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import PermPhoneMsgTwoToneIcon from '@mui/icons-material/PermPhoneMsgTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import BookOnlineTwoToneIcon from '@mui/icons-material/BookOnlineTwoTone';
import HealingTwoToneIcon from '@mui/icons-material/HealingTwoTone';
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import TimerOffTwoToneIcon from '@mui/icons-material/TimerOffTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import SchemaTwoToneIcon from '@mui/icons-material/SchemaTwoTone';
import ModelTrainingTwoToneIcon from '@mui/icons-material/ModelTrainingTwoTone';
import GroupWorkTwoToneIcon from '@mui/icons-material/GroupWorkTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';

export const getSideMenu = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    admin: [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          },

          {
            name: 'Online Booking',
            icon: BookOnlineTwoToneIcon,
            link: '/app/bookingpages',
            items: [
              {
                name: 'Booking Pages',
                icon: AutoStoriesTwoToneIcon,
                link: '/app/booking-pages/list'
              },
              {
                name: 'Transactions',
                icon: ReceiptTwoToneIcon,
                link: '/app/booking-transactions/list'
              }
            ]
          },

          {
            name: 'Promo Codes',
            icon: LocalActivityTwoToneIcon,
            link: '/app/promo-codes'
          }
        ]
      },

      {
        heading: 'Finance',
        items: [
          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices',
            items: [
              {
                name: 'Listing',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/invoices/search'
              }
            ]
          },

          {
            name: 'Credit Notes',
            icon: RequestQuoteTwoToneIcon,
            link: '/app/creditnotes',
            items: [
              {
                name: 'Listing',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/creditnotes/search'
              }
            ]
          },

          {
            name: 'Payments',
            icon: PaidIcon,
            link: '/app/payments/list'
          }
        ]
      },
      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'TimeOffs',
            icon: TimerOffTwoToneIcon,
            link: '/app/timeoffs'
          },

          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Users',
            icon: ManageAccountsTwoToneIcon,
            link: '/app/users'
          },

          {
            name: 'Locations',
            icon: LocationOnTwoToneIcon,
            link: '/app/locations'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
          // {
          //   name: 'System Settings',
          //   icon: SettingsTwoToneIcon,
          //   link: '/app/settings'
          // }
        ]
      }
    ],
    'admin-practitioner': [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              },
              {
                name: 'Copilot',
                icon: ModelTrainingTwoToneIcon,
                link: 'communications/followup'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          },

          {
            name: 'Online Booking',
            icon: BookOnlineTwoToneIcon,
            link: '/app/bookingpages',
            items: [
              {
                name: 'Booking Pages',
                icon: AutoStoriesTwoToneIcon,
                link: '/app/booking-pages/list'
              },
              {
                name: 'Transactions',
                icon: ReceiptTwoToneIcon,
                link: '/app/booking-transactions/list'
              }
            ]
          },

          {
            name: 'Promo Codes',
            icon: LocalActivityTwoToneIcon,
            link: '/app/promo-codes'
          }
        ]
      },

      {
        heading: 'Finance',
        items: [
          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices',
            items: [
              {
                name: 'Listing',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices/list'
              },

              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/invoices/search'
              }
            ]
          },

          {
            name: 'Credit Notes',
            icon: RequestQuoteTwoToneIcon,
            link: '/app/creditnotes',
            items: [
              {
                name: 'Listing',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/creditnotes/search'
              }
            ]
          },

          {
            name: 'Payments',
            icon: PaidIcon,
            link: '/app/payments/list'
          }
        ]
      },
      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'TimeOffs',
            icon: TimerOffTwoToneIcon,
            link: '/app/timeoffs'
          },

          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Users',
            icon: ManageAccountsTwoToneIcon,
            link: '/app/users'
          },

          {
            name: 'Locations',
            icon: LocationOnTwoToneIcon,
            link: '/app/locations'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
          // {
          //   name: 'System Settings',
          //   icon: SettingsTwoToneIcon,
          //   link: '/app/settings'
          // }
        ]
      }
    ],
    'admin-owner': [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          },

          {
            name: 'Online Booking',
            icon: BookOnlineTwoToneIcon,
            link: '/app/bookingpages',
            items: [
              {
                name: 'Booking Pages',
                icon: AutoStoriesTwoToneIcon,
                link: '/app/booking-pages/list'
              },
              {
                name: 'Transactions',
                icon: ReceiptTwoToneIcon,
                link: '/app/booking-transactions/list'
              }
            ]
          },

          {
            name: 'Promo Codes',
            icon: LocalActivityTwoToneIcon,
            link: '/app/promo-codes'
          }
        ]
      },

      {
        heading: 'Finance',
        items: [
          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices',
            items: [
              {
                name: 'Listing',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices/list'
              },

              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/invoices/search'
              }
            ]
          },

          {
            name: 'Credit Notes',
            icon: RequestQuoteTwoToneIcon,
            link: '/app/creditnotes',
            items: [
              {
                name: 'Listing',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/creditnotes/search'
              }
            ]
          },

          {
            name: 'Payments',
            icon: PaidIcon,
            link: '/app/payments/list'
          }
        ]
      },
      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'TimeOffs',
            icon: TimerOffTwoToneIcon,
            link: '/app/timeoffs'
          },

          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Users',
            icon: ManageAccountsTwoToneIcon,
            link: '/app/users'
          },

          {
            name: 'Locations',
            icon: LocationOnTwoToneIcon,
            link: '/app/locations'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
          // {
          //   name: 'System Settings',
          //   icon: SettingsTwoToneIcon,
          //   link: '/app/settings'
          // }
        ]
      }
    ],
    'admin-practitioner-owner': [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              },
              {
                name: 'Copilot',
                icon: ModelTrainingTwoToneIcon,
                link: 'communications/followup'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          },

          {
            name: 'Online Booking',
            icon: BookOnlineTwoToneIcon,
            link: '/app/bookingpages',
            items: [
              {
                name: 'Booking Pages',
                icon: AutoStoriesTwoToneIcon,
                link: '/app/booking-pages/list'
              },
              {
                name: 'Transactions',
                icon: ReceiptTwoToneIcon,
                link: '/app/booking-transactions/list'
              }
            ]
          },

          {
            name: 'Promo Codes',
            icon: LocalActivityTwoToneIcon,
            link: '/app/promo-codes'
          }
        ]
      },

      {
        heading: 'Finance',
        items: [
          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices',
            items: [
              {
                name: 'Listing',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices/list'
              },

              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/invoices/search'
              }
            ]
          },

          {
            name: 'Credit Notes',
            icon: RequestQuoteTwoToneIcon,
            link: '/app/creditnotes',
            items: [
              {
                name: 'Listing',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/creditnotes/search'
              }
            ]
          },

          {
            name: 'Payments',
            icon: PaidIcon,
            link: '/app/payments/list'
          }
        ]
      },
      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'TimeOffs',
            icon: TimerOffTwoToneIcon,
            link: '/app/timeoffs'
          },

          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Users',
            icon: ManageAccountsTwoToneIcon,
            link: '/app/users'
          },

          {
            name: 'Locations',
            icon: LocationOnTwoToneIcon,
            link: '/app/locations'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
          // {
          //   name: 'System Settings',
          //   icon: SettingsTwoToneIcon,
          //   link: '/app/settings'
          // }
        ]
      }
    ],
    operations: [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          },

          {
            name: 'Online Booking',
            icon: BookOnlineTwoToneIcon,
            link: '/app/bookingpages',
            items: [
              {
                name: 'Booking Pages',
                icon: AutoStoriesTwoToneIcon,
                link: '/app/booking-pages/list'
              },
              {
                name: 'Transactions',
                icon: ReceiptTwoToneIcon,
                link: '/app/booking-transactions/list'
              }
            ]
          },

          {
            name: 'Promo Codes',
            icon: LocalActivityTwoToneIcon,
            link: '/app/promo-codes'
          }
        ]
      },

      {
        heading: 'Finance',
        items: [
          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices',
            items: [
              {
                name: 'Listing',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/invoices/search'
              }
            ]
          },

          {
            name: 'Credit Notes',
            icon: RequestQuoteTwoToneIcon,
            link: '/app/creditnotes',
            items: [
              {
                name: 'Listing',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes/list'
              },
              {
                name: 'Search',
                icon: SearchTwoToneIcon,
                link: '/app/creditnotes/search'
              }
            ]
          },

          {
            name: 'Payments',
            icon: PaidIcon,
            link: '/app/payments/list'
          }
        ]
      },
      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'TimeOffs',
            icon: TimerOffTwoToneIcon,
            link: '/app/timeoffs'
          },

          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Users',
            icon: ManageAccountsTwoToneIcon,
            link: '/app/users'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
        ]
      }
    ],
    practitioner: [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Queue',
            icon: AccessTimeTwoToneIcon,
            link: '/app/queue'
          },

          {
            name: 'Calendar',
            icon: TodayIcon,
            link: '/app/calendar'
          },

          {
            name: 'Patients',
            icon: GroupTwoToneIcon,
            link: '/app/patients'
          },

          {
            name: 'Classes',
            icon: GroupsTwoToneIcon,
            link: '/app/classes'
          },

          {
            name: 'Communications',
            icon: PermPhoneMsgTwoToneIcon,
            link: '/accent-header/dashboards',
            items: [
              {
                name: 'SMS',
                icon: SmsTwoToneIcon,
                link: 'communications/sms'
                // badgeTooltip: 'SMS'
              },
              {
                name: 'Email',
                icon: EmailTwoToneIcon,
                link: 'communications/email'
              },
              {
                name: 'Copilot',
                icon: ModelTrainingTwoToneIcon,
                link: 'communications/followup'
              }
            ]
          }
        ]
      },

      {
        heading: 'Marketing',
        items: [
          {
            name: 'Corporates',
            icon: CorporateFareTwoToneIcon,
            link: '/app/corporates'
          },

          {
            name: 'Contacts',
            icon: RecentActorsTwoToneIcon,
            link: '/app/contacts'
          }
        ]
      },

      {
        heading: 'Analytics',
        items: [
          {
            name: 'Reports',
            link: '/app/reports/list',
            icon: AnalyticsTwoToneIcon
          }
        ]
      },

      {
        heading: 'Manage',
        items: [
          {
            name: 'Inventory',
            icon: TableChartTwoToneIcon,
            link: '/app/inventory'
          },

          {
            name: 'Services',
            icon: HealingTwoToneIcon,
            link: '/app/services'
          },

          {
            name: 'Services (Class)',
            icon: GroupsTwoToneIcon,
            link: '/app/class-services'
          },

          {
            name: 'Passes',
            icon: ConfirmationNumberTwoToneIcon,
            link: '/app/passes'
          },

          {
            name: 'Plan Templates',
            icon: SchemaTwoToneIcon,
            link: '/app/plan-templates'
          },

          {
            name: 'Account Settings',
            icon: VpnKeyTwoToneIcon,
            link: '/app/preferences'
          }
        ]
      }
    ]
  };

  try {
    const sideMenu = {
      cityosteophysio: {
        // cop
        admin: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },
              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },
              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },
              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-practitioner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },
                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },
              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },
              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        operations: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              }

              // {
              //   name: 'Communications',
              //   icon: PermPhoneMsgTwoToneIcon,
              //   link: '/accent-header/dashboards',
              //   items: [
              //     {
              //       name: 'SMS',
              //       icon: SmsTwoToneIcon,
              //       link: 'communications/sms'
              //       // badgeTooltip: 'SMS'
              //     },
              //     {
              //       name: 'Email',
              //       icon: EmailTwoToneIcon,
              //       link: 'communications/email'
              //     }
              //   ]
              // }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },

          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              // {
              //   name: 'Services',
              //   icon: HealingTwoToneIcon,
              //   link: '/app/services'
              // },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        practitioner: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },
                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              }
            ]
          },

          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              // {
              //   name: 'Inventory',
              //   icon: TableChartTwoToneIcon,
              //   link: '/app/inventory'
              // },

              // {
              //   name: 'Services',
              //   icon: HealingTwoToneIcon,
              //   link: '/app/services'
              // },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ]
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmw
        admin: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/customers'
              },
              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },
              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },
              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },
              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },
              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-practitioner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/customers'
              },
              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },
                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },
              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },
              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },
              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },
              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        operations: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/customers'
              },
              ,
              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },
              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },
              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },
              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },
              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },
              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        practitioner: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },
              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },
              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },
              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/customers'
              },
              ,
              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },
                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },
              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              }
            ]
          },

          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },
              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },
              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ]
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtt
        admin: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  // {
                  //   name: 'Listing',
                  //   icon: ReceiptTwoToneIcon,
                  //   link: '/app/invoices/list'
                  // },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              }
              // {
              //   name: 'Payments',
              //   icon: PaidIcon,
              //   link: '/app/payments/list'
              // }
            ]
          },

          // {
          //   heading: 'Analytics',
          //   items: [
          //     {
          //       name: 'Reports',
          //       link: '/app/reports/list',
          //       icon: AnalyticsTwoToneIcon
          //     }
          //   ]
          // },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        'admin-practitioner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  // {
                  //   name: 'Listing',
                  //   icon: ReceiptTwoToneIcon,
                  //   link: '/app/invoices/list'
                  // },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              }
              // {
              //   name: 'Payments',
              //   icon: PaidIcon,
              //   link: '/app/payments/list'
              // }
            ]
          },

          // {
          //   heading: 'Analytics',
          //   items: [
          //     {
          //       name: 'Reports',
          //       link: '/app/reports/list',
          //       icon: AnalyticsTwoToneIcon
          //     }
          //   ]
          // },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        'admin-practitioner-owner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },

                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-owner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },

                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        operations: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  // {
                  //   name: 'Listing',
                  //   icon: ReceiptTwoToneIcon,
                  //   link: '/app/invoices/list'
                  // },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              }
              // {
              //   name: 'Payments',
              //   icon: PaidIcon,
              //   link: '/app/payments/list'
              // }
            ]
          },

          // {
          //   heading: 'Analytics',
          //   items: [
          //     {
          //       name: 'Reports',
          //       link: '/app/reports/list',
          //       icon: AnalyticsTwoToneIcon
          //     }
          //   ]
          // },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        practitioner: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Customers',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  // {
                  //   name: 'Listing',
                  //   icon: ReceiptTwoToneIcon,
                  //   link: '/app/invoices/list'
                  // },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              }
              // {
              //   name: 'Payments',
              //   icon: PaidIcon,
              //   link: '/app/payments/list'
              // }
            ]
          },

          // {
          //   heading: 'Analytics',
          //   items: [
          //     {
          //       name: 'Reports',
          //       link: '/app/reports/list',
          //       icon: AnalyticsTwoToneIcon
          //     }
          //   ]
          // },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              // {
              //   name: 'Passes',
              //   icon: ConfirmationNumberTwoToneIcon,
              //   link: '/app/passes'
              // },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ]
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // csg
        admin: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              {
                name: 'Plan Templates',
                icon: SchemaTwoToneIcon,
                link: '/app/plan-templates'
              },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Stations',
                icon: GroupWorkTwoToneIcon,
                link: '/app/stations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-owner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },

                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              {
                name: 'Plan Templates',
                icon: SchemaTwoToneIcon,
                link: '/app/plan-templates'
              },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Stations',
                icon: GroupWorkTwoToneIcon,
                link: '/app/stations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        operations: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              
              {
                name: 'Credit Notes',
                icon: RequestQuoteTwoToneIcon,
                link: '/app/creditnotes',
                items: [
                  {
                    name: 'Listing',
                    icon: RequestQuoteTwoToneIcon,
                    link: '/app/creditnotes/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/creditnotes/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              {
                name: 'Plan Templates',
                icon: SchemaTwoToneIcon,
                link: '/app/plan-templates'
              },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        practitioner: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              {
                name: 'Classes',
                icon: GroupsTwoToneIcon,
                link: '/app/classes'
              },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              }
            ]
          },

          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              {
                name: 'Services (Class)',
                icon: GroupsTwoToneIcon,
                link: '/app/class-services'
              },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              {
                name: 'Plan Templates',
                icon: SchemaTwoToneIcon,
                link: '/app/plan-templates'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ]
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // keiki
        admin: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },
              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-practitioner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },

                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        'admin-practitioner-owner': [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },

                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              {
                name: 'Passes',
                icon: ConfirmationNumberTwoToneIcon,
                link: '/app/passes'
              },

              // {
              //   name: 'Plan Templates',
              //   icon: SchemaTwoToneIcon,
              //   link: '/app/plan-templates'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Locations',
                icon: LocationOnTwoToneIcon,
                link: '/app/locations'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
              // {
              //   name: 'System Settings',
              //   icon: SettingsTwoToneIcon,
              //   link: '/app/settings'
              // }
            ]
          }
        ],
        operations: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },
                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              },

              {
                name: 'Online Booking',
                icon: BookOnlineTwoToneIcon,
                link: '/app/bookingpages',
                items: [
                  {
                    name: 'Booking Pages',
                    icon: AutoStoriesTwoToneIcon,
                    link: '/app/booking-pages/list'
                  },
                  {
                    name: 'Transactions',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/booking-transactions/list'
                  }
                ]
              },

              {
                name: 'Promo Codes',
                icon: LocalActivityTwoToneIcon,
                link: '/app/promo-codes'
              }
            ]
          },

          {
            heading: 'Finance',
            items: [
              {
                name: 'Invoices',
                icon: ReceiptTwoToneIcon,
                link: '/app/invoices',
                items: [
                  {
                    name: 'Listing',
                    icon: ReceiptTwoToneIcon,
                    link: '/app/invoices/list'
                  },
                  {
                    name: 'Search',
                    icon: SearchTwoToneIcon,
                    link: '/app/invoices/search'
                  }
                ]
              },

              {
                name: 'Payments',
                icon: PaidIcon,
                link: '/app/payments/list'
              }
            ]
          },
          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'TimeOffs',
                icon: TimerOffTwoToneIcon,
                link: '/app/timeoffs'
              },

              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              {
                name: 'Users',
                icon: ManageAccountsTwoToneIcon,
                link: '/app/users'
              },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ],
        practitioner: [
          {
            heading: 'General',
            items: [
              {
                name: 'Home',
                icon: HomeIcon,
                link: '/app/overview/home'
              },

              {
                name: 'Queue',
                icon: AccessTimeTwoToneIcon,
                link: '/app/queue'
              },

              {
                name: 'Calendar',
                icon: TodayIcon,
                link: '/app/calendar'
              },

              {
                name: 'Patients',
                icon: GroupTwoToneIcon,
                link: '/app/patients'
              },

              // {
              //   name: 'Classes',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/classes'
              // },

              {
                name: 'Communications',
                icon: PermPhoneMsgTwoToneIcon,
                link: '/accent-header/dashboards',
                items: [
                  {
                    name: 'SMS',
                    icon: SmsTwoToneIcon,
                    link: 'communications/sms'
                    // badgeTooltip: 'SMS'
                  },

                  {
                    name: 'Email',
                    icon: EmailTwoToneIcon,
                    link: 'communications/email'
                  },

                  {
                    name: 'Copilot',
                    icon: ModelTrainingTwoToneIcon,
                    link: 'communications/followup'
                  }
                ]
              }
            ]
          },

          {
            heading: 'Marketing',
            items: [
              {
                name: 'Corporates',
                icon: CorporateFareTwoToneIcon,
                link: '/app/corporates'
              },

              {
                name: 'Contacts',
                icon: RecentActorsTwoToneIcon,
                link: '/app/contacts'
              }
            ]
          },

          {
            heading: 'Analytics',
            items: [
              {
                name: 'Reports',
                link: '/app/reports/list',
                icon: AnalyticsTwoToneIcon
              }
            ]
          },

          {
            heading: 'Manage',
            items: [
              {
                name: 'Inventory',
                icon: TableChartTwoToneIcon,
                link: '/app/inventory'
              },

              {
                name: 'Services',
                icon: HealingTwoToneIcon,
                link: '/app/services'
              },

              // {
              //   name: 'Services (Class)',
              //   icon: GroupsTwoToneIcon,
              //   link: '/app/class-services'
              // },

              {
                name: 'Account Settings',
                icon: VpnKeyTwoToneIcon,
                link: '/app/preferences'
              }
            ]
          }
        ]
      }
    };

    const result = sideMenu[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getTerminalSideMenu = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    terminal: [
      {
        heading: 'General',
        items: [
          {
            name: 'Patient Profile',
            icon: BackupTableTwoToneIcon,
            link: 'terminal/home'
          }
        ]
      }
    ]
  };

  try {
    const sideMenu = {
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        terminal: [
          {
            heading: 'General',
            items: [
              {
                name: 'Customer Profile',
                icon: BackupTableTwoToneIcon,
                link: 'terminal/home'
              }
            ]
          }
        ]
      }
    };

    const result = sideMenu[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCustomerTabMenu = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [
    { value: 'profile', label: 'Profile' },
    { value: 'bookings', label: 'Appointments' },
    { value: 'notes', label: 'Notes' },
    { value: 'passes', label: 'Passes' },
    { value: 'finances', label: 'Finances' },
    { value: 'communication', label: 'Communication' },
    { value: 'documents', label: 'Documents' },
    { value: 'feedback', label: 'Feedback' },
    { value: 'memos', label: 'Memos' },
    { value: 'blocks', label: 'Blocks' }
    // { value: 'notifications', label: t('Notifications') },
    // { value: 'security', label: t('Passwords/Security') }
  ];

  try {
    const customerTabMenu = {
      cityosteophysio: [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'passes', label: 'Passes' },
        { value: 'plans', label: 'Plans' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'passes', label: 'Passes' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ],

      // Dev
      'a75b6463-f88f-4299-ad5d-b184eeae0513': [
        { value: 'profile', label: 'Profile' },
        // { value: 'appointments', label: 'Appointments' },
        { value: 'bookings', label: 'Appointments' },
        { value: 'notes', label: 'Notes' },
        { value: 'passes', label: 'Passes' },
        { value: 'plans', label: 'Plans' },
        { value: 'finances', label: 'Finances' },
        { value: 'communication', label: 'Communication' },
        { value: 'documents', label: 'Documents' },
        { value: 'memos', label: 'Memos' },
        { value: 'feedback', label: 'Feedback' }
      ]
    };

    const result = customerTabMenu[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

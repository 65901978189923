export const showInvoiceCharts = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    owner: true,
    admin: true,
    'admin-practitioner': true,
    'admin-practitioner-owner': true,
    practitioner: true,
    operations: false
  };

  try {
    const showInvoiceChartMapping = {
      cityosteophysio: {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        owner: true,
        admin: false,
        'admin-practitioner': false,
        'admin-practitioner-owner': true,
        practitioner: false,
        operations: false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      }
    };

    const result = showInvoiceChartMapping[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const showPaymentCharts = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    owner: true,
    admin: true,
    'admin-practitioner': true,
    'admin-practitioner-owner': true,
    practitioner: true,
    operations: false
  };

  try {
    const showPaymentChartMapping = {
      cityosteophysio: {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        owner: true,
        admin: false,
        'admin-practitioner': false,
        'admin-practitioner-owner': true,
        practitioner: false,
        operations: false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        owner: true,
        admin: true,
        'admin-practitioner': true,
        'admin-practitioner-owner': true,
        practitioner: true,
        operations: false
      }
    };

    const result = showPaymentChartMapping[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

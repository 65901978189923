import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';
import { endOfToday } from 'date-fns';

import {
  createAppointmentPlanTemplateAPI,
  updateAppointmentPlanTemplateAPI,
  deleteAppointmentPlanTemplateAPI,
  listAppointmentPlanTemplatesAPI,
  getAppointmentPlanTemplateAPI
} from 'src/content/AppointmentPlanTemplates/api';

interface AppointmentPlanTemplateState {
  isLoadingPlanTemplates: boolean;
  isEditingPlanTemplate: boolean;
  isCreatingPlanTemplate: boolean;
  isDeletingPlanTemplate: boolean;
  allAppointmentPlanTemplatesList: any[];
  activeAppointmentPlanTemplatesList: any[];
  inactiveAppointmentPlanTemplatesList: any[];
  appointmentPlanTemplateDetail: any[];
  appointmentPlanTemplateErrorMessage: string;
  appointmentPlanTemplateSuccessMessage: string;
}

const initialState: AppointmentPlanTemplateState = {
  isLoadingPlanTemplates: false,
  isEditingPlanTemplate: false,
  isCreatingPlanTemplate: false,
  isDeletingPlanTemplate: false,
  allAppointmentPlanTemplatesList: [],
  activeAppointmentPlanTemplatesList: [],
  inactiveAppointmentPlanTemplatesList: [],
  appointmentPlanTemplateDetail: [],
  appointmentPlanTemplateErrorMessage: '',
  appointmentPlanTemplateSuccessMessage: ''
};

// Sort by created time DSCending first
const sortAppointmentPlanTemplatesDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

// Sort by created time ASCending first
const sortAppointmentPlanTemplatesAsc = (a, b) => {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'appointmentPlanTemplates',
  initialState,
  reducers: {
    getAppointmentPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        state.appointmentPlanTemplateDetail = [action.payload];
      } else {
        state.appointmentPlanTemplateDetail = [{ id: 'Not Found' }];
      }
    },

    listAppointmentPlanTemplates(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      const allAppointmentPlanTemplates = action.payload;

      allAppointmentPlanTemplates.sort(sortAppointmentPlanTemplatesDesc);

      state.allAppointmentPlanTemplatesList = [...allAppointmentPlanTemplates];

      state.activeAppointmentPlanTemplatesList =
        allAppointmentPlanTemplates?.filter(
          (template) => template.isActive === true
        );

      state.inactiveAppointmentPlanTemplatesList =
        allAppointmentPlanTemplates?.filter(
          (template) => template.isActive === false
        );
    },

    createAppointmentPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      const newAppointmentPlanTemplate = action.payload;

      state.allAppointmentPlanTemplatesList = [
        newAppointmentPlanTemplate,
        ...state.allAppointmentPlanTemplatesList
      ];

      if (newAppointmentPlanTemplate.isActive) {
        state.activeAppointmentPlanTemplatesList = [
          newAppointmentPlanTemplate,
          ...state.activeAppointmentPlanTemplatesList
        ];
      }
    },

    updateAppointmentPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      const updatedAppointmentPlan = action.payload;

      state.appointmentPlanTemplateDetail =
        state.appointmentPlanTemplateDetail[0]?.id === updatedAppointmentPlan.id
          ? [updatedAppointmentPlan]
          : [...state.appointmentPlanTemplateDetail];

      let templateIndex = state.allAppointmentPlanTemplatesList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      // search all templates list
      if (templateIndex >= 0) {
        state.allAppointmentPlanTemplatesList =
          state.allAppointmentPlanTemplatesList.map((appt, i) =>
            i === templateIndex ? action.payload : appt
          );
      }

      templateIndex = state.activeAppointmentPlanTemplatesList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      // search active templates list
      if (templateIndex >= 0) {
        state.activeAppointmentPlanTemplatesList =
          state.activeAppointmentPlanTemplatesList.map((appt, i) =>
            i === templateIndex ? action.payload : appt
          );
      }

      templateIndex = state.inactiveAppointmentPlanTemplatesList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      // search inactive templates list
      if (templateIndex >= 0) {
        state.inactiveAppointmentPlanTemplatesList =
          state.inactiveAppointmentPlanTemplatesList.map((appt, i) =>
            i === templateIndex ? action.payload : appt
          );
      }
    },

    deleteAppointmentPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.allAppointmentPlanTemplatesList =
        state.allAppointmentPlanTemplatesList.filter(
          (template) => template.id !== action.payload.id
        );

      state.activeAppointmentPlanTemplatesList =
        state.activeAppointmentPlanTemplatesList.filter(
          (template) => template.id !== action.payload.id
        );

      state.inactiveAppointmentPlanTemplatesList =
        state.inactiveAppointmentPlanTemplatesList.filter(
          (template) => template.id !== action.payload.id
        );
    },

    clearSelectedAppointmentPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.appointmentPlanTemplateDetail = [];
    },

    setIsLoadingPlanTemplates(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.isLoadingPlanTemplates = action.payload;
    },

    setIsEditingPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.isEditingPlanTemplate = action.payload;
    },

    setIsCreatingPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.isCreatingPlanTemplate = action.payload;
    },

    setIsDeletingPlanTemplate(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.isDeletingPlanTemplate = action.payload;
    },

    setErrorMessage(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.appointmentPlanTemplateErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: AppointmentPlanTemplateState,
      action: PayloadAction<any>
    ) {
      state.appointmentPlanTemplateSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

// Missing Get and List methods
export const getAppointmentPlanTemplate =
  (templateId): AppThunk =>
  async (dispatch) => {
    const response = await getAppointmentPlanTemplateAPI(templateId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getAppointmentPlanTemplate(
          response.data.getAppointmentPlanTemplate
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listAppointmentPlanTemplates =
  (): AppThunk => async (dispatch) => {
    dispatch(startLoadingPlanTemplates());

    const response = await backOff(() => listAppointmentPlanTemplatesAPI(), {
      numOfAttempts: 3
    });

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listAppointmentPlanTemplates(
          response.data.listAppointmentPlanTemplates
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingPlanTemplates());
  };

export const createAppointmentPlanTemplate =
  (template): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingPlanTemplate());
    dispatch(startLoadingPlanTemplates());

    const response = await createAppointmentPlanTemplateAPI(template);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createAppointmentPlanTemplate(
          response.data.createAppointmentPlanTemplate
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Template successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingPlanTemplate());
      dispatch(stopLoadingPlanTemplates());
    };

    return followUp();
  };

export const updateAppointmentPlanTemplate =
  (template): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingPlanTemplate());

    const response = await updateAppointmentPlanTemplateAPI(template);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateAppointmentPlanTemplate(
          response.data.updateAppointmentPlanTemplate
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Template successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingPlanTemplate());
  };

export const deleteAppointmentPlanTemplate =
  (templateId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPlanTemplates());
    dispatch(startDeletingPlanTemplate());

    const response = await deleteAppointmentPlanTemplateAPI(templateId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteAppointmentPlanTemplate(
          response.data.deleteAppointmentPlanTemplate
        )
      );
      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Template successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingPlanTemplate());
      dispatch(stopLoadingPlanTemplates());
    };

    return followUp();
  };

export const clearSelectedAppointmentPlanTemplate =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedAppointmentPlanTemplate({}));
  };

export const startLoadingPlanTemplates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPlanTemplates(true));
};

export const stopLoadingPlanTemplates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPlanTemplates(false));
};

export const startEditingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPlanTemplate(true));
};

export const stopEditingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPlanTemplate(false));
};

export const startCreatingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPlanTemplate(true));
};

export const stopCreatingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPlanTemplate(false));
};

export const startDeletingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingPlanTemplate(true));
};

export const stopDeletingPlanTemplate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingPlanTemplate(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

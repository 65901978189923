import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateCommunicationMutation,
  ListPatientCommunicationsQuery,
  ListAppointmentCommunicationsQuery,
  ListCommunicationsInDateRangeQuery,
  ListReviewerCommunicationsQuery,
  UpdateCommunicationMutation,
  VoidCommunicationMutation
} from 'src/API';
import {
  createCommunication,
  updateCommunication,
  voidCommunication
} from 'src/graphql/mutations';
import {
  listAppointmentCommunications,
  listPatientCommunications,
  listCommunicationsInDateRange,
  listReviewerCommunications
} from 'src/graphql/queries';
// import { listAllInventoriesQuery } from './queries';

export const createCommunicationAPI = async (commsInput) => {
  try {
    const communication = await callGraphQL<CreateCommunicationMutation>(
      createCommunication,
      {
        variables: {
          input: {
            appointmentId: commsInput?.appointmentId || '',
            body: commsInput.body,
            cc: commsInput.cc || [],
            bcc: commsInput.bcc || [],
            destination: commsInput.destination,
            documents: commsInput.documents,
            from: commsInput.from || 'contact@gethelm.io',
            fromName: commsInput.fromName,
            medium: commsInput.medium,
            patientId: commsInput.patientId,
            replyTo: commsInput.replyTo || {
              email: 'contact@gethelm.io',
              name: 'Clinic'
            },
            subject: commsInput.subject,
            type: commsInput.type,
            invoiceId: commsInput?.invoiceId || ''
          }
        }
      }
    );

    return communication;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCommunicationAPI = async (commsInput) => {
  try {
    const communication = await callGraphQL<UpdateCommunicationMutation>(
      updateCommunication,
      {
        variables: {
          input: {
            id: commsInput.id,
            body: commsInput.body,
            cc: commsInput?.cc || [],
            bcc: commsInput?.bcc || [],
            destination: commsInput.destination,
            documents: commsInput?.documents || [],
            from: commsInput.from || 'contact@gethelm.io',
            fromName: commsInput.fromName,
            patientId: commsInput.patientId,
            replyTo: commsInput.replyTo || {
              email: 'contact@gethelm.io',
              name: 'Clinic'
            },
            subject: commsInput.subject,
            invoiceId: commsInput?.invoiceId || '',
            status: commsInput?.status || 'Draft'
          }
        }
      }
    );

    return communication;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientCommunicationsAPI = async (patientId) => {
  try {
    const patientComms = await callGraphQL<ListPatientCommunicationsQuery>(
      listPatientCommunications,
      {
        variables: {
          patientId
        }
      }
    );

    return patientComms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listPatientRecentCommunicationsAPI = async (
  patientId,
  startTime,
  endTime
) => {
  try {
    const patientComms = await callGraphQL<ListPatientCommunicationsQuery>(
      listPatientCommunications,
      {
        variables: {
          patientId,
          startTime,
          endTime
        }
      }
    );

    return patientComms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listAppointmentCommunicationsAPI = async (appointmentId) => {
  try {
    const apptComms = await callGraphQL<ListAppointmentCommunicationsQuery>(
      listAppointmentCommunications,
      {
        variables: {
          appointmentId
        }
      }
    );

    return apptComms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCommunicationsInDateRangeAPI = async (startTime, endTime) => {
  try {
    const comms = await callGraphQL<ListCommunicationsInDateRangeQuery>(
      listCommunicationsInDateRange,
      {
        variables: {
          startTime,
          endTime
        }
      }
    );

    // console.log(comms);
    return comms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listReviewerCommunicationsAPI = async (
  reviewerId,
  status,
  startTime,
  endTime
) => {
  try {
    const comms = await callGraphQL<ListReviewerCommunicationsQuery>(
      listReviewerCommunications,
      {
        variables: {
          reviewerId,
          status,
          startTime,
          endTime
        }
      }
    );

    // console.log(comms);
    return comms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createConfirmationCommunicationAPI = async (
  patientId,
  appointmentId,
  medium,
  from,
  fromName,
  replyTo,
  replyToName,
  subject,
  templateId
) => {
  try {
    const communication = await callGraphQL<CreateCommunicationMutation>(
      createCommunication,
      {
        variables: {
          input: {
            patientId,
            appointmentId,
            medium,
            from,
            type: 'Reminder',
            fromName,
            replyTo: {
              email: replyTo,
              name: replyToName
            },
            subject,
            templateId
          }
        }
      }
    );

    return communication;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const voidCommunicationAPI = async (id, voidReason) => {
  try {
    const voidedComms = await callGraphQL<VoidCommunicationMutation>(
      voidCommunication,
      {
        variables: {
          id,
          voidReason
        }
      }
    );

    // console.log(voidedComms);
    return voidedComms;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const customVoidPatientPass = /* GraphQL */ `
  mutation VoidPatientPass($id: ID!, $reason: String!) {
    voidPatientPass(id: $id, reason: $reason) {
      id
      ownerId
      ownerCorporateId
      # patientIds
      passId
      passName
      passCode
      purchaseId
      invoiceId
      purchasedLocationId
      eligibleClassServices
      eligibleServices
      status
      note
      totalRedemptions
      redemptionsMade
      redemptionsRemaining
      redemptionValue {
        amount
        currency
        precision
        scale
      }
      expiryDate
      assignedDate
      purchaseDate
      # purchasedLocation {
      #  id
      #  name
      #  code
      #  addressStreetAddress
      #  addressUnitNo
      #  addressCountry
      #  addressPostalCode
      #  taxStatus
      #  registeredName
      #  registrationNumber
      #  operatingHours
      #  telephones
      #  whatsapp
      #  emails
      #  createdAt
      #  createdBy
      #  updatedAt
      #  updatedBy
      # }
      passRedemptions {
        id
        patientPassId
        patientId
        locationId
        appointmentId
        practitionerId
        serviceId
        classId
        classServiceId
        invoiceId
        redemptionNote
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

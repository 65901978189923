export const customGetWalletByPatient = /* GraphQL */ `
  query GetWalletByPatient($patientId: ID!) {
    getWalletByPatient(patientId: $patientId) {
      id
      patientId
      balance {
        amount
        currency
        precision
        scale
      }
      transactions {
        id
        amount {
          amount
          currency
          precision
          scale
        }
        type
        note
        paymentId
        paymentNumber
        transactionNumber
        transactionTime
        createdAt
        createdBy
        voidedAt
        voidedBy
        voidedReason
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetPatientWalletBalance = /* GraphQL */ `
  query GetWalletByPatient($patientId: ID!) {
    getWalletByPatient(patientId: $patientId) {
      id
      patientId
      balance {
        amount
        currency
        precision
        scale
      }
    }
  }
`;

export const customListAppointmentPlanTemplates = /* GraphQL */ `
  query ListAppointmentPlanTemplates(
    $minCreatedTime: AWSDateTime
    $maxCreatedTime: AWSDateTime
  ) {
    listAppointmentPlanTemplates(
      minCreatedTime: $minCreatedTime
      maxCreatedTime: $maxCreatedTime
    ) {
      id
      name
      description
      planType
      # allowedServices {
      #  id
      #  name
      # }
      # allowedClassServices {
      #  id
      #  name
      # }
      schedule {
        periodType
        periodFrequency
        totalPeriods
      }
      # milestones {
      #  appointmentSequence
      #  milestone
      # }
      totalSessions
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

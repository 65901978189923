export const listAvailableClassServicesQuery = /* GraphQL */ `
  query ListClassServices($locationId: ID, $practitionerId: ID) {
    listClassServices(
      locationId: $locationId
      practitionerId: $practitionerId
    ) {
      id
      name
      description
      code
      category
      duration
      isActive
      price {
        amount
        currency
        precision
        scale
      }
      locationId
      practitionerId
      onlineBookingName
      onlineBookingCategory
      onlineBookingDescription
      # location {
      #   id
      #   name
      # }
      # practitioner {
      #   id
      #   firstName
      #   lastName
      # }
      maxParticipants
    }
  }
`;

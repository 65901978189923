import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material';

const RaisedPopper = styled(Popper)(
  ({ theme }) => `
      z-index: 10;
`
);

export default function CustomSplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // const handleClick = () => {
  //   console.info(`You clicked ${props?.options[selectedIndex].label}`);
  // };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        color={props?.color || 'primary'}
        ref={anchorRef}
        aria-label="split button"
        fullWidth={props?.fullWidth}
        sx={{ mx: 1 }}
      >
        <Button
          data-testid={props?.options[selectedIndex]?.testid}
          startIcon={props?.startIcon}
          disabled={props?.disabled}
          onClick={props?.options[selectedIndex]?.function}
          color={props?.options[selectedIndex]?.color || 'primary'}
        >
          {props?.options[selectedIndex]?.label}
        </Button>
        <Button
          data-testid={props.testid}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={props?.disabled}
          color={props?.options[selectedIndex]?.color || 'primary'}
          sx={{ maxWidth: '20px' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <RaisedPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props?.options.map((option, index) => (
                    <MenuItem
                      data-testid={'option-' + option.label.replace(' ', '_')}
                      key={option.label}
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </RaisedPopper>
    </React.Fragment>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
// import axios from 'src/utils/axios';
// import objectArray from 'src/utils/objectArray';
// import type { Mail, Tag } from 'src/models/mailbox';
import {
  listReducedCustomerPiiProfilesAPI,
  getCustomerAPI,
  createCustomerAPI,
  deleteCustomerAPI,
  deleteCustomerCreditCardAPI,
  addCustomerCreditCardAPI,
  updatePatientPersonalDetailsAPI,
  updateCustomerInternalNoteAPI,
  updateCustomerEmailAPI
} from '../content/Patients/api';
import { createCommunicationAPI } from 'src/content/Communications/api';

import tenantMappings from 'src/utils/tenantMappings';

interface PatientState {
  isSearchingCustomers: boolean;
  isEditMarketingFormLoading: boolean;
  isMergingCustomerData: boolean;
  isSubmittingInternalNote: boolean;
  isRetrievingQueueCustomer: boolean;
  isSendingCardEmail: boolean;
  isUpdatingCustomer: boolean;
  isCreatingCustomer: boolean;
  isDeletingCustomerCard: boolean;
  customersResults: any[];
  selectedPatient: any;
  queueSelectedCustomer: any;
  createdCustomer: any;
  customerErrorMessage: string;
  customerSuccessMessage: string;
  quickActionCustomerErrorMessage: string;
  quickActionCustomerSuccessMessage: string;
}

const initialState: PatientState = {
  isSearchingCustomers: false,
  isEditMarketingFormLoading: false,
  isMergingCustomerData: false,
  isSubmittingInternalNote: false,
  isRetrievingQueueCustomer: false,
  isSendingCardEmail: false,
  isUpdatingCustomer: false,
  isCreatingCustomer: false,
  isDeletingCustomerCard: false,
  customersResults: [],
  selectedPatient: {},
  queueSelectedCustomer: {},
  createdCustomer: {},
  customerErrorMessage: '',
  customerSuccessMessage: '',
  quickActionCustomerErrorMessage: '',
  quickActionCustomerSuccessMessage: ''
};

const userMappings = tenantMappings?.userMappings;
const communicationMappings = tenantMappings?.communicationMappings;
const dictionaryMappings = tenantMappings?.dictionaryMappings;

const slice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    getCustomersResults(state: PatientState, action: PayloadAction<any>) {
      const patients = action.payload;

      state.customersResults = patients;

      state.isSearchingCustomers = false;
    },

    getSelectedPatient(state: PatientState, action: PayloadAction<any>) {
      if (action.payload) {
        state.selectedPatient = action.payload;
      } else {
        state.selectedPatient = { id: 'Not Found' };
      }
    },

    getQueueSelectedCustomer(state: PatientState, action: PayloadAction<any>) {
      if (action.payload) {
        state.queueSelectedCustomer = action.payload;
      } else {
        state.queueSelectedCustomer = { id: 'Not Found' };
      }
    },

    clearSelectedPatient(state: PatientState, action: PayloadAction<any>) {
      state.selectedPatient = {};
    },

    clearQueueSelectedCustomer(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.queueSelectedCustomer = {};
    },

    createCustomer(state: PatientState, action: PayloadAction<any>) {
      const newPatient = action.payload;

      state.createdCustomer = newPatient;
    },

    clearCreatedCustomer(state: PatientState, action: PayloadAction<any>) {
      state.createdCustomer = {};
    },

    deleteCustomer(state: PatientState, action: PayloadAction<any>) {
      state.selectedPatient = {};
    },

    deleteCustomerCreditCard(state: PatientState, action: PayloadAction<any>) {
      const remainCards =
        state.selectedPatient?.piiProfile?.stripePaymentMethods.filter(
          (card) => card.id !== action.payload
        );

      state.selectedPatient = {
        ...state.selectedPatient,
        piiProfile: {
          ...state.selectedPatient.piiProfile,
          stripePaymentMethods: remainCards
        }
      };
    },

    editCustomerInternalNote(state: PatientState, action: PayloadAction<any>) {
      const editedCustomer = action.payload;

      const updatedCustomer = editedCustomer?.piiProfile?.internalNote
        ? {
            ...state.selectedPatient,
            piiProfile: {
              ...state.selectedPatient?.piiProfile,
              internalNote: editedCustomer?.piiProfile?.internalNote
            }
          }
        : state.selectedPatient;

      state.selectedPatient = updatedCustomer;
    },

    editQueueCustomerInternalNote(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      const editedCustomer = action.payload;

      const updatedCustomer = editedCustomer?.piiProfile?.internalNote
        ? {
            ...state.queueSelectedCustomer,
            piiProfile: {
              ...state.queueSelectedCustomer?.piiProfile,
              internalNote: editedCustomer?.piiProfile?.internalNote
            }
          }
        : state.queueSelectedCustomer;

      state.queueSelectedCustomer = updatedCustomer;
    },

    editCustomerEmail(state: PatientState, action: PayloadAction<any>) {
      const editedCustomer = action.payload;

      const updatedCustomer = editedCustomer?.piiProfile?.email
        ? {
            ...state.selectedPatient,
            piiProfile: {
              ...state.selectedPatient?.piiProfile,
              email: editedCustomer?.piiProfile?.email
            }
          }
        : state.selectedPatient;

      state.selectedPatient = updatedCustomer;
    },

    setIsEditMarketingFormLoading(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.isEditMarketingFormLoading = action.payload;
    },

    clearCustomersResults(state: PatientState, action: PayloadAction<any>) {
      state.customersResults = [];
    },

    setIsMergingCustomerData(state: PatientState, action: PayloadAction<any>) {
      state.isMergingCustomerData = action.payload;
    },

    setIsSearchingCustomers(state: PatientState, action: PayloadAction<any>) {
      state.isSearchingCustomers = action.payload;
    },

    setIsRetrievingQueueCustomer(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.isRetrievingQueueCustomer = action.payload;
    },

    setIsSubmittingInternalNote(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.isSubmittingInternalNote = action.payload;
    },

    setIsSendingCardEmail(state: PatientState, action: PayloadAction<any>) {
      state.isSendingCardEmail = action.payload;
    },

    setIsUpdatingCustomer(state: PatientState, action: PayloadAction<any>) {
      state.isUpdatingCustomer = action.payload;
    },

    setIsCreatingCustomer(state: PatientState, action: PayloadAction<any>) {
      state.isCreatingCustomer = action.payload;
    },

    setIsDeletingCustomerCard(state: PatientState, action: PayloadAction<any>) {
      state.isDeletingCustomerCard = action.payload;
    },

    setErrorMessage(state: PatientState, action: PayloadAction<any>) {
      state.customerErrorMessage = action.payload;
    },

    setSuccessMessage(state: PatientState, action: PayloadAction<any>) {
      state.customerSuccessMessage = action.payload;
    },

    setQuickActionErrorMessage(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.quickActionCustomerErrorMessage = action.payload;
    },

    setQuickActionSuccessMessage(
      state: PatientState,
      action: PayloadAction<any>
    ) {
      state.quickActionCustomerSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getCustomersResults =
  (query): AppThunk =>
  async (dispatch) => {
    dispatch(startSearchingCustomers());

    const response = await listReducedCustomerPiiProfilesAPI(query);
    // console.log(response)

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getCustomersResults(
          response.data.listPatientPiiProfiles.items
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopSearchingCustomers());
  };

export const getSelectedPatient =
  (patientId): AppThunk =>
  async (dispatch) => {
    const response = await getCustomerAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getSelectedPatient(response.data.getPatient));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getQueueSelectedCustomer =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startRetrievingQueueCustomer());

    const response = await getCustomerAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getQueueSelectedCustomer(response.data.getPatient)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopRetrievingQueueCustomer());
  };

export const clearSelectedPatient = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedPatient({}));
};

export const clearQueueSelectedCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearQueueSelectedCustomer({}));
};

export const createCustomer =
  (patient): AppThunk =>
  async (dispatch) => {
    const response = await createCustomerAPI(patient);
    // console.log(response)

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createCustomer(response.data.createPatient));
      dispatch(slice.actions.setSuccessMessage('Record successfully created'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearCreatedCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCreatedCustomer({}));
};

export const deleteCustomer =
  (patientId): AppThunk =>
  async (dispatch) => {
    const response = await deleteCustomerAPI(patientId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteCustomer(response.data.deletePatient));
      dispatch(slice.actions.setSuccessMessage('Record successfully deleted'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const emailCustomerAddCreditCard =
  (tenantIdLowerCase, patient, location): AppThunk =>
  async (dispatch) => {
    dispatch(startSendingCardEmail());

    const response = await addCustomerCreditCardAPI(patient?.id);

    if (
      !response.customErrorMessage &&
      response.data?.requestManualPatientStripeIntegration?.result
    ) {
      const stripeURL =
        response?.data?.requestManualPatientStripeIntegration?.result;

      const data = {
        patient: patient,
        location: location,
        stripeURL: stripeURL
      };

      const addCardEmailTemplate = communicationMappings?.addCardEmailTemplate(
        tenantIdLowerCase,
        data
      );

      const emailResponse = await createCommunicationAPI({
        appointmentId: '',
        body: addCardEmailTemplate,
        cc: [],
        bcc: [],
        destination: patient?.piiProfile?.email,
        from: `contact+${userMappings?.getMappedTenantName(
          tenantIdLowerCase
        )}@gethelm.io`,
        fromName: location?.name,
        medium: 'Email',
        patientId: patient?.id,
        replyTo: {
          email:
            location?.emails[0] ||
            `contact+${userMappings?.getMappedTenantName(
              tenantIdLowerCase
            )}@gethelm.io`,
          name: location?.name
        },
        subject: `Request For Card Details`,
        type: 'Html'
      });

      dispatch(
        slice.actions.setSuccessMessage(
          'Payment link successfully generated and sent'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopSendingCardEmail());
  };

export const emailCustomerRegistrationLink =
  (tenantId, patient, location): AppThunk =>
  async (dispatch) => {
    const mappedWordsToUse = dictionaryMappings?.getMappedWordToUse(tenantId);
    const getMappedWordToUse = (word) => {
      const fallback = word;

      const result = mappedWordsToUse[word];

      if (result) {
        return result;
      } else {
        return fallback;
      }
    };

    const registrationURL = `https://app.gethelm.io/intake/${getMappedWordToUse(
      'patient'
    )}?tenant=${tenantId}&id=${patient?.id}`;

    const data = {
      patient: patient,
      location: location,
      registrationURL: registrationURL
    };

    const registrationLinkEmailTemplate =
      communicationMappings?.sendPublicRegistrationEmailTemplate(
        tenantId,
        data
      );

    const emailResponse = await createCommunicationAPI({
      appointmentId: '',
      body: registrationLinkEmailTemplate,
      cc: [],
      bcc: [],
      destination: patient?.piiProfile?.email,
      from: `contact+${userMappings?.getMappedTenantName(
        tenantId?.toLowerCase()
      )}@gethelm.io`,
      fromName: location?.name,
      medium: 'Email',
      patientId: patient?.id,
      replyTo: {
        email:
          location?.emails[0] ||
          `contact+${userMappings?.getMappedTenantName(
            tenantId?.toLowerCase()
          )}@gethelm.io`,
        name: location?.name
      },
      subject: `Registration Details Required`,
      type: 'Html'
    });

    if (!emailResponse.customErrorMessage) {
      return dispatch(
        slice.actions.setSuccessMessage(
          'Registration link successfully sent via email'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(emailResponse.customErrorMessage));
    }
  };

export const editCustomerInternalNote =
  (customer): AppThunk =>
  async (dispatch) => {
    dispatch(startSubmittingInternalNote());

    const response = await updateCustomerInternalNoteAPI(customer);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editCustomerInternalNote(response.data.updatePatientFull)
      );

      dispatch(
        slice.actions.setSuccessMessage('Internal Note successfully updated')
      );
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopSubmittingInternalNote());
  };

export const editQueueCustomerInternalNote =
  (customer): AppThunk =>
  async (dispatch) => {
    dispatch(startSubmittingInternalNote());

    const response = await updateCustomerInternalNoteAPI(customer);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editQueueCustomerInternalNote(
          response.data.updatePatientFull
        )
      );

      dispatch(
        slice.actions.setSuccessMessage('Internal Note successfully updated')
      );
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopSubmittingInternalNote());
  };

export const editCustomerEmail =
  (customer): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingCustomer());

    const response = await updateCustomerEmailAPI(customer);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editCustomerEmail(response.data.updatePatientFull)
      );

      dispatch(slice.actions.setSuccessMessage('Email successfully updated'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingCustomer());
  };

export const deleteCustomerCreditCard =
  (patientId, paymentMethodId): AppThunk =>
  async (dispatch) => {
    dispatch(startDeletingCustomerCard());

    const response = await deleteCustomerCreditCardAPI(
      patientId,
      paymentMethodId
    );
    // const response = {
    //   data: {
    //     removePatientStripePaymentMethod: { result: true }
    //   },
    //   customErrorMessage: null
    // };

    // console.log(response);
    if (
      !response.customErrorMessage &&
      response.data.removePatientStripePaymentMethod?.result
    ) {
      dispatch(slice.actions.deleteCustomerCreditCard(paymentMethodId));
      dispatch(slice.actions.setSuccessMessage('Card successfully removed'));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopDeletingCustomerCard());
  };

export const setIsEditMarketingFormLoading =
  (loading): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsEditMarketingFormLoading(loading));
  };

export const clearCustomersResults = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCustomersResults(''));
};

export const startSearchingCustomers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingCustomers(true));
};

export const stopSearchingCustomers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingCustomers(false));
};

export const startRetrievingQueueCustomer =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsRetrievingQueueCustomer(true));
  };

export const stopRetrievingQueueCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsRetrievingQueueCustomer(false));
};

export const startMergingCustomerData = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsMergingCustomerData(true));
};

export const stopMergingCustomerData = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsMergingCustomerData(false));
};

export const startSubmittingInternalNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSubmittingInternalNote(true));
};

export const stopSubmittingInternalNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSubmittingInternalNote(false));
};

export const startSendingCardEmail = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSendingCardEmail(true));
};

export const stopSendingCardEmail = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSendingCardEmail(false));
};

export const startUpdatingCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingCustomer(true));
};

export const stopUpdatingCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingCustomer(false));
};

export const startCreatingCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomer(true));
};

export const stopCreatingCustomer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomer(false));
};

export const startDeletingCustomerCard = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingCustomerCard(true));
};

export const stopDeletingCustomerCard = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingCustomerCard(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const setQuickActionErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setQuickActionErrorMessage(message));
  };

export const setQuickActionSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setQuickActionSuccessMessage(message));
  };

export default slice;

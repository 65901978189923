import { Box, Tabs, Tab, styled } from '@mui/material';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-scrollableX {
          overflow-x: auto !important;
        }

        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;  
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                color: ${theme.colors.alpha.black[50]};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }
  `
);

function TabsWrapper({
  currentTab,
  tabs,
  handleTabsChange,
  isLoading = false
}) {
  return (
    <TabsContainerWrapper>
      <Tabs
        onChange={handleTabsChange}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        textColor="primary"
        indicatorColor="primary"
     
      >
        {tabs.map((tab) => (
          <Tab
            data-testid={`single-tabs-${tab.value}`}
            key={tab.value}
            label={tab.label}
            value={tab.value}
            disabled={isLoading}
          />
        ))}
      </Tabs>
    </TabsContainerWrapper>
  );
}

export default TabsWrapper;

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const OnlineBookingIndexV2 = Loader(lazy(() => import('src/content/OnlineBooking/bookingv2')));
const OnlineBookingIndex = Loader(lazy(() => import('src/content/OnlineBooking')));
const SuccessPage = Loader(lazy(() => import('src/content/OnlineBooking/SuccessPage')));
const ErrorPage = Loader(lazy(() => import('src/content/OnlineBooking/ErrorPage')));
const RescheduleAppointment = Loader(lazy(() => import('src/content/OnlineBooking/RescheduleAppointment')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);


const onlineBookingRoutes = [
  {
    path: '/',
    // element: <Overview />
    // Force navingation to Home Page of the App.
    element: <Status404 replace />
  },
  {
    path: ':onlineBookingId',
    element: <OnlineBookingIndex />
  },
  {
    path: '/v2/:onlineBookingId',
    element: <OnlineBookingIndexV2 />
  },

  {
    path: '/reschedule/:tenantId/appointment',
    element: <RescheduleAppointment />
  },

  {
    path: '/success',
    element: <SuccessPage />
  },

  {
    path: '/error',
    element: <ErrorPage />
  },
  
  {
    path: '*',
    element: <Status404 />
  }
];

export default onlineBookingRoutes;

export const listReducedPatientPiiProfilesQuery = /* GraphQL */ `
  query ListPatientPiiProfiles(
    $searchTerm: String
    $locationId: ID
    $limit: Int
    $nextToken: String
  ) {
    listPatientPiiProfiles(
      searchTerm: $searchTerm
      locationId: $locationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        preferredName
        identityNumber
        birthday
        mobile
        email
        addressPostalCode
        addressUnitNo
        occupation
        gender
        internalNote
        stripePaymentMethods {
          id
        }
      }
      nextToken
    }
  }
`;

export const isExistingPatientQuery = /* GraphQL */ `
  query ListPatientPiiProfiles(
    $searchTerm: String
    $locationId: ID
    $limit: Int
    $nextToken: String
  ) {
    listPatientPiiProfiles(
      searchTerm: $searchTerm
      locationId: $locationId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        preferredName
        identityNumber
        birthday
        mobile
        email
      }
      nextToken
    }
  }
`;

export const getPatientInternalNote = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      fullName
      piiProfile {
        internalNote
      }
    }
  }
`;

export const getPatientFlow = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      fullName
      profilePicture
      location {
        id
        code
      }
      piiProfile {
        birthday
        internalNote
      }
      extraData {
        completedAppointmentsCount
      }
    }
  }
`;

export const customGetPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      fullName
      profilePicture
      location {
        id
        code
      }
      preferredProviderIds
      piiProfile {
        id
        fullName
        preferredName
        identityNumber
        identityType
        birthday
        maritalStatus
        title
        gender
        nationality
        mobileCountryCode
        mobile
        email
        addressStreetAddress
        addressUnitNo
        addressCountry
        addressPostalCode
        telephone2
        telephone3
        occupation
        internalNote
        employer
        nextOfKin
        stripeCustomerId
        stripePaymentMethods {
          id
          billingAddress
          billingEmail
          billingName
          billingPhone
          type
          cardBrand
          cardExpMonth
          cardExpYear
          cardFunding
          cardLast4
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      medicalProfile {
        id
        noKnownDrugAllergy
        noKnownFoodAllergy
        noKnownOtherAllergy
        drugAllergies
        foodAllergies
        otherAllergies
        vaccines
        insurance
        subsidySchemes
        medicalConditions
        noKnownMedicalConditions
        corporates {
          corporateId
          corporate {
            id
            companyName
            code
            category
            officePhone
            officeFax
            officeEmail
            addressStreetAddress
            addressUnitNo
            addressCountry
            addressPostalCode
            websiteURL
            note
            affectsPricing
            insurancePaymentType
            invoiceNoteTemplate
            validFrom
            validTo
            isActive
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          paymentSchemeId
          membershipDetails
          isActive
          notes
          start
          expiry
          number
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      marketingProfile {
        id
        referredBy
        referralSource
        referralCategory
        consent
        membershipTier
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      isInitialized
      customerShortCode
      strictlyProviders
      quickbooksId
      extraData {
        completedAppointmentsCount
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customGetCustomerByShortCode = /* GraphQL */ `
  query GetCustomerByShortCode($shortCode: String!) {
    getCustomerByShortCode(shortCode: $shortCode) {
      id
      fullName
    }
  }
`;

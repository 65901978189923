export const getRegistrationConsent = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <div>
      <h2>
        Customer Declaration & Waiver
      </h2>
      <ol>
        <li>
          I declare that the information I have provided in this form is true and correct to the best of my knowledge, and that I have not withheld any information that may be relevant to the service rendered. I am aware that it is my responsibility to inform my service provider and the company offering said service (Company”) of any updates to the information provided.
        </li>
        <li>
          I acknowledge that the Company may publish advertising, publicity or marketing content containing images of the Company's premises, and customers, but such content shall not contain any of my personal information without my express consent.
        </li>
        <li>
          I grant my consent for the Company to use, and where applicable, disclose the information I have provided in this form to relevant authorities and parties to facilitate the delivery of the Company's services.
        </li>
      </ol>
    </div>
    `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <div>
					<div>
						<h2>
							Consent for Treatment/Procedure ("Consent")
						</h2>
						<p>
							<b>
								I consent, agree and acknowledge:
							</b>
						</p>
						<ol>
							<li>
								to receive treatment from practitioners at City Osteopathy & Physiotherapy Group Pte Ltd ("Company") and/or practitioners at any of the Company's Affiliate(s) ("Practitioner").
							</li>
							<li>
								that the actual risk of a treatment with a Practitioner depends on my underlying medical condition, predisposing factors and the nature of the treatment provided.
							</li>
							<li>
								that I can refuse a treatment (or any part of treatment) now or in the future.
							</li>
							<li>
								that a treatment may involve some contact with a Practitioner in the form of massage, manipulations and therapeutic devices, which may induce but are not limited to: bruises, minor skin abrasion and or swelling. I will inform my Practitioner of any concerns, reactions or discomfort associated with treatment.
							</li>
							<li>
								that as parent, guardian or appointed carer to this patient receiving treatment <u>(applicable for patients aged 18 years or younger)</u>
							</li>
						</ol>
					</div>

					<div>
						<p>
							<b>
								The foregoing statement shall stand as my formal consent for all my treatment(s) at the Company and/or its Affiliate(s). I acknowledge that I will declare to the Practitioner prior to any treatment if there are any changes to my health condition or if I am receiving any form of treatment(s) outside of the Company.
							</b>
						</p>
						<p>
							The following words in this Consent shall be defined as follows:
						</p>
						<p>
							(a) “Affiliate” means, in relation to the Company, any person directly or indirectly Controlled by, or Controlling of, or under common Control with the Company;
						</p>
						<p>
							(b) "Control" means the possession by a person directly or indirectly, of (a) the legal and beneficial ownership of more than 50% of the voting shares of another person; or (b) the power to secure, directly or indirectly, (whether by the holding of shares, possession of voting rights or by virtue of any other power conferred by the articles of association, constitution, partnership deed or other documents regulating another person or otherwise) that the affairs of such other person are conducted in accordance with his or its wishes and "Controlled" and "Controlling" shall be construed accordingly
						</p>
					</div>

					<div>
						<h2>
							Consent for Data Protection Policy
						</h2>
						<p>
							I have read and agree to the terms of the data protection policy listed <a href="https://cityosteophysio.com/data-protection-policy/" target="_blank" rel="noopener noreferrer">here</a>.
						</p>
					</div>
				</div>
      `,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': `
        <div>
					<div>
						<h2>
							Consent for Treatment/Procedure ("Consent")
						</h2>
						<p>
							<b>
								I consent, agree and acknowledge:
							</b>
						</p>
						<ol>
							<li>
								to receive treatment from practitioners at Chiropractic Singapore ("Company") and/or practitioners at any of the Company's Affiliate(s) ("Practitioner").
							</li>
							<li>
								that the actual risk of a treatment with a Practitioner depends on my underlying medical condition, predisposing factors and the nature of the treatment provided.
							</li>
							<li>
								that I can refuse a treatment (or any part of treatment) now or in the future.
							</li>
							<li>
								that a treatment may involve some contact with a Practitioner in the form of massage, manipulations and therapeutic devices, which may induce but are not limited to: bruises, minor skin abrasion and or swelling. I will inform my Practitioner of any concerns, reactions or discomfort associated with treatment.
							</li>
							<li>
								that as parent, guardian or appointed carer to this patient receiving treatment <u>(applicable for patients aged 18 years or younger)</u>
							</li>
						</ol>
					</div>

					<div>
						<p>
							<b>
								The foregoing statement shall stand as my formal consent for all my treatment(s) at the Company and/or its Affiliate(s). I acknowledge that I will declare to the Practitioner prior to any treatment if there are any changes to my health condition or if I am receiving any form of treatment(s) outside of the Company.
							</b>
						</p>
						<p>
							The following words in this Consent shall be defined as follows:
						</p>
						<p>
							(a) “Affiliate” means, in relation to the Company, any person directly or indirectly Controlled by, or Controlling of, or under common Control with the Company;
						</p>
						<p>
							(b) "Control" means the possession by a person directly or indirectly, of (a) the legal and beneficial ownership of more than 50% of the voting shares of another person; or (b) the power to secure, directly or indirectly, (whether by the holding of shares, possession of voting rights or by virtue of any other power conferred by the articles of association, constitution, partnership deed or other documents regulating another person or otherwise) that the affairs of such other person are conducted in accordance with his or its wishes and "Controlled" and "Controlling" shall be construed accordingly
						</p>
					</div>

					<div>
						<h2>
							Consent for Data Protection Policy
						</h2>
						<p>
							I have read and agree to the terms of the data protection policy listed <a href="https://www.chiropractic-singapore.com.sg/privacy-and-cookies-policy/" target="_blank" rel="noopener noreferrer">here</a>.
						</p>
					</div>
				</div>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
			<div>
				<div>
					<h2> Customer Declaration & Waiver </h2>
					<ol>
						<li> I declare that the information I have provided to SGTT is true and correct to the best of my knowledge, and that I have not withheld any information that may be relevant to my treatment, I am aware that it is my responsibility to inform my therapist and SG Tit Tar Pte Ltd (“SGTT”) of my current health conditions and of any changes to my health and medical treatments. </li>
						<li> I understand that the treatments and services offered by SGTT are for general well-being and body balance and do not constitute medical diagnosis, treatment or professional care for any medical conditions. I confirm that I voluntarily assume all risks associated with the treatments and services. </li>
						<li> In consideration of SGTT providing me with its treatments and services and to the maximum extent permitted by law, I agree to waive all claims against SGTT, the Therapist, or any of SGTT’s directors, shareholders, agents, consultants, advisors or other associated parties, arising out of or in connection with the treatments and services provided by SGTT. I further agree to hold harmless and fully indemnify SGTT, the Therapist, or any of SGTT’s directors, shareholders, agents, consultants, advisors or other associated parties from and against all liabilities, claims, expenses, damages and losses, arising out of or in connection with the treatments and services provided by SGTT. On rare occasions needles may be inadvertently left in the body or clothings following an acupuncture treatment; I acknowledge that I understand this potential risk and consent to acupuncture treatment despite this possibility. </li>
						<li> I acknowledge that SGTT may publish advertising, publicity or marketing content containing images of SGTT’s premises, treatment centres, and customers, but such content shall not contain any of my personal information without my express consent. </li>
						<li> I grant my consent for SGTT to use, and where applicable, disclose the information I have provided in this form to relevant authorities and parties to facilitate the delivery of SGTT’s treatments and services and for emergency purposes. </li>
					</ol>
				</div>
				<div>
					<h2> Consent for Privacy Policy </h2>
					<p> I have read and agree to the terms of the privacy policy listed <a href="https://www.sgtittar.com/privacy-policy/" target="_blank" rel="noopener noreferrer">here</a>. </p>
				</div>
			</div>
      `,
      '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': `
        <div>
          <h2>
            Customer Declaration & Waiver
          </h2>
          <ol>
            <li>
              I declare that the information I have provided in this form is true and correct to the best of my knowledge, and that I have not withheld any information that may be relevant to my treatment, I am aware that it is my responsibility to inform my therapist and The Restoration Room Pte Ltd (“The Restoration Room”) of my current health conditions and of any changes to my health and medical treatments
            </li>
            <li>
              I understand that the treatments and services offered by The Restoration Room are for general well-being and body balance and do not constitute medical diagnosis, treatment or profressional care for any medical conditions. I confirm that I voluntarily assume all risks associated with the treatments and services.
            </li>
            <li>
              In consideration of The Restoration Room providing me with its treatments and services and to the maximum extent permitted by law, I agree to waive all claims against The Restoration Room, the Therapist, or any of The Restoration Room directors, shareholders, agents, consultants, advisors or other associated parties, arising out of or in connection with the treatments and services provided by The Restoration Room. I further agree to hold harmless and fully indemnify The Restoration Room, the Therapist, or any of The Restoration Room directors, shareholders, agents, consultants, advisors or other associated parties from and against all liabilities, claims, expenses, damages and losses, arising out of or in connection with the treatments and services provided by The Restoration Room.
            </li>
            <li>
              I acknowledge that The Restoration Room may publish advertising, publicity or marketing content containing images of The Restoration Room premises, treatment centres, and customers, but such content shall not contain any of my personal information without my express consent.
            </li>
            <li>
              I grant my consent for The Restoration Room to use, and where applicable, disclose the information I have provided in this form to relevant authorities and parties to facilitate the delivery of The Restoration Room treatments and services and for emergency purposes.
            </li>
          </ol>
        </div>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <div>
          <h2>
            Customer Consent
          </h2>
          <ol>
            <li>
              I acknowledge that the Company may publish advertising, publicity or marketing content containing images of the Company's premises, and customers, but such content shall not contain any of my personal information without my express consent.
            </li>
            <li>
              I grant my consent for the Company to use, and where applicable, disclose the information I have provided in this form to relevant parties to facilitate the delivery of the Company's services.
            </li>
          </ol>
        </div>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getDryNeedlingConsent = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = `
    <div>
      <h2>
        <u>Trigger Point Dry Needling Consent Form</u>
      </h2>
      <p>
        Trigger point dry needling ("TDN") involves placing a small needle into the muscle at the trigger point in order to cause the muscle to contract and then release, improving the flexibility of the muscle and therefore decreasing the symptoms.
      </p>

      <p>TDN is a valuable treatment for musculoskeletal pain.&nbsp; Like any treatment, there are possible complications.&nbsp; While these complications are rare in occurrence, they are real and must be considered prior to giving consent to treatment.</p>

      <p>
        <strong>Risks of the procedure:</strong>
      </p>
      <p>
        The most serious risk associated with TDN is accidental puncture of a lung (pneumothorax).&nbsp; If this were to occur, it may likely only require a chest x-ray and no further treatment. The symptoms of shortness of breath may last for several days to weeks.&nbsp; A more severe lung puncture can require hospitalization and re-inflation of the lung.&nbsp; This is a rare complication and in skilled hands should not be a concern.&nbsp;
      </p>

      <p>
        Other risks may include excessive bleeding (causing a bruise), infection and nerve injury.&nbsp; Bruising is a common occurrence and should not be a concern unless you are taking a blood thinner.&nbsp; As the needles are very small and do not have a cutting edge, the likelihood of any significant tissue trauma from TDN is unlikely.
      </p>
      <p>
        Please consult with your practitioner if you have any questions regarding the treatment above.
      </p>
    </div>
  `;

  try {
    const mappedTenantIds = {
      cityosteophysio: `
        <div>
					<h2>
						<u>Trigger Point Dry Needling Consent Form</u>
					</h2>
					<p>
						Trigger point dry needling ("TDN") involves placing a small needle into the muscle at the trigger point in order to cause the muscle to contract and then release, improving the flexibility of the muscle and therefore decreasing the symptoms.
					</p>

					<p>TDN is a valuable treatment for musculoskeletal pain.&nbsp; Like any treatment, there are possible complications.&nbsp; While these complications are rare in occurrence, they are real and must be considered prior to giving consent to treatment.</p>

					<p>
						<strong>Risks of the procedure:</strong>
					</p>
					<p>
						The most serious risk associated with TDN is accidental puncture of a lung (pneumothorax).&nbsp; If this were to occur, it may likely only require a chest x-ray and no further treatment. The symptoms of shortness of breath may last for several days to weeks.&nbsp; A more severe lung puncture can require hospitalization and re-inflation of the lung.&nbsp; This is a rare complication and in skilled hands should not be a concern.&nbsp;
					</p>

					<p>
						Other risks may include excessive bleeding (causing a bruise), infection and nerve injury.&nbsp; Bruising is a common occurrence and should not be a concern unless you are taking a blood thinner.&nbsp; As the needles are very small and do not have a cutting edge, the likelihood of any significant tissue trauma from TDN is unlikely.
					</p>
					<p>
						Please consult with your practitioner if you have any questions regarding the treatment above.
					</p>
				</div>
      `,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': `
        <div>
				</div>
      `,
      '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': `
        <div>
        </div>
      `,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': `
        <div>
        </div>
      `
    };

    const result = mappedTenantIds[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

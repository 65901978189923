import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const AccountSettings = Loader(lazy(() => import('src/content/Settings/AccountSettings')));



const preferencesRoutes = [
  {
    path: '/',
    element: <Navigate to="account/profile" replace />
    
  },
  {
    path: 'account/:tabName',
    element: <AccountSettings />
  },
  


  // {
  //   path: ':patientId',
  //   element: <SinglePatient />
  // },

  // {
  //   path: 'users',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="list" replace />
  //     },
  //     {
  //       path: 'list',
  //       element: <Users />
  //     },
  //     {
  //       path: 'single',
  //       children: [
  //         {
  //           path: '/',
  //           element: <Navigate to="1" replace />
  //         },
  //         {
  //           path: ':userId',
  //           element: <SingleUser />
  //         }
  //       ]
  //     }
  //   ]
  // },

];

export default preferencesRoutes;

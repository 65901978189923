import callGraphQL from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateStationMutation,
  UpdateStationMutation,
  GetStationQuery,
  ListStationsQuery,
  DeleteStationMutation
} from 'src/API';
import { getStation, listStations } from 'src/graphql/queries';
import {
  createStation,
  updateStation,
  deleteStation
} from 'src/graphql/mutations';

export const listStationsAPI = async (
  activitySessionId = '',
  locationId = '',
  providerId = ''
) => {
  try {
    const stations = await callGraphQL<ListStationsQuery>(listStations, {
      variables: {
        activitySessionId,
        locationId,
        providerId
      }
    });
    // console.log(stations);
    return stations;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getStationAPI = async (stationId) => {
  try {
    const station = await callGraphQL<GetStationQuery>(getStation, {
      variables: {
        id: stationId
      }
    });
    // console.log(station);
    return station;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createStationAPI = async ({
  name,
  capacity,
  locationId = '',
  providerId = '',
  customerIds = [],
  isInSession = false,
  activitySessionId = ''
}) => {
  try {
    const newStation = await callGraphQL<CreateStationMutation>(createStation, {
      variables: {
        input: {
          name,
          capacity,
          locationId,
          providerId,
          customerIds,
          isInSession,
          activitySessionId,
          isActive: true
        }
      }
    });

    // console.log(newStation);
    return newStation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateStationAPI = async ({
  stationId,
  name,
  capacity,
  isActive = true
  // locationId = '',
  // providerId = '',
  // customerIds = [],
  // isInSession = false,
  // activitySessionId = ''
}) => {
  try {
    const updatedStation = await callGraphQL<UpdateStationMutation>(
      updateStation,
      {
        variables: {
          input: {
            id: stationId,
            name,
            capacity,
            isActive
            // locationId,
            // providerId,
            // customerIds,
            // isInSession,
            // activitySessionId
          }
        }
      }
    );

    // console.log(updatedStation);
    return updatedStation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const resetStationAPI = async ({
  stationId,
  providerId = '',
  customerIds = [],
  activitySessionId = '',
  isInSession = false
}) => {
  try {
    const resetStation = await callGraphQL<UpdateStationMutation>(
      updateStation,
      {
        variables: {
          input: {
            id: stationId,
            providerId,
            customerIds,
            activitySessionId,
            isInSession
          }
        }
      }
    );

    // console.log(resetStation);
    return resetStation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const toggleStationAPI = async ({ stationId, isInSession = false }) => {
  try {
    const updatedStation = await callGraphQL<UpdateStationMutation>(
      updateStation,
      {
        variables: {
          input: {
            id: stationId,
            isInSession
          }
        }
      }
    );

    // console.log(updatedStation);
    return updatedStation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteStationAPI = async (stationId) => {
  try {
    const deletedStation = await callGraphQL<DeleteStationMutation>(
      deleteStation,
      {
        variables: {
          id: stationId
        }
      }
    );

    // console.log(deletedStation);
    return deletedStation;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getOnlineBookingWindowPeriod = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    addDaysForStartDate: 1,
    addDaysForEndDate: 30,
    leadTimeinMinutes: {
      general: 60 * 6
    },
    appointmentIntervals: 60
  };

  try {
    const mappedOnlineBookingWindowPeriod = {
      cityosteophysio: {
        addDaysForStartDate: 1,
        addDaysForEndDate: 30,
        leadTimeinMinutes: {
          general: 60 * 11
        },
        appointmentIntervals: 60
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        addDaysForStartDate: 0,
        addDaysForEndDate: 30,
        leadTimeinMinutes: {
          general: 60 * 1
        },
        appointmentIntervals: 60
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        addDaysForStartDate: 0,
        addDaysForEndDate: 90,
        leadTimeinMinutes: {
          general: 60 * 1,
          '37651fe1-d9bc-4dc6-a72a-6ae1604dea1b': 60 * 12
        },
        appointmentIntervals: 30
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        addDaysForStartDate: 0,
        addDaysForEndDate: 30,
        leadTimeinMinutes: {
          general: 60 * 1
        },
        appointmentIntervals: 60
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        addDaysForStartDate: 0,
        addDaysForEndDate: 30,
        leadTimeinMinutes: {
          general: 60 * 1
        },
        appointmentIntervals: 60
      }
    };

    const result = mappedOnlineBookingWindowPeriod[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getWarningMessage = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    warningPeriodinMinutes: 43200,
    warningMessage: ''
  };

  try {
    const warningMessageDetails = {
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        warningPeriodinMinutes: 1440,
        warningMessage:
          'EMERGENCY SURCHARGE APPLIED: To ensure the smooth operation of our business, please be advised that there will be an additional fee for bookings made less than 24 hours in advance.'
      },
      cityosteophysio: {
        warningPeriodinMinutes: 43200,
        warningMessage:
          'Your credit card details might be required to complete your booking.'
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        warningPeriodinMinutes: 43200,
        warningMessage:
          'Do note that this booking is non-refundable due to limited slots.'
      }
    };

    const result = warningMessageDetails[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getOptionalFieldsToShow = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    lastName: true
  };

  try {
    const optionalFields = {
      cityosteophysio: {
        lastName: true
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        // sgtittar
        lastName: false
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        // qmenswear
        lastName: true
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        // chirosg
        lastName: true
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        // chirosg
        lastName: true
      }
    };

    const result = optionalFields[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getFieldLabels = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    preferredName: 'First / Preferred Name',
    lastName: 'Last / Family Name',
    mobileCountryCode: 'Mobile Country Code',
    mobile: 'Mobile',
    email: 'Email'
  };

  try {
    const fieldLabels = {
      cityosteophysio: {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        preferredName: 'Patient Full Name as NRIC/Passport',
        lastName: 'Last / Family Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Customer Mobile',
        email: 'Customer Email'
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        preferredName: 'First / Preferred Name',
        lastName: 'Last / Family Name',
        mobileCountryCode: 'Mobile Country Code',
        mobile: 'Patient Mobile',
        email: 'Patient Email'
      }
    };

    const result = fieldLabels[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getFieldMandatoryRequirements = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    preferredName: 'First / Preferred Name is required.',
    lastName: 'Last / Family Name is required.',
    mobileCountryCode: 'Mobile Country Code is required.',
    mobile: 'Mobile is required.',
    email: 'Email is required.'
  };

  try {
    const fieldRequirements = {
      cityosteophysio: {
        preferredName: 'First / Preferred Name is required.',
        lastName: 'Last / Family Name is required.',
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Patient Mobile is required.',
        email: 'Patient Email is required.'
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        preferredName: 'Patient Full Name is required.',
        lastName: 'Last / Family Name is required.',
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Patient Mobile is required.',
        email: 'Patient Email is required.'
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        preferredName: 'First / Preferred Name is required.',
        lastName: 'Last / Family Name is required.',
        mobileCountryCode: false,
        mobile: false,
        email: 'Customer Email is required.'
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        preferredName: 'First / Preferred Name is required.',
        lastName: 'Last / Family Name is required.',
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Patient Mobile is required.',
        email: 'Patient Email is required.'
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        preferredName: 'First / Preferred Name is required.',
        lastName: 'Last / Family Name is required.',
        mobileCountryCode: 'Mobile Country Code is required.',
        mobile: 'Patient Mobile is required.',
        email: 'Patient Email is required.'
      }
    };

    const result = fieldRequirements[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

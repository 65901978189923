import { setHours, setMinutes, subDays, addDays } from 'date-fns';
import _ from 'lodash';
import { mock } from 'src/utils/axios';
import type { Event } from 'src/models/calendar';
import randomId from 'src/utils/randomId';
import { Appointment } from 'src/models/clinicCalendar';

let events: Event[] = [
  {
    id: '1',
    allDay: false,
    color: '#57CA22',
    description: '',
    end: setHours(setMinutes(subDays(new Date(), 1), 14), 6),
    start: setHours(setMinutes(subDays(new Date(), 1), 8), 6),
    title: 'Investors Meeting'
  },
  {
    id: '2',
    allDay: false,
    color: '#FF1943',
    description: '',
    end: setHours(setMinutes(addDays(new Date(), 2), 5), 4),
    start: setHours(setMinutes(addDays(new Date(), 2), 7), 3),
    title: 'UX Design Gathering'
  },
  {
    id: '3',
    allDay: false,
    color: '#1975FF',
    description: '',
    end: setHours(setMinutes(subDays(new Date(), 3), 3), 1),
    start: setHours(setMinutes(subDays(new Date(), 4), 3), 2),
    title: 'Set up a board meeting'
  },
  {
    id: '4',
    allDay: false,
    color: '#1975FF',
    description: '',
    end: setHours(setMinutes(addDays(new Date(), 5), 1), 4),
    start: setHours(setMinutes(addDays(new Date(), 5), 1), 4),
    title: 'Call all developers'
  }
];

let appointments: Appointment[] = [
  {
    "appointmentDate": "2022-04-03",
    "appointmentEndTime": "2022-04-02T06:00:00.000Z",
    "appointmentStartTime": "2022-04-03T05:00:00.000Z",
    "appointmentNote": "CY Test Appointment Notes\n",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "b80429eb-810a-46b0-a221-dd7fcb55b3a9",
    "patient": {
      "fullName": "Test Cheng Yong Patient",
      "id": "9a421adc-3da5-4310-8e29-9eea305f7149"
    },
    "practitioner": {
      "firstName": "Cheng Li",
      "lastName": "Sim",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "CROWN"
    },
    "id": "9d9d8b3e-b86c-4782-8f63-0eb204655c9a"
  },
  {
    "appointmentDate": "2022-04-09",
    "appointmentEndTime": "2022-04-09T09:30:22.812Z",
    "appointmentStartTime": "2022-04-09T09:30:22.812Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "bc16b91e-31d8-49ec-be17-6ce631036527",
    "patient": {
      "fullName": "Benedict Khong",
      "id": "00a063db-9181-48b9-8207-304da01db7d7"
    },
    "practitioner": {
      "firstName": "Cheng Li",
      "lastName": "Sim",
      "middleName": null
    },
    "service": {
      "category": "Diagnostic",
      "code": "CONSULT"
    },
    "id": "30f56714-d326-4b1a-9236-c6068c7575f3"
  },
  {
    "appointmentDate": "2022-04-10",
    "appointmentEndTime": "2022-04-10T05:01:44.437Z",
    "appointmentStartTime": "2022-04-10T04:00:44.000Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "b80429eb-810a-46b0-a221-dd7fcb55b3a9",
    "patient": {
      "fullName": "Test Cheng Yong Patient",
      "id": "9a421adc-3da5-4310-8e29-9eea305f7149"
    },
    "practitioner": {
      "firstName": "Cheng Li",
      "lastName": "Sim",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "CROWN"
    },
    "id": "3d62e0fd-2d3c-44fd-83b9-76a180d64fe1"
  },
  {
    "appointmentDate": "2022-04-12",
    "appointmentEndTime": "2022-04-12T06:01:44.000Z",
    "appointmentStartTime": "2022-04-12T05:00:44.000Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Follow Up",
    "status": "Pending",
    "serviceId": "b80429eb-810a-46b0-a221-dd7fcb55b3a9",
    "patient": {
      "fullName": "Test Cheng Yong Patient",
      "id": "9a421adc-3da5-4310-8e29-9eea305f7149"
    },
    "practitioner": {
      "firstName": "Cheng Li",
      "lastName": "Sim",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "CROWN"
    },
    "id": "071871a3-b002-4cb6-9c2d-214cde450767"
  },
  {
    "appointmentDate": "2022-04-23",
    "appointmentEndTime": "2022-04-23T10:50:47.000Z",
    "appointmentStartTime": "2022-04-23T08:51:47.000Z",
    "appointmentNote": "Another appointment",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "bc16b91e-31d8-49ec-be17-6ce631036527",
    "patient": {
      "fullName": "Henry",
      "id": "5ade9733-1fa2-4e4a-afd6-16588a7addeb"
    },
    "practitioner": {
      "firstName": "Cheng Li",
      "lastName": "Sim",
      "middleName": null
    },
    "service": {
      "category": "Diagnostic",
      "code": "CONSULT"
    },
    "id": "64cc9bce-a35f-4acd-81c8-cd34450e02dc"
  },
  {
    "appointmentDate": "2022-04-03",
    "appointmentEndTime": "2022-04-02T23:07:06.870Z",
    "appointmentStartTime": "2022-04-02T23:07:06.870Z",
    "appointmentNote": "first crown visit",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "13f3df30-4b43-4045-9d05-549743b2f8d3",
    "patient": {
      "fullName": "Benedict Khong",
      "id": "00a063db-9181-48b9-8207-304da01db7d7"
    },
    "practitioner": {
      "firstName": "Zheng Rong, Dennis",
      "lastName": "Tng",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "CROWN"
    },
    "id": "af55b20d-5769-42c0-82d0-aa9a22e5a0d2"
  },
  {
    "appointmentDate": "2022-04-06",
    "appointmentEndTime": "2022-04-06T10:00:00.000Z",
    "appointmentStartTime": "2022-04-06T09:30:00.000Z",
    "appointmentNote": "First visit",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "bf1b6302-41a4-44d3-885c-324c4f74e5fd",
    "patient": {
      "fullName": "Henry",
      "id": "5ade9733-1fa2-4e4a-afd6-16588a7addeb"
    },
    "practitioner": {
      "firstName": "Huan Ding",
      "lastName": "Khoo",
      "middleName": null
    },
    "service": {
      "category": "Diagnostic",
      "code": "CONSULT"
    },
    "id": "881c7ab5-bbd0-4012-998f-3440f6f5ffa1"
  },
  {
    "appointmentDate": "2022-04-09",
    "appointmentEndTime": "2022-04-09T09:52:28.018Z",
    "appointmentStartTime": "2022-04-09T09:52:28.018Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "1cec58ff-927a-44de-af7e-9f71e5c1cc1b",
    "patient": {
      "fullName": "Benedict Khong",
      "id": "00a063db-9181-48b9-8207-304da01db7d7"
    },
    "practitioner": {
      "firstName": "Huan Ding",
      "lastName": "Khoo",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "KIT"
    },
    "id": "9e1db2df-3045-4972-b88d-c17129b5fbfa"
  },
  {
    "appointmentDate": "2022-04-14",
    "appointmentEndTime": "2022-04-14T08:01:44.000Z",
    "appointmentStartTime": "2022-04-14T07:00:44.000Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Follow Up",
    "status": "Pending",
    "serviceId": "28f06b8a-f246-44d7-8e6b-f6f2e6237a0e",
    "patient": {
      "fullName": "Test Cheng Yong Patient",
      "id": "9a421adc-3da5-4310-8e29-9eea305f7149"
    },
    "practitioner": {
      "firstName": "Guan Chiang",
      "lastName": "Khor",
      "middleName": null
    },
    "service": {
      "category": "Hygiene",
      "code": "GUM"
    },
    "id": "0cf59708-af0a-431c-9beb-b98fd8748139"
  },
  {
    "appointmentDate": "2022-04-19",
    "appointmentEndTime": "2022-04-19T10:20:00.000Z",
    "appointmentStartTime": "2022-04-19T10:20:00.000Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Initial Visit",
    "status": "Pending",
    "serviceId": "5a96a2c5-e8bb-4753-b4a3-ee4c146ac7d6",
    "patient": {
      "fullName": "Benedict Khong",
      "id": "00a063db-9181-48b9-8207-304da01db7d7"
    },
    "practitioner": {
      "firstName": "Zheng Rong, Dennis",
      "lastName": "Tng",
      "middleName": null
    },
    "service": {
      "category": "Hygiene",
      "code": "GUM"
    },
    "id": "f9c51dfd-149d-4e6c-9b3f-76ca9850eb42"
  },
  {
    "appointmentDate": "2022-04-10",
    "appointmentEndTime": "2022-04-10T06:01:44.437Z",
    "appointmentStartTime": "2022-04-10T05:00:44.000Z",
    "appointmentNote": "",
    "confirmedAt": null,
    "cancellationAt": null,
    "type": "Follow Up",
    "status": "Pending",
    "serviceId": "188d3b1c-69e6-4f7f-b43b-1a17aa42a43e",
    "patient": {
      "fullName": "Test Cheng Yong Patient",
      "id": "9a421adc-3da5-4310-8e29-9eea305f7149"
    },
    "practitioner": {
      "firstName": "Mian Yu Naomi",
      "lastName": "Tan",
      "middleName": null
    },
    "service": {
      "category": "Procedure",
      "code": "KIT"
    },
    "id": "df8b8a3b-08a3-480d-96ba-f7716a65f074"
  }
];


// mock.onGet('/api/calendar/meetings').reply(200, { events });
// Testing Appointments

mock.onGet('/api/calendar/meetings').reply(200, { appointments });

mock.onPost('/api/calendar/meetings/create').reply((request) => {
  try {
    const { allDay, description, end, start, title } = JSON.parse(request.data);
    const event = {
      id: randomId(),
      allDay,
      description,
      end,
      start,
      title
    };

    events = [...events, event];

    return [200, { event }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Encountered a server error' }];
  }
});

mock.onPost('/api/calendar/meetings/update').reply((request) => {
  try {
    const { eventId, update } = JSON.parse(request.data);
    let event = null;

    events = _.map(events, (_event) => {
      if (_event.id === eventId) {
        _.assign(_event, { ...update });
        event = _event;
      }

      return _event;
    });

    return [200, { event }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Encountered a server error' }];
  }
});

mock.onPost('/api/calendar/meetings/delete').reply((request) => {
  try {
    const { eventId } = JSON.parse(request.data);

    events = _.reject(events, { id: eventId });

    return [200, { eventId }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Encountered a server error' }];
  }
});

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createOnlineBookingTransactionAPI,
  getOnlineBookingTransactionAPI,
  listOnlineBookingTransactionsAPI,
  listCustomerOnlineBookingTransactionsAPI
} from 'src/content/OnlineBookingTransactions/api';

interface OnlineBookingTransactionState {
  isLoadingCustomerOnlineBookingTransactions: boolean;
  onlineBookingTransactions: any[];
  customerOnlineBookingTransactions: any[];
  onlineBookingTransactionDetail: any[];
  queueSelectedOnlineBookingTransaction: any;
  onlineBookingTransactionErrorMessage: string;
  onlineBookingTransactionSuccessMessage: string;
}

const initialState: OnlineBookingTransactionState = {
  isLoadingCustomerOnlineBookingTransactions: false,
  onlineBookingTransactions: [],
  customerOnlineBookingTransactions: [],
  onlineBookingTransactionDetail: [],
  queueSelectedOnlineBookingTransaction: {},
  onlineBookingTransactionErrorMessage: '',
  onlineBookingTransactionSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortOnlineBookingTransactionsDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'onlineBookingTransactions',
  initialState,
  reducers: {
    createOnlineBookingTransaction(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingTransactions = [
        ...state.onlineBookingTransactions,
        action.payload
      ];
    },

    getOnlineBookingTransaction(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        // const onlineBookingTransactionDetail = action.payload;
        state.onlineBookingTransactionDetail = [action.payload];
      } else {
        state.onlineBookingTransactionDetail = [{ id: 'Not Found' }];
      }
    },

    getQueueSelectedOnlineBookingTransaction(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        // const queueSelectedOnlineBookingTransaction = action.payload;
        state.queueSelectedOnlineBookingTransaction = action.payload;
      } else {
        state.queueSelectedOnlineBookingTransaction = { id: 'Not Found' };
      }
    },

    listOnlineBookingTransactions(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      let onlineBookingTransactions = [...action.payload];
      onlineBookingTransactions.sort(sortOnlineBookingTransactionsDesc);

      state.onlineBookingTransactions = [...onlineBookingTransactions];
    },

    listCustomerOnlineBookingTransactions(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      let onlineBookingTransactions = [...action.payload];
      onlineBookingTransactions.sort(sortOnlineBookingTransactionsDesc);

      state.customerOnlineBookingTransactions = [...onlineBookingTransactions];
    },

    clearSelectedOnlineBookingTransaction(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingTransactionDetail = [];
    },

    clearQueueSelectedOnlineBookingTransaction(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.queueSelectedOnlineBookingTransaction = {};
    },

    setIsLoadingCustomerOnlineBookingTransactions(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerOnlineBookingTransactions = action.payload;
    },

    setErrorMessage(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingTransactionErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: OnlineBookingTransactionState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingTransactionSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createOnlineBookingTransaction =
  (onlineBookingTransaction): AppThunk =>
  async (dispatch) => {
    const response = await createOnlineBookingTransactionAPI(
      onlineBookingTransaction
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createOnlineBookingTransaction(
          response.data.createOnlineBookingTransaction
        )
      );
      dispatch(
        slice.actions.setSuccessMessage(
          'Online Booking Transaction successfully created'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getOnlineBookingTransaction =
  (onlineBookingTransactionId): AppThunk =>
  async (dispatch) => {
    const response = await getOnlineBookingTransactionAPI(
      onlineBookingTransactionId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getOnlineBookingTransaction(
          response.data.getOnlineBookingTransaction
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const getQueueSelectedOnlineBookingTransaction =
  (onlineBookingTransactionId): AppThunk =>
  async (dispatch) => {
    const response = await getOnlineBookingTransactionAPI(
      onlineBookingTransactionId
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getQueueSelectedOnlineBookingTransaction(
          response.data.getOnlineBookingTransaction
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listOnlineBookingTransactions =
  (locationIds, startTime, endTime): AppThunk =>
  async (dispatch) => {
    const response = await listOnlineBookingTransactionsAPI(
      locationIds,
      startTime,
      endTime
    );
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listOnlineBookingTransactions(
          response.data.listOnlineBookingTransactions
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const listCustomerOnlineBookingTransactions =
  (customerId, startTime?, endTime?): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerOnlineBookingTransactions());

    const response = await listCustomerOnlineBookingTransactionsAPI(
      customerId,
      startTime,
      endTime
    );
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listCustomerOnlineBookingTransactions(
          response.data.listOnlineBookingTransactions
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingCustomerOnlineBookingTransactions());
  };

export const clearSelectedOnlineBookingTransaction =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedOnlineBookingTransaction({}));
  };

export const clearQueueSelectedOnlineBookingTransaction =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearQueueSelectedOnlineBookingTransaction({}));
  };

export const startLoadingCustomerOnlineBookingTransactions =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerOnlineBookingTransactions(true));
  };

export const stopLoadingCustomerOnlineBookingTransactions =
  (): AppThunk => async (dispatch) => {
    dispatch(
      slice.actions.setIsLoadingCustomerOnlineBookingTransactions(false)
    );
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { parseISO, isToday } from 'date-fns';

import {
  createCreditNoteAPI,
  voidCreditNoteAPI,
  listCreditNotesByCustomerAPI,
  getCreditNoteByIdAPI,
  updateCreditNoteNoteAPI,
  updateCreditNoteReasonAPI,
  getCreditNoteByNumberAPI
} from '../content/CreditNotes/api';

// import { createPaymentAPI, voidPaymentAPI } from 'src/content/Payments/api';
// import { listPatientPayments } from './payments';

interface CreditNoteState {
  isCreatingCreditNote: boolean;
  isUpdatingCreditNote: boolean;
  isVoidingCreditNote: boolean;
  isSearchingCreditNote: boolean;
  isLoadingCustomerCreditNotes: boolean;
  isLoadingQueueCreditNotes: boolean;
  isLoadingOverviewCreditNotes: boolean;
  creditNoteItemList: any[];
  customerCreditNotes: any[];
  overviewCreditNotes: any[];
  listCreditNotes: any[];
  queueAppointmentsCreditNotes: any[];
  creditNoteSearchResults: any[];
  creditNoteErrorMessage: string;
  creditNoteSuccessMessage: string;
}

const initialState: CreditNoteState = {
  isCreatingCreditNote: false,
  isUpdatingCreditNote: false,
  isVoidingCreditNote: false,
  isSearchingCreditNote: false,
  isLoadingCustomerCreditNotes: false,
  isLoadingQueueCreditNotes: false,
  isLoadingOverviewCreditNotes: false,

  creditNoteItemList: [],
  customerCreditNotes: [],
  overviewCreditNotes: [],
  listCreditNotes: [],
  queueAppointmentsCreditNotes: [],
  creditNoteSearchResults: [],

  creditNoteErrorMessage: '',
  creditNoteSuccessMessage: ''
};

const sortCreditNotesDesc = (a, b) => {
  if (a.effectiveAt > b.effectiveAt) {
    return -1;
  }
  if (a.effectiveAt < b.effectiveAt) {
    return 1;
  }
  return 0;
};

// NEED TO EDIT FROM HERE

const slice = createSlice({
  name: 'creditNotes',
  initialState,
  reducers: {
    addCreditNoteItem(state: CreditNoteState, action: PayloadAction<any>) {
      const newItem = action.payload;

      state.creditNoteItemList = [...state.creditNoteItemList, newItem];
    },

    deleteCreditNoteItem(state: CreditNoteState, action: PayloadAction<any>) {
      const itemIndex = action.payload;
      const newList = [...state.creditNoteItemList];
      newList.splice(itemIndex, 1);

      state.creditNoteItemList = [...newList];
    },

    clearCreditNoteItem(state: CreditNoteState, action: PayloadAction<any>) {
      state.creditNoteItemList = [];
    },

    createCreditNote(state: CreditNoteState, action: PayloadAction<any>) {
      const newCreditNote = action.payload;

      state.customerCreditNotes = [newCreditNote, ...state.customerCreditNotes];

      if (isToday(parseISO(newCreditNote.effectiveAt))) {
        state.queueAppointmentsCreditNotes = [
          newCreditNote,
          ...state.queueAppointmentsCreditNotes
        ];
      }

      state.isCreatingCreditNote = false;
    },

    updateCreditNoteNote(state: CreditNoteState, action: PayloadAction<any>) {
      if (
        state.customerCreditNotes.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.customerCreditNotes.findIndex(
          (obj) => obj.id === action.payload.id
        );

        state.customerCreditNotes = state.customerCreditNotes.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                note: action.payload.note
              }
            : inv
        );
      }

      if (
        state.creditNoteSearchResults.find(
          (inv) => inv.id === action.payload.id
        )
      ) {
        let invIndex = state.creditNoteSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );

        state.creditNoteSearchResults = state.creditNoteSearchResults.map(
          (inv, i) =>
            i === invIndex
              ? {
                  ...inv,
                  updatedBy: action.payload.updatedBy,
                  updatedAt: action.payload.updatedAt,
                  note: action.payload.note
                }
              : inv
        );
      }
    },

    updateCreditNoteReason(state: CreditNoteState, action: PayloadAction<any>) {
      if (
        state.customerCreditNotes.find((inv) => inv.id === action.payload.id)
      ) {
        let invIndex = state.customerCreditNotes.findIndex(
          (obj) => obj.id === action.payload.id
        );

        state.customerCreditNotes = state.customerCreditNotes.map((inv, i) =>
          i === invIndex
            ? {
                ...inv,
                updatedBy: action.payload.updatedBy,
                updatedAt: action.payload.updatedAt,
                reason: action.payload.reason
              }
            : inv
        );
      }

      if (
        state.creditNoteSearchResults.find(
          (inv) => inv.id === action.payload.id
        )
      ) {
        let invIndex = state.creditNoteSearchResults.findIndex(
          (obj) => obj.id === action.payload.id
        );

        state.creditNoteSearchResults = state.creditNoteSearchResults.map(
          (inv, i) =>
            i === invIndex
              ? {
                  ...inv,
                  updatedBy: action.payload.updatedBy,
                  updatedAt: action.payload.updatedAt,
                  reason: action.payload.reason
                }
              : inv
        );
      }
    },

    voidCreditNote(state: CreditNoteState, action: PayloadAction<any>) {
      const invIndex = state.customerCreditNotes.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (invIndex >= 0) {
        state.customerCreditNotes = state.customerCreditNotes.map((inv, i) =>
          i === invIndex ? action.payload : inv
        );
      }

      const queueInvIndex = state.queueAppointmentsCreditNotes.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (queueInvIndex >= 0) {
        const newList = [...state.queueAppointmentsCreditNotes];
        newList.splice(queueInvIndex, 1);
        state.queueAppointmentsCreditNotes = [...newList];
      }
    },

    // getRefreshedInvoice(state: CreditNoteState, action: PayloadAction<any>) {
    //   const invIndex = state.patientInvoices.findIndex(
    //     (obj) => obj.id === action.payload.id
    //   );
    //   if (invIndex >= 0) {
    //     state.patientInvoices = state.patientInvoices.map((inv, i) =>
    //       i === invIndex ? action.payload : inv
    //     );
    //   }

    //   const invSearchIndex = state.invoiceSearchResults.findIndex(
    //     (obj) => obj.id === action.payload.id
    //   );
    //   if (invSearchIndex >= 0) {
    //     state.invoiceSearchResults = state.invoiceSearchResults.map((inv, i) =>
    //       i === invSearchIndex ? action.payload : inv
    //     );
    //   }

    //   const corpInvIndex = state.corporateInvoices.findIndex(
    //     (obj) => obj.id === action.payload.id
    //   );
    //   if (corpInvIndex >= 0) {
    //     state.corporateInvoices = state.corporateInvoices.map((inv, i) =>
    //       i === corpInvIndex ? action.payload : inv
    //     );
    //   }

    //   const queueInvIndex = state.queueAppointmentsInvoices.findIndex(
    //     (obj) => obj.id === action.payload.id
    //   );
    //   if (queueInvIndex >= 0) {
    //     state.queueAppointmentsInvoices = state.queueAppointmentsInvoices.map(
    //       (inv, i) => (i === queueInvIndex ? action.payload : inv)
    //     );
    //   }

    //   state.isLoadingCustomerInvoices = false;
    // },

    getCustomerCreditNotes(state: CreditNoteState, action: PayloadAction<any>) {
      let cns = [...action.payload];
      cns.sort(sortCreditNotesDesc);

      state.customerCreditNotes = cns;

      state.isLoadingCustomerCreditNotes = false;
    },

    // getCorporateInvoices(state: CreditNoteState, action: PayloadAction<any>) {
    //   let invoices = [...action.payload];
    //   invoices.sort(sortInvoicesDesc);

    //   state.corporateInvoices = invoices;

    //   state.isLoadingCorporateInvoices = false;
    // },

    // getPatientTodayInvoices(state: CreditNoteState, action: PayloadAction<any>) {
    //   let invoices = [...action.payload];
    //   invoices.sort(sortInvoicesDesc);

    //   state.patientInvoices = invoices;

    //   state.isLoadingCustomerInvoices = false;
    // },

    // listQueueAppointmentsInvoices(
    //   state: CreditNoteState,
    //   action: PayloadAction<any>
    // ) {
    //   let invoices = [...action.payload];
    //   // invoices.sort(sortInvoicesDesc);

    //   const validInvoices = invoices?.filter((inv) => inv.voidedAt === null);

    //   state.queueAppointmentsInvoices = validInvoices;

    //   state.isLoadingQueueInvoices = false;
    // },

    // listPossibleCustomerAppointmentInvoices(
    //   state: CreditNoteState,
    //   action: PayloadAction<any>
    // ) {
    //   let invoices = [...action.payload];
    //   invoices.sort(sortInvoicesDesc);

    //   state.patientInvoices = invoices;

    //   state.isLoadingCustomerInvoices = false;
    // },

    // listOverviewInvoices(state: CreditNoteState, action: PayloadAction<any>) {
    //   let invoices = [...action.payload];
    //   // invoices.sort(sortInvoicesDesc);

    //   state.overviewInvoices = invoices;
    // },

    // listInvoicesInDateRange(state: CreditNoteState, action: PayloadAction<any>) {
    //   let invoices = [...action.payload];
    //   invoices.sort(sortInvoicesDesc);

    //   state.listInvoices = invoices;
    // },

    // getPatientOutstandingInvoices(
    //   state: CreditNoteState,
    //   action: PayloadAction<any>
    // ) {
    //   state.patientOutstandingInvoices = action.payload;
    // },

    setErrorMessage(state: CreditNoteState, action: PayloadAction<any>) {
      state.creditNoteErrorMessage = action.payload;
    },

    setSuccessMessage(state: CreditNoteState, action: PayloadAction<any>) {
      state.creditNoteSuccessMessage = action.payload;
    },

    searchCreditNote(state: CreditNoteState, action: PayloadAction<any>) {
      state.creditNoteSearchResults = [action.payload];

      state.isSearchingCreditNote = false;
    },

    clearSearchCreditNote(state: CreditNoteState, action: PayloadAction<any>) {
      state.creditNoteSearchResults = [];
    },

    setIsCreatingCreditNote(
      state: CreditNoteState,
      action: PayloadAction<any>
    ) {
      state.isCreatingCreditNote = action.payload;
    },

    setIsUpdatingCreditNote(
      state: CreditNoteState,
      action: PayloadAction<any>
    ) {
      state.isUpdatingCreditNote = action.payload;
    },

    setIsVoidingCreditNote(state: CreditNoteState, action: PayloadAction<any>) {
      state.isVoidingCreditNote = action.payload;
    },

    setIsLoadingCustomerCreditNotes(
      state: CreditNoteState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerCreditNotes = action.payload;
    },

    // setIsLoadingQueueInvoices(
    //   state: CreditNoteState,
    //   action: PayloadAction<any>
    // ) {
    //   state.isLoadingQueueInvoices = action.payload;
    // },

    // setIsLoadingOverviewInvoices(
    //   state: CreditNoteState,
    //   action: PayloadAction<any>
    // ) {
    //   state.isLoadingOverviewInvoices = action.payload;
    // },

    clearCustomerCreditNotes(
      state: CreditNoteState,
      action: PayloadAction<any>
    ) {
      state.customerCreditNotes = [];
    },

    setIsSearchingCreditNote(
      state: CreditNoteState,
      action: PayloadAction<any>
    ) {
      state.isSearchingCreditNote = action.payload;
    }

    // listRelatedInvoices(state: CreditNoteState, action: PayloadAction<any>) {
    //   let invoices = [...action.payload];
    //   invoices.sort(sortInvoicesDesc);

    //   state.relatedInvoices = invoices;
    // }
  }
});

export const reducer = slice.reducer;

export const addCreditNoteItem =
  (cnItem): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addCreditNoteItem(cnItem));
  };

export const deleteCreditNoteItem =
  (itemIndex): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.deleteCreditNoteItem(itemIndex));
  };

export const clearCreditNoteItem = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCreditNoteItem([]));
};

export const createCreditNote =
  (newCreditNote): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingCreditNote());

    const response = await createCreditNoteAPI(newCreditNote);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.createCreditNote(response.data.createCreditNote));
      dispatch(
        slice.actions.setSuccessMessage('Credit note successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopCreatingCreditNote());
  };

export const updateCreditNoteNote =
  ({ creditNoteId, note }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingCreditNote());

    const response = await updateCreditNoteNoteAPI({
      creditNoteId,
      note
    });
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCreditNoteNote(response.data.updateCreditNoteNote)
      );
      dispatch(
        slice.actions.setSuccessMessage('Credit Note successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingCreditNote());
  };

export const updateCreditNoteReason =
  ({ creditNoteId, reason }): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingCreditNote());

    const response = await updateCreditNoteReasonAPI({
      creditNoteId,
      reason
    });
    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.updateCreditNoteReason(
          response.data.updateCreditNoteReason
        )
      );
      dispatch(
        slice.actions.setSuccessMessage('Credit Note successfully updated')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopUpdatingCreditNote());
  };

export const voidCreditNote =
  (voidCN): AppThunk =>
  async (dispatch) => {
    dispatch(startVoidingCreditNote());

    const response = await voidCreditNoteAPI(voidCN);
    // console.log(response);
    if (!response.customErrorMessage) {
      dispatch(slice.actions.voidCreditNote(response.data.voidCreditNote));
      dispatch(
        slice.actions.setSuccessMessage('Credit note successfully voided')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopVoidingCreditNote());
  };

// export const getRefreshedInvoice =
//   (invoiceId): AppThunk =>
//   async (dispatch) => {
//     dispatch(startLoadingCustomerInvoices());

//     const response = await backOff(() => getInvoiceAPI(invoiceId), {
//       numOfAttempts: 3
//     });

//     if (!response.customErrorMessage) {
//       dispatch(slice.actions.getRefreshedInvoice(response.data.getInvoice));
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//     return dispatch(stopLoadingCustomerInvoices());
//   };

export const getCustomerCreditNotes =
  (customerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerCreditNotes());

    const response = await backOff(
      () => listCreditNotesByCustomerAPI(customerId),
      { numOfAttempts: 3 }
    );

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getCustomerCreditNotes(response.data.listCreditNotes)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopLoadingCustomerCreditNotes());
  };


// export const listPossibleCustomerAppointmentInvoices =
//   (patientId, appointmentId): AppThunk =>
//   async (dispatch) => {
//     dispatch(startLoadingCustomerInvoices());

//     const response = await backOff(
//       () =>
//         listPossibleCustomerAppointmentInvoicesAPI(patientId, appointmentId),
//       { numOfAttempts: 3 }
//     );

//     if (!response.customErrorMessage) {
//       dispatch(
//         slice.actions.listPossibleCustomerAppointmentInvoices(
//           response.data.listPossibleCustomerAppointmentInvoices
//         )
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//     return dispatch(stopLoadingCustomerInvoices());
//   };

// export const listQueueAppointmentsInvoices =
//   (appointmentIds): AppThunk =>
//   async (dispatch) => {
//     dispatch(startLoadingQueueInvoices());

//     const response = await backOff(
//       () => listAppointmentsInvoicesAPI(appointmentIds),
//       { numOfAttempts: 3 }
//     );

//     // console.log(response);

//     if (!response.customErrorMessage) {
//       dispatch(
//         slice.actions.listQueueAppointmentsInvoices(response.data.listInvoices)
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//     return dispatch(stopLoadingQueueInvoices());
//   };

export const stopLoadingCustomerCreditNotes =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerCreditNotes(false));
  };

export const startLoadingCustomerCreditNotes =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerCreditNotes(true));
  };

// export const stopLoadingQueueInvoices = (): AppThunk => async (dispatch) => {
//   dispatch(slice.actions.setIsLoadingQueueInvoices(false));
// };

// export const startLoadingQueueInvoices = (): AppThunk => async (dispatch) => {
//   dispatch(slice.actions.setIsLoadingQueueInvoices(true));
// };

// export const stopLoadingOverviewInvoices = (): AppThunk => async (dispatch) => {
//   dispatch(slice.actions.setIsLoadingOverviewInvoices(false));
// };

// export const startLoadingOverviewInvoices =
//   (): AppThunk => async (dispatch) => {
//     dispatch(slice.actions.setIsLoadingOverviewInvoices(true));
//   };

export const clearCustomerCreditNotes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCustomerCreditNotes(true));
};

export const startCreatingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCreditNote(true));
};

export const stopCreatingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCreditNote(false));
};

export const startUpdatingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingCreditNote(true));
};

export const stopUpdatingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsUpdatingCreditNote(false));
};

export const startVoidingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingCreditNote(true));
};

export const stopVoidingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsVoidingCreditNote(false));
};

export const startSearchingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingCreditNote(true));
};

export const stopSearchingCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsSearchingCreditNote(false));
};

// export const listOverviewInvoices =
//   (invoiceStartTime, invoiceEndTime, locationIds = []): AppThunk =>
//   async (dispatch) => {
//     dispatch(startLoadingOverviewInvoices());

//     const response = await listShortInvoicesInDateRangeAPI(
//       invoiceStartTime,
//       invoiceEndTime,
//       locationIds
//     );

//     if (!response.customErrorMessage) {
//       dispatch(slice.actions.listOverviewInvoices(response.data.listInvoices));
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }

//     return dispatch(stopLoadingOverviewInvoices());
//   };

// export const listInvoicesInDateRange =
//   (invoiceStartTime, invoiceEndTime, locationIds = []): AppThunk =>
//   async (dispatch) => {
//     const response = await listInvoicesInDateRangeAPI(
//       invoiceStartTime,
//       invoiceEndTime,
//       locationIds
//     );

//     if (!response.customErrorMessage) {
//       dispatch(
//         slice.actions.listInvoicesInDateRange(response.data.listInvoices)
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//   };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export const searchCreditNoteById =
  (creditNoteId): AppThunk =>
  async (dispatch) => {
    dispatch(startSearchingCreditNote());

    const response = await getCreditNoteByIdAPI(creditNoteId);

    if (!response.customErrorMessage) {
      // dispatch(slice.actions.searchInvoice(response.data.getInvoice));
      if (response.data.getCreditNote) {
        dispatch(slice.actions.searchCreditNote(response.data.getCreditNote));
      } else {
        dispatch(slice.actions.clearSearchCreditNote([]));
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopSearchingCreditNote());
  };

export const searchCreditNoteByNumber =
  (creditNoteNumber): AppThunk =>
  async (dispatch) => {
    dispatch(startSearchingCreditNote());

    // const response = await getInvoiceByNumberAPI(creditNoteNumber);

    // this is wrong and will always be null
    const response = await getCreditNoteByNumberAPI(creditNoteNumber);
    // console.log('searching for CN!!');

    if (!response.customErrorMessage) {
      if (response?.data?.getCreditNoteByNumber) {
        dispatch(
          slice.actions.searchCreditNote(response.data.getCreditNoteByNumber)
        );
      } else {
        dispatch(clearSearchCreditNote());

        dispatch(stopSearchingCreditNote());
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
    return dispatch(stopSearchingCreditNote());
  };

export const clearSearchCreditNote = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSearchCreditNote([]));
};

// export const listRelatedInvoices =
//   (appointmentId): AppThunk =>
//   async (dispatch) => {
//     const response = await listRelatedInvoicesAPI(appointmentId);
//     // console.log('searching for invoice');

//     if (!response.customErrorMessage) {
//       if (response.data.listInvoices) {
//         dispatch(slice.actions.listRelatedInvoices(response.data.listInvoices));
//       } else {
//         dispatch(slice.actions.listRelatedInvoices([]));
//       }
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//   };

export default slice;

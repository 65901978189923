import {
  getPaymentModeLabel,
  getPaymentTypeLabel,
  getPaymentStatusLabel
} from './paymentEnums';
import {
  getAppointmentStatuses,
  getAppointmentStatusLabel,
  getAppointmentTypeDetails,
  getClassAppointmentStatusLabel,
  getClassAppointmentBookingAllowedLabel
} from './appointmentEnums';
import { getWalletTransactionTypeLabel } from './walletEnums';
import { getUserTypeLabel, getUserStatusLabel } from './userEnums';
import {
  getLocationTaxStatusLabel,
  getLocationStatusLabel
} from './locationEnums';
import {
  getOnlineBookingPageStatusLabel,
  getOnlineBookingPaymentTypeLabel
} from './onlineBookingPageEnums';
import { getServiceStatusLabel } from './serviceEnums';
import { getPatientPassStatusLabel } from './patientPassEnums';
import { getImageNoteDetails, getStructuredNoteDetails } from './noteEnums';
import { getPromoCodeStatusLabel } from './promoCodeEnums';
import { getCorporateStatusLabel } from './corporateEnums';
import { getPassStatusLabel } from './passEnums';
import { getAppointmentPlanTemplateStatusLabel } from './appointmentPlanTemplateEnums';
import { getAppointmentPlanStatusLabel } from './appointmentPlanEnums';
import { getCommunicationStatusLabel } from './communicationEnums';
import { getOnlineBookingTransactionStatusLabel } from './onlineBookingTransactionEnums';
import { getStationStatusLabel } from './stationEnums';

const enumMapper = {
  payments: { getPaymentModeLabel, getPaymentTypeLabel, getPaymentStatusLabel },
  appointments: {
    getAppointmentStatuses,
    getAppointmentStatusLabel,
    getAppointmentTypeDetails,
    getClassAppointmentStatusLabel,
    getClassAppointmentBookingAllowedLabel
  },
  wallets: { getWalletTransactionTypeLabel },
  users: { getUserTypeLabel, getUserStatusLabel },
  locations: { getLocationTaxStatusLabel, getLocationStatusLabel },
  onlineBookingPages: {
    getOnlineBookingPageStatusLabel,
    getOnlineBookingPaymentTypeLabel
  },
  services: { getServiceStatusLabel },
  patientPasses: { getPatientPassStatusLabel },
  notes: { getImageNoteDetails, getStructuredNoteDetails },
  promoCodes: { getPromoCodeStatusLabel },
  corporates: { getCorporateStatusLabel },
  passes: { getPassStatusLabel },
  appointmentPlanTemplates: { getAppointmentPlanTemplateStatusLabel },
  appointmentPlans: { getAppointmentPlanStatusLabel },
  communications: { getCommunicationStatusLabel },
  onlineBookingTransactions: { getOnlineBookingTransactionStatusLabel },
  stations: { getStationStatusLabel }
};

export default enumMapper;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createPromoCodeAPI,
  updatePromoCodeAPI,
  deletePromoCodeAPI,
  getPromoCodeAPI,
  listPromoCodesAPI,
  listPromoCodeRedemptionsAPI,
  voidPromoCodeRedemptionAPI
} from 'src/content/PromoCodes/api';

interface PromoCodeState {
  isLoadingPromoCodes: boolean;
  isLoadingPromoCodeRedemptions: boolean;
  isEditingPromoCode: boolean;
  isCreatingPromoCode: boolean;
  isDeletingPromoCode: boolean;
  allPromoCodes: any[];
  promoCodeDetail: any[];
  promoCodeRedemptions: any[];
  promoCodeErrorMessage: string;
  promoCodeSuccessMessage: string;
}

const initialState: PromoCodeState = {
  isLoadingPromoCodes: false,
  isLoadingPromoCodeRedemptions: false,
  isEditingPromoCode: false,
  isCreatingPromoCode: false,
  isDeletingPromoCode: false,
  allPromoCodes: [],
  promoCodeDetail: [],
  promoCodeRedemptions: [],
  promoCodeErrorMessage: '',
  promoCodeSuccessMessage: ''
};

const sortPromoCodesDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    createPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.allPromoCodes = [action.payload, ...state.allPromoCodes];
    },

    getPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      if (action.payload) {
        state.promoCodeDetail = [action.payload];
      } else {
        state.promoCodeDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.promoCodeDetail = [];
    },

    listPromoCodes(state: PromoCodeState, action: PayloadAction<any>) {
      let promoCodesSorted = [...action.payload];
      promoCodesSorted.sort(sortPromoCodesDesc);

      state.allPromoCodes = [...promoCodesSorted];

      state.isLoadingPromoCodes = false;
    },

    deletePromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.allPromoCodes = [
        ...state.allPromoCodes.filter((promo) => promo.id !== action.payload.id)
      ];
    },

    editPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      const editedPromoCode = action.payload;

      state.allPromoCodes = [
        ...state.allPromoCodes.map((promo) =>
          promo.id === editedPromoCode.id ? editedPromoCode : promo
        )
      ];

      state.promoCodeDetail =
        state.promoCodeDetail[0]?.id === editedPromoCode.id
          ? [editedPromoCode]
          : [...state.promoCodeDetail];
    },

    listPromoCodeRedemptions(
      state: PromoCodeState,
      action: PayloadAction<any>
    ) {
      let promoCodeRedemptionsSorted = [...action.payload];
      promoCodeRedemptionsSorted.sort(sortPromoCodesDesc);

      state.promoCodeRedemptions = [...promoCodeRedemptionsSorted];
    },

    voidPromoCodeRedemption(state: PromoCodeState, action: PayloadAction<any>) {
      const voidedPromoCodeRedemption = action.payload;

      state.promoCodeRedemptions = [
        ...state.promoCodeRedemptions.map((redemption) =>
          redemption.id === voidedPromoCodeRedemption.id
            ? voidedPromoCodeRedemption
            : redemption
        )
      ];

      const editedPromoCode = state.promoCodeDetail[0]
        ? {
            ...state.promoCodeDetail[0],
            redemptionCount: voidedPromoCodeRedemption?.redemptionCount
          }
        : {};

      // pending BE to patch the redemptionCount issue
      // state.promoCodeDetail =
      //   state.promoCodeDetail[0].id === editedPromoCode.promoCodeId
      //     ? [editedPromoCode]
      //     : [...state.promoCodeDetail];
    },

    setIsLoadingPromoCodes(state: PromoCodeState, action: PayloadAction<any>) {
      state.isLoadingPromoCodes = action.payload;
    },

    setIsEditingPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.isEditingPromoCode = action.payload;
    },

    setIsCreatingPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.isCreatingPromoCode = action.payload;
    },

    setIsDeletingPromoCode(state: PromoCodeState, action: PayloadAction<any>) {
      state.isDeletingPromoCode = action.payload;
    },

    setIsLoadingPromoCodeRedemptions(
      state: PromoCodeState,
      action: PayloadAction<any>
    ) {
      state.isLoadingPromoCodeRedemptions = action.payload;
    },

    setErrorMessage(state: PromoCodeState, action: PayloadAction<any>) {
      state.promoCodeErrorMessage = action.payload;
    },

    setSuccessMessage(state: PromoCodeState, action: PayloadAction<any>) {
      state.promoCodeSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createPromoCode =
  (promoCode): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingPromoCode());
    dispatch(startLoadingPromoCodes());

    const response = await createPromoCodeAPI(promoCode);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createPromoCode(response.data.createPromoCode));
      dispatch(
        slice.actions.setSuccessMessage('Promo Code successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingPromoCode());
      dispatch(stopLoadingPromoCodes());
    };

    return followUp();
  };

export const getPromoCode =
  (promoCodeId): AppThunk =>
  async (dispatch) => {
    const response = await getPromoCodeAPI(promoCodeId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getPromoCode(response.data.getPromoCode));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedPromoCode({}));
};

export const listPromoCodes =
  (validStartTime, validEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPromoCodes());

    const response = await listPromoCodesAPI(validStartTime, validEndTime);

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.listPromoCodes(response.data.listPromoCodes));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingPromoCodes());
  };

export const deletePromoCode =
  (promoCodeId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPromoCodes());
    dispatch(startDeletingPromoCode());

    const response = await deletePromoCodeAPI(promoCodeId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deletePromoCode(response.data.deletePromoCode));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Promo Code successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingPromoCode());
      dispatch(stopLoadingPromoCodes());
    };

    return followUp();
  };

export const editPromoCode =
  (promoCode, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingPromoCode());

    const response = await updatePromoCodeAPI(promoCode);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editPromoCode(response.data.updatePromoCode));

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage('Promo Code successfully updated')
        );
      }
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingPromoCode());
  };

export const listPromoCodeRedemptions =
  (promoCodeId, redemptionStartTime, redemptionEndTime): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPromoCodeRedemptions());

    const response = await listPromoCodeRedemptionsAPI(
      promoCodeId,
      redemptionStartTime,
      redemptionEndTime
    );

    // console.log(response);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.listPromoCodeRedemptions(
          response.data.listPromoCodeRedemptions
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingPromoCodeRedemptions());
  };

export const voidPromoCodeRedemption =
  (promoCodeRedemption, bulkVoid = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingPromoCodeRedemptions());

    const response = await voidPromoCodeRedemptionAPI(promoCodeRedemption);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.voidPromoCodeRedemption(
          response.data.voidPromoCodeRedemption
        )
      );

      if (!bulkVoid) {
        dispatch(
          slice.actions.setSuccessMessage(
            'Promo Code Redemption successfully voided'
          )
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopLoadingPromoCodeRedemptions());
  };

export const startLoadingPromoCodeRedemptions =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingPromoCodeRedemptions(true));
  };

export const stopLoadingPromoCodeRedemptions =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingPromoCodeRedemptions(false));
  };

export const startLoadingPromoCodes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPromoCodes(true));
};

export const stopLoadingPromoCodes = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingPromoCodes(false));
};

export const startEditingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPromoCode(true));
};

export const stopEditingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingPromoCode(false));
};

export const startCreatingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPromoCode(true));
};

export const stopCreatingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingPromoCode(false));
};

export const startDeletingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingPromoCode(true));
};

export const stopDeletingPromoCode = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingPromoCode(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

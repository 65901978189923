import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  createOnlineBookingPageAPI,
  getOnlineBookingPageAPI,
  listOnlineBookingPagesAPI,
  deleteOnlineBookingPageAPI,
  updateOnlineBookingPageAPI
} from 'src/content/OnlineBookingPages/api';

interface OnlineBookingPageState {
  isLoadingOnlineBookingPages: boolean;
  isEditingOnlineBookingPage: boolean;
  isCreatingOnlineBookingPage: boolean;
  isDeletingOnlineBookingPage: boolean;
  onlineBookingPages: any[];
  onlineBookingPageDetail: any[];
  onlineBookingPageErrorMessage: string;
  onlineBookingPageSuccessMessage: string;
}

const initialState: OnlineBookingPageState = {
  isLoadingOnlineBookingPages: false,
  isEditingOnlineBookingPage: false,
  isCreatingOnlineBookingPage: false,
  isDeletingOnlineBookingPage: false,
  onlineBookingPages: [],
  onlineBookingPageDetail: [],
  onlineBookingPageErrorMessage: '',
  onlineBookingPageSuccessMessage: ''
};

// IDEA: Sort the array of comms by sentAt DESCending first before rendering below
const sortOnlineBookingPagesDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'onlineBookingPages',
  initialState,
  reducers: {
    createOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingPages = [action.payload, ...state.onlineBookingPages];
    },

    getOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      if (action.payload) {
        // const onlineBookingPageDetail = action.payload;
        state.onlineBookingPageDetail = [action.payload];
      } else {
        state.onlineBookingPageDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingPageDetail = [];
    },

    listOnlineBookingPages(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      let onlineBookingPagesSorted = [...action.payload];
      onlineBookingPagesSorted.sort(sortOnlineBookingPagesDesc);

      state.onlineBookingPages = [...onlineBookingPagesSorted];

      state.isLoadingOnlineBookingPages = false;
    },

    deleteOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      const delOnlineBookingPage = action.payload;

      let pageIndex = state.onlineBookingPages.findIndex(
        (obj) => obj.id === delOnlineBookingPage.id
      );

      if (pageIndex >= 0) {
        const newList = [...state.onlineBookingPages];
        newList.splice(pageIndex, 1);
        state.onlineBookingPages = [...newList];
      } else {
        state.onlineBookingPages = [...state.onlineBookingPages];
      }
    },

    editOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      const editedOnlineBookingPage = action.payload;

      let pageIndex = state.onlineBookingPages.findIndex(
        (obj) => obj.id === editedOnlineBookingPage.id
      );

      if (pageIndex >= 0) {
        const newList = [...state.onlineBookingPages];
        newList.splice(pageIndex, 1);
        state.onlineBookingPages = [editedOnlineBookingPage, ...newList];
      } else {
        state.onlineBookingPages = [...state.onlineBookingPages];
      }

      state.onlineBookingPageDetail = [editedOnlineBookingPage];
    },

    setIsLoadingOnlineBookingPages(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.isLoadingOnlineBookingPages = action.payload;
    },

    setIsEditingOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.isEditingOnlineBookingPage = action.payload;
    },

    setIsCreatingOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.isCreatingOnlineBookingPage = action.payload;
    },

    setIsDeletingOnlineBookingPage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.isDeletingOnlineBookingPage = action.payload;
    },

    setErrorMessage(state: OnlineBookingPageState, action: PayloadAction<any>) {
      state.onlineBookingPageErrorMessage = action.payload;
    },

    setSuccessMessage(
      state: OnlineBookingPageState,
      action: PayloadAction<any>
    ) {
      state.onlineBookingPageSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const createOnlineBookingPage =
  (onlineBookingPage): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingOnlineBookingPage());
    dispatch(startLoadingOnlineBookingPages());

    const response = await createOnlineBookingPageAPI(onlineBookingPage);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createOnlineBookingPage(
          response.data.createOnlineBookingPage
        )
      );
      dispatch(
        slice.actions.setSuccessMessage(
          'Online Booking Page successfully created'
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingOnlineBookingPage());
      dispatch(stopLoadingOnlineBookingPages());
    };

    return followUp();
  };

export const getOnlineBookingPage =
  (onlineBookingPageId): AppThunk =>
  async (dispatch) => {
    const response = await getOnlineBookingPageAPI(onlineBookingPageId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.getOnlineBookingPage(response.data.getOnlineBookingPage)
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.clearSelectedOnlineBookingPage({}));
  };

export const listOnlineBookingPages = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingOnlineBookingPages());

  const response = await listOnlineBookingPagesAPI();
  // console.log(response);

  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.listOnlineBookingPages(response.data.listOnlineBookingPages)
    );
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingOnlineBookingPages());
};

export const deleteOnlineBookingPage =
  (onlineBookingPageId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingOnlineBookingPages());
    dispatch(startDeletingOnlineBookingPage());

    const response = await deleteOnlineBookingPageAPI(onlineBookingPageId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteOnlineBookingPage(
          response.data.deleteOnlineBookingPage
        )
      );
      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage(
            'Online Booking Page successfully deleted'
          )
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingOnlineBookingPage());
      dispatch(stopLoadingOnlineBookingPages());
    };

    return followUp();
  };

export const editOnlineBookingPage =
  (onlineBookingPage, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingOnlineBookingPage());

    const response = await updateOnlineBookingPageAPI(onlineBookingPage);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editOnlineBookingPage(
          response.data.updateOnlineBookingPage
        )
      );

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage(
            'Online Booking Page successfully updated'
          )
        );
      }
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingOnlineBookingPage());
  };

export const startLoadingOnlineBookingPages =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOnlineBookingPages(true));
  };

export const stopLoadingOnlineBookingPages =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingOnlineBookingPages(false));
  };

export const startEditingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsEditingOnlineBookingPage(true));
  };

export const stopEditingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsEditingOnlineBookingPage(false));
  };

export const startCreatingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingOnlineBookingPage(true));
  };

export const stopCreatingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsCreatingOnlineBookingPage(false));
  };

export const startDeletingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsDeletingOnlineBookingPage(true));
  };

export const stopDeletingOnlineBookingPage =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsDeletingOnlineBookingPage(false));
  };

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

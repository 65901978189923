import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import type { AppThunk } from '../store';

import {
  listAvailableClassServicesAPI,
  createClassServiceAPI,
  getClassServiceAPI,
  deleteClassServiceAPI,
  updateClassServiceAPI
} from 'src/content/ClassServices/api';

interface ClassServiceState {
  isLoadingClassServices: boolean;
  isEditingClassService: boolean;
  isCreatingClassService: boolean;
  isDeletingClassService: boolean;
  allClassServicesList: any[];
  activeClassServicesList: any[];
  classServiceDetail: any[];
  classServiceErrorMessage: string;
  classServiceSuccessMessage: string;
}

const initialState: ClassServiceState = {
  isLoadingClassServices: false,
  isEditingClassService: false,
  isCreatingClassService: false,
  isDeletingClassService: false,
  allClassServicesList: [],
  activeClassServicesList: [],
  classServiceDetail: [],
  classServiceErrorMessage: '',
  classServiceSuccessMessage: ''
};

const slice = createSlice({
  name: 'classServices',
  initialState,
  reducers: {
    getAllClassServices(state: ClassServiceState, action: PayloadAction<any>) {
      const allClassServices = action.payload;

      // sort DESC by category and then ASC by name
      const sortClassServices = (a, b) => {
        if (a.category > b.category) {
          return -1;
        }
        if (a.category < b.category) {
          return 1;
        } else {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }
      };

      allClassServices.sort(sortClassServices);

      state.allClassServicesList = [...allClassServices];
      state.activeClassServicesList = [
        ...allClassServices.filter((service) => service.isActive === true)
      ];
    },

    createClassService(state: ClassServiceState, action: PayloadAction<any>) {
      const newClassService = action.payload;

      state.allClassServicesList = [
        newClassService,
        ...state.allClassServicesList
      ];

      if (newClassService.isActive) {
        state.activeClassServicesList = [
          newClassService,
          ...state.activeClassServicesList
        ];
      }
    },

    getClassService(state: ClassServiceState, action: PayloadAction<any>) {
      if (action.payload) {
        // const service = action.payload;
        state.classServiceDetail = [action.payload];
      } else {
        state.classServiceDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedClassService(
      state: ClassServiceState,
      action: PayloadAction<any>
    ) {
      state.classServiceDetail = [];
    },

    deleteClassService(state: ClassServiceState, action: PayloadAction<any>) {
      const delService = action.payload;

      let svcIndex = state.allClassServicesList.findIndex(
        (obj) => obj.id === delService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.allClassServicesList];
        newList.splice(svcIndex, 1);
        state.allClassServicesList = [...newList];
      } else {
        state.allClassServicesList = [...state.allClassServicesList];
      }

      // Repeat the same for activeServicesList
      svcIndex = state.activeClassServicesList.findIndex(
        (obj) => obj.id === delService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.activeClassServicesList];
        newList.splice(svcIndex, 1);
        state.activeClassServicesList = [...newList];
      } else {
        state.activeClassServicesList = [...state.activeClassServicesList];
      }
    },

    editClassService(state: ClassServiceState, action: PayloadAction<any>) {
      const editedClassService = action.payload;

      let svcIndex = state.allClassServicesList.findIndex(
        (obj) => obj.id === editedClassService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.allClassServicesList];
        newList.splice(svcIndex, 1);
        state.allClassServicesList = [editedClassService, ...newList];
      } else {
        state.allClassServicesList = [...state.allClassServicesList];
      }

      // Repeat code
      svcIndex = state.activeClassServicesList.findIndex(
        (obj) => obj.id === editedClassService.id
      );

      if (svcIndex >= 0) {
        const newList = [...state.activeClassServicesList];
        newList.splice(svcIndex, 1);
        state.activeClassServicesList = [editedClassService, ...newList];
      } else {
        state.activeClassServicesList = [...state.activeClassServicesList];
      }

      state.classServiceDetail = [editedClassService];
    },

    setIsLoadingClassServices(
      state: ClassServiceState,
      action: PayloadAction<any>
    ) {
      state.isLoadingClassServices = action.payload;
    },

    setIsEditingClassService(
      state: ClassServiceState,
      action: PayloadAction<any>
    ) {
      state.isEditingClassService = action.payload;
    },

    setIsCreatingClassService(
      state: ClassServiceState,
      action: PayloadAction<any>
    ) {
      state.isCreatingClassService = action.payload;
    },

    setIsDeletingClassService(
      state: ClassServiceState,
      action: PayloadAction<any>
    ) {
      state.isDeletingClassService = action.payload;
    },

    setErrorMessage(state: ClassServiceState, action: PayloadAction<any>) {
      state.classServiceErrorMessage = action.payload;
    },

    setSuccessMessage(state: ClassServiceState, action: PayloadAction<any>) {
      state.classServiceSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getAllClassServices = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingClassServices());

  const response = await backOff(() => listAvailableClassServicesAPI(), {
    numOfAttempts: 3
  });
  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.getAllClassServices(response.data.listClassServices)
    );
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingClassServices());
};

export const createClassService =
  (classService): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingClassService());
    dispatch(startLoadingClassServices());

    const response = await createClassServiceAPI(classService);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.createClassService(response.data.createClassService)
      );
      dispatch(
        slice.actions.setSuccessMessage('Class Service successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingClassService());
      dispatch(stopLoadingClassServices());
    };

    return followUp();
  };

export const getClassService =
  (classServiceId): AppThunk =>
  async (dispatch) => {
    const response = await getClassServiceAPI(classServiceId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getClassService(response.data.getClassService));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedClassService({}));
};

export const deleteClassService =
  (classServiceId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startDeletingClassService());
    dispatch(startLoadingClassServices());

    const response = await deleteClassServiceAPI(classServiceId);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.deleteClassService(response.data.deleteClassService)
      );

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Class Service successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingClassService());
      dispatch(stopLoadingClassServices());
    };

    return followUp();
  };

export const editClassService =
  (classService, bulkEdit = false): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingClassService());

    const response = await updateClassServiceAPI(classService);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editClassService(response.data.updateClassService)
      );

      if (!bulkEdit) {
        dispatch(
          slice.actions.setSuccessMessage('Class Service successfully updated')
        );
      }
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingClassService());
  };

export const startLoadingClassServices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingClassServices(true));
};

export const stopLoadingClassServices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingClassServices(false));
};

export const startEditingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingClassService(true));
};

export const stopEditingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingClassService(false));
};

export const startCreatingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingClassService(true));
};

export const stopCreatingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingClassService(false));
};

export const startDeletingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingClassService(true));
};

export const stopDeletingClassService = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingClassService(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import {
  listPatientCommunicationsAPI,
  listCommunicationsInDateRangeAPI
} from 'src/content/Communications/api';
import {
  getCorporateAPI,
  getCorporatePricingAPI,
  listCorporatesAPI,
  listCorporatesByCategoryAPI,
  createCorporateAPI,
  deleteCorporateAPI,
  updateCorporateAPI,
  listCorporatesOnLoadAPI
} from 'src/content/Corporates/api';

interface CorporateState {
  isLoadingCorporates: boolean;
  isEditingCorporate: boolean;
  isCreatingCorporate: boolean;
  isDeletingCorporate: boolean;
  allCorporates: any[];
  insuranceOptions: any[];
  discountList: any[];
  specialPriceList: any[];
  restrictionList: any[];
  corporateDetail: any[];
  patientApplicableSchemes: any[];
  corporateErrorMessage: string;
  corporateSuccessMessage: string;
}

const initialState: CorporateState = {
  isLoadingCorporates: false,
  isEditingCorporate: false,
  isCreatingCorporate: false,
  isDeletingCorporate: false,
  allCorporates: [],
  insuranceOptions: [],
  discountList: [],
  specialPriceList: [],
  restrictionList: [],
  corporateDetail: [],
  patientApplicableSchemes: [],
  corporateErrorMessage: '',
  corporateSuccessMessage: ''
};

// Sort corporate names ascending
const sortCorporateCodesAsc = (a, b) => {
  if (a.code < b.code) {
    return -1;
  }
  if (a.code > b.code) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'corporates',
  initialState,
  reducers: {
    listCorporates(state: CorporateState, action: PayloadAction<any>) {
      const allCorporatesUnsorted = [...action.payload];
      const allCorporatesSorted = allCorporatesUnsorted.sort(
        sortCorporateCodesAsc
      );
      // console.log(allCorporatesSorted);

      state.allCorporates = [...allCorporatesSorted];

      state.isLoadingCorporates = false;
    },

    listInsuranceOptions(state: CorporateState, action: PayloadAction<any>) {
      // const insuranceCorporates = [...action.payload];
      // state.insuranceOptions = insuranceCorporates.map((corp) => ({
      //   label: corp.code,
      //   value: corp.id
      // }));

      const insuranceCorporatesUnsorted = [...action.payload];
      const insuranceCorporatesSorted = insuranceCorporatesUnsorted.sort(
        sortCorporateCodesAsc
      );

      const allInsuranceCorps = insuranceCorporatesSorted.map((corp) => ({
        label: corp.code,
        value: corp.id,
        isActive: corp.isActive
      }));

      state.insuranceOptions = [
        ...allInsuranceCorps.filter((opt) => opt.isActive),
        ...allInsuranceCorps.filter((opt) => !opt.isActive)
      ];
    },

    createCorporate(state: CorporateState, action: PayloadAction<any>) {
      state.allCorporates = [action.payload, ...state.allCorporates];
    },

    addDiscount(state: CorporateState, action: PayloadAction<any>) {
      const newItem = action.payload;

      state.discountList = [...state.discountList, newItem];
    },

    deleteDiscount(state: CorporateState, action: PayloadAction<any>) {
      const itemIndex = action.payload;
      const newList = [...state.discountList];
      newList.splice(itemIndex, 1);

      state.discountList = [...newList];
    },

    clearDiscount(state: CorporateState, action: PayloadAction<any>) {
      state.discountList = [];
    },

    addSpecialPricing(state: CorporateState, action: PayloadAction<any>) {
      const newItem = action.payload;

      state.specialPriceList = [...state.specialPriceList, newItem];
    },

    deleteSpecialPricing(state: CorporateState, action: PayloadAction<any>) {
      const itemIndex = action.payload;
      const newList = [...state.specialPriceList];
      newList.splice(itemIndex, 1);

      state.specialPriceList = [...newList];
    },

    clearSpecialPricing(state: CorporateState, action: PayloadAction<any>) {
      state.specialPriceList = [];
    },

    addRestriction(state: CorporateState, action: PayloadAction<any>) {
      const newItem = action.payload;

      state.restrictionList = [...state.restrictionList, newItem];
    },

    deleteRestriction(state: CorporateState, action: PayloadAction<any>) {
      const itemIndex = action.payload;
      const newList = [...state.restrictionList];
      newList.splice(itemIndex, 1);

      state.restrictionList = [...newList];
    },

    clearRestriction(state: CorporateState, action: PayloadAction<any>) {
      state.restrictionList = [];
    },

    deleteCorporate(state: CorporateState, action: PayloadAction<any>) {
      const delCorporate = action.payload;

      let corpIndex = state.allCorporates.findIndex(
        (obj) => obj.id === delCorporate.id
      );

      if (corpIndex >= 0) {
        const newList = [...state.allCorporates];
        newList.splice(corpIndex, 1);
        state.allCorporates = [...newList];
      } else {
        state.allCorporates = [...state.allCorporates];
      }
    },

    editCorporate(state: CorporateState, action: PayloadAction<any>) {
      const editedCorporate = action.payload;

      let corpIndex = state.allCorporates.findIndex(
        (obj) => obj.id === editedCorporate.id
      );

      if (corpIndex >= 0) {
        const newList = [...state.allCorporates];
        newList.splice(corpIndex, 1);
        state.allCorporates = [editedCorporate, ...newList];
      } else {
        state.allCorporates = [...state.allCorporates];
      }

      state.corporateDetail = [editedCorporate];
    },

    editCorporateSpecialPricing(
      state: CorporateState,
      action: PayloadAction<any>
    ) {
      const editedCorporate = action.payload;

      let corpIndex = state.allCorporates.findIndex(
        (obj) => obj.id === editedCorporate.id
      );

      const originalCorporate = state.corporateDetail[0];

      const finalCorporate = {
        ...originalCorporate,
        specialPricing: editedCorporate.specialPricing
      };

      if (corpIndex >= 0) {
        const newList = [...state.allCorporates];
        newList.splice(corpIndex, 1);
        state.allCorporates = [finalCorporate, ...newList];
      } else {
        state.allCorporates = [...state.allCorporates];
      }

      state.corporateDetail = [finalCorporate];
    },

    getCorporate(state: CorporateState, action: PayloadAction<any>) {
      if (action.payload) {
        state.corporateDetail = [action.payload];
      } else {
        state.corporateDetail = [{ id: 'Not Found' }];
      }
    },

    clearSelectedCorporate(state: CorporateState, action: PayloadAction<any>) {
      state.corporateDetail = [];
    },

    getCorporatePricing(state: CorporateState, action: PayloadAction<any>) {
      state.patientApplicableSchemes = [...action.payload];
    },

    clearCorporatePricing(state: CorporateState, action: PayloadAction<any>) {
      state.patientApplicableSchemes = [];
    },

    setIsLoadingCorporates(state: CorporateState, action: PayloadAction<any>) {
      state.isLoadingCorporates = action.payload;
    },

    setIsEditingCorporate(state: CorporateState, action: PayloadAction<any>) {
      state.isEditingCorporate = action.payload;
    },

    setIsCreatingCorporate(state: CorporateState, action: PayloadAction<any>) {
      state.isCreatingCorporate = action.payload;
    },

    setIsDeletingCorporate(state: CorporateState, action: PayloadAction<any>) {
      state.isDeletingCorporate = action.payload;
    },

    setErrorMessage(state: CorporateState, action: PayloadAction<any>) {
      state.corporateErrorMessage = action.payload;
    },

    setSuccessMessage(state: CorporateState, action: PayloadAction<any>) {
      state.corporateSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listCorporatesOnLoad = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingCorporates());

  const response = await listCorporatesOnLoadAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listCorporates(response.data.listCorporates));

    dispatch(listInsuranceOptions());
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }

  return dispatch(stopLoadingCorporates());
};

export const listCorporates = (): AppThunk => async (dispatch) => {
  const response = await listCorporatesAPI();

  if (!response.customErrorMessage) {
    dispatch(slice.actions.listCorporates(response.data.listCorporates));
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const listInsuranceOptions = (): AppThunk => async (dispatch) => {
  const response = await listCorporatesByCategoryAPI('Insurance');

  if (!response.customErrorMessage) {
    dispatch(
      slice.actions.listInsuranceOptions(response.data.listCorporatesByCategory)
    );
  } else {
    dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  }
};

export const createCorporate =
  (corporate): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingCorporate());
    dispatch(startLoadingCorporates());

    const response = await createCorporateAPI(corporate);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.createCorporate(response.data.createCorporate));
      dispatch(
        slice.actions.setSuccessMessage('Corporate successfully created')
      );
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopCreatingCorporate());
      dispatch(stopLoadingCorporates());
    };

    return followUp();
  };

export const addDiscount =
  (discount): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addDiscount(discount));
  };

export const deleteDiscount =
  (itemIndex): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.deleteDiscount(itemIndex));
  };

export const clearDiscount = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearDiscount([]));
};

export const addSpecialPricing =
  (special): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addSpecialPricing(special));
  };

export const deleteSpecialPricing =
  (itemIndex): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.deleteSpecialPricing(itemIndex));
  };

export const clearSpecialPricing = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSpecialPricing([]));
};

export const addRestriction =
  (restriction): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addRestriction(restriction));
  };

export const deleteRestriction =
  (itemIndex): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.deleteRestriction(itemIndex));
  };

export const clearRestriction = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearRestriction([]));
};

export const deleteCorporate =
  (corporateId, bulkDelete = false): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCorporates());
    dispatch(startDeletingCorporate());

    const response = await deleteCorporateAPI(corporateId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.deleteCorporate(response.data.deleteCorporate));

      if (!bulkDelete) {
        dispatch(
          slice.actions.setSuccessMessage('Corporate successfully deleted')
        );
      }
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    const followUp = () => {
      dispatch(stopDeletingCorporate());
      dispatch(stopLoadingCorporates());
    };

    return followUp();
  };

export const getCorporate =
  (corporateId): AppThunk =>
  async (dispatch) => {
    const response = await getCorporateAPI(corporateId);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.getCorporate(response.data.getCorporate));
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const clearSelectedCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearSelectedCorporate({}));
};

export const getCorporatePricing =
  (corporateIds): AppThunk =>
  async (dispatch) => {
    let resultArray = [];
    let errorArray = [];

    for (const corpId of corporateIds) {
      const response = await getCorporatePricingAPI(corpId);

      if (!response.customErrorMessage) {
        resultArray.push(response.data.getCorporate);
      } else {
        errorArray.push(response.customErrorMessage);
      }
    }

    if (errorArray.length === 0) {
      dispatch(
        slice.actions.getCorporatePricing(
          resultArray.filter((element) => element.isActive === true)
        )
      );
    } else {
      dispatch(slice.actions.setErrorMessage(errorArray[0]));
    }
  };

export const editCorporate =
  (corporate): AppThunk =>
  async (dispatch) => {
    const response = await updateCorporateAPI(corporate);

    if (!response.customErrorMessage) {
      dispatch(slice.actions.editCorporate(response.data.updateCorporate));
      dispatch(
        slice.actions.setSuccessMessage('Corporate successfully updated')
      );
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }
  };

export const editCorporateSpecialPricing =
  (corporate): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingCorporate());

    const response = await updateCorporateAPI(corporate);

    if (!response.customErrorMessage) {
      dispatch(
        slice.actions.editCorporateSpecialPricing(response.data.updateCorporate)
      );
      dispatch(
        slice.actions.setSuccessMessage('Corporate successfully updated')
      );
      // return window.location.reload();
    } else {
      dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    }

    return dispatch(stopEditingCorporate());
  };

export const clearCorporatePricing = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearCorporatePricing(''));
};

export const startLoadingCorporates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCorporates(true));
};

export const stopLoadingCorporates = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCorporates(false));
};

export const startEditingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCorporate(true));
};

export const stopEditingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCorporate(false));
};

export const startCreatingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCorporate(true));
};

export const stopCreatingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCorporate(false));
};

export const startDeletingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingCorporate(true));
};

export const stopDeletingCorporate = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsDeletingCorporate(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;

import callGraphQL, { subscribeGraphQL } from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  CreateAppointmentPlanMutation,
  ListCustomerAppointmentPlansQuery,
  GetAppointmentPlanQuery
} from 'src/API';
import {
  createAppointmentPlan,
  updateAppointmentPlan,
  deleteAppointmentPlan
} from 'src/graphql/mutations';
import {
  getAppointmentPlan,
  listCustomerAppointmentPlans
} from 'src/graphql/queries';

export const getAppointmentPlanAPI = async (planId) => {
  try {
    const plan = await callGraphQL<GetAppointmentPlanQuery>(
      getAppointmentPlan,
      {
        variables: {
          id: planId
        }
      }
    );
    // console.log(plan);
    return plan;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listCustomerAppointmentPlansAPI = async (
  customerId,
  minStartTime?,
  maxStartTime?
) => {
  try {
    const plans = await callGraphQL<ListCustomerAppointmentPlansQuery>(
      listCustomerAppointmentPlans,
      {
        variables: {
          customerId: customerId,
          minStartTime: minStartTime,
          maxStartTime: maxStartTime
        }
      }
    );
    // console.log(plans);
    return plans;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const listTemplateAppointmentPlansAPI = async (
  appointmentPlanId,
  minCreatedTime?,
  maxCreatedTime?
) => {
  try {
    const plans = await callGraphQL<ListCustomerAppointmentPlansQuery>(
      listCustomerAppointmentPlans,
      {
        variables: {
          templateId: appointmentPlanId,
          minCreatedTime: minCreatedTime,
          maxCreatedTime: maxCreatedTime
        }
      }
    );
    // console.log(plans);
    return plans;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const createAppointmentPlanAPI = async (plan) => {
  try {
    const newPlan = await callGraphQL<CreateAppointmentPlanMutation>(
      createAppointmentPlan,
      {
        variables: {
          input: plan
        }
      }
    );
    // console.log(newPlan);
    return newPlan;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateAppointmentPlanAPI = async (plan) => {
  try {
    const updatedPlan = await callGraphQL<CreateAppointmentPlanMutation>(
      updateAppointmentPlan,
      {
        variables: {
          input: plan
        }
      }
    );
    // console.log(updatedPlan);
    return updatedPlan;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const deleteAppointmentPlanAPI = async (planId) => {
  try {
    const deletedPlan = await callGraphQL<CreateAppointmentPlanMutation>(
      deleteAppointmentPlan,
      {
        variables: {
          id: planId
        }
      }
    );
    // console.log(deletedPlan);
    return deletedPlan;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const getWalletTransactionTypeLabel = (
  walletTransactionType: string
): String => {
  try {
    const map = {
      Deposit: {
        text: 'Deposit'
      },
      Withdrawal: {
        text: 'Withdrawal'
      },
      Refund: {
        text: 'Refund'
      },
      Spend: {
        text: 'Spend'
      }
    };

    const { text }: any = map[walletTransactionType];

    return text;
  } catch {
    return 'Unknown';
  }
};

export const customCreatePromoCode = /* GraphQL */ `
  mutation CreatePromoCode($input: CreatePromoCodeInput!) {
    createPromoCode(input: $input) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
        amount {
          amount
          currency
          precision
          scale
        }
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpdatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode($input: UpdatePromoCodeInput!) {
    updatePromoCode(input: $input) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
        amount {
          amount
          currency
          precision
          scale
        }
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const customDeletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode($id: ID!) {
    deletePromoCode(id: $id) {
      id
      code
      type
      name
      description
      applicableLocationIds
      applicableServiceIds
      applicableClassServiceIds
      applicableInventoryIds
      startTime
      endTime
      isActive
      promoCodeValue {
        type
        amount {
          amount
          currency
          precision
          scale
        }
      }
      maxTotalRedemptions
      maxPerCustomerRedemptions
      currentRedemptions
      ruleTemplateType
      ruleTemplateVariables
      rules
      isStackable
      stackingPriorityLevel
      contactId
      corporateId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customVoidPromoCodeRedemption = /* GraphQL */ `
  mutation VoidPromoCodeRedemption($id: ID!, $reason: String!) {
    voidPromoCodeRedemption(id: $id, reason: $reason) {
      id
      promoCodeId
      promoCodeCode
      promoCodeType
      locationId
      customerId
      customerName
      redemptionCount
      # onlineBookingPageId
      # onlineBookingTransactionId
      # appointmentId
      invoiceId
      invoiceNumber
      providerId
      providerName
      serviceId
      serviceName
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidedReason
    }
  }
`;

export const listOfficeLocationsOnLoad = /* GraphQL */ `
  query ListOfficeLocations {
    listOfficeLocations {
      id
      name
      code
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      taxStatus
      registeredName
      registrationNumber
      operatingHours
      telephones
      whatsapp
      emails
      isActive
      quickbooks {
        isEnabled
      }
      billingAddresses {
        streetAddress
        unitNo
        country
        postalCode
        startDate
        endDate
      }
    }
  }
`;

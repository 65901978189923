export const customGetCreditNote = /* GraphQL */ `
  query GetCreditNote($id: ID!) {
    getCreditNote(id: $id) {
      id
      locationId
      customerId
      customer {
        id
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
        unitPrice {
          amount
          currency
          precision
          scale
        }
        preTaxTotal {
          amount
          currency
          precision
          scale
        }
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customListCreditNotes = /* GraphQL */ `
  query ListCreditNotes(
    $locationId: ID
    $customerId: ID
    $invoiceId: ID
    $effectiveStartTime: AWSDateTime
    $effectiveEndTime: AWSDateTime
  ) {
    listCreditNotes(
      locationId: $locationId
      customerId: $customerId
      invoiceId: $invoiceId
      effectiveStartTime: $effectiveStartTime
      effectiveEndTime: $effectiveEndTime
    ) {
      id
      locationId
      customerId
      customer {
        id
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
        unitPrice {
          amount
          currency
          precision
          scale
        }
        preTaxTotal {
          amount
          currency
          precision
          scale
        }
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;



export const customListShortCreditNotesInDateRange = /* GraphQL */ `
  query ListCreditNotes(
    $locationId: ID
    $customerId: ID
    $invoiceId: ID
    $effectiveStartTime: AWSDateTime
    $effectiveEndTime: AWSDateTime
  ) {
    listCreditNotes(
      locationId: $locationId
      customerId: $customerId
      invoiceId: $invoiceId
      effectiveStartTime: $effectiveStartTime
      effectiveEndTime: $effectiveEndTime
    ) {
      id
      locationId
      customerId
      customer {
        id
        piiProfile {
          fullName
        }
      }
      invoiceId
      effectiveAt
      refundPaymentId
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;



export const customGetCreditNoteByNumber = /* GraphQL */ `
  query GetCreditNoteByNumber($noteNumber: String!) {
    getCreditNoteByNumber(noteNumber: $noteNumber) {
      id
      locationId
      customerId
      customer {
        id
        piiProfile {
          preferredName
          fullName
          email
          identityNumber
          addressStreetAddress
          addressUnitNo
          addressPostalCode
          addressCountry
          employer
        }
      }
      invoiceId
      effectiveAt
      refundPaymentId
      items {
        id
        creditNoteId
        invoiceId
        invoiceItemId
        name
        description
        quantity
        unitPrice {
          amount
          currency
          precision
          scale
        }
        preTaxTotal {
          amount
          currency
          precision
          scale
        }
      }
      reason
      note
      status
      creditNoteNumber
      totalPreTaxAmount {
        amount
        currency
        precision
        scale
      }
      taxRateUsed {
        amount
        currency
        precision
        scale
      }
      taxAmount {
        amount
        currency
        precision
        scale
      }
      totalAmountDue {
        amount
        currency
        precision
        scale
      }
      type
      voidedAt
      voidedBy
      voidedReason
      voidEffectiveAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
import { Box, Grid, Stack, Skeleton, Typography } from '@mui/material';

function ResultsLoader({ dataset = 'records' }) {
  return (
    <Grid container rowGap={1} columnSpacing={3} sx={{ px: 2 }}>
      <Grid item xs={12}>
        <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
      </Grid>

      <Grid container item xs={12} columnSpacing={2}>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
      </Grid>

      <Grid container item xs={12} columnSpacing={2}>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={9}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
      </Grid>

      <Grid container item xs={12} columnSpacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
      </Grid>

      <Grid container item xs={12} columnSpacing={2}>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
      </Grid>

      <Grid container item xs={12} columnSpacing={2}>
        <Grid item xs={5}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        </Grid>
      </Grid>

      <Grid item xs={8}></Grid>
      <Grid item xs={4}>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </Grid>
    </Grid>
  );
}

export default ResultsLoader;

import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';

import HeaderLockScreen from './LockScreen';
import FlowAppLauncher from './FlowAppLauncher';

import tenantMappings from 'src/utils/tenantMappings';

const integrationMappings = tenantMappings?.integrationMappings;

function HeaderButtons() {
  const auth = useAuth();
  const tenantIdLowerCase = auth?.user?.tenantId?.toLowerCase();

  const isFlowEnabled =
    integrationMappings?.isFlowIntegrated(tenantIdLowerCase) || false;

  return (
    <Box>
      {/* <HeaderNotifications />
      <LanguageSwitcher /> */}

      {isFlowEnabled ? <FlowAppLauncher /> : null}

      <HeaderLockScreen />
    </Box>
  );
}

export default HeaderButtons;

export const getLocationTaxStatusLabel = (
  locationTaxStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      GST: {
        text: 'GST-Registered',
        color: 'primary'
      },
      noGST: {
        text: 'Not GST-Registered',
        color: 'warning'
      }
    };

    const { text, color }: any = map[locationTaxStatus];

    return { text, color };
  } catch {
    return { text: 'Unknown', color: 'error' };
  }
};

export const getLocationStatusLabel = (
  locationStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Active: {
        text: 'ACTIVE',
        color: 'primary'
      },
      Inactive: {
        text: 'INACTIVE',
        color: 'error'
      }
    };

    const { text, color }: any = map[locationStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};

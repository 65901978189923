export const customPutResourceScheduleDay = /* GraphQL */ `
  mutation PutResourceScheduleDay($input: PutResourceScheduleDayInput!) {
    putResourceScheduleDay(input: $input) {
      id
      resourceId
      resourceType
      year
      days {
        day
        slots {
          startTime
          endTime
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const customUpsertResourceSchedule = /* GraphQL */ `
  mutation UpsertResourceSchedule($input: UpsertResourceScheduleInput!) {
    upsertResourceSchedule(input: $input) {
      id
      resourceId
      resourceType
      year
      days {
        day
        slots {
          startTime
          endTime
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
